import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import makeStyles from "@material-ui/styles/makeStyles";

import img from "../../res/images/suspendimg.png";
import config from "../../config";

const useStyles = makeStyles((theme) => ({
    avatar: {
        width: theme.spacing(12),
        height: theme.spacing(12),
        borderRadius: 0
    }
}));
export default function Suspend() {
    const classes = useStyles();
    return (
        <Box height={"100%"}>
            <Grid
                container
                alignItems="center"
                justify="center"
                direction="row"
                style={{ minHeight: "100vh" }}
            >
                <Grid item xs={4}>
                    <Box display={"flex"}>
                        <Avatar
                            className={classes.avatar}
                            src={img}
                            sizes={"large"}
                        ></Avatar>
                        <Box ml={2}>
                            <Typography variant="h4" color="textPrimary">
                                The account has been suspended
                            </Typography>
                            <br />
                            <Button
                                onClick={() =>
                                    (window.location.href = config.routes.login)
                                }
                            >
                                {">> Back to home <<"}{" "}
                            </Button>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
}
