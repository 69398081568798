/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { Box } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import config from "../../../../config";
import actionTypes from "../../../../redux/actionTypes";
import userService from "../../../../services/user";
import { useSnackbar } from "notistack";
import AccountDesignUI from "../../components/AccountDesignUI";

export default function EditAccountDesign() {
    const { enqueueSnackbar } = useSnackbar();
    // const globalClasses = useGlobalStyles();
    const user = useSelector((state) => state.auth.user);
    const baseAccountSettings = user.baseAccountSettings;
    const dispatch = useDispatch();

    const [isLoading, setIsLoading] = useState(false);
    const isManagedDesignActive = user?.managedBy?.isManagedDesignActive;

    const handleAccountDesign = ({ accountShape, accountStyle }) => {
        if (!isManagedDesignActive) {
            setIsLoading(true);
            userService
                .updateAccountSettings({
                    userId: user._id,
                    accountShape,
                    accountStyle
                })
                .then((res) => {
                    enqueueSnackbar(config.defaultNotiStackMessage, {
                        variant: "success"
                    });

                    const baseAccountSettings = res.data.baseAccountSettings;
                    dispatch({
                        type: actionTypes.UPDATE_USER,
                        payload: { baseAccountSettings }
                    });
                    setIsLoading(false);
                })
                .catch((ex) => {
                    enqueueSnackbar(ex?.response?.data?.message, {
                        variant: "error"
                    });

                    setIsLoading(false);
                });
        }
    };

    return (
        <Box>
            <AccountDesignUI
                isLoading={isLoading}
                accountShape={baseAccountSettings?.accountShape}
                accountStyle={baseAccountSettings?.accountStyle}
                onAccountDesignUpdate={handleAccountDesign}
            />
        </Box>
    );
}
