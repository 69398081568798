import { Box, Grid, Typography } from "@material-ui/core";
// import clsx from "clsx";
import React from "react";
import useGlobalStyles from "../../hooks/useGlobalStyles";

export default function Beta() {
    const globalClasses = useGlobalStyles();
    return (
        <Grid item md={12} xs={12}>
            <Box textAlign={"center"} lineHeight={1.8}>
                <Typography
                    variant="caption"
                    color="textSecondary"
                    // align="center"
                    style={{ fontSize: 14 }}
                >
                    Base is currently in beta, we are working to bring you new
                    features as quickly as possible. You can help us by
                    <br />
                    <a
                        className={globalClasses.aTagText}
                        href="https://help.base.gg/support/tickets/new"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        providing feedback on your experience!
                    </a>
                </Typography>
            </Box>
        </Grid>
    );
}
