import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Switch, Route, Redirect, useHistory } from "react-router-dom";
import AdminRoutes from "./components/Routes/AdminRoutes";
import AppUserRoutes from "./components/Routes/AppUserRoutes";
import PublicRoutes from "./components/Routes/PublicRoutes";
import InCompleteUserRoute from "./components/Routes/InCompleteUserRoute";
import config from "./config";
import UsersList from "./pages/admin/UsersList";
import AdminDashboard from "./pages/admin/AdminDashboard";
import Authenticate from "./pages/auth/Authenticate";
import Dashboard from "./pages/user/dashboard";
import Settings from "./pages/user/settings/Settings";
// import Login from "./pages/auth/Login";
import CompleteRegistation from "./pages/auth/CompleteRegistration";
import EditUser from "./pages/admin/EditUser";
import BannedUrls from "./pages/admin/BannedUrls";
import Accounts from "./pages/admin/Accounts/index";
import axios from "./config/axios";
import auth from "./services/auth";
import actionTypes from "./redux/actionTypes";
import EditProfile from "./pages/user/editProfile/EditProfile";
import Profile from "./pages/user/Profile/Profile";
import PublicUser from "./pages/public/PublicUser";
import TermsOfService from "./pages/public/TermsOfService";
import Privacy from "./pages/public/Privacy";
import PageNotFound from "./pages/public/PageNotFound";
import Error from "./pages/public/Error";
import Suspend from "./pages/public/Suspend";
import CustomAccounts from "./pages/admin/CustomAccounts";
import UserSurvey from "./pages/admin/UserSurvey";
import AccountCategoryList from "./pages/admin/AccountCategory/AccountCategoryList";
import Integrations from "./pages/admin/Integrations";
import management from "./services/management";
import { Avatar, Box, Grid } from "@material-ui/core";
// import EditAccounts from "./pages/admin/EditAccounts";
import baseIcon from "./res/images/base-icon1.png";
import { useSnackbar } from "notistack";
import LandingPage from "./pages/auth/LandingPage/index";
import Management from "./pages/user/management";

export default function AppRouter() {
    const dispatch = useDispatch();
    const history = useHistory();
    const currentUser = useSelector((state) => state?.auth?.currentUser);
    const token = useSelector((state) => state?.auth?.token);
    const isTokenExpired = useSelector((state) => state?.auth?.isTokenExpired);
    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(true);
    axios.setHeaders(token);

    useEffect(() => {
        if (isTokenExpired) {
            dispatch({
                type: actionTypes.SET_AUTH,
                payload: { auth: {} }
            });
            history.push(config.routes.login);

            enqueueSnackbar("Session has been expired please signin again.", {
                variant: "info"
            });
        }
        // eslint-disable-next-line
    }, [isTokenExpired]);

    useEffect(() => {
        const getUserManagement = () => {
            if (currentUser.userType !== config.userType.admin) {
                management
                    .getManagement()
                    .then((res) => {
                        dispatch({
                            type: actionTypes.UPDATE_AUTH,
                            payload: {
                                auth: {
                                    management: res.data
                                }
                            }
                        });
                    })
                    .catch((ex) => {
                        enqueueSnackbar(ex.message, { variant: "error" });
                    });
            }
        };

        window.addEventListener("load", () => {
            if (currentUser) {
                auth.getCurrentUser()
                    .then((res) => {
                        setLoading(false);
                        dispatch({
                            type: actionTypes.UPDATE_AUTH,
                            payload: {
                                auth: {
                                    currentUser: res.data.user,
                                    user: res.data.user
                                }
                            }
                        });
                        getUserManagement();
                    })
                    .catch((ex) => {
                        setLoading(false);

                        // window.location.href = config.routes.login;
                    });
            } else {
                setLoading(false);
            }
        });
        // eslint-disable-next-line
    }, []);

    if (loading) {
        return (
            <Box width={"100%"}>
                <Grid
                    container
                    alignItems="center"
                    justifyContent="center"
                    direction="row"
                    style={{ minHeight: "100vh" }}
                >
                    <Grid item xs={4}>
                        <Box align="center">
                            <Avatar
                                src={baseIcon}
                                className="heatbeat-animation"
                                style={{ height: "100px", width: "100px" }}
                            />
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        );
    } else {
        return (
            <Switch>
                {/* <Switch> */}

                {!currentUser && (
                    <Switch>
                        <Route
                            path={config.routes.publicUser}
                            component={PublicUser}
                            // user={currentUser}
                            exact
                        />

                        <Route
                            path={config.routes.tos}
                            component={TermsOfService}
                            // user={currentUser}
                            exact
                        />
                        <Route
                            path={config.routes.privacy}
                            component={Privacy}
                            // user={currentUser}
                            exact
                        />
                        <Route
                            path={config.routes.pageNotFound}
                            component={PageNotFound}
                            // user={currentUser}
                            exact
                        />
                        <Route
                            path={config.routes.suspended}
                            component={Suspend}
                            // user={currentUser}
                            exact
                        />
                        <PublicRoutes
                            path={config.routes.login}
                            // component={Login}
                            component={LandingPage}
                            user={currentUser}
                            restricted={true}
                            exact
                        />
                        <PublicRoutes
                            path={config.routes.auth}
                            component={Authenticate}
                            user={currentUser}
                            restricted={false}
                            exact
                        />
                        <PublicRoutes
                            path={config.routes.error}
                            component={Error}
                            user={currentUser}
                            restricted={false}
                            exact
                        />
                        <Redirect to={config.routes.pageNotFound} />
                    </Switch>
                )}
                {currentUser && currentUser.userType === config.userType.admin && (
                    <Switch>
                        <AdminRoutes
                            exact
                            path={config.routes.admin}
                            component={AdminDashboard}
                            user={currentUser}
                        />
                        <AdminRoutes
                            exact
                            path={config.routes.adminBannedUrls}
                            component={BannedUrls}
                            user={currentUser}
                        />

                        <AdminRoutes
                            exact
                            path={config.routes.adminAccounts}
                            component={Accounts}
                            user={currentUser}
                        />
                        <AdminRoutes
                            // exact
                            path={config.routes.userSurvey}
                            component={UserSurvey}
                            user={currentUser}
                        />
                        <AdminRoutes
                            // exact
                            path={config.routes.accountCategory}
                            component={AccountCategoryList}
                            user={currentUser}
                        />
                        <AdminRoutes
                            exact
                            path={config.routes.adminCustomAccounts}
                            component={CustomAccounts}
                            user={currentUser}
                        />
                        <AdminRoutes
                            exact
                            path={config.routes.adminUsers}
                            component={UsersList}
                            user={currentUser}
                        />
                        <AdminRoutes
                            exact
                            path={config.routes.adminEditUser}
                            component={EditUser}
                            user={currentUser}
                        />
                        <AdminRoutes
                            exact
                            path={config.routes.adminIntegrations}
                            component={Integrations}
                            user={currentUser}
                        />
                        <Route
                            path={config.routes.tos}
                            component={TermsOfService}
                            user={currentUser}
                            exact
                        />
                        <Route
                            path={config.routes.privacy}
                            component={Privacy}
                            user={currentUser}
                            exact
                        />
                        <Route
                            path={config.routes.publicUser}
                            component={PublicUser}
                            user={currentUser}
                            exact
                        />
                        <Route
                            path={config.routes.error}
                            component={Error}
                            user={currentUser}
                            exact
                        />
                        <Redirect
                            from={config.routes.login}
                            to={config.routes.admin}
                            exact
                        />
                        <Redirect
                            from={config.routes.auth}
                            to={config.routes.admin}
                            exact
                        />
                        <Redirect to={config.routes.pageNotFound} />
                    </Switch>
                )}
                {currentUser &&
                    (currentUser.userType === config.userType.free ||
                        currentUser.userType === config.userType.plus ||
                        currentUser.userType === config.userType.pro) &&
                    currentUser.baseUrl && (
                        <Switch>
                            <AppUserRoutes
                                exact
                                path={config.routes.dashboard}
                                component={Dashboard}
                                user={currentUser}
                            />
                            <AppUserRoutes
                                exact
                                path={config.routes.settings}
                                component={Settings}
                                user={currentUser}
                            />
                            <AppUserRoutes
                                exact
                                path={config.routes.editProfile}
                                component={EditProfile}
                                user={currentUser}
                            />
                            <AppUserRoutes
                                exact
                                path={config.routes.management}
                                component={Management}
                                user={currentUser}
                            />
                            <AppUserRoutes
                                exact
                                path={config.routes.profile}
                                component={Profile}
                                user={currentUser}
                            />
                            <Route
                                path={config.routes.publicUser}
                                component={PublicUser}
                                // user={currentUser}
                                exact
                            />
                            <Route
                                path={config.routes.error}
                                component={Error}
                                // user={currentUser}
                                exact
                            />
                            <Route
                                path={config.routes.tos}
                                component={TermsOfService}
                                // user={currentUser}
                                exact
                            />
                            <Route
                                path={config.routes.privacy}
                                component={Privacy}
                                // user={currentUser}
                                exact
                            />
                            <Redirect
                                from={config.routes.login}
                                to={config.routes.dashboard}
                                exact
                            />
                            <Redirect
                                from={config.routes.auth}
                                to={config.routes.dashboard}
                                exact
                            />
                            <Redirect to={config.routes.pageNotFound} />
                        </Switch>
                    )}
                {currentUser && !currentUser.baseUrl && (
                    <Switch>
                        <InCompleteUserRoute
                            exact
                            path={config.routes.completeRegistration}
                            component={CompleteRegistation}
                            user={currentUser}
                        />
                        <Route
                            path={config.routes.publicUser}
                            component={PublicUser}
                            // user={currentUser}
                            exact
                        />
                        <Route
                            path={config.routes.tos}
                            component={TermsOfService}
                            // user={currentUser}
                            exact
                        />
                        <Route
                            path={config.routes.privacy}
                            component={Privacy}
                            // user={currentUser}
                            exact
                        />
                        <Route
                            path={config.routes.error}
                            component={Error}
                            // user={currentUser}
                            exact
                        />
                        <Redirect
                            from={config.routes.login}
                            to={config.routes.completeRegistration}
                            exact
                        />
                        <Redirect
                            from={config.routes.auth}
                            to={config.routes.completeRegistration}
                            exact
                        />
                        <Redirect to={config.routes.pageNotFound} />
                    </Switch>
                )}

                {/* </Switch> */}
            </Switch>
        );
    }
}
