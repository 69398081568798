import { makeStyles, useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
import React from "react";

const useStyles = makeStyles((theme) => ({
    backgroundVideo: {
        width: ({ iframeWidth }) => iframeWidth,
        height: ({ iframeHeight }) => iframeHeight,
        objectFit: "cover",
        position: "fixed",
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        zIndex: "-1"
    }
}));
export default function ProfileBackgroundVideo({
    iframeHeight,
    iframeWidth,
    backgroundType,
    backgroundVideo,
    backgroundMobileVideo,
    isPlusOrProUser
}) {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    // debugger;
    const classes = useStyles({
        backgroundType,

        iframeHeight,
        iframeWidth
    });
    const getFormattedURL = (URL) => {
        if (URL) {
            var urlArr = URL.split("/");
            var provider = urlArr[2];
            if (provider === "www.vimeo.com" || provider === "vimeo.com") {
                urlArr[0] = "https://";
                urlArr[1] = "player.";
                urlArr[2] = provider + "/video/";
                urlArr[4] =
                    "?background=1&autoplay=1&loop=1&autopause=0&muted=1";
                videoURL = urlArr.join();
                videoURL = videoURL.replaceAll(",", "");
                return videoURL;
            }
            if (provider === "www.youtube.com" || provider === "youtube.com") {
                var videioId = urlArr[3].split("v=")[1];
                var ampersandPosition = videioId.indexOf("&");
                if (ampersandPosition !== -1) {
                    videioId = videioId.substring(0, ampersandPosition);
                }
                urlArr[0] = "https://";
                urlArr[2] = provider + "/embed/";
                urlArr[3] = videioId;
                urlArr[4] =
                    "?autoplay=1&controls=0&showinfo=0&autohide=1&mute=1";
                videoURL = urlArr.join();
                videoURL = videoURL.replaceAll(",", "");
                return videoURL;
            }
        }
    };
    var videoURL = "";
    if (isMobile) {
        videoURL = getFormattedURL(backgroundMobileVideo);
    } else {
        videoURL = getFormattedURL(backgroundVideo);
    }
    return (
        <div>
            {isPlusOrProUser && backgroundType === "video" && (
                <iframe
                    className={classes.backgroundVideo}
                    src={videoURL}
                    frameBorder={0}
                    allowFullScreen
                    allow="autoplay"
                    volume={0}
                    title={"background video"}
                />
            )}
        </div>
    );
}
