import { createTheme } from "@material-ui/core";
import { cyan, grey } from "@material-ui/core/colors";

// const primaryMain = "#5BAABA";
let secondaryMain = "#141828";
secondaryMain = "#DB6B6B";
// const textPrimary = "#fff";
let paperColor = "#2c2c2c";
let bgColor = "#232323";
paperColor = "#191919";
bgColor = "#000000";

const primary = cyan[400];
const theme = createTheme({
    palette: {
        type: "dark",
        primary: {
            main: primary
        },
        secondary: {
            main: secondaryMain
        },
        // text: {
        //     primary: textPrimary
        // },
        background: {
            paper: paperColor,
            secondary: grey[900],
            default: bgColor
        }
    },
    shape: {
        // borderRadius:16,
    },
    typography: {
        fontFamily: "Darker Grotesque",
        h6: {
            fontWeight: 700
        },
        subtitle1: {
            fontWeight: 500
        },
        body1: {
            fontWeight: 700
        },
        h3: {
            fontWeight: 700
        }
    },
    overrides: {
        MuiTabs: {
            root: {
                // backgroundColor: secondaryMain
            },
            indicator: {
                // backgroundColor: primaryMain
            }
        },
        MuiTab: {
            root: {
                backgroundColor: paperColor,
                fontWeight: 700,
                "&:hover": {
                    backgroundColor: paperColor
                    // color: primaryMain
                },
                "&$selected": {
                    backgroundColor: primary,
                    color: bgColor
                    // color: primaryMain,
                    // "&:hover": {
                    //     backgroundColor: "secondaryMain",
                    //     color: primaryMain
                    // }
                }
            },
            wrapper: {
                // color: primaryMain
            }
        },
        // MuiFormControl: {
        //     root: {
        //         backgroundColor: bgColor,
        //         borderWidth: 0
        //     }
        // },
        MuiInputBase: {
            root: {
                // "&$disabled": {
                //     "& fieldset.MuiOutlinedInput-notchedOutline": {
                // borderColor: "blue",
                borderWidth: 0,
                backgroundColor: grey[900],
                "& fieldset.MuiOutlinedInput-notchedOutline": {
                    borderWidth: 0

                    // borderColor: "blue",
                    // background: "grey"
                }
                //     }
                // }
            }
        }
    }

    // components: {
    //     // Name of the component
    //     MuiTextField: {
    //         styleOverrides: {
    //             // Name of the slot
    //             root: {
    //                 // Some CSS
    //                 fontSize: "21rem"
    //             }
    //         }
    //     }
    // }
});

export default theme;
