import { Switch, Avatar, Box, makeStyles, Typography } from "@material-ui/core";
import clsx from "clsx";
import React from "react";
import { useSelector } from "react-redux";
import config from "../../../../../config";
// import useGlobalStyles from "../../../../../hooks/useGlobalStyles";
import DragHandleIcon from "@material-ui/icons/DragHandle";

// const BORDER = config.accountStyle.border
// const FILL = config.accountStyle.fill

const CIRCLE = config.shape.circle;
const ROUNDED = config.shape.rounded;

const LEFT = config.iconPosition.left;
const OVERLAP = config.iconPosition.overlap;
const INSIDE = config.iconPosition.inside;
const useStyles = makeStyles((theme) => ({
    customAccountText: {
        textAlign: "center",
        flexGrow: 1,
        [theme.breakpoints.down("xs")]: {
            textAlign: "left",
            marginLeft: theme.spacing(2)
        }
    },
    accountCircleBorder: {
        borderRadius: 50,
        boxShadow: "none"
    },
    accountRoundedBorder: {
        borderRadius: 8,
        boxShadow: "none"
    },
    accountAvatarPosition: {
        position: "relative"
        // marginLeft: "-10px"
    },
    positionLeft: {
        position: "relative",
        // marginTop: theme.spacing(-1.2),
        width: theme.spacing(5),
        height: theme.spacing(5),
        marginLeft: theme.spacing(-6)
    },
    positionOverlap: {
        position: "relative",
        marginLeft: theme.spacing(-3)
    }
}));

export default function AccountHeader({
    bgColor,
    avatar,
    textColor,
    label,
    border,
    borderColor,
    hasSwitch = false,
    isSwitchChecked = false,
    isSwitchDisabled = false,
    onSwitchChange = () => {},
    onAccountClick = () => {}
}) {
    const classes = useStyles();
    // const globalClasses = useGlobalStyles();
    const user = useSelector((state) => state.auth.user);

    const baseAccountSettings = user?.baseAccountSettings;

    const accountShape = baseAccountSettings?.accountShape;
    const accountIconShape = baseAccountSettings?.accountIconShape;
    const accountIconPosition = baseAccountSettings?.accountIconPosition;
    const isAccountIcon = baseAccountSettings?.isAccountIcon;
    return (
        <Box display={"flex"}>
            <Box mr={1} mt={1.5}>
                <DragHandleIcon color="disabled" />
            </Box>
            <Box
                // onClick={onAccountClick}
                // display="flex"
                width="100%"
                // height={10}
                bgcolor={bgColor}
                p={0.5}
                borderRadius={4}
                className={clsx(
                    accountShape === CIRCLE && classes.accountCircleBorder,
                    accountShape === ROUNDED && classes.accountRoundedBorder
                )}
                ml={
                    accountIconPosition !== INSIDE && isAccountIcon
                        ? accountIconPosition === LEFT && isAccountIcon
                            ? 6
                            : 2
                        : 0
                }
                border={border}
                borderColor={borderColor}
            >
                <Box display={"flex"} justifyContent="space-between">
                    <Box display={"flex"} flexGrow={1} onClick={onAccountClick}>
                        <Box>
                            {/* <Box display="flex" width="100%"> */}
                            {isAccountIcon && (
                                <Box>
                                    <Avatar
                                        // sizes={"sm"}
                                        src={avatar}
                                        className={clsx(
                                            classes.accountAvatarPosition,
                                            accountIconPosition === LEFT &&
                                                classes.positionLeft,
                                            accountIconPosition === OVERLAP &&
                                                classes.positionOverlap
                                        )}
                                        variant={
                                            accountIconShape === CIRCLE
                                                ? "circular"
                                                : accountIconShape
                                        }
                                    />
                                </Box>
                            )}

                            {/* </Box> */}
                        </Box>
                        <Box pt={1} flexGrow={1}>
                            <Typography
                                align="center"
                                style={{
                                    flexGrow: "1",
                                    color: textColor
                                }}
                                className={classes.customAccountText}
                            >
                                {label}
                            </Typography>
                        </Box>
                    </Box>
                    {hasSwitch && (
                        <Box>
                            <Switch
                                color="primary"
                                // size="small"
                                checked={isSwitchChecked}
                                onChange={onSwitchChange}
                                disabled={isSwitchDisabled}
                            />
                        </Box>
                    )}
                </Box>
            </Box>
        </Box>
    );
}
