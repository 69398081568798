import { Avatar, Box, Link, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import Carousel from "react-multi-carousel";

// import { Carousel as Carousel3D } from "3d-react-carousal";
// import Carousel3D from "3d-react-carousal";
import "react-multi-carousel/lib/styles.css";

import config from "../../../config";

const useStyles = makeStyles((theme) => ({
    imageSize: {
        width: theme.spacing(8),
        height: theme.spacing(8),
        [theme.breakpoints.down("xs")]: {
            width: theme.spacing(6),
            height: theme.spacing(6)
        }
    },
    textContainer: {
        width: theme.spacing(10),
        // height: theme.spacing(10),
        fontSize: 9,
        marginTop: theme.spacing(0.5),
        [theme.breakpoints.down("xs")]: {
            width: theme.spacing(8)
            // height: theme.spacing(8)
        }
    }
}));
const responsive = {
    desktop: {
        breakpoint: {
            max: 3000,
            min: 1024
        },
        items: 5
    },
    tablet: {
        breakpoint: {
            max: 1024,
            min: 464
        },
        items: 4
    },
    mobile: {
        breakpoint: {
            max: 464,
            min: 0
        },
        items: 3
    }
};

const autoPlayCarousel = {
    additionalTransfrom: 0,
    arrows: false,
    autoPlay: true,
    autoPlaySpeed: 4000,
    centerMode: false,
    className: "",
    containerClass: "container-with-dots",
    dotListClass: "",
    draggable: true,
    focusOnSelect: false,
    infinite: true,
    itemClass: "",
    keyBoardControl: true,
    minimumTouchDrag: 80,
    renderButtonGroupOutside: false,
    renderDotsOutside: false,
    responsive: responsive,
    showDots: false,
    sliderClass: "",
    slidesToSlide: 1,
    swipeable: true,
    pauseOnHover: true
};

const stillCarousel = {
    additionalTransfrom: 0,
    arrows: true,
    autoPlay: false,
    autoPlaySpeed: 9000,
    centerMode: false,
    className: "",
    containerClass: "container",
    dotListClass: "",
    draggable: true,
    focusOnSelect: false,
    infinite: true,
    itemClass: "",
    keyBoardControl: true,
    minimumTouchDrag: 80,
    renderButtonGroupOutside: false,
    renderDotsOutside: true,
    responsive: responsive,
    // showDots
    sliderClass: "",
    slidesToSlide: 1,
    swipeable: true
};

export default function UserProfileSponsorLogos({
    isPlusOrProUser,
    isBaseSponsorLogoActive,
    sponsorLogos,
    isSponsorLogoBackground,
    sponsorLogoBackgroundColor,
    isSponsorLogoAnimation,
    sponsorLogoShape
}) {
    const classes = useStyles();
    // const user = useSelector((state) => state.auth.user);
    // const isBaseSponsorLogoActive = useSelector(
    //     (state) => state.auth.user?.baseProfileSettings?.isBaseSponsorLogoActive
    // );
    // const sponsorLogos = useSelector(
    //     (state) => state.auth.user?.baseProfileSettings?.baseSponsorLogos
    // );
    // const isSponsorLogoBackground = useSelector(
    //     (state) => state.auth.user?.baseProfileSettings?.isSponsorLogoBackground
    // );

    // const sponsorLogoBackgroundColor = useSelector(
    //     (state) =>
    //         state.auth.user?.baseProfileSettings?.sponsorLogoBackgroundColor
    // );

    // const isSponsorLogoAnimation = useSelector(
    //     (state) => state.auth.user?.baseProfileSettings?.isSponsorLogoAnimation
    // );
    // const sponsorLogoShape = useSelector(
    //     (state) => state.auth.user?.baseProfileSettings?.sponsorLogoShape
    // );
    // const [carouselData3d, setCarouselData3d] = useState([]);
    // useEffect(() => {
    //     prepare3dCarouselData();
    // }, []);
    // const prepare3dCarouselData = () => {
    //     const carouselData = [];
    //     sponsorLogos.forEach((el) => {
    //         carouselData.push(
    //             <div
    //                 style={{
    //                     textAlign: "center"
    //                 }}
    //             >
    //                 <img
    //                     src={el.sponsorLogoUrl}
    //                     className={classes.imageSize}
    //                 />
    //             </div>
    //         );
    //     });
    //     setCarouselData3d(carouselData);
    // };
    const getSelectedAnimation = () => {
        const numOfLogos = sponsorLogos.length;
        let selectedAnimation = stillCarousel;
        if (isSponsorLogoAnimation) {
            selectedAnimation = autoPlayCarousel;
        }
        if (numOfLogos < 6) {
            selectedAnimation.responsive.desktop.items =
                numOfLogos - 1 > 0 ? numOfLogos : 1;
            selectedAnimation.responsive.tablet.items =
                numOfLogos - 2 > 0 ? numOfLogos - 1 : 1;
            selectedAnimation.responsive.mobile.items =
                numOfLogos - 3 > 0 ? numOfLogos - 2 : 1;
        }

        return selectedAnimation;
    };
    // const CustomRightArrow = ({ onClick, ...rest }) => {
    //     const {
    //         onMove,
    //         carouselState: { currentSlide, deviceType }
    //     } = rest;
    //     // onMove means if dragging or swiping in progress.
    //     return <button onClick={() => onClick()} />;
    // };
    return (
        <div>
            {isPlusOrProUser &&
                isBaseSponsorLogoActive &&
                sponsorLogos.length > 0 && (
                    <Box
                        // p={-}
                        // m={-2}
                        // my={2}
                        bgcolor={
                            isSponsorLogoBackground &&
                            sponsorLogoBackgroundColor
                        }
                        borderRadius={5}
                    >
                        <Carousel {...getSelectedAnimation()}>
                            {sponsorLogos.map((el, i) => {
                                return (
                                    <Box p={1} key={el?._id}>
                                        <Box
                                            display="flex"
                                            alignItems="center"
                                            justifyContent="center"
                                        >
                                            <Link
                                                href={el.url}
                                                target="_blank"
                                                rel="noopener"
                                                style={{
                                                    textAlign: "center"
                                                }}
                                            >
                                                <Avatar
                                                    variant={
                                                        sponsorLogoShape ===
                                                        config.shape.circle
                                                            ? "circular"
                                                            : sponsorLogoShape
                                                    }
                                                    style={{
                                                        width: 50,
                                                        height: 50
                                                    }}
                                                    alt={"logo" + i}
                                                    src={el.sponsorLogoUrl}
                                                    className={
                                                        classes.imageSize
                                                    }
                                                />
                                            </Link>
                                        </Box>
                                        <Box
                                            display="flex"
                                            alignItems="center"
                                            justifyContent="center"
                                            textAlign="center"
                                        >
                                            <Typography
                                                variant="caption"
                                                className={
                                                    classes.textContainer
                                                }
                                                color="textPrimary"
                                            >
                                                {el.description}
                                            </Typography>
                                        </Box>
                                    </Box>
                                );
                            })}
                        </Carousel>
                        {/* <Carousel3D
                    slides={carouselData3d}
                    autoplay={true}
                    interval={1000}
                    arrows={false}
                ></Carousel3D> */}
                    </Box>
                )}
        </div>
    );
}

// function WithStyles(description, headline, image, index) {
//     return (

//     );
// }
