/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Typography } from "@material-ui/core";

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AvatarShapeSelector from "../../../../components/core/AvatarShapeSelector";
import config from "../../../../config";
import actionTypes from "../../../../redux/actionTypes";

import userService from "../../../../services/user";
import baseIcon from "../../../../res/images/base-icon1.png";
import { useSnackbar } from "notistack";
import LabelWithEmoji from "../../../../components/common/LabelWithEmoji";
import SaveActionButton from "../../components/SaveActionButton";

const CIRCLE = config.shape.circle;
export default function EditAvatar() {
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const user = useSelector((state) => state.auth.user);
    const [selectedAvatar, setSelectedAvatar] = useState();
    const [selectedAvatarShape, setSelectedAvatarShape] = useState(CIRCLE);
    const [avatarUrl, setAvatarUrl] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    // const isManagedDesignActive = user?.managedBy?.isManagedDesignActive;

    useEffect(() => {
        setUserStates();
    }, [user]);
    const setUserStates = () => {
        setSelectedAvatarShape(user?.avatarShape);
        setAvatarUrl(user.avatarUrl);
    };

    const handleUserAvatar = (e) => {
        // if (!isManagedDesignActive) {
        let data = {
            userId: user._id,
            avatarShape: selectedAvatarShape
        };
        if (
            selectedAvatar &&
            selectedAvatar.file &&
            selectedAvatar.fileSize &&
            selectedAvatar.fileType
        ) {
            data.avatarImage = {
                file: selectedAvatar.file,
                fileSize: selectedAvatar.fileSize,
                fileType: selectedAvatar.fileType.toLowerCase()
            };
        }

        if (selectedAvatarShape !== user.avatarShape || selectedAvatar) {
            setIsLoading(true);
            userService
                .updateUserBase(data)
                .then((res) => {
                    enqueueSnackbar(config.defaultNotiStackMessage, {
                        variant: "success"
                    });
                    setIsLoading(false);
                    const user = res.data.user;
                    dispatch({ type: actionTypes.SET_USER, payload: { user } });
                })
                .catch((ex) => {
                    enqueueSnackbar(ex?.response?.data?.message, {
                        variant: "error"
                    });
                    setIsLoading(false);
                });
        }
        // }
    };

    return (
        <Box>
            <Box>
                <SaveActionButton
                    label={
                        <Typography variant="h6" color="textPrimary">
                            <LabelWithEmoji text="Avatar" emoji="😎" />
                        </Typography>
                    }
                    isLoading={isLoading}
                    onSave={handleUserAvatar}
                />
                <br />

                <AvatarShapeSelector
                    setSelectedAvatar={setSelectedAvatar}
                    setSelectedAvatarShape={setSelectedAvatarShape}
                    selectedAvatarShape={selectedAvatarShape}
                    selectedAvatar={selectedAvatar}
                    avatarUrl={avatarUrl || baseIcon}
                />
                {/* <Typography color="textSecondary"></Typography> */}
            </Box>
        </Box>
    );
}
