import {
    Box,
    Typography,
    IconButton,
    CircularProgress
} from "@material-ui/core";
import { Check } from "@material-ui/icons";
import React from "react";

export default function SaveActionButton({
    onSave,
    label,
    isLoading,
    labelColor = "primary"
}) {
    return (
        <div>
            <Box display="flex">
                <Box flexGrow={1}>
                    <Typography color={labelColor}>{label}</Typography>
                </Box>
                <Box mt={0}>
                    <IconButton
                        color="primary"
                        onClick={onSave}
                        disabled={isLoading}
                    >
                        {isLoading ? <CircularProgress size={18} /> : <Check />}
                    </IconButton>
                </Box>
            </Box>
        </div>
    );
}
