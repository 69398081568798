import config from "../config";
import axios from "../config/axios";

const connectWithYoutube = async () => {
    return axios.get(
        `${config.apiEndPoint}${config.apiRoutes.intergration.youtube}`
    );
};

const getAllIntegrations = async () => {
    return axios.get(
        `${config.apiEndPoint}${config.apiRoutes.intergration.default}`
    );
};

const getIntegrationById = async (id) => {
    return axios.get(
        `${config.apiEndPoint}${config.apiRoutes.intergration.default}?id=${id}`
    );
};

const addNewIntegration = async (integration) => {
    return axios.post(
        `${config.apiEndPoint}${config.apiRoutes.intergration.default}`,
        integration
    );
};
const editIntegrationById = async (integration) => {
    return axios.put(
        `${config.apiEndPoint}${config.apiRoutes.intergration.default}`,
        integration
    );
};
// const deleteAccountById = async (id) => {
//     return axios.delete(
//         `${config.apiEndPoint}${config.apiRoutes.accounts.default}`,
//         { data: { id } }
//     );
// };

export default {
    connectWithYoutube,
    getAllIntegrations,
    getIntegrationById,
    addNewIntegration,
    editIntegrationById
};
