/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from "react";
import {
    // makeStyles,
    Switch,
    Collapse,
    Grid,
    TextField,
    Typography,
    Box
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import * as Yup from "yup";

import SaveActionButton from "./SaveActionButton";
import CustomBadge from "../../../components/common/CustomBadge";
import LabelWithEmoji from "../../../components/common/LabelWithEmoji";
import ColorPickerField from "../../../components/core/ColorPickerField";
import config from "../../../config";

let schema = Yup.object().shape({
    baseBannerText: Yup.string()
        .required()
        .max(config.constants.bannerTextMaxCharacter)
        .label("Banner Text"),
    baseBannerColor: Yup.string().notRequired().label("Banner Color"),
    baseBannerTextColor: Yup.string().notRequired().label("Banner Text Color")
});

export default function BannerUI({
    disableBannerSwitch = false,
    enableFeature = false,
    isBaseBannerActive = false,
    isLoading = false,
    baseBannerColor,
    baseBannerText,
    baseBannerTextColor,
    onBannerStatusChange = () => {},
    onBannerUpdate = () => {}
}) {
    const [bannerTextInput, setBannerTextInput] = useState("");
    const [bannerColorInput, setBannerColorInput] = useState(
        config.defaultBannerColor.backgroundColor
    );
    const [bannerTextColor, setBannerTextColor] = useState(
        config.defaultBannerColor.textColor
    );

    const [error, setError] = useState("");
    const setUserStates = useCallback(() => {
        setBannerTextInput(baseBannerText);
        if (baseBannerColor) {
            setBannerColorInput(baseBannerColor);
        }
        if (baseBannerTextColor) {
            setBannerTextColor(baseBannerTextColor);
        }
    }, [baseBannerColor, baseBannerText, baseBannerTextColor]);

    useEffect(() => {
        setUserStates();
    }, []);

    const handleSaveBanner = () => {
        // delete croppedImage.file;

        if (
            bannerColorInput !== baseBannerColor ||
            bannerTextInput !== baseBannerText ||
            bannerTextColor !== baseBannerTextColor
        ) {
            schema
                .validate({
                    baseBannerColor: bannerColorInput,
                    baseBannerText: bannerTextInput,
                    baseBannerTextColor: bannerTextColor
                })
                .then(() => {
                    onBannerUpdate({
                        baseBannerColor: bannerColorInput,
                        baseBannerText: bannerTextInput,
                        baseBannerTextColor: bannerTextColor
                    });
                })
                .catch((err) => {
                    setError(err.message);
                    resetErrors();
                });
        }
    };

    const resetErrors = () => {
        setTimeout(() => {
            setError("");
        }, 3000);
    };
    return (
        <Box
            // p={2}
            mt={1}
            square
        >
            <Box display="flex">
                <Box flexGrow={1}>
                    {!enableFeature ? (
                        <Box display="flex">
                            <Typography color="textPrimary" variant="h6">
                                <LabelWithEmoji text="Base Banner" emoji="🚩" />
                            </Typography>
                            <CustomBadge
                                badgeContent={"plus"}
                                uppercase={true}
                            ></CustomBadge>
                        </Box>
                    ) : (
                        <Typography color="textPrimary" variant="h6">
                            <LabelWithEmoji text="Base Banner" emoji="🚩" />
                        </Typography>
                    )}
                </Box>
                <Box>
                    <Switch
                        checked={
                            (enableFeature && isBaseBannerActive) ||
                            !enableFeature
                        }
                        onChange={onBannerStatusChange}
                        disabled={!enableFeature || disableBannerSwitch}
                        color="primary"
                    />
                </Box>
            </Box>
            <Collapse in={enableFeature && isBaseBannerActive}>
                <Box py={2} mr={1}>
                    <Box width="100%" padding={0}>
                        <div>
                            {error && <Alert severity="error">{error}</Alert>}
                            <Box mb={2} width="100%">
                                <SaveActionButton
                                    label={
                                        <Typography
                                            variant="subtitle1"
                                            color="textSecondary"
                                        >
                                            Enter Banner Text
                                        </Typography>
                                    }
                                    onSave={handleSaveBanner}
                                    isLoading={isLoading}
                                    labelColor={"textPrimary"}
                                    variant="subtitle1"
                                />

                                <TextField
                                    required
                                    placeholder="e.g. I will be live tonight at 9:00PM on twitch"
                                    fullWidth
                                    value={bannerTextInput}
                                    onChange={(e) =>
                                        setBannerTextInput(
                                            e.currentTarget.value
                                        )
                                    }
                                    variant="outlined"
                                />
                            </Box>

                            <Grid container spacing={4}>
                                <Grid item sm={6} md={6} lg={6} xs={6}>
                                    <ColorPickerField
                                        enableAlpha={true}
                                        fieldLabel={"Banner Color"}
                                        color={bannerColorInput}
                                        onColorPickerValueChange={(color) => {
                                            setError("");
                                            setBannerColorInput(
                                                `#${color.hex}`
                                            );
                                        }}
                                        onFieldValueChange={(e) => {
                                            setError("");
                                            setBannerColorInput(
                                                `${e.currentTarget.value}`
                                            );
                                        }}
                                    />
                                </Grid>
                                <Grid item sm={6} md={6} lg={6} xs={6}>
                                    <ColorPickerField
                                        fieldLabel={"Banner Text Color"}
                                        color={bannerTextColor}
                                        onColorPickerValueChange={(color) => {
                                            setError("");
                                            setBannerTextColor(`#${color.hex}`);
                                        }}
                                        onFieldValueChange={(e) => {
                                            setError("");
                                            setBannerTextColor(
                                                `${e.currentTarget.value}`
                                            );
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </div>
                    </Box>
                </Box>
            </Collapse>
        </Box>
    );
}
