/* eslint-disable react-hooks/exhaustive-deps */
import { Backdrop, Box, Container, Paper, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import UserAppBar from "../../../components/core/UserAppBar/index";
// import useGlobalStyles from "../../../hooks/useGlobalStyles";
import EditAccountDesign from "./editAccountDesign";
import EditAccountIcon from "./editAccountIcon/index";
import EditAvatar from "./editAvatar";
import EditBaseBackground from "./editBaseBackground";
import EditBaseHeader from "./editBaseHeader";
import EditBaseText from "./editBaseText";
import Beta from "../../../components/common/Beta";
import { useSelector } from "react-redux";
import Footer from "../../../components/common/Footer";

const useStyles = makeStyles((theme) => ({
    backdrop: {
        backgroundColor: "rgba(0,0,0,0.8)",
        position: "absolute",
        zIndex: theme.zIndex.drawer + 1
        // color: "#fff"
    }
}));
export default function EditProfile() {
    const classes = useStyles();
    const user = useSelector((state) => state.auth.user);
    const isManagedDesignActive = user?.managedBy?.isManagedDesignActive;

    return (
        <div>
            <UserAppBar />
            <Container maxWidth={"md"}>
                <Box p={2}>
                    <Beta />
                </Box>
                <Box>
                    <Box mt={2}>
                        <Paper>
                            <Box p={4}>
                                <EditAvatar />
                            </Box>
                        </Paper>
                    </Box>
                    <Box position={"relative"}>
                        <Backdrop
                            className={classes.backdrop}
                            open={isManagedDesignActive}
                        >
                            <Box
                                position={"absolute"}
                                top={180}
                                textAlign={"center"}
                            >
                                <Typography variant="h5">
                                    <b>
                                        <span
                                            role="img"
                                            aria-label="management"
                                        >
                                            💼{" "}
                                        </span>
                                        This Base is being managed.
                                        <br /> Please contact the Manager or
                                        disable Management.
                                    </b>
                                </Typography>
                            </Box>
                        </Backdrop>
                        <Box Box mt={2}>
                            <Paper>
                                <Box p={4}>
                                    <EditBaseHeader />
                                </Box>
                            </Paper>
                        </Box>
                        <Box mt={2}>
                            <Paper>
                                <Box p={4}>
                                    <EditBaseBackground />
                                </Box>
                            </Paper>
                        </Box>
                        <Box mt={2}>
                            <Paper>
                                <Box p={4}>
                                    <EditAccountIcon />
                                </Box>
                            </Paper>
                        </Box>
                        <Box mt={2}>
                            <Paper>
                                <Box p={4}>
                                    <EditAccountDesign />
                                </Box>
                            </Paper>
                        </Box>
                        <Box mt={2}>
                            <Paper>
                                <Box p={4}>
                                    <EditBaseText />
                                </Box>
                            </Paper>
                        </Box>
                        <Box></Box>
                    </Box>
                </Box>
                <Box p={2}>
                    <Footer />
                </Box>
            </Container>
        </div>
    );
}
