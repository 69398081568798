// import _ from "lodash";
import actionType from "../actionTypes";

const initialState = {
    userSurveys: [],

    loading: false
};

export default function (state = initialState, action) {
    switch (action.type) {
        case actionType.SET_SURVEYS: {
            return {
                ...state,
                userSurveys: action.payload.surveys
            };
        }
        default: {
            return state;
        }
    }
}
