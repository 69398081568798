import React from "react";
import SponsorLogo from "./Components/SponsorLogo";

export default function AddSponorLogo({ onComplete }) {
    return (
        <div>
            <SponsorLogo
                removeButton={false}
                operation="add"
                onAddSuccess={onComplete}
            />
        </div>
    );
}
