import React, { useState } from "react";
import { Box } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";

import config from "../../../../config";
import actionTypes from "../../../../redux/actionTypes";
import auth from "../../../../services/auth";
import userService from "../../../../services/user";
import { useSnackbar } from "notistack";
import BannerUI from "../../components/BannerUI";

export default function EditBaseBanner() {
    // const globalClasses = useGlobalStyles();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    // const classes = useStyles();

    const user = useSelector((state) => state.auth.user);
    const isPlusOrProUser = auth.isPlusOrProUser();
    const isBaseBannerActive = user.baseProfileSettings.isBaseBannerActive;
    const baseBannerColor = user.baseProfileSettings.baseBannerColor;
    const baseBannerText = user.baseProfileSettings.baseBannerText;
    const baseBannerTextColor = user.baseProfileSettings.baseBannerTextColor;

    const [disableBaaseBannerSwitch, setDisableBaseBannerSwitch] =
        useState(false);

    const [isLoading, setIsLoading] = useState(false);

    const handleBaseBannerStatus = (e) => {
        setDisableBaseBannerSwitch(true);
        userService
            .updateBaseProfileSettings({
                userId: user._id,
                isBaseBannerActive: e.target.checked
            })
            .then((res) => {
                const baseProfileSettings = res.data.baseProfileSettings;
                setDisableBaseBannerSwitch(false);
                dispatch({
                    type: actionTypes.UPDATE_USER,
                    payload: { baseProfileSettings }
                });
            })
            .catch((ex) => {
                setDisableBaseBannerSwitch(false);
            });
    };

    const handleUpdateBanner = ({
        baseBannerColor,
        baseBannerText,
        baseBannerTextColor
    }) => {
        setIsLoading(true);
        userService
            .updateBaseProfileSettings({
                userId: user._id,
                baseBannerColor,
                baseBannerText,
                baseBannerTextColor
            })
            .then((res) => {
                enqueueSnackbar(config.defaultNotiStackMessage, {
                    variant: "success"
                });
                const baseProfileSettings = res.data.baseProfileSettings;
                dispatch({
                    type: actionTypes.UPDATE_USER,
                    payload: { baseProfileSettings }
                });
                setIsLoading(false);
            })
            .catch((ex) => {
                enqueueSnackbar(ex?.response?.data?.message, {
                    variant: "error"
                });
                setIsLoading(false);
            });
    };

    return (
        <Box>
            <BannerUI
                disableBannerSwitch={disableBaaseBannerSwitch}
                enableFeature={isPlusOrProUser}
                isBaseBannerActive={isBaseBannerActive}
                baseBannerColor={baseBannerColor}
                baseBannerText={baseBannerText}
                baseBannerTextColor={baseBannerTextColor}
                onBannerStatusChange={handleBaseBannerStatus}
                onBannerUpdate={handleUpdateBanner}
                isLoading={isLoading}
            />
        </Box>
    );
}
