/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
// import * as Scroll from "react-scroll";
import {
    Box,
    // Button,
    Container,
    // Container,
    // CssBaseline,
    // Divider,
    // Fade,
    Grid
    // IconButton,
    // Paper,
    // Typography
} from "@material-ui/core";
// import { Close, MenuOutlined } from "@material-ui/icons";
import config from "../../config";
import { Link } from "react-router-dom";
// import useGlobalStyles from "../../hooks/useGlobalStyles";
import baseggLogo from "../../res/images/basegg-logo1.png";
// import heroImg from "../../res/images/Gradient.png";

const useStyles = makeStyles((theme) => ({
    sidebar: {
        height: "100vh",
        width: "100vw",
        position: "fixed",
        zIndex: 3,
        top: "0",
        left: "0",
        backgroundColor: "rgba(13, 12, 12, 0.9)",
        overflowX: "hidden"
    },
    sideBarToggleBtn: {
        position: "absolute",
        top: "0",
        left: "0",
        fontSize: "36px",
        margin: theme.spacing(1)
    }
}));

// var scroller = Scroll.scroller;
// let Element = Scroll.Element;

export default function PublicAppbar() {
    const [isMobile, setIsMobile] = useState(false);
    // const [showSideNav, setShowSideNav] = useState(false);
    const classes = useStyles({ isMobile });
    // const globalClasses = useGlobalStyles();
    // const url = queryString.parse(window.location.search);

    const handleNavLinkDisplay = () => {
        if (window.innerWidth <= 600) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
            // setShowSideNav(false);
        }
    };

    const handleMoileView = () => {
        handleNavLinkDisplay();
        window.addEventListener("resize", () => {
            handleNavLinkDisplay();
        });
    };

    useEffect(() => {
        handleMoileView();
    }, []);

    // const scrollToElement = (eleName) => {
    //     if (showSideNav) {
    //         setShowSideNav(false);
    //     }
    //     scroller.scrollTo(eleName, {
    //         // duration: 1500,
    //         delay: 100,
    //         smooth: true,
    //         offset: 50 // Scrolls to element + 50 pixels down the page
    //     });
    // };
    // const redirectTo = (url) => {
    //     window.open(url, "_blank");
    // };
    const desktopNavUI = (
        <Box display="flex" justifyContent="center">
            <Box
                flexGrow={1}
                mt={4}
                textAlign={isMobile && "center"}
                // width={"100%"}
            >
                <Link to={config.routes.login}>
                    <img
                        className={classes.logo}
                        src={baseggLogo}
                        alt="base-logo"
                        height={20}
                    />
                </Link>
            </Box>

            <Box mt={3}>
                <Grid container spacing={2}>
                    {/* <Grid item>
                        <Button
                            onClick={() => scrollToElement("home")}
                            className={globalClasses.textTransformNone}
                        >
                            <Typography color="textPrimary">Home</Typography>
                        </Button>
                    </Grid> */}
                    {/* <Grid item>
                        <Button
                            onClick={() => scrollToElement("feature")}
                            className={globalClasses.textTransformNone}
                        >
                            <Typography color="textPrimary">
                                Features
                            </Typography>
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            onClick={() => scrollToElement("contact")}
                            className={globalClasses.textTransformNone}
                        >
                            <Typography color="textPrimary">Contact</Typography>
                        </Button>
                    </Grid> */}
                </Grid>
            </Box>
        </Box>
    );

    // const mobileNavUI = (
    //     <Box>
    //         <Box className={classes.sideBarToggleBtn}>
    //             <Fade in={isMobile && !showSideNav}>
    //                 <IconButton onClick={() => setShowSideNav(true)}>
    //                     <MenuOutlined
    //                         style={{ color: "#fff" }}
    //                         fontSize="large"
    //                     />
    //                 </IconButton>
    //             </Fade>
    //         </Box>
    //         <Box
    //             flexGrow={1}
    //             mt={3}
    //             textAlign={isMobile && "center"}
    //             width={"100%"}
    //         >
    //             <img
    //                 // className={classes.logo}
    //                 src={baseggLogo}
    //                 alt="base-logo"
    //                 height={"20px"}
    //                 style={{ height: "20px" }}
    //             />
    //         </Box>
    //         {/* {isMobile && !showSideNav && ( */}
    //         <Fade in={showSideNav}>
    //             <div id="mySidenav" className={classes.sidebar}>
    //                 <Box display="flex" justifyContent="center">
    //                     <Box className={classes.sideBarToggleBtn}>
    //                         <Fade in={showSideNav}>
    //                             <IconButton
    //                                 onClick={() => setShowSideNav(false)}
    //                             >
    //                                 <Close
    //                                     style={{
    //                                         color: "#fff"
    //                                     }}
    //                                     fontSize="large"
    //                                 />
    //                             </IconButton>
    //                         </Fade>
    //                     </Box>
    //                     <Box
    //                         flexGrow={1}
    //                         mt={3}
    //                         textAlign={isMobile && "center"}
    //                         width={"100%"}
    //                     >
    //                         <img
    //                             className={classes.logo}
    //                             src={baseggLogo}
    //                             alt="base-logo"
    //                         />
    //                     </Box>
    //                 </Box>
    //                 <Box mt={8}>
    //                     <Box p={2} textAlign="center">
    //                         <Button onClick={() => scrollToElement("home")}>
    //                             <Typography
    //                                 color="textPrimary"
    //                                 variant="h4"
    //                                 className={globalClasses.textTransformNone}
    //                             >
    //                                 Home
    //                             </Typography>
    //                         </Button>
    //                     </Box>
    //                     <Box p={2} textAlign="center">
    //                         <Button
    //                             onClick={() => scrollToElement("feature")}
    //                             className={globalClasses.textTransformNone}
    //                         >
    //                             <Typography color="textPrimary" variant="h4">
    //                                 Features
    //                             </Typography>
    //                         </Button>
    //                     </Box>
    //                     <Box p={2} textAlign="center">
    //                         <Button
    //                             onClick={() => scrollToElement("contact")}
    //                             className={globalClasses.textTransformNone}
    //                         >
    //                             <Typography color="textPrimary" variant="h4">
    //                                 Contact
    //                             </Typography>
    //                         </Button>
    //                     </Box>
    //                 </Box>
    //             </div>
    //         </Fade>
    //         {/* )} */}
    //     </Box>
    // );

    return (
        <Box>
            {/* <Box display="flex" justifyContent="center"> */}

            <Container>
                {/* {isMobile && mobileNavUI} */}
                {!isMobile && desktopNavUI}
                {/* </Box> */}
            </Container>
        </Box>
    );
}
