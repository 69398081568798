import React, { useEffect, useState } from "react";

import {
    Typography,
    Grid,
    Box,
    CircularProgress,
    Avatar,
    Button,
    TextField,
    Switch
} from "@material-ui/core";
import accountsOps from "../../../services/accounts";
import { toBase64 } from "../../../utils";
import { Alert, Autocomplete } from "@material-ui/lab";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import * as yup from "yup";
import ColorPickerField from "../../../components/core/ColorPickerField";

// const CONSTANTS = config.constants;

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%"
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular
    },
    previewIcon: {
        border: `1px dashed ${theme.palette.primary.main}`,
        width: theme.spacing(9),
        height: theme.spacing(9)
        // [theme.breakpoints.down("xs")]: {
        //   width: theme.spacing(8),
        //   height: theme.spacing(8),
        // },
    },
    previewIconOverlay: {
        backgroundColor: "#000",
        opacity: "0.5",
        position: "absolute",
        bottom: 0,
        height: "50%",
        width: theme.spacing(9),
        // [theme.breakpoints.down("xs")]: {
        //   width: theme.spacing(8),
        // },
        borderBottomRightRadius: 4,
        borderBottomLeftRadius: 4
    }
}));

let schema = yup.object().shape({
    accountId: yup.string().required().min(5).label("accountId"),
    accountImage: yup
        .object({
            file: yup
                .string()
                .min(5)
                .notRequired()
                .default(undefined)
                .label("File"),
            fileType: yup
                .string()
                .oneOf(
                    ["image/png", "image/jpg", "image/jpeg"],
                    "File must be an image"
                )
                .notRequired()
                .default(undefined)
                .label("File"),
            fileSize: yup
                .number()
                .max(2000000, "File size must be less or equal to 2MB")
                .notRequired()
                .default(undefined)
                .label("File")
        })
        .notRequired(),
    accountUrl: yup.string().url().notRequired().min(5).label("accountUrl"),
    accountName: yup.string().notRequired().min(5).max(20).label("accountName"),
    accountTextColor: yup
        .string()
        .notRequired()
        .min(7)
        .label("accountTextColor"),
    accountBackgroundColor: yup
        .string()
        .notRequired()
        .min(7)
        .label("accountBackgroundColor"),
    accountCategoryId: yup.string().required().label("Category"),
    accountUserIdentifierLabel: yup
        .string()
        .notRequired()
        .min(3)
        .label("accountUserIdentifierLabel"),
    isEntryInCustomAccountText: yup
        .boolean()
        .notRequired()
        .label("isEntryInCustomAccountText"),
    isAccountActive: yup.boolean().notRequired().label("isAccountActive"),
    isCustomUrl: yup.boolean().notRequired().label("isCustomUrl")
});

export default function EditAccount({
    account,
    onSuccess,
    accountCategoryList
}) {
    // const params = useParams();
    const classes = useStyles();
    // const globalClasses = useGlobalStyles();
    const setDefaultValues = () => {
        account.accountCategoryId = {
            ...account.accountCategoryId,
            id: account?.accountCategoryId?._id
        };
        return account;
    };
    // const [account, setAccount] = useState({});
    const [error, setError] = useState("");
    const [successMsg, setSuccessMsg] = useState("");
    const [accountData, setAccountData] = useState(setDefaultValues);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        // const id = params.accountId;
        // accountsOps
        //     .getAccountById(id)
        //     .then((res) => {
        //         setLoading(false);
        //         setAccount(res?.data?.account);
        //         setAccountData(res?.data?.account);
        //     })
        //     .catch((ex) => {
        //         setLoading(false);
        //         setError(ex?.response?.data?.message);
        //     });
        // getyD;
        // eslint-disable-next-line
    }, []);

    const handlefileInput = (e) => {
        e.preventDefault();
        const input = e.currentTarget;
        const file = input.files[0];
        if (file) {
            const fileType = file.type;

            toBase64(file)
                .then((base64File) => {
                    setAccountData({
                        ...accountData,
                        accountImage: {
                            file: base64File,
                            fileSize: file.size,
                            fileType
                        }
                    });
                })
                .catch((error) => {});
        }
    };

    const handleChange = (e) => {
        var val = e.target.value;
        const fieldName = e.target.name;
        setError("");
        if (fieldName === "accountUrl") {
            val = e.currentTarget.value.trim().toLowerCase();
        }
        setAccountData({ ...accountData, [fieldName]: val });
    };

    const submitForm = (e) => {
        e.preventDefault();
        accountData.accountId = account._id;
        accountData.accountCategoryId = accountData?.accountCategory?.id;

        delete accountData._id;
        // delete accountData.accountCategoryId;

        console.log("accountData", accountData);
        // alert(JSON.stringify(accountData, null, 2));

        schema
            .validate(accountData)
            .then(async () => {
                try {
                    setLoading(true);
                    const res = await accountsOps.editAccountById(accountData);
                    const account = res?.data?.account;
                    setAccountData(account);
                    setSuccessMsg(res?.data?.message);
                    onSuccess(account);
                    setLoading(false);
                } catch (ex) {
                    setLoading(false);
                    setError(ex?.response?.data?.message);
                }
            })
            .catch((err) => {
                setLoading(false);
                setError(err.message);
            });
        // alert(JSON.stringify(accountData, null, 2));
    };

    return (
        <Grid container>
            <Grid item md={12} lg={12} sm={12} xs={12}>
                <Box bgcolor="background.default" width="100%" padding={2}>
                    <form onSubmit={submitForm}>
                        {loading && (
                            <Box align="center">
                                <CircularProgress size={28} />
                            </Box>
                        )}
                        {error && (
                            <Box mb={1}>
                                <Alert severity="error">{error}</Alert>
                            </Box>
                        )}
                        {successMsg && (
                            <Box mb={1}>
                                <Alert severity="success">{successMsg}</Alert>
                            </Box>
                        )}

                        <Box mb={2}>
                            <Grid
                                container
                                direction="row"
                                justify="left"
                                alignItems="left"
                                spacing={2}
                            >
                                <Grid item lg={1} md={2} sm={3} xs={4}>
                                    <div style={{ position: "relative" }}>
                                        <Avatar
                                            variant={"rounded"}
                                            className={clsx(
                                                classes.previewIcon
                                            )}
                                            src={
                                                accountData?.accountImage
                                                    ?.file ||
                                                accountData?.accountImageUrl
                                            }
                                        ></Avatar>
                                        <Box
                                            className={
                                                classes.previewIconOverlay
                                            }
                                        >
                                            <Typography
                                                align="center"
                                                className={
                                                    classes.changeAvatarText
                                                }
                                            >
                                                <Button
                                                    component="label"
                                                    style={{ fontSize: 8 }}
                                                >
                                                    Change
                                                    <input
                                                        type="file"
                                                        style={{
                                                            display: "none"
                                                        }}
                                                        accept="image/png, image/jpg, image/jpeg"
                                                        onChange={
                                                            handlefileInput
                                                        }
                                                    />
                                                </Button>
                                            </Typography>
                                        </Box>
                                    </div>
                                </Grid>
                                <Grid lg={5} md={4} sm={9} xs={12} item>
                                    <Box width={"100%"} mt={2}>
                                        <Typography variant="subtitle1">
                                            Account
                                        </Typography>
                                        <TextField
                                            required
                                            value={
                                                accountData?.accountName ||
                                                account?.accountName
                                            }
                                            name="accountName"
                                            onChange={handleChange}
                                            placeholder="e.g. Straeam"
                                            fullWidth
                                        />
                                    </Box>
                                </Grid>
                                <Grid item lg={6} md={6} sm={12} xs={12}>
                                    <Box width={"100%"} mt={2}>
                                        <Typography variant="subtitle1">
                                            URL
                                        </Typography>
                                        <TextField
                                            required
                                            value={accountData?.accountUrl}
                                            placeholder="e.g. https://steamcommunity.com/"
                                            name="accountUrl"
                                            onChange={handleChange}
                                            fullWidth
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>

                        <Grid container spacing={2}>
                            <Grid item lg={6} md={6} sm={12} xs={12}>
                                <Typography variant="subtitle1">
                                    Category
                                </Typography>
                                <Autocomplete
                                    loading={loading}
                                    fullWidth
                                    options={accountCategoryList?.map(
                                        (e) => e.title || ""
                                    )}
                                    value={
                                        accountData?.accountCategoryId?.title
                                    }
                                    onChange={(e, val) => {
                                        e.preventDefault();

                                        const acList =
                                            accountCategoryList.filter(
                                                (e) => e.title === val
                                            );
                                        const ac = acList[0];
                                        setError("");
                                        console.log(val, ac);
                                        setAccountData({
                                            ...accountData,
                                            accountCategory: {
                                                title: ac.title,
                                                id: ac._id
                                            }
                                        });
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            fullWidth
                                            label=""
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item lg={6} md={6} sm={12} xs={12}>
                                <Typography variant="subtitle1">
                                    User Identifier Field Label
                                </Typography>
                                <TextField
                                    required
                                    name={"accountUserIdentifierLabel"}
                                    value={
                                        accountData?.accountUserIdentifierLabel
                                    }
                                    placeholder="e.g. Username"
                                    onChange={handleChange}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item lg={6} md={6} sm={12} xs={12}>
                                <ColorPickerField
                                    fieldLabel={" Custom Text Color"}
                                    color={accountData?.accountTextColor}
                                    onColorPickerValueChange={(color) => {
                                        setError("");
                                        setAccountData({
                                            ...accountData,
                                            accountTextColor: `#${color.hex}`
                                        });
                                    }}
                                    onFieldValueChange={(e) => {
                                        setError("");
                                        setAccountData({
                                            ...accountData,
                                            accountTextColor:
                                                e.currentTarget.value
                                        });
                                    }}
                                />
                            </Grid>
                            <Grid item lg={6} md={6} sm={12} xs={12}>
                                <ColorPickerField
                                    fieldLabel={"Custom Account Color"}
                                    color={accountData?.accountBackgroundColor}
                                    onColorPickerValueChange={(color) => {
                                        setError("");
                                        setAccountData({
                                            ...accountData,
                                            accountBackgroundColor: `#${color.hex}`
                                        });
                                    }}
                                    onFieldValueChange={(e) => {
                                        setError("");
                                        setAccountData({
                                            ...accountData,
                                            accountBackgroundColor:
                                                e.currentTarget.value
                                        });
                                    }}
                                />
                            </Grid>
                            <Grid item lg={4} md={4} sm={12} xs={12}>
                                <Typography variant="subtitle1">
                                    Entery in Account Text
                                </Typography>
                                <Switch
                                    color={"primary"}
                                    name={"isEntryInCustomAccountText"}
                                    checked={Boolean(
                                        accountData?.isEntryInCustomAccountText
                                    )}
                                    // onChange={handleChange}
                                    // onChange={() =>
                                    //     setEntryInCustomAccountText(
                                    //         !isEntryInCustomAccountText
                                    //     )
                                    // }
                                    onChange={() =>
                                        setAccountData({
                                            ...accountData,
                                            isEntryInCustomAccountText:
                                                !accountData.isEntryInCustomAccountText
                                        })
                                    }
                                />
                            </Grid>
                            <Grid item lg={4} md={4} sm={12} xs={12}>
                                <Typography variant="subtitle1">
                                    Active Account
                                </Typography>
                                <Switch
                                    color={"primary"}
                                    checked={Boolean(
                                        accountData?.isAccountActive
                                    )}
                                    name={"isAccountActive"}
                                    // onChange={handleChange}

                                    onChange={() =>
                                        setAccountData({
                                            ...accountData,
                                            isAccountActive:
                                                !accountData.isAccountActive
                                        })
                                    }
                                />
                            </Grid>
                            <Grid item lg={4} md={4} sm={12} xs={12}>
                                <Typography variant="subtitle1">
                                    Custom Url
                                </Typography>
                                <Switch
                                    color={"primary"}
                                    checked={Boolean(accountData?.isCustomUrl)}
                                    name={"isCustomUrl"}
                                    // onChange={handleChange}
                                    // onChange={() =>
                                    //     setCustomUrl(!isCustomUrl)
                                    // }
                                    onChange={() =>
                                        setAccountData({
                                            ...accountData,
                                            isCustomUrl:
                                                !accountData.isCustomUrl
                                        })
                                    }
                                />
                            </Grid>
                        </Grid>

                        <Box mt={2}>
                            <Button
                                color="primary"
                                type="submit"
                                variant="contained"
                                fullWidth
                                size="small"
                            >
                                Save
                            </Button>
                        </Box>
                    </form>
                </Box>
            </Grid>
        </Grid>
    );
}
