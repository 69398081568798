import {
    Container,
    // createTheme,
    // makeStyles,
    useMediaQuery
} from "@material-ui/core";
import React from "react";
import Footer from "./Footer";
import Gaming from "./Gaming";
import Hero from "./Hero";
import Navbar from "./NavBar";
import Setup from "./Setup";
import Standout from "./Standout";

// const useStyles = makeStyles((theme) => ({
//     root: { backgroundColor: "#0C0C0C" }
// }));

// const theme = createTheme({
//     components: {
//         MuiContainer: {
//             styleOverrides: {
//                 maxWidthXl: {
//                     maxWidth: 1440
//                 }
//             }
//         }
//     }
// });

const LandingPage = () => {
    const isSmallScreen = useMediaQuery("(max-width:960px)");
    // const classes = useStyles();
    return (
        <Container
            style={{
                backgroundColor: "black"
            }}
            maxWidth={isSmallScreen ? "" : "xl"}
            // sx={{ mt: 2 }}
        >
            <Container maxWidth={isSmallScreen ? "" : "lg"}>
                <Navbar />
                <Hero />
                <Gaming />
                <Standout />
                <Setup />
                <Footer />
            </Container>
        </Container>
    );
};

export default LandingPage;
