import React from "react";

import { Box, Grid, makeStyles, Typography } from "@material-ui/core";
import config from "../../config";
// import clsx from "clsx";
import { CheckCircle } from "@material-ui/icons";

const SQUARE = config.shape.square;
const CIRCLE = config.shape.circle;
const ROUNDED = config.shape.rounded;

const useStyles = makeStyles((theme) => ({
    shapeArea: {
        position: "relative",
        // backgroundColor: theme.palette.secondary.main,
        // border:'1px solid #fff',
        borderWidth: 2,
        borderStyle: "solid",
        borderColor: theme.palette.text.primary,
        width: theme.spacing(5),
        height: theme.spacing(5),
        marginBottom: theme.spacing(1),
        outlineWidth: 1,
        // outlineColor: theme.palette.secondary.main,
        // outlineStyle: "dotted",
        cursor: "pointer",
        [theme.breakpoints.down("xs")]: {
            width: theme.spacing(4),
            height: theme.spacing(4)
        }
    },
    selectedShapeArea: {
        cursor: "pointer",
        // backgroundColor: theme.palette.primary.main,
        borderWidth: 2,
        borderStyle: "solid",
        // borderColor: theme.palette.secondary.main,
        width: theme.spacing(5),
        height: theme.spacing(5),
        marginBottom: theme.spacing(1),
        outlineWidth: 1,
        // outlineColor: theme.palette.primary.main,
        // outlineStyle: "dotted",
        outlineOffset: 1,
        MozOutlineRadius: 4,
        outlineRadius: 4,
        [theme.breakpoints.down("xs")]: {
            width: theme.spacing(4),
            height: theme.spacing(4)
        }
    },
    shapesTitle: {
        fontSize: 12,
        fontWeight: 100
    },
    check: {
        position: "absolute",
        top: 6,
        left: 6,
        [theme.breakpoints.down("xs")]: {
            top: 2,
            left: 2
        }
    }
}));

export default function ShapeSelector({
    setSelectedAccountShape,
    selectedAccountShape,
    label = "Shape"
}) {
    const classes = useStyles();
    const checkUI = (
        <Box className={classes.check}>
            <CheckCircle color="primary" />
        </Box>
    );

    return (
        <Box>
            <Typography color="textPrimary" variant="subtitle1" gutterBottom>
                {label}
            </Typography>
            <Grid container direction="row" spacing={2}>
                <Grid item align={"center"}>
                    <Box
                        onClick={() => setSelectedAccountShape(SQUARE)}
                        className={classes.shapeArea}
                        // className={clsx(
                        //     selectedAccountShape !== SQUARE &&
                        //         classes.shapeArea,
                        //     selectedAccountShape === SQUARE &&
                        //         classes.selectedShapeArea
                        // )}
                    >
                        {selectedAccountShape === SQUARE && checkUI}
                    </Box>

                    <Typography
                        className={classes.shapesTitle}
                        color="textPrimary"
                    >
                        Square
                    </Typography>
                </Grid>
                <Grid item align={"center"}>
                    <Box
                        borderRadius={8}
                        className={classes.shapeArea}
                        // className={clsx(
                        //     selectedAccountShape !== ROUNDED &&
                        //         classes.shapeArea,
                        //     selectedAccountShape === ROUNDED &&
                        //         classes.selectedShapeArea
                        // )}
                        onClick={() => setSelectedAccountShape(ROUNDED)}
                    >
                        {selectedAccountShape === ROUNDED && checkUI}
                    </Box>
                    <Typography
                        className={classes.shapesTitle}
                        color="textPrimary"
                    >
                        Rounded
                    </Typography>
                </Grid>
                <Grid item align={"center"}>
                    <Box
                        borderRadius="50%"
                        className={classes.shapeArea}
                        // className={clsx(
                        //     selectedAccountShape !== CIRCLE &&
                        //         classes.shapeArea,
                        //     selectedAccountShape === CIRCLE &&
                        //         classes.selectedShapeArea
                        // )}
                        onClick={() => setSelectedAccountShape(CIRCLE)}
                    >
                        {selectedAccountShape === CIRCLE && checkUI}
                    </Box>

                    <Typography
                        className={classes.shapesTitle}
                        color="textPrimary"
                    >
                        Circle
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    );
}
