import {
    Box,
    Button,
    CircularProgress,
    Collapse,
    Grid,
    InputAdornment,
    // makeStyles,
    TextField,
    Typography
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import DragHandleIcon from "@material-ui/icons/DragHandle";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
// import useGlobalStyles from "../../../../../../../hooks/useGlobalStyles";
import actionTypes from "../../../../../../../redux/actionTypes";
import userService from "../../../../../../../services/user";
import ColorPickerField from "../../../../../../../components/core/ColorPickerField";
import config from "../../../../../../../config";
// import AccordionCustomHeader from "../../../../../../components/core/AccordionCustomHeader";
import { useSnackbar } from "notistack";
import * as Yup from "yup";
import AccountHeader from "../../../components/AccountHeader";

const validationSchema = Yup.object({
    userId: Yup.string().required(),
    accountId: Yup.string().required(),
    userIdentifier: Yup.string()
        .required()
        .min(config.constants.nameMinCharacter)
        .max(62),
    customAccountText: Yup.string().notRequired(),
    accountTextColor: Yup.string().required(),
    accountBackgroundColor: Yup.string().required()
});

const BORDER = config.accountStyle.border;

// const useStyles = makeStyles((theme) => ({
//     absoluteSwitch: {
//         top: theme.spacing(3.3),
//         right: theme.spacing(2),
//         zIndex: 10
//     },
//     toggleMargin: {
//         marginTop: theme.spacing(2)
//     },
//     saveBtn: {
//         backgroundColor: theme.palette.primary.dark,
//         padding: ({ loading }) => loading && 1.4,
//         color: theme.palette.background.paper,
//         "&:hover": {
//             backgroundColor: theme.palette.primary.dark
//         }
//     }
// }));
export default function AddUserAccount(props) {
    // const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    // const globalClasses = useGlobalStyles();
    const user = useSelector((state) => state.auth.user);
    const { selectedAccount } = props;
    const [userIdentifier, setUserIdentifier] = React.useState("");
    const [customAccountText, setCustomAccountText] = React.useState("");
    const [accountTextColor, setAccountTextColor] = useState();
    const [accountBackgroundColor, setAccountBackgroundColor] = useState();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    // const [successMsg, setSuccessMsg] = useState("");

    const baseAccountSettings = user?.baseAccountSettings;

    useEffect(() => {
        setAccountTextColor(selectedAccount.accountTextColor);
        setAccountBackgroundColor(selectedAccount.accountBackgroundColor);
    }, [selectedAccount]);

    const resetErrors = () => {
        setTimeout(() => {
            setError("");
        }, 3000);
    };
    const handleAccountSubmit = (e) => {
        e.preventDefault();
        let newAccount = {
            userId: user._id,
            accountId: selectedAccount._id,
            userIdentifier: userIdentifier
        };
        if (customAccountText) {
            newAccount.customAccountText = customAccountText;
        }
        if (accountTextColor) {
            newAccount.accountTextColor = accountTextColor;
        }
        if (accountBackgroundColor) {
            newAccount.accountBackgroundColor = accountBackgroundColor;
        }
        validationSchema
            .validate(newAccount)
            .then(() => {
                setLoading({ status: true, type: "addAccount" });

                userService
                    .addUserAccount(newAccount)
                    .then((res) => {
                        enqueueSnackbar(config.defaultNotiStackMessage, {
                            variant: "success"
                        });
                        setLoading(false);

                        const accounts = res.data.accounts;

                        setUserIdentifier("");
                        setCustomAccountText("");
                        props.closeAddAccount();
                        dispatch({
                            type: actionTypes.UPDATE_USER,
                            payload: { accounts }
                        });
                    })
                    .catch((ex) => {
                        enqueueSnackbar(ex?.response?.data?.message, {
                            variant: "error"
                        });
                        setLoading(false);
                        // setError({
                        //     text: ex?.response?.data?.message,
                        //     type: "addAccount"
                        // });
                        // resetErrors();
                    });
            })
            .catch((err) => {
                setLoading(false);
                setError({
                    text: err.message,
                    type: "addAccount"
                });
                resetErrors();
            });
    };

    const accountForm = (
        <form onSubmit={handleAccountSubmit}>
            <Box mb={1.5}>
                {error && error.text && error.type === "addAccount" && (
                    <Alert severity="error">{error.text}</Alert>
                )}

                <Typography variant="subtitle1" color="textSecondary">
                    {selectedAccount.accountUserIdentifierLabel}
                </Typography>
                <TextField
                    variant="outlined"
                    placeholder={selectedAccount.accountUserIdentifierLabel}
                    fullWidth
                    value={userIdentifier}
                    onChange={(e) => setUserIdentifier(e.currentTarget.value)}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Typography color="primary">
                                    {selectedAccount.accountUrl}
                                </Typography>
                            </InputAdornment>
                        )
                    }}
                />
            </Box>

            <Box mb={1.5}>
                {selectedAccount.isEntryInCustomAccountText && (
                    <div>
                        <Typography variant="subtitle1" color="textSecondary">
                            Custom Text
                        </Typography>
                        <TextField
                            variant="outlined"
                            placeholder={
                                "e.g. My " + selectedAccount.accountName
                            }
                            fullWidth
                            value={customAccountText}
                            onChange={(e) =>
                                setCustomAccountText(e.currentTarget.value)
                            }
                        />
                    </div>
                )}
            </Box>
            {baseAccountSettings?.accountStyle !== BORDER && (
                <Grid container spacing={4}>
                    <Grid item sm={6} md={6} lg={6} xs={6}>
                        <ColorPickerField
                            fieldLabel={"Custom Text Color"}
                            color={accountTextColor}
                            onColorPickerValueChange={(color) => {
                                setError("");
                                setAccountTextColor(`#${color.hex}`);
                            }}
                            onFieldValueChange={(e) => {
                                setError("");
                                setAccountTextColor(`${e.currentTarget.value}`);
                            }}
                        />
                    </Grid>
                    <Grid item sm={6} md={6} lg={6} xs={6}>
                        <ColorPickerField
                            fieldLabel={"Custom Account Color"}
                            color={accountBackgroundColor}
                            onColorPickerValueChange={(color) => {
                                setError("");
                                setAccountBackgroundColor(`#${color.hex}`);
                            }}
                            onFieldValueChange={(e) => {
                                setError("");
                                setAccountBackgroundColor(
                                    `${e.currentTarget.value}`
                                );
                            }}
                        />
                    </Grid>
                </Grid>
            )}
            {baseAccountSettings?.accountStyle === BORDER && (
                <Grid container spacing={4}>
                    <Grid item sm={12} md={12} lg={12} xs={12}>
                        <ColorPickerField
                            fieldLabel={"Custom Account Color"}
                            color={accountBackgroundColor}
                            onColorPickerValueChange={(color) => {
                                setError("");
                                setAccountBackgroundColor(`#${color.hex}`);
                            }}
                            onFieldValueChange={(e) => {
                                setError("");
                                setAccountBackgroundColor(
                                    `${e.currentTarget.value}`
                                );
                            }}
                        />
                    </Grid>
                </Grid>
            )}
            <Box mt={2}>
                <Button
                    color="primary"
                    type="submit"
                    fullWidth
                    // className={classes.saveBtn}
                    disabled={loading}
                >
                    {loading ? (
                        <Box
                            align="center"
                            mt={0.5}
                            // className={classes.saveBtn}
                        >
                            <CircularProgress
                                size={16}
                                // className={classes.saveBtn}
                            ></CircularProgress>
                        </Box>
                    ) : (
                        "Save"
                    )}
                </Button>
            </Box>
        </form>
    );

    return (
        <Collapse in={selectedAccount && selectedAccount._id}>
            {/* <Grid container>
                <Grid item md={12} xs={12}> */}
            {/* <Box display="flex" width={"100%"} mb={2}> */}
            {/* <Box mr={1} mt={1.5}>
                            <DragHandleIcon color="disabled" />
                        </Box> */}

            <Box width={"100%"} mb={2}>
                <AccountHeader
                    label={selectedAccount.accountName}
                    textColor={selectedAccount.accountTextColor}
                    avatar={selectedAccount.accountImageUrl}
                    bgColor={selectedAccount.accountBackgroundColor}
                    hasSwitch={true}
                    isSwitchChecked={true}
                    onSwitchChange={props.closeAddAccount}
                />

                <Box
                    // pl={
                    //     baseAccountSettings?.accountIconPosition ===
                    //     config.iconPosition.left
                    //         ? 6
                    //         : 0
                    // }
                    // pl={4}
                    ml={5}
                >
                    <Grid container>
                        <Grid item md={6} xs={6}>
                            <Button
                                size="small"
                                color="primary"
                                onClick={props.resetSelectedAccount}
                                startIcon={
                                    <ArrowBackIosIcon
                                        style={{ fontSize: 12 }}
                                        // fontSize={"large"}
                                    />
                                }
                            >
                                Back
                            </Button>
                        </Grid>
                    </Grid>
                    {accountForm}
                </Box>
            </Box>
            {/* </Box> */}
            {/* </Grid>
            </Grid> */}
        </Collapse>
    );
}
