import config from "../config";
import axios from "../config/axios";

const getSubscriptionPlans = async (baseUrl) => {
    return axios.get(
        `${config.apiEndPoint}${config.apiRoutes.subscription.plans}`
    );
};

const subscribeUser = (data) => {
    return axios.post(
        `${config.apiEndPoint}${config.apiRoutes.subscription.subscribe}`,
        data
    );
};
const verifySubscription = () => {
    return axios.get(
        `${config.apiEndPoint}${config.apiRoutes.subscription.verify}`
    );
};
const cancelSubscription = () => {
    return axios.get(
        `${config.apiEndPoint}${config.apiRoutes.subscription.cancel}`
    );
};
const resumeSubscription = () => {
    return axios.get(
        `${config.apiEndPoint}${config.apiRoutes.subscription.resume}`
    );
};
const updateSubscription = (data) => {
    return axios.put(
        `${config.apiEndPoint}${config.apiRoutes.subscription.update}`,
        data
    );
};
const getCustomerInvoices = () => {
    return axios.get(
        `${config.apiEndPoint}${config.apiRoutes.subscription.customerInvoice}`
    );
};

export default {
    getSubscriptionPlans,
    subscribeUser,
    cancelSubscription,
    getCustomerInvoices,
    resumeSubscription,
    verifySubscription,
    updateSubscription
};
