import React from "react";
import {
    Container,
    Paper,
    Tabs,
    Tab,
    Box,
    Backdrop,
    Typography,
    makeStyles
} from "@material-ui/core";

import UserAppBar from "../../../components/core/UserAppBar/index";
// import useGlobalStyles from "../../../hooks/useGlobalStyles";
import EditBaseDetails from "./editBaseDetails/index";
import Beta from "../../../components/common/Beta";
import AddAccount from "./accounts/addAccount";
import PreviewMessage from "../../../components/common/PreviewMessage";
import { useSelector } from "react-redux";
import config from "../../../config";
import EditBaseBranding from "./editBaseBranding";
import EditBaseBanner from "./editBaseBanner";
import EditBaseSponsorLogos from "./addBaseSponsorLogos";
// import Integrations from "./Integrations";
import authService from "../../../services/auth";
import Footer from "../../../components/common/Footer";
// import clsx from "clsx";

const BORDER = config.accountStyle.border;

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`wrapped-tabpanel-${index}`}
            aria-labelledby={`wrapped-tab-${index}`}
            {...other}
        >
            {value === index && <div>{children}</div>}
        </div>
    );
}
function a11yProps(index) {
    return {
        id: `wrapped-tab-${index}`,
        "aria-controls": `wrapped-tabpanel-${index}`
    };
}
const useStyles = makeStyles((theme) => ({
    backdrop: {
        backgroundColor: "rgba(0,0,0,0.8)",
        position: "absolute",
        zIndex: theme.zIndex.drawer + 1
        // color: "#fff"
    }
}));
export default function Dashboard(props) {
    // const globalClasses = useGlobalStyles();
    // const classes = useCl();
    const classes = useStyles();

    const auth = useSelector((state) => state.auth);
    const [tabValue, setTabValue] = React.useState(0);
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };
    const user = auth.user;
    const baseAccountSettings = user.baseAccountSettings;
    const isManagedDesignActive = user?.managedBy?.isManagedDesignActive;

    return (
        <div>
            <UserAppBar />

            <Container maxWidth="md">
                <Box p={2}>
                    <Beta />
                </Box>
                {baseAccountSettings?.accountStyle === BORDER && (
                    <Box my={2}>
                        <PreviewMessage
                            message={
                                "Your Account Design is set to Outline. Your Base Background option is shown in the preview."
                            }
                        />
                    </Box>
                )}
                <Box>
                    <Box my={2}>
                        <Tabs
                            value={tabValue}
                            onChange={handleTabChange}
                            aria-label="dashboard tabs"
                            variant="fullWidth"
                            indicatorColor="primary"
                        >
                            <Tab
                                label="Accounts"
                                {...a11yProps(0)}

                                // className={classes.tabBtn}
                            />
                            {/* <Tab
                                        label="Integrations"
                                        {...a11yProps(1)}
                                        // className={classes.tabBtn}
                                    /> */}
                            <Tab
                                label="Logos"
                                {...a11yProps(1)}
                                // className={classes.tabBtn}
                            />
                        </Tabs>
                    </Box>
                    <Paper>
                        <Box p={4}>
                            <TabPanel value={tabValue} index={0}>
                                <AddAccount />
                            </TabPanel>
                            {/* <TabPanel value={tabValue} index={1}>
                                <Integrations />
                            </TabPanel> */}
                            <TabPanel value={tabValue} index={1}>
                                <EditBaseSponsorLogos />
                            </TabPanel>
                        </Box>
                    </Paper>
                </Box>

                {authService.isCurrentUserSelected() && (
                    <Box mt={2}>
                        <Paper>
                            <Box p={4}>
                                <EditBaseDetails />
                            </Box>
                        </Paper>
                    </Box>
                )}

                <Box position={"relative"}>
                    <Container>
                        <Backdrop
                            className={classes.backdrop}
                            open={isManagedDesignActive}
                        >
                            <Box
                                position={"absolute"}
                                top={130}
                                // width={600}
                                textAlign={"center"}
                            >
                                <Typography variant="h5">
                                    <b>
                                        <span
                                            role="img"
                                            aria-label="management"
                                        >
                                            💼{" "}
                                        </span>
                                        This Base is being managed.
                                        <br /> Please contact the Manager or
                                        disable Management.
                                    </b>
                                </Typography>
                            </Box>
                        </Backdrop>
                    </Container>

                    <Box mt={2}>
                        <Paper>
                            <Box p={4}>
                                <EditBaseBanner />
                            </Box>
                        </Paper>
                    </Box>

                    {!isManagedDesignActive && (
                        <Box mt={2}>
                            <Paper>
                                <Box p={4}>
                                    <EditBaseBranding />
                                </Box>
                            </Paper>
                        </Box>
                    )}
                </Box>
                <Box p={2}>
                    <Footer />
                </Box>
            </Container>
        </div>
    );
}
