import React, { useState } from "react";
import { Box, Grid, Switch, Typography } from "@material-ui/core";
import HeaderImageDropzone from "../../../components/core/HeaderImageDropzone";
import Collapse from "@material-ui/core/Collapse";

import CustomBadge from "../../../components/common/CustomBadge";
import LabelWithEmoji from "../../../components/common/LabelWithEmoji";
import SaveActionButton from "./SaveActionButton";

export default function HeaderUI({
    enableFeature = false,
    disableHeaderSwitch = false,
    handleHeaderStatus = () => {},
    handleSaveHeader = () => {},
    handleDeleteHeader = () => {},
    isHeaderActive = false,
    baseHeader,
    isSavingHeader = false,
    isDeletingHeader = false
}) {
    const [croppedImage, setCroppedImage] = useState();

    return (
        <div>
            <Box mt={1}>
                <Grid container>
                    <Grid item xs={6} sm={6} md={6} lg={6}>
                        <Box>
                            {!enableFeature ? (
                                <Box display="flex">
                                    <Typography
                                        color="textPrimary"
                                        variant="h6"
                                    >
                                        <LabelWithEmoji
                                            text="Header"
                                            emoji="🏬"
                                        />
                                    </Typography>
                                    <CustomBadge
                                        badgeContent={"plus"}
                                        uppercase={true}
                                    ></CustomBadge>
                                </Box>
                            ) : (
                                <Typography color="textPrimary" variant="h6">
                                    <LabelWithEmoji text="Header" emoji="🏬" />
                                </Typography>
                            )}
                        </Box>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6}>
                        <Box align="right">
                            <Switch
                                color="primary"
                                checked={enableFeature && isHeaderActive}
                                onChange={handleHeaderStatus}
                                disabled={!enableFeature || disableHeaderSwitch}
                            />
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <Collapse in={enableFeature && isHeaderActive}>
                <Box>
                    <SaveActionButton
                        label=""
                        isLoading={isSavingHeader}
                        onSave={() => handleSaveHeader(croppedImage)}
                    />
                </Box>
                <Box style={{ position: "relative" }} variant={"rounded"}>
                    <HeaderImageDropzone
                        previewImg={baseHeader}
                        onImageChange={setCroppedImage}
                        croppedImage={croppedImage?.file}
                        onDelete={handleDeleteHeader}
                        isDeleting={isDeletingHeader}
                    />
                </Box>
            </Collapse>
        </div>
    );
}
