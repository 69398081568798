const { makeStyles } = require("@material-ui/core");

const useGlobalStyles = makeStyles((theme) => ({
    root: {
        width: "100%"
    },
    grow: {
        flexGrow: 1
    },
    appBar: {
        // backgroundColor: theme.palette.background.paper
    },
    paper: {
        // padding: theme.spacing(1),
        // backgroundColor: theme.palette.secondary.main,
        // boxShadow: "0px 1px 3px 0px rgba(0,0,0,0)",
        // // boxShadow: "0px 1px 3px 0px rgba(0,0,0,0.8)",
        // [theme.breakpoints.down("sm")]: {
        //     padding: theme.spacing(0.5)
        // }
    },

    bottomShadow: {
        // margin: theme.spacing(0.5),
        // paddingBottom: theme.spacing(2),
        // backgroundColor: theme.palette.secondary.main,
        // boxShadow: " 0 2px 2px -2px rgba(0,0,0,0.8)"
    },
    removeBottomShadow: {
        // margin: theme.spacing(0.5),
        // paddingBottom: theme.spacing(1),
        // backgroundColor: theme.palette.secondary.main,
        // boxShadow: " 0 2px 2px -2px rgba(0,0,0,0)"
    },

    container: {
        // padding: theme.spacing(4),
        // // marginTop: theme.spacing(-15),
        // [theme.breakpoints.down("sm")]: {
        //     padding: theme.spacing(1)
        // }
    },
    input: {
        // "& .MuiInput-underline:before": {
        //     // borderBottomStyle: "solid",
        //     // borderBottomWidth: 1,
        //     // borderColor: theme.palette.primary.main,
        //     borderBottom: `1px solid ${theme.palette.primary.main}`
        // },
        // "& .MuiInputBase-input ": {
        //     fontSize: 12,
        //     paddingTop: theme.spacing(1)
        //     // borderBottom: `1px solid ${theme.palette.primary.main}`,
        // },
        // "& .MuiInputBase-input::placeholder ": {
        //     fontSize: 12,
        //     fontWeight: 100,
        //     color: theme.palette.primary.main
        // }
    },
    select: {
        // "& .MuiSelect-icon": {
        //     color: theme.palette.primary.main
        // },
        // "&:before": {
        //     borderBottom: `1px solid ${theme.palette.primary.main}`
        // },
        // "& .MuiSelect-select.MuiSelect-select": {
        //     fontSize: 12
        // }
    },
    switch: {
        // padding:theme.spacing(0)
        // marginTop: theme.spacing(-1)
    },
    editButton: {
        // color: theme.palette.info.dark,
        // border: "none"
    },
    deleteButton: {
        // color: theme.palette.error.dark,
        // border: "none"
    },
    logoutButton: {
        // backgroundColor: theme.palette.error.light,
        // border: "none",
        // "&:hover": {
        //     backgroundColor: theme.palette.error.light
        // }
    },
    smallButton: {
        // fontSize: theme.spacing(1.5)
        // fontSize: "1rem",
        // height: "1rem",
        // width: "1rem"
    },
    smallAvatar: {
        // marginTop: theme.spacing(-0.8),
        // width: theme.spacing(4),
        // height: theme.spacing(4)
    },
    alert: {
        // marginBottom: theme.spacing(1)
    },
    accordion: {
        // width: "100%",
        // border: "none"
    },
    accordionWithoutShadow: {
        // width: "100%",
        // border: "none",
        // "&.MuiAccordion-root:before": {
        //     backgroundColor: "transparent"
        // },
        // boxShadow: "none"
    },
    accordionSummary: {
        // height: theme.spacing(5),
        // minHeight: theme.spacing(5),
        // padding: 0,
        // boxShadow: "0px 5px 10px -2px rgba(0,0,0,0.8)"
    },
    accordionSummaryWithoutShadow: {
        // height: theme.spacing(5),
        // minHeight: theme.spacing(5),
        // padding: 0,
        // boxShadow: "none"
    },
    checkIcon: {
        // height: theme.spacing(2.3),
        // marginBottom: theme.spacing(-0.5),
        // marginLeft: theme.spacing(0.5)
    },
    aTag: {
        textDecoration: "none"
        // color: theme.palette.text.primary
    },
    aTagText: {
        color: theme.palette.primary.main
    },

    textTransformNone: { textTransform: "none" },
    containedIconButton: {
        backgroundColor: theme.palette.primary.main, // Change the color as needed
        color: theme.palette.primary.contrastText,
        padding: theme.spacing(1),
        // borderRadius: theme.shape.borderRadius,
        "&:hover": {
            backgroundColor: theme.palette.primary.dark,
            color: theme.palette.primary.contrastText
        }
    }
}));

export default useGlobalStyles;
