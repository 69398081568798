import React from "react";

export default function LabelWithEmoji({ emoji, text }) {
    return (
        <span>
            <span aria-label={text} role="img">
                {emoji}
            </span>
            {" " + text}
        </span>
    );
}
