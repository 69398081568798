/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Box, Collapse, Switch, Typography } from "@material-ui/core";

import SaveActionButton from "./SaveActionButton";
import LabelWithEmoji from "../../../components/common/LabelWithEmoji";
import AccountIconPositionSelector from "../../../components/core/AccountIconPositionSelector";
import ShapeSelector from "../../../components/core/ShapeSelector";
import config from "../../../config";

const CIRCLE = config.shape.circle;
const INSIDE = config.iconPosition.inside;

export default function AccountIconDesignUI({
    disableIconSwitch = false,
    isLoading = false,
    isAccountIcon,
    accountIconShape,
    accountIconPosition,
    onAccountIconsStatusChange = () => {},
    onAccounIconDesignUpdate = () => {}
}) {
    const [selectedIconShape, setSelectedIconShape] = useState(CIRCLE);
    const [selectedIconPosition, setSelectedIconPosition] = useState(INSIDE);

    useEffect(() => {
        setStates();
    }, []);

    useEffect(() => {
        setStates();
    }, [accountIconPosition, accountIconPosition]);

    const setStates = () => {
        if (accountIconShape) {
            setSelectedIconShape(accountIconShape);
        }
        if (accountIconPosition) {
            setSelectedIconPosition(accountIconPosition);
        }
    };

    const handleAccountIconDesign = (e) => {
        e.preventDefault();
        if (
            selectedIconShape !== accountIconShape ||
            selectedIconPosition !== accountIconPosition
        ) {
            onAccounIconDesignUpdate({
                accountIconPosition: selectedIconPosition,
                accountIconShape: selectedIconShape
            });
        }
    };

    return (
        <Box>
            <Box display="flex">
                <Box flexGrow={1}>
                    <Typography color="textPrimary" variant="h6">
                        <LabelWithEmoji text="Icon Design" emoji="🌀" />
                    </Typography>
                </Box>
                <Box display="flex">
                    <Box mt={0.6}>
                        <Typography>Show Icon </Typography>
                    </Box>
                    <Switch
                        checked={isAccountIcon}
                        onChange={onAccountIconsStatusChange}
                        disabled={disableIconSwitch}
                        color="primary"
                    />
                </Box>
            </Box>

            <Collapse in={isAccountIcon}>
                <div style={{ position: "relative" }}>
                    <div
                        style={{
                            position: "absolute",
                            width: "100%",
                            top: 0
                        }}
                    >
                        <SaveActionButton
                            label=""
                            isLoading={isLoading}
                            onSave={handleAccountIconDesign}
                        />
                    </div>
                    <ShapeSelector
                        setSelectedAccountShape={setSelectedIconShape}
                        selectedAccountShape={selectedIconShape}
                    />
                    <AccountIconPositionSelector
                        setSelectedIconPosition={setSelectedIconPosition}
                        selectedIconPosition={selectedIconPosition}
                        label={"Position"}
                    />
                </div>
            </Collapse>
        </Box>
    );
}
