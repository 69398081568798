import React from "react";
import { Avatar, Box, Grid, makeStyles, Typography } from "@material-ui/core";
import useGlobalStyles from "../../../hooks/useGlobalStyles";
import clsx from "clsx";
import config from "../../../config";
import utils from "../../../utils";
import baseIcon from "../../../res/images/base-icon1.png";

const useStyles = makeStyles((theme) => ({
    acccountBox: {
        cursor: "pointer"
    },
    accountCircleBorder: {
        borderRadius: 50
    },
    accountRoundedBorder: {
        borderRadius: 8
    },
    accountAvatarPosition: {
        position: "relative"
        // marginLeft: "-10px"
    },
    positionLeft: {
        position: "relative",
        // marginTop: theme.spacing(-1.2),
        width: theme.spacing(5),
        height: theme.spacing(5),
        marginLeft: theme.spacing(-6)
    },
    positionOverlap: {
        position: "relative",
        marginLeft: theme.spacing(-3)
    }
}));

const BORDER = config.accountStyle.border;
const FILL = config.accountStyle.fill;

const CIRCLE = config.shape.circle;
const ROUNDED = config.shape.rounded;

const LEFT = config.iconPosition.left;
const OVERLAP = config.iconPosition.overlap;
const INSIDE = config.iconPosition.inside;

export default function UserProfileAccount({ account, baseAccountSettings }) {
    const classes = useStyles();
    const globalClasses = useGlobalStyles();

    const accountStyle = baseAccountSettings?.accountStyle;
    const accountShape = baseAccountSettings?.accountShape;
    const accountIconShape = baseAccountSettings?.accountIconShape;
    const accountIconPosition = baseAccountSettings?.accountIconPosition;
    const isAccountIcon = baseAccountSettings?.isAccountIcon;

    const handleRedirect = (account) => {
        const isCustomAccount = account.accountId.isCustomAccount;
        const isCustomUrl = account.accountId.isCustomUrl;
        const isEntryInCustomAccountText =
            account.accountId.isEntryInCustomAccountText;
        if (isCustomAccount || isCustomUrl) {
            redirect(account.userIdentifier);
            return true;
        }
        if (isEntryInCustomAccountText) {
            redirect(account.accountId.accountUrl + account.userIdentifier);
            return true;
        }
    };
    const redirect = (url) => {
        const isHttp =
            url &&
            (url.substring(0, 7) === "http://" ||
                url.substring(0, 8) === "https://");

        if (isHttp) {
            window.open(url, "_blank");
        }
        if (!isHttp) {
            window.open("http://" + url, "_blank");
        }
    };

    const isAccountActive = account.isAccountActive;
    // custom account = created by user
    const isEntryInCustomAccountText =
        account?.accountId?.isEntryInCustomAccountText;
    // account image icon
    const accountImageUrl = account?.accountId?.accountImageUrl;
    // account Name
    // const accountName = account.accountId.accountName;
    // // userIdentifier
    // const userIdentifier = account.userIdentifier;
    // const isCustomUserAccount = account?.accountId.isCustomAccount;
    // const userCustomAccountText = account?.customAccountText;

    const accountText = utils.getAccountText(account);
    //   if (isCustomUserAccount) {
    //     accountText = userCustomAccountText;
    //   } else {
    //     if (isEntryInCustomAccountText) {
    //       if (userCustomAccountText) {
    //         accountText = userCustomAccountText;
    //       } else {
    //         accountText = accountName;
    //       }
    //     } else {
    //       accountText = `${accountName}: ${userIdentifier}`;
    //     }
    //   }

    //   // accounts setting
    //   const accountBgColor = account.accountId.accountBackgroundColor; //bg color
    //   let accountTextColor = account.accountId.accountTextColor; //text color
    //   // changing account text conditionally
    //   accountTextColor =
    //     accountStyle === BORDER ? accountBgColor : accountTextColor;

    const accountColors = utils.getAccountColors(account, baseAccountSettings);

    if (isAccountActive) {
        return (
            <Grid
                item
                md={12}
                xs={12}
                className={isEntryInCustomAccountText && classes.acccountBox}
                onClick={() => handleRedirect(account)}
            >
                <Box
                    className={clsx(
                        accountShape === CIRCLE && classes.accountCircleBorder,
                        accountShape === ROUNDED && classes.accountRoundedBorder
                    )}
                    align="center"
                    // height={10}
                    bgcolor={
                        accountStyle === FILL &&
                        accountColors.accountBackgroundColor
                    }
                    border={accountStyle === BORDER ? 1 : 0}
                    borderColor={
                        accountStyle === BORDER
                            ? accountColors.accountBackgroundColor
                            : ""
                    }
                    p={0.5}
                    ml={
                        accountIconPosition !== INSIDE && isAccountIcon
                            ? accountIconPosition === LEFT && isAccountIcon
                                ? 6
                                : 2
                            : 0
                    }
                    borderRadius={4}
                >
                    <Box display="flex" position="relative">
                        {isAccountIcon && (
                            <Avatar
                                sizes={"sm"}
                                src={accountImageUrl || baseIcon}
                                className={clsx(
                                    globalClasses.smallAvatar,
                                    classes.accountAvatarPosition,
                                    accountIconPosition === LEFT &&
                                        classes.positionLeft,
                                    accountIconPosition === OVERLAP &&
                                        classes.positionOverlap
                                )}
                                variant={
                                    accountIconShape === CIRCLE
                                        ? "circular"
                                        : accountIconShape
                                }
                            />
                        )}
                        <Box pt={1} width={"85%"}>
                            <Typography
                                align="center"
                                style={{
                                    flexGrow: "1",
                                    color: accountColors.accountTextColor
                                }}
                            >
                                {accountText}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </Grid>
        );
    } else {
        return <React.Fragment />;
    }
}
