import config from "../config";
import axios from "../config/axios";

const addSurvey = async (survey) => {
    return axios.post(
        `${config.apiEndPoint}${config.apiRoutes.survey.default}`,
        survey
    );
};
const getSurveys = async () => {
    return axios.get(`${config.apiEndPoint}${config.apiRoutes.survey.default}`);
};

const arrangeSurvey = async (data) => {
    return axios.put(
        `${config.apiEndPoint}${config.apiRoutes.survey.arrangeSurvey}`,
        data
    );
};
const getSurveysForUser = async () => {
    return axios.get(`${config.apiEndPoint}${config.apiRoutes.user.survey}`);
};

export default {
    addSurvey,
    getSurveys,
    arrangeSurvey,
    getSurveysForUser
};
