import {
    Grid,
    Avatar,
    Box,
    Container,
    makeStyles,
    Typography,
    useMediaQuery,
    useTheme
} from "@material-ui/core";
import clsx from "clsx";
import React, { useState, useEffect } from "react";
import { CheckCircle } from "@material-ui/icons";
import baseIcon from "../../../res/images/base-icon1.png";
import UserProfileAccount from "./UserProfileAccount";

// import useGlobalStyles from "../../../hooks/useGlobalStyles";
import MadeWithBase from "../../../components/common/MadeWithBase";
import config, { userType } from "../../../config";
import ProfileBackgroundVideo from "./Components/profileBackgroundVideo";
import ProfileBaseHeader from "./Components/profileBaseHeader";
import ProfileBaseBanner from "./Components/profileBaseBanner";
import UserProfileSponsorLogos from "./UserProfileSponsorLogos";
// import UserProfileIntegrations from "./UserProfileIntegrations";
import auth from "../../../services/auth";
const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(4),
        marginTop: ({ isBaseHeaderActive, isPlusOrProUser }) =>
            isBaseHeaderActive && isPlusOrProUser && "-100px",
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(2),
            marginTop: ({ isBaseHeaderActive, isPlusOrProUser }) =>
                isBaseHeaderActive && isPlusOrProUser && "-80px"
        }
    },
    avatarSize: {
        width: theme.spacing(16),
        height: theme.spacing(16),
        borderWidth: 2,
        borderStyle: "solid",
        borderColor: theme.palette.primary.main
    },
    name: {
        color: theme.palette.text.primary
        // marginTop: theme.spacing(3)
    },
    bio: {
        color: theme.palette.grey[100],
        marginTop: theme.spacing(1),
        wordWrap: "break-word",
        fontWeight: 400,
        fontSize: 14
    },
    customTextColor: {
        color: ({ textColor }) => textColor
    },
    roundedAvatar: {
        borderRadius: 8
    }
}));
export default function ProfileSection({ user, extraHeight }) {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    // const globalClasses = useGlobalStyles();
    var userBaseProfileSettings = user?.baseProfileSettings;
    // if (!user?.baseProfileSettings?._id) {
    //     userBaseProfileSettings = user?.baseSetting;
    // }
    var baseAccountSettings = user?.baseAccountSettings;
    // if (!user?.baseAccountSettings?._id) {
    //     baseAccountSettings = user?.accountSetting;
    // }

    const textColor = userBaseProfileSettings?.textColor;
    const backgroundColor = userBaseProfileSettings?.backgroundColor;
    const backgroundImage = userBaseProfileSettings?.backgroundImage;
    const backgroundType = userBaseProfileSettings?.backgroundType;
    const backgroundVideo = userBaseProfileSettings?.backgroundVideo;
    const backgroundMobileVideo =
        userBaseProfileSettings?.backgroundMobileVideo;

    const isBaseHeaderActive = userBaseProfileSettings?.isBaseHeaderActive;
    const baseHeaderImage = userBaseProfileSettings?.baseHeaderImage;

    const isBaseBannerActive = userBaseProfileSettings?.isBaseBannerActive;
    const baseBannerText = userBaseProfileSettings?.baseBannerText;
    const baseBannerColor = userBaseProfileSettings?.baseBannerColor;
    const baseBannerTextColor = userBaseProfileSettings?.baseBannerTextColor;

    const isBaseSponsorLogoActive =
        userBaseProfileSettings?.isBaseSponsorLogoActive;
    const sponsorLogos = userBaseProfileSettings?.baseSponsorLogos;

    const isSponsorLogoBackground =
        userBaseProfileSettings?.isSponsorLogoBackground;

    const sponsorLogoBackgroundColor =
        userBaseProfileSettings?.sponsorLogoBackgroundColor;

    const isSponsorLogoAnimation =
        userBaseProfileSettings?.isSponsorLogoAnimation;
    const sponsorLogoShape = userBaseProfileSettings?.sponsorLogoShape;

    const [iframeHeight, setIframeHeight] = useState(window.innerHeight);
    const [iframeWidth, setIframeWidth] = useState(window.innerWidth);

    const isPlusOrProUser = auth.isPlusOrProUser();

    const classes = useStyles({
        textColor,
        backgroundColor,
        backgroundType,
        backgroundImage,
        iframeHeight,
        iframeWidth,
        isBaseHeaderActive,
        isPlusOrProUser
    });

    useEffect(() => {
        window.addEventListener("resize", () => {
            setIframeHeight(window.innerHeight);
            setIframeWidth(window.innerWidth);
        });
    }, []);

    // var videoURL = backgroundVideo;
    // if (backgroundVideo) {
    //     var urlArr = backgroundVideo.split("/");
    //     var provider = urlArr[2];
    //     if (provider === "www.vimeo.com" || provider === "vimeo.com") {
    //         urlArr[0] = "https://";
    //         urlArr[1] = "player.";
    //         urlArr[2] = provider + "/video/";
    //         urlArr[4] = "?background=1&autoplay=1&loop=1&autopause=0&muted=1";
    //         var videoURL = urlArr.join();
    //         videoURL = videoURL.replaceAll(",", "");
    //     }
    //     if (provider === "www.youtube.com" || provider === "youtube.com") {
    //         var videioId = urlArr[3].split("v=")[1];
    //         var ampersandPosition = videioId.indexOf("&");
    //         if (ampersandPosition != -1) {
    //             videioId = videioId.substring(0, ampersandPosition);
    //         }
    //         urlArr[0] = "https://";
    //         urlArr[2] = provider + "/embed/";
    //         urlArr[3] = videioId;
    //         urlArr[4] = "?autoplay=1&controls=0&showinfo=0&autohide=1&mute=1";
    //         var videoURL = urlArr.join();
    //         videoURL = videoURL.replaceAll(",", "");
    //     }
    // }
    return (
        <div>
            <Box position="relative">
                <Box position="absolute" top={isMobile ? 10 : 0} width="100%">
                    <Container maxWidth="sm">
                        <ProfileBaseBanner
                            isPlusOrProUser={isPlusOrProUser}
                            isBaseBannerActive={isBaseBannerActive}
                            baseBannerColor={baseBannerColor}
                            baseBannerText={baseBannerText}
                            baseBannerTextColor={baseBannerTextColor}
                        />
                    </Container>
                </Box>
                <ProfileBaseHeader
                    isPlusOrProUser={isPlusOrProUser}
                    baseHeaderImage={baseHeaderImage}
                    isBaseHeaderActive={isBaseHeaderActive}
                />
                {(!isBaseHeaderActive || !baseHeaderImage) && (
                    <Box height={30}></Box>
                )}
            </Box>
            <ProfileBackgroundVideo
                iframeHeight={iframeHeight}
                iframeWidth={iframeWidth}
                backgroundType={backgroundType}
                backgroundVideo={backgroundVideo}
                backgroundMobileVideo={backgroundMobileVideo}
                isPlusOrProUser={isPlusOrProUser}
            />
            <Box
                minHeight={window.innerHeight}
                // height={
                //     extraHeight ? window.innerHeight + 80 : window.innerHeight
                // }

                // height={window.innerHeight - 180}
            >
                <Container className={clsx(classes.container)} maxWidth="sm">
                    <Grid
                        container
                        // align={isMobile ? "center" : "left"}
                        align={"center"}
                        direction="row-reverse"
                        display="relative"
                        p={10}
                    >
                        <Grid item md={12} xs={12}>
                            <Box
                                mt={
                                    !isBaseHeaderActive && isBaseBannerActive
                                        ? 2
                                        : isBaseHeaderActive && !baseHeaderImage
                                        ? 20
                                        : 0
                                }
                            >
                                <Avatar
                                    variant={
                                        user?.avatarShape ===
                                        config.shape.circle
                                            ? "circular"
                                            : user?.avatarShape
                                    }
                                    src={user?.avatarUrl || baseIcon}
                                    className={clsx(
                                        classes.avatarSize,
                                        user?.avatarShape ===
                                            config.shape.rounded &&
                                            classes.roundedAvatar
                                    )}
                                />
                                <Box
                                    display={"flex"}
                                    justifyContent={
                                        "center"
                                        // isMobile ? "center" : "left"
                                    }
                                    mt={3}
                                >
                                    <Box>
                                        <Typography
                                            variant="h5"
                                            className={clsx(
                                                classes.name,
                                                classes.customTextColor
                                            )}
                                        >
                                            <b>{user?.displayName}</b>
                                        </Typography>
                                    </Box>
                                    {(user?.isVerifiedBase ||
                                        user?.managedBy?._id ||
                                        user?.userType === userType.plus ||
                                        user?.userType === userType.pro) && (
                                        <Box py={1} px={0.5}>
                                            <CheckCircle
                                                color="primary"
                                                style={{
                                                    fontSize: 18,
                                                    marginTop: 1
                                                    // marginLeft: 2
                                                }}
                                            />
                                        </Box>
                                    )}
                                </Box>
                                <Typography
                                    // variant="body1"
                                    className={clsx(
                                        classes.bio,
                                        classes.customTextColor
                                    )}
                                >
                                    {user?.bio}
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                    <Box mt={4}>
                        <UserProfileSponsorLogos
                            isPlusOrProUser={isPlusOrProUser}
                            isBaseSponsorLogoActive={isBaseSponsorLogoActive}
                            sponsorLogos={sponsorLogos}
                            isSponsorLogoBackground={isSponsorLogoBackground}
                            sponsorLogoBackgroundColor={
                                sponsorLogoBackgroundColor
                            }
                            isSponsorLogoAnimation={isSponsorLogoAnimation}
                            sponsorLogoShape={sponsorLogoShape}
                        />
                    </Box>
                    {/* <Box mt={4}>
                        <UserProfileIntegrations
                            usereIntegrationList={user?.integrations}
                        />
                    </Box> */}

                    <Box
                        mt={5}
                        mb={5}
                        // style={{
                        //     height: 300,
                        //     width: "100%",
                        //     overflowY: "scroll",
                        //     overflowX: "hidden"
                        // }}
                    >
                        <Grid container spacing={2}>
                            {user?.accounts.map((account) => (
                                <UserProfileAccount
                                    key={account._id}
                                    account={account}
                                    baseAccountSettings={baseAccountSettings}
                                />
                            ))}
                        </Grid>
                    </Box>
                </Container>
                {isPlusOrProUser && userBaseProfileSettings?.isBaseBranding && (
                    <Box>
                        <MadeWithBase />
                    </Box>
                )}
                {!isPlusOrProUser && (
                    <Box>
                        <MadeWithBase />
                    </Box>
                )}
            </Box>
        </div>
    );
}
