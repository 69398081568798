import config from "../config";
import axios from "../config/axios";

const getPublicUserProfile = async (baseUrl) => {
    return axios.get(`${config.apiEndPoint}${config.apiRoutes.public.user}`, {
        params: baseUrl
    });
};

export default {
    getPublicUserProfile
};
