import { Box, Grid, Typography } from "@material-ui/core";
import React from "react";
import useGlobalStyles from "../../hooks/useGlobalStyles";
import { Link } from "react-scroll";
import config from "../../config";

export default function Footer() {
    const globalClasses = useGlobalStyles();
    return (
        <Grid item md={12} xs={12}>
            <Box textAlign={"center"} lineHeight={1.8}>
                <Typography
                    variant="caption"
                    color="textSecondary"
                    // align="center"
                    style={{ fontSize: 14 }}
                >
                    {`All Rights Reserved ${new Date().getFullYear()}® - Base.gg `}
                    <Link
                        to={config.routes.tos}
                        className={globalClasses.aTagText}
                    >
                        <Typography variant={"caption"}>Terms</Typography>
                    </Link>{" "}
                    and{" "}
                    <Link
                        to={config.routes.privacy}
                        className={globalClasses.aTagText}
                    >
                        <Typography variant={"caption"}>
                            Privacy Policy
                        </Typography>
                    </Link>
                </Typography>
            </Box>
        </Grid>
    );
}
