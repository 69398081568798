import { Collapse, TableCell, TableRow } from "@material-ui/core";
import React from "react";
const ExpandableTableRow = ({
    children,
    expandComponent,
    isExpanded,
    expandedRowcellProps = {}
}) => {
    return (
        <>
            <TableRow>{children}</TableRow>
            {/* {isExpanded && ( */}
            <TableRow>
                <TableCell {...expandedRowcellProps}>
                    <Collapse in={isExpanded}>{expandComponent}</Collapse>
                </TableCell>
            </TableRow>
            {/* )} */}
        </>
    );
};
export default ExpandableTableRow;
