import React, { Fragment, useEffect, useState } from "react";
// import { makeStyles } from "@material-ui/core/styles";
import * as Yup from "yup";
// import Table from "@material-ui/core/Table";
// import TableBody from "@material-ui/core/TableBody";
// import TableCell from "@material-ui/core/TableCell";
// import TableContainer from "@material-ui/core/TableContainer";
// import TableHead from "@material-ui/core/TableHead";
// import TablePagination from "@material-ui/core/TablePagination";
// import TableRow from "@material-ui/core/TableRow";
// import TableSortLabel from "@material-ui/core/TableSortLabel";
import Paper from "@material-ui/core/Paper";
import { Check, CloseSharp, DragHandle, Edit } from "@material-ui/icons";

import {
    // Avatar,
    Box,
    Button,
    ButtonGroup,
    CircularProgress,
    // Container,
    Grid,
    IconButton,
    // LinearProgress,
    TextField,
    Typography
    // Typography
} from "@material-ui/core";
// import AdminPageContainer from "../../components/core/AdminPageContainer";

// import clsx from "clsx";
// import user from "../../services/user";
// import config from "../../config";
import Alert from "@material-ui/lab/Alert";
// import useGlobalStyles from "../../hooks/useGlobalStyles";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
// import ConfirmationDialog from "../../components/common/ConfirmationDialog";
import acService from "../../../services/accountCategories";
import AdminPageContainer from "../../../components/core/AdminPageContainer";
import config from "../../../config";

const schema = Yup.object({
    title: Yup.string().required(),
    position: Yup.number().required()
});

export default function AccountCategoryList(props) {
    // const classes = useStyles();

    const [accountCategoryList, setAccountCategoryList] = useState([]);
    const [selectedCategoryToEdit, setSelectedCategoryToEdit] = useState(null);
    const [accountCategory, setAccountCategory] = useState({
        title: "",
        position: 0
    });
    const [error, setError] = useState("");
    const [message, setMessage] = useState("");

    const [updating, setUpdating] = useState(false);
    const [saving, setSaving] = useState(false);
    const [loading, setLoading] = useState(true);

    const handleChange = (e) => {
        e.preventDefault();
        const value = e.target.value;
        setAccountCategory({
            title: value,
            position: accountCategoryList.length
        });
    };

    const resetMessages = () => {
        setTimeout(() => {
            setError("");
            setMessage("");
        }, 3000);
    };

    const handleSubmit = () => {
        schema
            .validate(accountCategory)
            .then(() => {
                setSaving(true);
                acService
                    .addAccountCategory(accountCategory)
                    // .addSurvey(accountCategory)
                    .then((res) => {
                        if (res?.data?.accountCategory) {
                            setAccountCategoryList([
                                ...accountCategoryList,
                                res.data.accountCategory
                            ]);
                            setAccountCategory({ title: "", position: "" });
                        }
                        setSaving(false);
                        setMessage(config.defaultNotiStackMessage);
                        resetMessages();
                    })
                    .catch((ex) => {
                        setSaving(false);

                        setError(ex?.response?.data?.message);
                        resetMessages();
                    });
            })
            .catch((err) => {
                setError(err.message);
                resetMessages();
            });
    };

    useEffect(() => {
        acService
            .getAccountCategories()

            .then((res) => {
                if (res?.data?.accountCategories) {
                    setAccountCategoryList(res?.data?.accountCategories);
                }
                setLoading(false);
            })
            .catch((ex) => {
                setLoading(false);
                setError(ex?.response?.data?.message);
                resetMessages();
            });
    }, []);

    const onDragEnd = async (result) => {
        try {
            const { source, destination } = result;

            if (!destination) {
                return false;
            }
            if (
                destination.droppableId === source.droppableId &&
                destination.index === source.index
            ) {
                return false;
            }
            // const previousState = [
            //     ...user.baseProfileSettings.baseSponsorLogos
            // ];
            const updatedState = [...accountCategoryList];
            const draggedItem = updatedState[source.index];
            // draggedItem.label = source.index;
            updatedState.splice(source.index, 1);
            updatedState.splice(destination.index, 0, draggedItem);
            setAccountCategoryList(updatedState);
            const filteredUpdatedState = updatedState.map(({ _id }, i) => {
                return {
                    _id,
                    position: i
                };
            });
            // debugger;
            acService
                .arrangeAc({ accountCategories: filteredUpdatedState })
                .then((res) => {
                    setAccountCategoryList(res?.data?.accountCategories);
                })
                .catch((ex) => {
                    setError(ex.response.data.message);
                    resetMessages();
                });
        } catch (ex) {}
    };

    const handleEdit = () => {
        setUpdating(true);
        acService
            .updateAccountCategory({
                id: selectedCategoryToEdit._id,
                title: selectedCategoryToEdit.title
            })
            .then((res) => {
                if (res?.data?.accountCategory) {
                    const updatedList = accountCategoryList.map((item) => {
                        if (
                            item._id === selectedCategoryToEdit._id &&
                            item.title !== selectedCategoryToEdit.title
                        ) {
                            return selectedCategoryToEdit;
                        }
                        return item;
                    });
                    setAccountCategoryList(updatedList);
                    setSelectedCategoryToEdit(null);
                }
                setUpdating(false);
                setMessage(config.defaultNotiStackMessage);
                resetMessages();
                selectedCategoryToEdit(null);
            })
            .catch((ex) => {
                setUpdating(false);

                setError(ex?.response?.data?.message);
                resetMessages();
            });
    };
    return (
        <AdminPageContainer {...props}>
            <Paper>
                <Box p={2}>
                    <Box mb={1}>
                        {loading && (
                            <Box align="center">
                                <CircularProgress />
                            </Box>
                        )}
                        {error && <Alert severity="error">{error}</Alert>}
                        {message && <Alert severity="success">{message}</Alert>}
                    </Box>
                    <Grid container>
                        <Grid item xs={12} md={5}>
                            <Box display={"flex"}>
                                <Box flexGrow={1}>
                                    <TextField
                                        required
                                        value={accountCategory.title}
                                        // name="Survey"
                                        // label="Survey"
                                        onChange={handleChange}
                                        placeholder="e.g. Social"
                                        fullWidth
                                    />
                                </Box>
                                <Box ml={2}>
                                    <Button
                                        size="small"
                                        // variant="contained"
                                        color="primary"
                                        onClick={handleSubmit}
                                    >
                                        {saving ? (
                                            <CircularProgress size={20} />
                                        ) : (
                                            "Add"
                                        )}
                                    </Button>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>

                    <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="acdnd">
                            {(provided, snapshot) => {
                                return (
                                    <div
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                        style={{
                                            width: "100%"
                                        }}
                                    >
                                        {accountCategoryList &&
                                            accountCategoryList?.map(
                                                (el, i) => {
                                                    return (
                                                        <Draggable
                                                            key={el._id}
                                                            draggableId={el._id}
                                                            index={i}
                                                        >
                                                            {(
                                                                provided,
                                                                snapshot
                                                            ) => {
                                                                return (
                                                                    <div
                                                                        ref={
                                                                            provided.innerRef
                                                                        }
                                                                        {...provided.draggableProps}
                                                                        {...provided.dragHandleProps}
                                                                    >
                                                                        <Grid
                                                                            container
                                                                        >
                                                                            <Grid
                                                                                item
                                                                                sm={
                                                                                    10
                                                                                }
                                                                                md={
                                                                                    4
                                                                                }
                                                                                xs={
                                                                                    10
                                                                                }
                                                                            >
                                                                                <Box
                                                                                    display={
                                                                                        "flex"
                                                                                    }
                                                                                >
                                                                                    <Box
                                                                                        display="flex"
                                                                                        alignItems="center"
                                                                                        justifyContent="center"
                                                                                    >
                                                                                        <DragHandle color="disabled" />
                                                                                    </Box>
                                                                                    <Box
                                                                                        // minWidth="25%"
                                                                                        flexGrow={
                                                                                            1
                                                                                        }
                                                                                        pt={
                                                                                            1
                                                                                        }
                                                                                        px={
                                                                                            2
                                                                                        }
                                                                                    >
                                                                                        {selectedCategoryToEdit?._id ===
                                                                                        el._id ? (
                                                                                            <TextField
                                                                                                value={
                                                                                                    selectedCategoryToEdit.title
                                                                                                }
                                                                                                onChange={(
                                                                                                    e
                                                                                                ) => {
                                                                                                    setSelectedCategoryToEdit(
                                                                                                        {
                                                                                                            ...selectedCategoryToEdit,
                                                                                                            title: e
                                                                                                                .target
                                                                                                                .value
                                                                                                        }
                                                                                                    );
                                                                                                }}
                                                                                                disabled={
                                                                                                    updating
                                                                                                }
                                                                                                // fullWidth
                                                                                            />
                                                                                        ) : (
                                                                                            <Typography>
                                                                                                {
                                                                                                    el.title
                                                                                                }
                                                                                            </Typography>
                                                                                        )}
                                                                                    </Box>
                                                                                </Box>
                                                                            </Grid>
                                                                            <Grid
                                                                                item
                                                                                sm={
                                                                                    2
                                                                                }
                                                                                md={
                                                                                    8
                                                                                }
                                                                                xs={
                                                                                    2
                                                                                }
                                                                            >
                                                                                <ButtonGroup
                                                                                    disableElevation
                                                                                    variant="contained"
                                                                                    aria-label="Disabled elevation buttons"
                                                                                >
                                                                                    {selectedCategoryToEdit?._id ===
                                                                                    el._id ? (
                                                                                        <Fragment>
                                                                                            <IconButton
                                                                                                disabled={
                                                                                                    updating
                                                                                                }
                                                                                                size="small"
                                                                                                onClick={
                                                                                                    handleEdit
                                                                                                }
                                                                                            >
                                                                                                {updating ? (
                                                                                                    <CircularProgress
                                                                                                        size={
                                                                                                            20
                                                                                                        }
                                                                                                    />
                                                                                                ) : (
                                                                                                    <Check />
                                                                                                )}
                                                                                            </IconButton>
                                                                                            <IconButton
                                                                                                disabled={
                                                                                                    updating
                                                                                                }
                                                                                                size="small"
                                                                                                onClick={() =>
                                                                                                    setSelectedCategoryToEdit(
                                                                                                        null
                                                                                                    )
                                                                                                }
                                                                                            >
                                                                                                <CloseSharp />
                                                                                            </IconButton>
                                                                                        </Fragment>
                                                                                    ) : (
                                                                                        <IconButton
                                                                                            size="small"
                                                                                            onClick={() =>
                                                                                                setSelectedCategoryToEdit(
                                                                                                    el
                                                                                                )
                                                                                            }
                                                                                        >
                                                                                            <Edit
                                                                                                style={{
                                                                                                    fontSize: 20
                                                                                                }}
                                                                                            />
                                                                                        </IconButton>
                                                                                    )}
                                                                                </ButtonGroup>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </div>
                                                                );
                                                            }}
                                                        </Draggable>
                                                    );
                                                }
                                            )}
                                        {provided.placeholder}
                                    </div>
                                );
                            }}
                        </Droppable>
                    </DragDropContext>
                </Box>
            </Paper>
        </AdminPageContainer>
    );
}
