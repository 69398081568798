import {
    Box,
    Typography,
    // Button,
    IconButton,
    CircularProgress
} from "@material-ui/core";
import { Check } from "@material-ui/icons";
// import clsx from "clsx";
import React from "react";
// import useGlobalStyles from "../../../../hooks/useGlobalStyles";

export default function EditDashboardSectionHeader({
    onSave,
    headerText,
    isLoading,
    headerTextColor = "primary",
    variant = "body1"
}) {
    // const globalClasses = useGlobalStyles();
    return (
        <div>
            <Box display="flex">
                <Box flexGrow={1} mt={0.5}>
                    <Typography color={headerTextColor} variant={variant}>
                        {headerText}
                    </Typography>
                </Box>
                <Box mt={0}>
                    <IconButton
                        color="primary"
                        // component="label"
                        // size="small"
                        onClick={onSave}
                        disabled={isLoading}
                    >
                        {isLoading ? <CircularProgress size={18} /> : <Check />}
                    </IconButton>
                </Box>
            </Box>
        </div>
    );
}
