import React from "react";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import actionTypes from "../../../../redux/actionTypes";
import auth from "../../../../services/auth";
import userService from "../../../../services/user";

import config from "../../../../config";
import { useSnackbar } from "notistack";
import HeaderUI from "../../components/HeaderUI";

export default function EditBaseHeader() {
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    // const [isLoading, setIsLoading] = useState(false);
    // const [isDeleting, setIsDeleting] = useState(false);

    const [actionStatus, setActionStatus] = useState({
        saving: false,
        deleting: false,
        status: false
    });
    const isPlusOrProUser = auth.isPlusOrProUser();
    const user = useSelector((state) => state.auth.user);
    const isHeaderActive = user.baseProfileSettings?.isBaseHeaderActive;
    const baseHeader = user.baseProfileSettings?.baseHeaderImage;
    const isManagedDesignActive = user?.managedBy?.isManagedDesignActive;
    const handleBaseHeaderStatus = (event) => {
        if (!isManagedDesignActive) {
            const isBaseHeaderActive = event.target.checked;

            setActionStatus((ps) => ({ ...ps, status: true }));
            userService
                .updateBaseProfileSettings({
                    userId: user._id,
                    isBaseHeaderActive
                })
                .then((res) => {
                    const baseProfileSettings = res.data.baseProfileSettings;
                    dispatch({
                        type: actionTypes.UPDATE_USER,
                        payload: { baseProfileSettings }
                    });
                    setActionStatus((ps) => ({ ...ps, status: false }));
                })
                .catch((ex) => {
                    enqueueSnackbar(ex?.response?.data?.message, {
                        variant: "error"
                    });
                    setActionStatus((ps) => ({ ...ps, status: false }));
                    // dispatch({
                    //     type: actionTypes.UPDATE_USER,
                    //     payload: {
                    //         baseProfileSettings: {
                    //             ...user.baseProfileSettings,
                    //             isBaseHeaderActive: !isBaseHeaderActive
                    //         }
                    //     }
                    // });
                });
        }
    };

    const handleSaveHeader = (croppedImage) => {
        if (!isManagedDesignActive) {
            if (croppedImage && croppedImage.file) {
                setActionStatus((ps) => ({ ...ps, saving: true }));
                userService
                    .updateBaseProfileSettings({
                        userId: user._id,
                        baseHeaderImage: { ...croppedImage }
                    })
                    .then((res) => {
                        enqueueSnackbar(config.defaultNotiStackMessage, {
                            variant: "success"
                        });

                        setActionStatus((ps) => ({ ...ps, saving: false }));

                        const baseProfileSettings =
                            res.data.baseProfileSettings;
                        dispatch({
                            type: actionTypes.UPDATE_USER,
                            payload: { baseProfileSettings }
                        });
                    })
                    .catch((ex) => {
                        enqueueSnackbar(ex?.response?.data?.message, {
                            variant: "error"
                        });
                        setActionStatus((ps) => ({ ...ps, saving: false }));
                    });
            }
        }
    };

    const handleDeleteHeader = () => {
        if (!isManagedDesignActive) {
            setActionStatus((ps) => ({ ...ps, deleting: true }));
            userService
                .updateBaseProfileSettings({
                    userId: user._id,
                    deleteBaseHeader: true
                })
                .then((res) => {
                    enqueueSnackbar(config.defaultNotiStackMessage, {
                        variant: "success"
                    });

                    setActionStatus((ps) => ({ ...ps, deleting: false }));
                    const baseProfileSettings = res.data.baseProfileSettings;
                    dispatch({
                        type: actionTypes.UPDATE_USER,
                        payload: { baseProfileSettings }
                    });
                })
                .catch((ex) => {
                    enqueueSnackbar(ex?.response?.data?.message, {
                        variant: "error"
                    });
                    setActionStatus((ps) => ({ ...ps, deleting: false }));
                });
        }
    };

    return (
        <div>
            <HeaderUI
                enableFeature={isPlusOrProUser}
                disableHeaderSwitch={actionStatus.status}
                handleHeaderStatus={handleBaseHeaderStatus}
                handleSaveHeader={handleSaveHeader}
                handleDeleteHeader={handleDeleteHeader}
                isHeaderActive={isHeaderActive}
                baseHeader={baseHeader}
                isSavingHeader={actionStatus.saving}
                isDeletingHeader={actionStatus.deleting}
            />
        </div>
    );
}
