import { Box, makeStyles, Typography } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
    madeWithContainer: {
        display: "flex",
        justifyContent: "center",
        padding: theme.spacing(1),
        backgroundColor: "#000",
        width: "fit-content"
    },
    madeWithText: {
        color: "#FEFEFEd1",
        fontSize: 13
    }
}));
export default function MadeWithBase() {
    const classes = useStyles();
    return (
        <Box
            justifyContent="center"
            display="flex"
            // position={"absolute"}
            // width={"100%"}
            // bottom={0}
        >
            <Box className={classes.madeWithContainer}>
                <Box>
                    <Typography className={classes.madeWithText}>
                        Made with
                    </Typography>
                </Box>
                <Box mt={-0.05} ml={0.5}>
                    <img
                        src={require("../../res/images/basegg-logo1.png")}
                        height={10}
                        alt="made-with-basegg"
                    />
                </Box>
            </Box>
        </Box>
    );
}
