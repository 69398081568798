import React, { Fragment, useState } from "react";
import {
    AppBar,
    Avatar,
    Box,
    Collapse,
    // Container,
    Divider,
    // Grid,
    IconButton,
    // ImageList,
    // ImageListItem,
    makeStyles,
    // Paper,
    Toolbar,
    Tooltip,
    useTheme
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import SpeedIcon from "@material-ui/icons/Speed";
import TuneIcon from "@material-ui/icons/Tune";
import { Link } from "react-router-dom";
import config from "../../../config";
import { useDispatch, useSelector } from "react-redux";
import baseIcon from "../../../res/images/base-icon1.png";
import clsx from "clsx";
import CustomBadge from "../../common/CustomBadge";
import Management from "./ManagementList";
import { Close, Visibility, WorkOutline } from "@material-ui/icons";
import actionTypes from "../../../redux/actionTypes";
import auth from "../../../services/auth";
import { useEffect } from "react";
// import useGlobalStyles from "../../hooks/useGlobalStyles";

const useStyles = makeStyles((theme) => ({
    grow: {
        flexGrow: 1
    },
    appBar: {
        backgroundColor: theme.palette.background.paper
    },
    iconButton: {
        color: theme.palette.text.primary
        // padding: theme.spacing(1),
    },
    avatarSize: {
        width: theme.spacing(6),
        height: theme.spacing(6),
        borderWidth: 2,
        borderStyle: "solid",
        // borderColor: theme.palette.secondary.main,
        borderColor: theme.palette.background.default,
        // outlineWidth: 1,
        // outlineColor: theme.palette.primary.main,
        // outlineStyle: "solid",
        [theme.breakpoints.down("sm")]: {
            width: theme.spacing(5),
            height: theme.spacing(5)
        }
    },
    avatarOutline: {
        width: theme.spacing(6.3),
        height: theme.spacing(6.3),
        borderWidth: 1,
        borderStyle: "solid",
        borderColor: theme.palette.primary.main,

        // outlineWidth: 1,
        // outlineColor: theme.palette.primary.main,
        // outlineStyle: "solid",
        [theme.breakpoints.down("sm")]: {
            width: theme.spacing(5.3),
            height: theme.spacing(5.3)
        }
    },
    avatarOutlineDotted: {
        width: theme.spacing(6.3),
        height: theme.spacing(6.3),
        borderWidth: 1,
        borderStyle: "dotted",
        borderColor: theme.palette.primary.main,
        color: theme.palette.primary.main,

        // outlineWidth: 1,
        // outlineColor: theme.palette.primary.main,
        // outlineStyle: "solid",
        [theme.breakpoints.down("sm")]: {
            width: theme.spacing(5.3),
            height: theme.spacing(5.3)
        }
    },
    avatarOutlineCircle: {
        width: theme.spacing(6.3),
        height: theme.spacing(6.3),
        borderWidth: 1,
        borderStyle: "solid",
        borderColor: theme.palette.primary.main,
        borderRadius: 100,
        [theme.breakpoints.down("sm")]: {
            width: theme.spacing(5.3),
            height: theme.spacing(5.3)
        }
    },
    roundedAvatar: {
        borderRadius: 8
    }
}));

export default function UserAppBar() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const user = useSelector((state) => state.auth.user);
    const currentUser = useSelector((state) => state.auth.currentUser);

    const theme = useTheme();
    const [showBaseList, setBaseList] = useState(false);
    const isProUser = currentUser.userType === config.userType.pro;

    const [currentUserAvatarDesign, setCurrentUserAvatarDesign] = useState({
        avatarShape: currentUser.avatarShape,
        avatarUrl: currentUser.avatarUrl
    });
    // const globalClasses = useGlobalStyles();
    // useEffect(() => {}, [user]);

    const toggleManagedUserList = () => {
        setBaseList(!showBaseList);
    };
    const handleResetBase = () => {
        dispatch({
            type: actionTypes.SET_USER,
            payload: { user: currentUser }
        });
    };

    useEffect(() => {
        if (auth.isCurrentUserSelected()) {
            setCurrentUserAvatarDesign({
                avatarShape: user?.avatarShape,
                avatarUrl: user.avatarUrl
            });
        }
    }, [user]);
    const isCurrentUserSelected = auth.isCurrentUserSelected();
    const currentUserAvatarUI = (
        <Box>
            <Link to={isCurrentUserSelected && config.routes.profile}>
                <Box
                    variant={
                        currentUserAvatarDesign.avatarShape ===
                        config.shape.circle
                            ? "circular"
                            : currentUser.avatarShape
                    }
                    className={clsx(
                        classes.avatarOutline,
                        // user.baseProfileSettings?.avatarShape === config.shape.rounded &&
                        // classes.roundedAvatar,
                        currentUserAvatarDesign.avatarShape ===
                            config.shape.circle && classes.avatarOutlineCircle
                    )}
                >
                    <Avatar
                        variant={
                            currentUserAvatarDesign.avatarShape ===
                            config.shape.circle
                                ? "circular"
                                : currentUserAvatarDesign.avatarShape
                        }
                        src={currentUserAvatarDesign.avatarUrl || baseIcon}
                        className={clsx(
                            classes.avatarSize,
                            currentUserAvatarDesign.avatarShape ===
                                config.shape.rounded && classes.roundedAvatar
                        )}
                    ></Avatar>
                </Box>
            </Link>
        </Box>
    );
    let showManagmentBtn = false;
    if (!isProUser) {
        showManagmentBtn = true;
    } else if (isProUser && isCurrentUserSelected) {
        showManagmentBtn = true;
    }
    return (
        <div>
            <AppBar position="static" className={classes.appBar}>
                <Toolbar>
                    <Box display={"flex"} position="relative">
                        {currentUserAvatarUI}
                        {isProUser && (
                            <Fragment>
                                <Box mx={1} color="primary.main">
                                    <Divider
                                        style={{
                                            backgroundColor:
                                                theme.palette.primary.main
                                        }}
                                        orientation="vertical"
                                        // flexItem
                                    />
                                </Box>
                                <Box
                                    position={"relative"}
                                    variant={
                                        user?.avatarShape ===
                                        config.shape.circle
                                            ? "circular"
                                            : user?.avatarShape
                                    }
                                    className={clsx(
                                        classes.avatarOutlineDotted,
                                        // user.baseProfileSettings?.avatarShape === config.shape.rounded &&
                                        // classes.roundedAvatar,
                                        user?.avatarShape ===
                                            config.shape.circle &&
                                            classes.avatarOutlineCircle
                                    )}
                                >
                                    <Avatar
                                        onClick={toggleManagedUserList}
                                        variant={
                                            user?.avatarShape ===
                                            config.shape.circle
                                                ? "circular"
                                                : user?.avatarShape
                                        }
                                        src={
                                            !auth.isCurrentUserSelected() &&
                                            user.avatarUrl
                                                ? user.avatarUrl
                                                : baseIcon
                                        }
                                        className={clsx(
                                            classes.avatarSize,
                                            user?.avatarShape ===
                                                config.shape.rounded &&
                                                classes.roundedAvatar
                                        )}
                                    ></Avatar>
                                    {!auth.isCurrentUserSelected() && (
                                        <Box
                                            position={"absolute"}
                                            top={-20}
                                            right={-12}
                                            // left={0}
                                        >
                                            <IconButton
                                                onClick={handleResetBase}
                                                size="small"
                                                color="error"
                                            >
                                                <Close
                                                    style={{ fontSize: 18 }}
                                                />
                                            </IconButton>
                                        </Box>
                                    )}
                                </Box>
                                <Box display={"flex"} alignItems="center">
                                    <Box>
                                        <CustomBadge
                                            badgeContent={"Pro"}
                                            uppercase={true}
                                        ></CustomBadge>
                                    </Box>
                                </Box>
                            </Fragment>
                        )}
                    </Box>

                    <div className={classes.grow} />
                    <div>
                        <Tooltip title="Dashboard">
                            <Link to={config.routes.dashboard}>
                                <IconButton
                                    color="inherit"
                                    className={classes.iconButton}
                                >
                                    <SpeedIcon />
                                </IconButton>
                            </Link>
                        </Tooltip>
                        <Tooltip title="Edit">
                            <Link to={config.routes.editProfile}>
                                <IconButton
                                    color="inherit"
                                    className={classes.iconButton}
                                >
                                    <EditIcon />
                                </IconButton>
                            </Link>
                        </Tooltip>

                        {showManagmentBtn && (
                            <Tooltip title="Management">
                                <Link to={config.routes.management}>
                                    <IconButton
                                        color="inherit"
                                        className={classes.iconButton}
                                    >
                                        <WorkOutline />
                                    </IconButton>
                                </Link>
                            </Tooltip>
                        )}
                        {/* <IconButton
              edge="end"
              color="inherit"
              className={classes.iconButton}
            >
              <EqualizerIcon />
            </IconButton> */}
                        {isCurrentUserSelected && (
                            <Tooltip title="Settings">
                                <Link to={config.routes.settings}>
                                    <IconButton
                                        edge="end"
                                        color="inherit"
                                        className={classes.iconButton}
                                    >
                                        <TuneIcon />
                                    </IconButton>
                                </Link>
                            </Tooltip>
                        )}
                        {!isCurrentUserSelected && (
                            <Tooltip title="Preview">
                                <Link to={config.routes.profile}>
                                    <IconButton
                                        edge="end"
                                        color="inherit"
                                        className={classes.iconButton}
                                    >
                                        <Visibility />
                                    </IconButton>
                                </Link>
                            </Tooltip>
                        )}
                    </div>
                </Toolbar>
            </AppBar>

            <Collapse in={showBaseList && isProUser}>
                <Management toggleManagedUserList={toggleManagedUserList} />
            </Collapse>
        </div>
    );
}
