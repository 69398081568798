/* eslint-disable react-hooks/exhaustive-deps */
import {
    Box,
    Button,
    CircularProgress,
    Grid,
    InputAdornment,
    TextField,
    Typography
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ColorPickerField from "../../../../../../../components/core/ColorPickerField";
import config from "../../../../../../../config";
// import useGlobalStyles from "../../../../../../../hooks/useGlobalStyles";
import actionTypes from "../../../../../../../redux/actionTypes";
import userService from "../../../../../../../services/user";
import AccountRemove from "../userAccountDetails/AccountRemove";
import { useSnackbar } from "notistack";
import * as Yup from "yup";
import {} from "@material-ui/core/colors";

const BORDER = config.accountStyle.border;

var validationSchema = Yup.object({
    userId: Yup.string().required(),
    accountId: Yup.string().required(),
    userIdentifier: Yup.string().required().max(62),
    customAccountText: Yup.string().notRequired().max(25),
    userAcccountTextColor: Yup.string()
        .notRequired()
        .matches(config.regx.color, config.validationErrors.colorError),
    userAccountBackgroundColor: Yup.string()
        .notRequired()
        .matches(config.regx.color, config.validationErrors.colorError)
});

export default function EditUserAccount({
    accountUrl,
    accountName,
    baseAccountUserFieldLabel,
    userAccountIdentifier,
    isUserEntryInCustomAccountText,
    accountTextColor,
    accountBackgroundColor,
    userCustomAccountText,
    userAccountId,
    accountId,
    selectedAccountToEditId,
    onSaveSuccess
}) {
    // const globalClasses = useGlobalStyles();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const user = useSelector((state) => state.auth.user);
    const [userIdentifier, setUserIdentifier] = useState(userAccountIdentifier);
    const [customAccountText, setCustomAccountText] = useState(
        userCustomAccountText
    );
    const [userAcccountTextColor, setAccountTextColor] =
        useState(accountTextColor);
    const [userAccountBackgroundColor, setAccountBackgroundColor] = useState(
        accountBackgroundColor
    );
    const [error, setError] = useState("");
    // const [successMessage, setSuccessMessage] = useState("");
    const [loading, setLoading] = useState(false);
    // const classes = useStyles({ loading });

    const baseAccountSettings = user.baseAccountSettings;

    useEffect(() => {
        setUserIdentifier(userAccountIdentifier);
        setCustomAccountText(userCustomAccountText);
        setAccountTextColor(accountTextColor);
        setAccountBackgroundColor(accountBackgroundColor);
    }, []);
    useEffect(() => {
        setUserIdentifier(userAccountIdentifier);
        setCustomAccountText(userCustomAccountText);
    }, [selectedAccountToEditId]);

    const submitEditAccount = (e) => {
        e.preventDefault();

        let data = {
            userId: user._id,
            userIdentifier,
            userAccountId,
            accountId,
            accountTextColor: userAcccountTextColor,
            accountBackgroundColor: userAccountBackgroundColor
        };
        if (isUserEntryInCustomAccountText) {
            data = {
                ...data,
                customAccountText
            };
        }

        validationSchema
            .validate(data)

            .then(() => {
                updateAccount(data);
            })
            .catch((err) => {
                setError(err.message);
                setTimeout(() => {
                    setError("");
                }, 3000);
            });
    };

    const updateAccount = (data) => {
        setLoading(true);
        userService
            .handleUserAccountEdit(data)
            .then((res) => {
                onSaveSuccess();
                // setSuccessMessage(config.defaultNotiStackMessage);
                enqueueSnackbar(config.defaultNotiStackMessage, {
                    variant: "success"
                });

                setLoading(false);
                dispatch({
                    type: actionTypes.UPDATE_USER,
                    payload: { accounts: res.data.accounts }
                });
                // setTimeout(() => setSuccessMessage(""), 3000);
            })
            .catch((ex) => {
                enqueueSnackbar(ex.response?.data?.message, {
                    variant: "error"
                });
                // setError(ex.response?.data.message);
                setLoading(false);
                // setTimeout(() => setError(""), 3000);
            });
    };
    return (
        <Box
            width="100%"
            padding={0}
            pl={
                baseAccountSettings.accountIconPosition ===
                config.iconPosition.left
                    ? 6
                    : 0
            }
        >
            {error && <Alert severity="error">{error}</Alert>}
            {/* {successMessage && (
                <Alert severity="success">{successMessage}</Alert>
            )} */}

            <Box mt={1}>
                <AccountRemove selectedAccountToDelete={userAccountId} />
            </Box>
            <form onSubmit={submitEditAccount}>
                <div>
                    <Box mb={3} width="100%">
                        <Typography variant="subtitle1" color="textSecondary">
                            {baseAccountUserFieldLabel}
                        </Typography>
                        <TextField
                            // required
                            // defaultValue={userAccountIdentifier}
                            fullWidth
                            value={userIdentifier}
                            onChange={(e) =>
                                setUserIdentifier(e.currentTarget.value)
                            }
                            variant="outlined"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Typography color="primary">
                                            {accountUrl}
                                        </Typography>
                                    </InputAdornment>
                                )
                            }}
                        />
                    </Box>
                    {isUserEntryInCustomAccountText && (
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box mb={3} width="100%">
                                <Typography
                                    variant="subtitle1"
                                    color="textSecondary"
                                >
                                    Custom Text
                                </Typography>
                                <TextField
                                    variant="outlined"
                                    placeholder={"e.g. My " + accountName}
                                    fullWidth
                                    // defaultValue={userCustomAccountText}
                                    value={customAccountText}
                                    onChange={(e) =>
                                        setCustomAccountText(
                                            e.currentTarget.value
                                        )
                                    }
                                />
                            </Box>
                        </Grid>
                    )}
                    {baseAccountSettings.accountStyle !== BORDER && (
                        <Grid container spacing={4}>
                            <Grid item sm={6} md={6} lg={6} xs={6}>
                                <ColorPickerField
                                    fieldLabel={"Custom Text Color"}
                                    color={userAcccountTextColor}
                                    onColorPickerValueChange={(color) => {
                                        setError("");
                                        setAccountTextColor(`#${color.hex}`);
                                    }}
                                    onFieldValueChange={(e) => {
                                        setError("");
                                        setAccountTextColor(
                                            `${e.currentTarget.value}`
                                        );
                                    }}
                                />
                            </Grid>
                            <Grid item sm={6} md={6} lg={6} xs={6}>
                                <ColorPickerField
                                    fieldLabel={"Custom Account Color"}
                                    color={userAccountBackgroundColor}
                                    onColorPickerValueChange={(color) => {
                                        setError("");
                                        setAccountBackgroundColor(
                                            `#${color.hex}`
                                        );
                                    }}
                                    onFieldValueChange={(e) => {
                                        setError("");
                                        setAccountBackgroundColor(
                                            `${e.currentTarget.value}`
                                        );
                                    }}
                                />
                            </Grid>
                        </Grid>
                    )}
                    {baseAccountSettings.accountStyle === BORDER && (
                        <Grid container spacing={4}>
                            <Grid item sm={12} md={12} lg={12} xs={12}>
                                <ColorPickerField
                                    fieldLabel={"Custom Account Color"}
                                    color={userAccountBackgroundColor}
                                    onColorPickerValueChange={(color) => {
                                        setError("");
                                        setAccountBackgroundColor(
                                            `#${color.hex}`
                                        );
                                    }}
                                    onFieldValueChange={(e) => {
                                        setError("");
                                        setAccountBackgroundColor(
                                            `${e.currentTarget.value}`
                                        );
                                    }}
                                />
                            </Grid>
                        </Grid>
                    )}
                </div>

                <Box mt={2}>
                    <Button
                        color="primary"
                        type="submit"
                        fullWidth
                        // className={classes.saveBtn}
                        // variant={loading && "contained"}
                        disabled={loading}
                    >
                        {loading ? (
                            <Box
                                align="center"
                                mt={0.5}
                                // className={classes.saveBtn}
                            >
                                <CircularProgress
                                    size={16}
                                    // className={classes.saveBtn}
                                ></CircularProgress>
                            </Box>
                        ) : (
                            "Save"
                        )}
                    </Button>
                </Box>
            </form>
        </Box>
    );
}
