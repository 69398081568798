import { makeStyles } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
    headerImage: {
        display: "none",
        width: "100%",
        border: "none",
        // borderRadius: 4,
        height: theme.spacing(25),
        [theme.breakpoints.down("xs")]: {
            width: "100%",
            display: "block",
            height: theme.spacing(15)
        }
    },
    headerImageAsBg: {
        background: ({ baseHeaderImage }) =>
            `url(${baseHeaderImage}) no-repeat`,
        width: "100%",
        border: "none",
        backgroundPosition: "center",
        backgroundSize: "cover !important",
        height: theme.spacing(30),
        [theme.breakpoints.down("xs")]: {
            height: theme.spacing(25)

            // display: "none"
            // backgroundSize: "contain !important"
        }
    }
}));
export default function ProfileBaseHeader({
    isPlusOrProUser,
    isBaseHeaderActive,
    baseHeaderImage
}) {
    const classes = useStyles({ baseHeaderImage });
    return (
        <div>
            {isPlusOrProUser && isBaseHeaderActive && baseHeaderImage && (
                <div>
                    <div className={classes.headerImageAsBg}></div>
                </div>
            )}
        </div>
    );
}
