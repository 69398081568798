/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Box, CircularProgress, makeStyles } from "@material-ui/core";

import publicService from "../../services/public";
import ProfileSection from "../user/Profile/ProfileSection";
import config from "../../config";

const useStyles = makeStyles((theme) => ({
    customBaseBackground: {
        backgroundSize: "cover !important",
        background: ({ backgroundColor, backgroundType, backgroundImage }) =>
            backgroundType === "color"
                ? backgroundColor
                : backgroundType === "image" &&
                  `url(${backgroundImage}) no-repeat`,
        [theme.breakpoints.down("xl")]: {
            height: "fit-content"
        },
        [theme.breakpoints.up("xl")]: {
            height: "100%"
        }
    },
    loaderContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh"
    }
}));

export default function PublicUser(props) {
    const baseUrl = props.match.params.baseUrl;
    const [user, setUser] = useState();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        publicService.getPublicUserProfile({ baseUrl }).then((res) => {
            setLoading(false);
            if (res.data.user) {
                const usr = res.data.user;
                if (usr.isSuspendedBase) {
                    window.location.href = config.routes.suspended;
                } else {
                    if (!usr?.baseProfileSettings?._id) {
                        usr.baseProfileSettings = usr?.baseSetting;
                        usr.baseProfileSettings.backgroundType =
                            usr?.baseSetting.background.type;
                        usr.baseProfileSettings.backgroundColor =
                            usr?.baseSetting.background.value;
                    }

                    if (!usr?.baseAccountSettings?._id) {
                        usr.baseAccountSettings = usr?.accountSetting;
                    }

                    if (
                        usr?.managedBy?._id &&
                        usr?.managedBy?.isManagedDesignActive
                    ) {
                        usr.baseProfileSettings =
                            usr.managedBy.managedBaseDesign.baseProfileSettings;

                        usr.baseAccountSettings =
                            usr.managedBy.managedBaseDesign.baseAccountSettings;
                    }
                    setUser(usr);
                    setTimeout(() => {
                        document.getElementById(
                            "freshworks-container"
                        ).style.display = "none";
                    }, 1000);
                }
            } else {
                setTimeout(() => {
                    document.getElementById(
                        "freshworks-container"
                    ).style.display = "none";
                }, 1000);
                // setIsUserFound(false);
            }
        });

        // return () =>
        //     (document.getElementById("launcher-frame").style.display = "block");
    }, []);

    var userBaseProfileSettings = user?.baseProfileSettings;
    const textColor = userBaseProfileSettings?.textColor;
    const backgroundColor = userBaseProfileSettings?.backgroundColor;
    const backgroundImage = userBaseProfileSettings?.backgroundImage;
    const backgroundType = userBaseProfileSettings?.backgroundType;

    const classes = useStyles({
        textColor,
        backgroundColor,
        backgroundType,
        backgroundImage
    });

    return (
        <Box className={classes.customBaseBackground} height={"100%"}>
            {!loading && (
                <Box>
                    <ProfileSection user={user} />
                </Box>
            )}
            {loading && (
                <Box className={classes.loaderContainer}>
                    <Box>
                        <CircularProgress color="primary" />
                    </Box>
                </Box>
            )}
        </Box>
    );
}
