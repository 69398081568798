/* eslint-disable react-hooks/exhaustive-deps */
import {
    Box,
    Grid,
    makeStyles,
    Typography,
    TextField
} from "@material-ui/core";
import Tab from "@material-ui/core/Tab";
import Alert from "@material-ui/lab/Alert";
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import TabPanel from "@material-ui/lab/TabPanel";
// import clsx from "clsx";
import * as Yup from "yup";
import React, { useEffect } from "react";
import { Fragment } from "react";
import { useState } from "react";
import CustomBadge from "../../../components/common/CustomBadge";
import ColorPickerField from "../../../components/core/ColorPickerField";
import ImageDropzone from "../../../components/core/ImageDropzone";
import config from "../../../config";
import LabelWithEmoji from "../../../components/common/LabelWithEmoji";
import SaveActionButton from "./SaveActionButton";

const useStyles = makeStyles((theme) => ({
    tabPanel: {
        padding: theme.spacing(2, 2, 0, 2)
    }
}));

const FILETYPES = ["image/jpeg", "image/jpg", "image/png"];

const PRO_USER_BG_TYPES = [
    config.backgroundType.color,
    config.backgroundType.image,
    config.backgroundType.video
];

const FREE_USER_BG_TYPES = [
    config.backgroundType.color,
    config.backgroundType.image
];

export default function BackgroundUI({
    backgroundType = "",
    backgroundColor = "",
    backgroundImage = "",
    backgroundVideo = "",
    backgroundMobileVideo = "",
    allowVideoBackground = false,
    isLoading = false,
    onBackgroundSave = () => {}
}) {
    const classes = useStyles();
    const [error, setError] = useState("");
    const [selectedBackgroundType, setSelectedBackgroundType] =
        useState("color");
    const [selectedBackgroundColor, setSelectedBackgroundColor] =
        useState("#141828");
    const [selectedBackgroundImage, setSelectedBackgroundImage] = useState("");
    const [previewImage, setPreviewImage] = useState("");

    const [backgroundVideoUrl, setSelectedBackgroundVideoURL] = useState("");
    const [backgroundMobileVideoUrl, setBackgroundMobileVideoURL] =
        useState("");

    useEffect(() => {
        setStates();
    }, []);

    const setStates = () => {
        setSelectedBackgroundType(backgroundType);
        setSelectedBackgroundColor(backgroundColor);
        setPreviewImage(backgroundImage);
        setSelectedBackgroundVideoURL(backgroundVideo);
        setBackgroundMobileVideoURL(backgroundMobileVideo);
    };

    const handleBackgtoundTabChange = (event, newType) => {
        if (newType === "video" || newType === "color" || newType === "image") {
            setSelectedBackgroundColor(backgroundColor);
            setPreviewImage(backgroundImage);
            setSelectedBackgroundVideoURL(backgroundVideo);
        } else {
            setSelectedBackgroundColor(config.colorPicker.defaultBackground);
        }
        setError("");
        setSelectedBackgroundType(newType);
    };

    const resetError = () => {
        setTimeout(() => {
            setError("");
        }, 3000);
    };

    const handleBaseBackground = (e) => {
        e.preventDefault();
        const isNeedToSave =
            selectedBackgroundType !== backgroundType ||
            selectedBackgroundColor !== backgroundColor ||
            backgroundVideoUrl !== backgroundVideo ||
            backgroundMobileVideoUrl !== backgroundMobileVideo ||
            selectedBackgroundImage.file;
        if (isNeedToSave) {
            var validationSchema = Yup.object({
                backgroundType: Yup.lazy(() =>
                    Yup.string()
                        .when("backgroundColor", {
                            is: (val) => val,
                            then: Yup.string()
                                .required()
                                .oneOf(
                                    allowVideoBackground
                                        ? PRO_USER_BG_TYPES
                                        : FREE_USER_BG_TYPES
                                ),
                            otherwise: Yup.string()
                                .notRequired()
                                .oneOf(
                                    allowVideoBackground
                                        ? PRO_USER_BG_TYPES
                                        : FREE_USER_BG_TYPES
                                )
                        })
                        .when("backgroundImage", {
                            is: (val) => val,
                            then: Yup.string()
                                .required()
                                .oneOf(
                                    allowVideoBackground
                                        ? PRO_USER_BG_TYPES
                                        : FREE_USER_BG_TYPES
                                ),
                            otherwise: Yup.string()
                                .notRequired()
                                .oneOf(
                                    allowVideoBackground
                                        ? PRO_USER_BG_TYPES
                                        : FREE_USER_BG_TYPES
                                )
                        })
                        .when("backgroundVideo", {
                            is: (val) => val,
                            then: Yup.string()
                                .required()
                                .oneOf(
                                    allowVideoBackground
                                        ? PRO_USER_BG_TYPES
                                        : FREE_USER_BG_TYPES
                                )
                                .label("Background Type"),
                            otherwise: Yup.string()
                                .notRequired()
                                .oneOf(
                                    allowVideoBackground
                                        ? PRO_USER_BG_TYPES
                                        : FREE_USER_BG_TYPES
                                )
                                .label("Background Type")
                        })
                        .when("backgroundMobileVideo", {
                            is: (val) => val,
                            then: Yup.string()
                                .required()
                                .oneOf(
                                    allowVideoBackground
                                        ? PRO_USER_BG_TYPES
                                        : FREE_USER_BG_TYPES
                                )
                                .label("Background Type"),
                            otherwise: Yup.string()
                                .notRequired()
                                .oneOf(
                                    allowVideoBackground
                                        ? PRO_USER_BG_TYPES
                                        : FREE_USER_BG_TYPES
                                )
                                .label("Background Type")
                        })
                ),
                backgroundColor: Yup.string()
                    .notRequired()
                    .when("backgroundType", {
                        is: (val) =>
                            val === config.backgroundType.color &&
                            !backgroundColor,
                        then: Yup.string()
                            .matches(
                                config.regx.color,
                                config.validationErrors.colorError
                            )
                            .required()
                            .label("Background Color"),
                        otherwise: Yup.string()
                            .matches(
                                config.regx.color,
                                config.validationErrors.colorError
                            )
                            .notRequired()
                            .label("Background Color")
                    }),
                backgroundImage: Yup.object()
                    .notRequired()
                    .when("backgroundType", {
                        is: (val) =>
                            val === config.backgroundType.image &&
                            !backgroundImage,
                        then: Yup.object({
                            file: Yup.string().required(),
                            fileType: Yup.string().required().oneOf(FILETYPES),
                            fileSize: Yup.number()
                                .lessThan(
                                    config.constants.baseBgImageFileSize *
                                        1000000,
                                    config.validationErrors.fileSizeError +
                                        config.constants.baseBgImageFileSize +
                                        " MB"
                                )
                                .label("Background Image")
                        }).required(),
                        otherwise: Yup.object({
                            file: Yup.string().required(),
                            fileType: Yup.string().required().oneOf(FILETYPES),
                            fileSize: Yup.number()
                                .lessThan(
                                    config.constants.baseBgImageFileSize *
                                        1000000,
                                    config.validationErrors.fileSizeError +
                                        config.constants.baseBgImageFileSize +
                                        " MB"
                                )
                                .label("Background Image")
                        }).notRequired()
                    })
                    .notRequired()
                    .default(undefined)
                    .typeError(
                        "backgroundImage" +
                            config.validationErrors.fileTypeError
                    ),
                backgroundVideo: Yup.string()
                    .notRequired()
                    .when("backgroundType", {
                        is: (val) => val === config.backgroundType.video,
                        then: Yup.string().when("allowVideoBackground", {
                            is: (val) =>
                                (val === allowVideoBackground &&
                                    !backgroundVideo) ||
                                !backgroundVideoUrl,
                            then: Yup.string()
                                .required()
                                .label("Background Video"),
                            otherwise: Yup.string()
                                .notRequired()
                                .label("Background Video")
                        })
                    }),
                backgroundMobileVideo: Yup.string()
                    .notRequired()
                    .when("backgroundType", {
                        is: (val) => val === config.backgroundType.video,
                        then: Yup.string().when("allowVideoBackground", {
                            is: (val) =>
                                (val === allowVideoBackground &&
                                    !backgroundMobileVideo) ||
                                !backgroundMobileVideoUrl,
                            then: Yup.string()
                                .required()
                                .label("Background Mobile Video"),
                            otherwise: Yup.string()
                                .notRequired()
                                .label("Background Mobile Video")
                        })
                    })
            });

            var newBaseProfileSetting = {
                backgroundType: selectedBackgroundType
            };
            if (
                selectedBackgroundType === config.backgroundType.color &&
                selectedBackgroundColor
            ) {
                newBaseProfileSetting.backgroundColor = selectedBackgroundColor;
            }
            if (
                selectedBackgroundType === config.backgroundType.image &&
                selectedBackgroundImage
            ) {
                newBaseProfileSetting.backgroundImage = selectedBackgroundImage;
            }
            if (
                selectedBackgroundType === config.backgroundType.video &&
                backgroundVideoUrl
            ) {
                newBaseProfileSetting.backgroundVideo = backgroundVideoUrl;
            }
            if (
                selectedBackgroundType === config.backgroundType.video &&
                backgroundMobileVideoUrl
            ) {
                newBaseProfileSetting.backgroundMobileVideo =
                    backgroundMobileVideoUrl;
            }

            validationSchema
                .validate(newBaseProfileSetting)
                .then(() => {
                    onBackgroundSave(newBaseProfileSetting);
                })
                .catch((ex) => {
                    setError(ex.message);
                    resetError();
                });
        }
    };
    return (
        <Box>
            <SaveActionButton
                label={
                    <Typography color="textPrimary" variant="h6">
                        <LabelWithEmoji text="Background" emoji="🖼️" />
                    </Typography>
                }
                isLoading={isLoading}
                onSave={handleBaseBackground}
            />
            <TabContext value={selectedBackgroundType}>
                <TabList
                    onChange={handleBackgtoundTabChange}
                    aria-label="simple tabs example"
                    indicatorColor="primary"
                >
                    <Tab label="Color" value="color" />
                    <Tab label="Image" value="image" />
                    <Tab
                        label={
                            <Fragment>
                                {!allowVideoBackground ? (
                                    <Box display="flex">
                                        <Box mt={0.7}>
                                            <Typography>Video</Typography>
                                        </Box>
                                        <CustomBadge
                                            badgeContent={"Plus"}
                                            uppercase={true}
                                        />
                                    </Box>
                                ) : (
                                    <Box display="flex">
                                        <Typography>Video</Typography>
                                    </Box>
                                )}
                            </Fragment>
                        }
                        value="video"
                        disabled={!allowVideoBackground}
                    />
                </TabList>

                <TabPanel value="color" className={classes.tabPanel}>
                    {error && <Alert severity="error">{error}</Alert>}
                    <br />
                    <Grid container>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                            <ColorPickerField
                                fieldLabel={"Base Background Color"}
                                color={selectedBackgroundColor}
                                onColorPickerValueChange={(color) => {
                                    setSelectedBackgroundColor(`#${color.hex}`);
                                }}
                                onFieldValueChange={(e) => {
                                    setSelectedBackgroundColor(
                                        `${e.currentTarget.value}`
                                    );
                                }}
                            />
                        </Grid>
                    </Grid>
                </TabPanel>
                <TabPanel value="image" className={classes.tabPanel}>
                    {error && <Alert severity="error">{error}</Alert>}
                    <Box align="center">
                        <ImageDropzone
                            selectedFile={selectedBackgroundImage}
                            setSelectedFile={setSelectedBackgroundImage}
                            previewImg={previewImage}
                        />
                    </Box>
                </TabPanel>
                {allowVideoBackground && (
                    <TabPanel value="video" className={classes.tabPanel}>
                        {error && <Alert severity="error">{error}</Alert>}
                        <br />
                        <Grid container>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Box mb={3}>
                                    <Typography
                                        variant="subtitle1"
                                        color="textSecondary"
                                    >
                                        Video URL
                                    </Typography>

                                    <TextField
                                        variant="outlined"
                                        multiline
                                        fullWidth
                                        value={backgroundVideoUrl}
                                        placeholder="https://www.youtube.com/watch?v=fgsdfhgfg"
                                        onChange={(e) =>
                                            setSelectedBackgroundVideoURL(
                                                e.currentTarget.value
                                            )
                                        }
                                    />
                                </Box>
                                <Box mb={3}>
                                    <Typography
                                        variant="subtitle1"
                                        color="textSecondary"
                                    >
                                        Mobile Video URL
                                    </Typography>

                                    <TextField
                                        variant="outlined"
                                        multiline
                                        fullWidth
                                        value={backgroundMobileVideoUrl}
                                        placeholder="https://www.youtube.com/watch?v=dfhyhhydw"
                                        onChange={(e) =>
                                            setBackgroundMobileVideoURL(
                                                e.currentTarget.value
                                            )
                                        }
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </TabPanel>
                )}
            </TabContext>
        </Box>
    );
}
