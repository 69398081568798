/**
 * this component is responsible for showing and updating the existing account
 * from the account list whether it is custom account or user account
 *
 * the component also recieve props such as  selectedAccountToEdit, setSelectedAccountToEdit
 * to set and rest the selected account and account prop to show account from the account list
 *
 */
import React, { useEffect, useState } from "react";
import { Box, Collapse, Grid } from "@material-ui/core";
// import useGlobalStyles from "../../../../../../hooks/useGlobalStyles";
// import DragHandleIcon from "@material-ui/icons/DragHandle";
import { Draggable } from "react-beautiful-dnd";
// import AccountSwitch from "./userAccountDetails/AccountSwitchArchive";
import EditCustomUserAccount from "./userAccountDetails/EditCustomUserAccount";
import EditUserAccount from "./userAccountDetails/EditUserAccount";
import { useSelector } from "react-redux";
// import clsx from "clsx";
import config from "../../../../../../config";
import userService from "../../../../../../services/user";
import actionTypes from "../../../../../../redux/actionTypes";
import utils from "../../../../../../utils";

import baseIcon from "../../../../../../res/images/base-icon1.png";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import AccountHeader from "../../components/AccountHeader";

const BORDER = config.accountStyle.border;

// const CIRCLE = config.shape.circle;
// const ROUNDED = config.shape.rounded;

export default function UserAccount(props) {
    // const globalClasses = useGlobalStyles();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const [isDisableSwitch, setDisableSwitch] = useState(false);
    const user = useSelector((state) => state.auth.user);
    const { account, selectedAccountToEdit, setSelectedAccountToEdit, index } =
        props;

    useEffect(() => {}, [selectedAccountToEdit]);

    const handleSelectedAccountToEdit = (account) => {
        if (account._id === selectedAccountToEdit._id) {
            setSelectedAccountToEdit({});
        } else {
            setSelectedAccountToEdit(account);
        }
    };

    const baseProfileSettings = user.baseProfileSettings;
    // accounts global settings
    const baseAccountSettings = user.baseAccountSettings;

    const accountStyle = baseAccountSettings.accountStyle;
    // const accountShape = baseAccountSettings.accountShape;
    //   user account
    const userAccountId = account?._id;
    const userAccountIdentifier = account?.userIdentifier;
    const userCustomAccountText = account?.customAccountText;
    // base account
    const accountId = account?.accountId?._id;
    const accountName = account?.accountId?.accountName;
    const accountUrl = account?.accountId?.accountUrl;
    const accountImageUrl = account?.accountId?.accountImageUrl;

    const baseAccountUserFieldLabel =
        account?.accountId?.accountUserIdentifierLabel;
    const isUserEntryInCustomAccountText =
        account?.accountId?.isEntryInCustomAccountText;
    const isCustomUserAccount = account?.accountId?.isCustomAccount;

    const accountColors = utils.getAccountColors(account, baseAccountSettings);

    // conditional account text
    let accountText = utils.getAccountText(account);

    // is current account is selected
    const isAccountSelectedToEdit =
        userAccountId === selectedAccountToEdit?._id;

    const accountSaved = () => {
        setSelectedAccountToEdit({});
    };

    const handleAccountStatus = (account) => {
        setDisableSwitch(true);

        userService
            .updateUserAccount({
                userId: user._id,
                userAccountId: account._id,
                isAccountActive: !account.isAccountActive
            })
            .then((res) => {
                enqueueSnackbar(config.defaultNotiStackMessage, {
                    variant: "success"
                });
                setDisableSwitch(false);
                dispatch({
                    type: actionTypes.UPDATE_USER,
                    payload: { accounts: res.data.accounts }
                });
            })
            .catch((ex) => {
                enqueueSnackbar(ex?.response?.data?.message, {
                    variant: "error"
                });
                setDisableSwitch(false);
            });
    };

    return (
        <Draggable draggableId={userAccountId} index={index}>
            {(provided) => (
                <Grid
                    style={{ marginRight: -10 }}
                    item
                    md={12}
                    xs={12}
                    sm={12}
                    lg={12}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    innerRef={provided.innerRef}
                >
                    {/* <Box display="flex" width={"100%"}> */}
                    {/* <Box pt={1} pr={1}>
                            <DragHandleIcon color="disabled" />
                        </Box> */}
                    <Box width="100%">
                        <AccountHeader
                            onAccountClick={() =>
                                handleSelectedAccountToEdit(account)
                            }
                            label={accountText}
                            textColor={accountColors.accountTextColor}
                            avatar={accountImageUrl || baseIcon}
                            bgColor={
                                accountStyle === BORDER
                                    ? baseProfileSettings.backgroundColor
                                    : accountColors.accountBackgroundColor
                            }
                            border={accountStyle === BORDER ? 1 : 0}
                            borderColor={
                                accountStyle === BORDER
                                    ? accountColors.accountTextColor
                                    : ""
                            }
                            hasSwitch={true}
                            isSwitchChecked={account?.isAccountActive}
                            onSwitchChange={() => handleAccountStatus(account)}
                            isSwitchDisabled={isDisableSwitch}
                        />

                        <Collapse in={isAccountSelectedToEdit}>
                            <Box display={"flex"}>
                                <Box mt={1.5} p={2}>
                                    {/* <DragHandleIcon color="disabled" /> */}
                                </Box>
                                <Box flexGrow={1}>
                                    {!isCustomUserAccount && (
                                        <EditUserAccount
                                            accountName={accountName}
                                            accountUrl={accountUrl}
                                            onSaveSuccess={accountSaved}
                                            baseAccountUserFieldLabel={
                                                baseAccountUserFieldLabel
                                            }
                                            userAccountIdentifier={
                                                userAccountIdentifier
                                            }
                                            userCustomAccountText={
                                                userCustomAccountText
                                            }
                                            isUserEntryInCustomAccountText={
                                                isUserEntryInCustomAccountText
                                            }
                                            accountTextColor={
                                                accountColors.accountTextColor
                                            }
                                            accountBackgroundColor={
                                                accountColors.accountBackgroundColor
                                            }
                                            userAccountId={userAccountId}
                                            accountId={accountId}
                                            selectedAccountToEditId={
                                                selectedAccountToEdit._id
                                            }
                                        />
                                    )}
                                    {isCustomUserAccount && (
                                        <EditCustomUserAccount
                                            onSaveSuccess={accountSaved}
                                            baseAccountUserFieldLabel={
                                                baseAccountUserFieldLabel
                                            }
                                            userAccountIdentifier={
                                                userAccountIdentifier
                                            }
                                            userCustomAccountText={
                                                userCustomAccountText
                                            }
                                            baseAccountTextColor={
                                                accountColors.accountTextColor
                                            }
                                            baseAccountBackgroundColor={
                                                accountColors.accountBackgroundColor
                                            }
                                            accountImageUrl={accountImageUrl}
                                            userAccountId={userAccountId}
                                            accountId={accountId}
                                            selectedAccountToEditId={
                                                selectedAccountToEdit._id
                                            }
                                        />
                                    )}
                                </Box>
                            </Box>
                        </Collapse>
                    </Box>
                    {/* </Box> */}
                </Grid>
            )}
        </Draggable>
    );
}
