import actionType from "../actionTypes";
const initialState = {
    token: "",
    refreshToken: "",
    currentUser: "",
    user: "",
    base: "",
    isTokenExpired: false,
    management: {
        managedBases: null,
        managementRequests: {},
        categoryList: {}
    }
};

export default function (state = initialState, action) {
    switch (action.type) {
        case actionType.SET_AUTH:
            return action.payload.auth;
        case actionType.UPDATE_AUTH:
            return { ...state, ...action.payload.auth };
        case actionType.SET_TOKEN:
            return {
                ...state,
                token: action.payload.token
            };
        case actionType.SET_REFRESH_TOKEN:
            return {
                ...state,
                refreshToken: action.payload.refreshToken
            };
        case actionType.SET_CURRENT_USER:
            return {
                ...state,
                currentUser: action.payload.currentUser
            };
        case actionType.UPDATE_CURRENT_USER:
            return {
                ...state,
                currentUser: { ...state.currentUser, ...action.payload }
            };
        case actionType.UPDATE_CURRENT_USER_MANAGED_BASE_DESIGN:
            return {
                ...state,
                currentUser: {
                    ...state.currentUser,
                    managedBaseDesign: {
                        ...state.currentUser.managedBaseDesign,
                        ...action.payload
                    }
                }
            };
        case actionType.SET_USER:
            return {
                ...state,
                user: action.payload.user
            };
        case actionType.UPDATE_USER:
            return {
                ...state,
                user: { ...state.user, ...action.payload }
            };
        // case actionType.SET_BASE:
        //     return {
        //         ...state,
        //         user: action.payload.user
        //     };
        // case actionType.UPDATE_BASE:
        //     return {
        //         ...state,
        //         user: { ...state.user, ...action.payload }
        //     };
        case actionType.SET_IS_TOKEN_EXPIRED:
            return {
                ...state,
                isTokenExpired: action.payload.isTokenExpired
            };
        case actionType.UPDATE_BASE_PROFILE_SETTINGS:
            return {
                ...state,
                user: {
                    ...state.user,
                    baseProfileSettings: {
                        ...state.user.baseProfileSettings,
                        ...action.payload
                    }
                }
            };
        case actionType.SET_MANAGEMENT:
            return {
                ...state,
                management: action.payload
            };
        case actionType.ADD_MANAGEMENT_REQUEST:
            return {
                ...state,
                management: {
                    ...state.management,
                    managementRequests: [
                        action.payload,
                        ...state.management.managementRequests
                    ]
                }
            };
        case actionType.REMOVE_MANAGEMENT_REQUEST:
            const filterReq = state.management.managementRequests.filter(
                (e) => e._id !== action.payload
            );
            return {
                ...state,
                management: {
                    ...state.management,
                    managementRequests: filterReq
                }
            };

        case actionType.SET_MANAGED_BASE:
            return {
                ...state,
                management: {
                    ...state.management,
                    managedBases: action.payload
                }
            };
        case actionType.REMOVE_MANAGED_BASE:
            const filterManagedBase = state.management.managedBases.filter(
                (e) => e._id !== action.payload
            );
            return {
                ...state,
                management: {
                    ...state.management,
                    managedBases: filterManagedBase
                }
            };
        case actionType.SET_MANAGEMENT_CATEGORY:
            return {
                ...state,
                management: {
                    ...state.managedBases,
                    categoryList: action.payload
                }
            };
        case actionType.ADD_MANAGEMENT_CATEGORY:
            return {
                ...state,
                management: {
                    ...state.management,
                    categoryList: [
                        ...state.management.categoryList,
                        action.payload
                    ]
                }
            };
        default:
            return state;
    }
}
