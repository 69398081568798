import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
    Avatar,
    Box,
    Button,
    CircularProgress,
    Grid,
    MenuItem,
    Select,
    Switch,
    TextField
} from "@material-ui/core";
import * as yup from "yup";
import * as Scroll from "react-scroll";
// import useGlobalStyles from "../../../hooks/useGlobalStyles";
import clsx from "clsx";
// import { ColorPicker } from "material-ui-color";
import utils from "../../../utils";
import Alert from "@material-ui/lab/Alert";
import accounts from "../../../services/accounts";
import config from "../../../config";
import ColorPickerField from "../../../components/core/ColorPickerField";

// const CONSTANTS = config.constants;
const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%"
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular
    },
    previewIcon: {
        border: `1px dashed ${theme.palette.primary.main}`,
        width: theme.spacing(9),
        height: theme.spacing(9)
        // [theme.breakpoints.down("xs")]: {
        //   width: theme.spacing(8),
        //   height: theme.spacing(8),
        // },
    },
    previewIconOverlay: {
        backgroundColor: "#000",
        opacity: "0.5",
        position: "absolute",
        bottom: 0,
        height: "50%",
        width: theme.spacing(9),
        // [theme.breakpoints.down("xs")]: {
        //   width: theme.spacing(8),
        // },
        borderBottomRightRadius: 4,
        borderBottomLeftRadius: 4
    }
}));

let schema = yup.object().shape({
    file: yup.string().min(5).notRequired().default(undefined).label("File"),
    fileType: yup
        .string()
        .oneOf(
            ["image/png", "image/jpg", "image/jpeg"],
            "File must be an image"
        )
        .required()
        .default(undefined)
        .label("File"),
    fileSize: yup
        .number()
        .max(2000000, "File size must be less or equal to 2MB")
        .required()
        .default(undefined)
        .label("File"),
    accountUrl: yup.string().url().required().label("Custom url"),
    accountName: yup
        .string()
        .required()
        .min(5)
        .max(20)
        .label("Custom link text"),
    accountTextColor: yup.string().required().label("Link color"),
    accountBackgroundColor: yup.string().required().label("Background color"),
    accountCategoryId: yup.string().required().label("Category"),
    accountUserIdentifierLabel: yup
        .string()
        .required()
        .label("User Identifier Label"),
    isEntryInCustomAccountText: yup
        .boolean()
        .required()
        .label("Entery in Custom Account"),
    isAccountActive: yup.boolean().required().label("Active Account"),
    isCustomUrl: yup.boolean().required().label("Custom Account")
});
var scroll = Scroll.animateScroll;
export default function AddAccount(props) {
    const classes = useStyles();
    // const globalClasses = useGlobalStyles();
    const [accountUrl, setAccountUrl] = useState("");
    const [accountName, setAccountName] = useState("");
    const [selectedFile, setselectedFile] = useState();
    const [accountTextColor, setAccountTextColor] = useState(
        config.colorPicker.defaultText
    );
    const [accountBackgroundColor, setAccountBackgroundColor] = useState(
        config.colorPicker.defaultBackground
    );
    const [accountCategory, setAccountCategory] = useState("");
    const [accountUserIdentifierLabel, setAccountUserIdentifierLabel] =
        useState("");
    const [isEntryInCustomAccountText, setEntryInCustomAccountText] =
        useState(true);
    const [isAccountActive, setAccountActive] = useState(true);
    const [isCustomUrl, setCustomUrl] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [successMsg, setSuccessMsg] = useState("");

    const handlefileInput = (e) => {
        e.preventDefault();
        setError("");
        const input = e.currentTarget;
        const file = input.files[0];
        if (file) {
            const fileType = file.type;

            utils
                .toBase64(file)
                .then((base64File) => {
                    setselectedFile({
                        file: base64File,
                        fileSize: file.size,
                        fileType
                    });
                })
                .catch((error) => {});
        }
    };

    const submitForm = (e) => {
        e.preventDefault();
        const isFile = selectedFile && selectedFile.file;
        let accountData = {
            accountUrl,
            accountName,
            accountTextColor,
            accountBackgroundColor,
            accountCategoryId: accountCategory._id,
            file: isFile ? selectedFile.file : "",
            fileSize: isFile ? selectedFile.fileSize : 0,
            fileType: isFile ? selectedFile.fileType.toLowerCase() : "",
            accountUserIdentifierLabel,
            isEntryInCustomAccountText,
            isAccountActive,
            isCustomUrl
        };

        schema
            .validate(accountData)
            .then(() => {
                setError("");
                setLoading(true);
                addAccount(accountData);
                scroll.scrollToTop();
            })
            .catch((e) => {
                scroll.scrollToTop();
                setError(e.message);
            });
    };
    const addAccount = (data) => {
        accounts
            .addNewAccount(data)
            .then((res) => {
                setLoading(false);
                setSuccessMsg(config.defaultNotiStackMessage);
                props.addAccount(res.data.account);
                setselectedFile("");
                setAccountUrl("");
                setAccountName("");
                setAccountTextColor("");
                setAccountBackgroundColor("");
                setAccountCategory("");
                setAccountUserIdentifierLabel("");
                setTimeout(() => setSuccessMsg(""), 5000);
            })
            .catch((ex) => {
                setLoading(false);
                setError(ex.response?.data.message);
                scroll.scrollToTop();
            });
    };
    return (
        // <AdminPageContainer>
        <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Typography className={classes.heading}>Add Account</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Grid container>
                    <Grid item md={12} lg={12} sm={12} xs={12}>
                        <Box
                            bgcolor="background.default"
                            width="100%"
                            padding={2}
                        >
                            <form onSubmit={submitForm}>
                                {loading && (
                                    <Box align="center">
                                        <CircularProgress />
                                    </Box>
                                )}
                                {error && (
                                    <Alert severity="error">{error}</Alert>
                                )}
                                {successMsg && (
                                    <Alert severity="success">
                                        {successMsg}
                                    </Alert>
                                )}

                                <Grid
                                    container
                                    direction="row"
                                    justify="left"
                                    alignItems="left"
                                    spacing={2}
                                >
                                    <Grid item lg={1} md={2} sm={3} xs={4}>
                                        <div style={{ position: "relative" }}>
                                            <Avatar
                                                variant={"rounded"}
                                                className={clsx(
                                                    classes.previewIcon
                                                )}
                                                src={
                                                    selectedFile
                                                        ? selectedFile.file
                                                        : ""
                                                }
                                            ></Avatar>
                                            <Box
                                                className={
                                                    classes.previewIconOverlay
                                                }
                                            >
                                                <Typography
                                                    align="center"
                                                    className={
                                                        classes.changeAvatarText
                                                    }
                                                >
                                                    <Button
                                                        component="label"
                                                        style={{ fontSize: 8 }}
                                                    >
                                                        Change
                                                        <input
                                                            type="file"
                                                            style={{
                                                                display: "none"
                                                            }}
                                                            onChange={
                                                                handlefileInput
                                                            }
                                                        />
                                                    </Button>
                                                </Typography>
                                            </Box>
                                        </div>
                                    </Grid>
                                    <Grid lg={5} md={4} sm={9} xs={12} item>
                                        <Box width={"100%"} mt={2}>
                                            <Typography variant="subtitle1">
                                                Account
                                            </Typography>
                                            <TextField
                                                required
                                                value={accountName}
                                                placeholder="e.g. Straeam"
                                                onChange={(e) => {
                                                    setError("");
                                                    setAccountName(
                                                        e.currentTarget.value
                                                    );
                                                }}
                                                fullWidth
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={12} xs={12}>
                                        <Box width={"100%"} mt={2}>
                                            <Typography variant="subtitle1">
                                                URL
                                            </Typography>
                                            <TextField
                                                required
                                                value={accountUrl}
                                                placeholder="e.g. https://steamcommunity.com/"
                                                onChange={(e) => {
                                                    setError("");
                                                    setAccountUrl(
                                                        e.currentTarget.value
                                                            .trim()
                                                            .toLowerCase()
                                                    );
                                                }}
                                                fullWidth
                                            />
                                        </Box>
                                    </Grid>
                                </Grid>

                                <Grid container spacing={2}>
                                    <Grid item lg={6} md={6} sm={12} xs={12}>
                                        <Box width={"100%"} mt={0.8}>
                                            <Typography variant="subtitle1">
                                                Category
                                            </Typography>

                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                fullWidth
                                                value={accountCategory.title}
                                                onChange={(e) => {
                                                    setError("");
                                                    setAccountCategory(
                                                        JSON.parse(
                                                            e.target.value
                                                        )
                                                    );
                                                }}
                                            >
                                                {props?.accountCategoryList?.map(
                                                    (cat) => (
                                                        <MenuItem
                                                            value={JSON.stringify(
                                                                cat
                                                            )}
                                                        >
                                                            {cat.title}
                                                        </MenuItem>
                                                    )
                                                )}
                                            </Select>
                                        </Box>
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={12} xs={12}>
                                        <Box width={"100%"} mt={1}>
                                            <Typography variant="subtitle1">
                                                User Identifier Field Label
                                            </Typography>
                                            <TextField
                                                required
                                                value={
                                                    accountUserIdentifierLabel
                                                }
                                                placeholder="e.g. Username"
                                                onChange={(e) => {
                                                    setError("");
                                                    setAccountUserIdentifierLabel(
                                                        e.currentTarget.value
                                                    );
                                                }}
                                                fullWidth
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={12} xs={12}>
                                        <ColorPickerField
                                            fieldLabel={"Custom Text Color"}
                                            color={accountTextColor}
                                            onColorPickerValueChange={(
                                                color
                                            ) => {
                                                setError("");
                                                setAccountTextColor(
                                                    `#${color.hex}`
                                                );
                                            }}
                                            onFieldValueChange={(e) => {
                                                setError("");
                                                setAccountTextColor(
                                                    e.currentTarget.value
                                                );
                                            }}
                                        />
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={12} xs={12}>
                                        <ColorPickerField
                                            fieldLabel={"Custom Account Color"}
                                            color={accountBackgroundColor}
                                            onColorPickerValueChange={(
                                                color
                                            ) => {
                                                setError("");
                                                setAccountBackgroundColor(
                                                    `#${color.hex}`
                                                );
                                            }}
                                            onFieldValueChange={(e) => {
                                                setError("");
                                                setAccountBackgroundColor(
                                                    e.currentTarget.value
                                                );
                                            }}
                                        />
                                    </Grid>
                                    <Grid item lg={4} md={4} sm={12} xs={12}>
                                        <Typography variant="subtitle1">
                                            Entery in Account Text
                                        </Typography>
                                        <Switch
                                            color={"primary"}
                                            checked={isEntryInCustomAccountText}
                                            onChange={() =>
                                                setEntryInCustomAccountText(
                                                    !isEntryInCustomAccountText
                                                )
                                            }
                                        />
                                    </Grid>
                                    <Grid item lg={4} md={4} sm={12} xs={12}>
                                        <Typography variant="subtitle1">
                                            Active Account
                                        </Typography>
                                        <Switch
                                            color={"primary"}
                                            checked={isAccountActive}
                                            onChange={() =>
                                                setAccountActive(
                                                    !isAccountActive
                                                )
                                            }
                                        />
                                    </Grid>
                                    <Grid item lg={4} md={4} sm={12} xs={12}>
                                        <Typography variant="subtitle1">
                                            Custom Url
                                        </Typography>
                                        <Switch
                                            color={"primary"}
                                            checked={isCustomUrl}
                                            onChange={() =>
                                                setCustomUrl(!isCustomUrl)
                                            }
                                        />
                                    </Grid>
                                </Grid>
                                <Box mt={2}>
                                    <Button
                                        color="primary"
                                        type="submit"
                                        variant="contained"
                                        fullWidth
                                    >
                                        Save
                                    </Button>
                                </Box>
                            </form>
                        </Box>
                    </Grid>
                </Grid>
            </AccordionDetails>
        </Accordion>
        // </AdminPageContainer>
    );
}
