import { Box, Grid, Typography, Button } from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";
import config from "../../config";
import queryString from "query-string";
export default function Error() {
    const url = queryString.parse(window.location.search);
    return (
        <div>
            <Grid
                container
                direction="column"
                alignItems="center"
                justify="center"
                spacing={2}
            >
                <Grid item>
                    <Box textAlign="center" style={{ marginTop: "30vh" }}>
                        {url && url.code && (
                            <Typography variant="h1" color="primary">
                                {url.code + "!"}
                            </Typography>
                        )}
                        {url && url.message && (
                            <Typography variant="h5" color="primary">
                                {url.message}
                            </Typography>
                        )}
                        <br />
                        <Link
                            to={config.routes.login}
                            style={{ textDecoration: "none" }}
                        >
                            <Button variant="contained" color="primary">
                                Back to home
                            </Button>
                        </Link>
                    </Box>
                </Grid>
            </Grid>
        </div>
    );
}
