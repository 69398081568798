import React from "react";
import { Box, Grid, makeStyles, Typography } from "@material-ui/core";
import { CheckCircle } from "@material-ui/icons";
import config from "../../config";
import clsx from "clsx";

const INSIDE = config.iconPosition.inside;
const OVERLAP = config.iconPosition.overlap;
const LEFT = config.iconPosition.left;

const useStyles = makeStyles((theme) => ({
    shapeArea: {
        backgroundColor: theme.palette.grey[900],
        // border:'1px solid #fff',
        position: "relative",

        // borderWidth: 2,
        // borderStyle: "solid",
        // borderColor: theme.palette.text.primary,
        width: theme.spacing(14),
        height: theme.spacing(5),
        marginBottom: theme.spacing(1),
        // outlineWidth: 1,
        // outlineColor: theme.palette.secondary.main,
        // outlineStyle: "dotted",
        cursor: "pointer",
        [theme.breakpoints.down("xs")]: {
            width: theme.spacing(8),
            height: theme.spacing(4)
        }
    },
    selectedShapeArea: {
        cursor: "pointer",
        position: "relative",
        backgroundColor: theme.palette.grey[900],
        // borderWidth: 2,
        // borderStyle: "solid",
        // borderColor: theme.palette.secondary.main,
        // border: `2px solid ${theme.palette.primary.main}`,
        width: theme.spacing(14),
        height: theme.spacing(5),
        marginBottom: theme.spacing(1),
        // outlineWidth: 1,
        // outlineColor: theme.palette.primary.main,
        // outlineStyle: "dotted",
        // outlineOffset: 2,
        // MozOutlineRadius: 4,
        // outlineRadius: 4,
        [theme.breakpoints.down("xs")]: {
            width: theme.spacing(8),
            height: theme.spacing(4)
        }
    },
    shapesTitle: {
        fontSize: 12,
        fontWeight: 100
    },
    selectedPosition: {
        backgroundColor: theme.palette.primary.main
        //  outlineWidth: 1,
        // outlineColor: theme.palette.primary.main,
        // outlineStyle: "dotted",
        // outlineOffset:2,
        // MozOutlineRadius:4,
        // outlineRadius:4,
    },
    positionInside: {
        position: "absolute",
        // backgroundColor: "#fff",
        width: theme.spacing(4),
        height: theme.spacing(4),
        padding: 1,
        // margin: theme.spacing(0.5),
        // marginTop: 2,
        borderRadius: 4,
        backgroundColor: theme.palette.primary.main,
        [theme.breakpoints.down("xs")]: {
            margin: theme.spacing(0.3),
            width: theme.spacing(2.8),
            height: theme.spacing(2.8),
            marginRight: theme.spacing(1)
        }
        // left:0
    },
    positionOverlap: {
        borderRadius: 4,
        position: "relative",
        // backgroundColor: "#fff",
        width: theme.spacing(4),
        height: theme.spacing(4),
        backgroundColor: theme.palette.primary.main,
        // margin: theme.spacing(0.5),
        left: theme.spacing(-7),
        [theme.breakpoints.down("xs")]: {
            width: theme.spacing(2.8),
            height: theme.spacing(2.8),
            margin: theme.spacing(0.3),
            // marginRight: theme.spacing(1)
            left: theme.spacing(-3.8)
        }
    },
    positionLeft: {
        borderRadius: 4,
        position: "relative",
        // backgroundColor: "#fff",
        width: theme.spacing(5),
        height: theme.spacing(5),
        backgroundColor: theme.palette.primary.main,
        // margin: theme.spacing(0.5),

        // top: -2,
        left: theme.spacing(-10),
        [theme.breakpoints.down("xs")]: {
            width: theme.spacing(4),
            height: theme.spacing(4),
            left: theme.spacing(-6.5)
        }
    }
}));

export default function AccountIconPositionSelector({
    setSelectedIconPosition,
    selectedIconPosition,
    label
}) {
    const classes = useStyles();
    const checkUI = (
        <Box p={0.5} mt={0.4}>
            <CheckCircle
                color="primary"
                fontSize="small"
                style={{
                    fontSize: 14
                }}
            />
        </Box>
    );
    return (
        <div>
            <Box mt={3} mb={2}>
                <Box mb={0}>
                    <Typography
                        color="textPrimary"
                        variant="subtitle1"
                        gutterBottom
                    >
                        {label}
                    </Typography>
                </Box>
                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="flex-start"
                >
                    <Grid item>
                        <Box
                            borderRadius={4}
                            p={0.5}
                            onClick={() => setSelectedIconPosition(INSIDE)}
                            className={clsx(
                                selectedIconPosition !== INSIDE &&
                                    classes.shapeArea,
                                selectedIconPosition === INSIDE &&
                                    classes.selectedShapeArea
                            )}
                        >
                            <Box
                                className={clsx(
                                    classes.positionInside,
                                    selectedIconPosition === INSIDE &&
                                        classes.selectedPosition
                                )}
                            />
                        </Box>
                        <Box display="flex" justifyContent="center">
                            <Box display="flex" alignItems="center">
                                {selectedIconPosition === INSIDE && checkUI}
                                <Typography
                                    className={classes.shapesTitle}
                                    color="textPrimary"
                                >
                                    INSIDE
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item>
                        <Box align="center">
                            <Box
                                p={0.5}
                                borderRadius={4}
                                className={clsx(
                                    selectedIconPosition !== OVERLAP &&
                                        classes.shapeArea,
                                    selectedIconPosition === OVERLAP &&
                                        classes.selectedShapeArea
                                )}
                                onClick={() => setSelectedIconPosition(OVERLAP)}
                            >
                                <Box
                                    className={clsx(
                                        classes.positionOverlap,
                                        selectedIconPosition === OVERLAP &&
                                            classes.selectedPosition
                                    )}
                                ></Box>
                            </Box>
                            <Box display="flex" justifyContent="center">
                                <Box display="flex" alignItems="center">
                                    {selectedIconPosition === OVERLAP &&
                                        checkUI}
                                    <Typography
                                        className={classes.shapesTitle}
                                        color="textPrimary"
                                    >
                                        OVERLAP
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item>
                        <Box align="center">
                            <Box
                                borderRadius={4}
                                className={clsx(
                                    selectedIconPosition !== LEFT &&
                                        classes.shapeArea,
                                    selectedIconPosition === LEFT &&
                                        classes.selectedShapeArea
                                )}
                                onClick={() => setSelectedIconPosition(LEFT)}
                            >
                                <Box
                                    className={clsx(
                                        classes.positionLeft,
                                        selectedIconPosition === LEFT &&
                                            classes.selectedPosition
                                    )}
                                ></Box>
                            </Box>

                            <Box display="flex" justifyContent="center">
                                <Box display="flex" alignItems="center">
                                    {selectedIconPosition === LEFT && checkUI}
                                    <Typography
                                        className={classes.shapesTitle}
                                        color="textPrimary"
                                    >
                                        LEFT
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </div>
    );
}
