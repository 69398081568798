// import _ from "lodash";
import actionType from "../actionTypes";

const initialState = {
    integrationList: [],

    loading: false
};

export default function (state = initialState, action) {
    switch (action.type) {
        case actionType.SET_INTEGRATIONS: {
            return {
                ...state,
                integrationList: action.payload.integrations
            };
        }
        default: {
            return state;
        }
    }
}
