import React from "react";
import { Route, Redirect } from "react-router-dom";
import config from "../../config";

export default function AdminRoutes({ component: Component, user, ...rest }) {
    const appUsers = config.userType;
    const currentUserType = user?.userType;
    return (
        // Show the component only when the user is logged in
        // Otherwise, redirect the user to /signin page
        <Route
            {...rest}
            render={(props) =>
                user && currentUserType === appUsers.admin ? (
                    <Component {...props} user={user} />
                ) : (user && currentUserType === appUsers.free) ||
                  currentUserType === appUsers.plus ||
                  currentUserType === appUsers.pro ? (
                    <Redirect to={config.routes.dashboard} />
                ) : (
                    <Redirect to={config.routes.login} />
                )
            }
        />
    );
}
