import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
    Avatar,
    Box,
    Button,
    CircularProgress,
    Grid,
    MenuItem,
    Select,
    TextField
} from "@material-ui/core";
import * as yup from "yup";
import * as Scroll from "react-scroll";
// import useGlobalStyles from "../../../hooks/useGlobalStyles";
import clsx from "clsx";
// import { ColorPicker } from "material-ui-color";
import utils from "../../../utils";
import Alert from "@material-ui/lab/Alert";
import integrations from "../../../services/integrations";
import config from "../../../config";

// const CONSTANTS = config.constants;
const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%"
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular
    },
    previewIcon: {
        border: `1px dashed ${theme.palette.primary.main}`,
        width: theme.spacing(9),
        height: theme.spacing(9)
        // [theme.breakpoints.down("xs")]: {
        //   width: theme.spacing(8),
        //   height: theme.spacing(8),
        // },
    },
    previewIconOverlay: {
        backgroundColor: "#000",
        opacity: "0.5",
        position: "absolute",
        bottom: 0,
        height: "50%",
        width: theme.spacing(9),
        // [theme.breakpoints.down("xs")]: {
        //   width: theme.spacing(8),
        // },
        borderBottomRightRadius: 4,
        borderBottomLeftRadius: 4
    }
}));

let schema = yup.object().shape({
    file: yup.string().min(5).required().label("File"),
    fileType: yup
        .string()
        .oneOf(
            ["image/png", "image/jpg", "image/jpeg"],
            "File must be an image"
        )
        .required()
        .default(undefined)
        .label("File type"),
    fileSize: yup
        .number()
        .max(2000000, "File size must be less or equal to 2MB")
        .required()
        .default(undefined)
        .label("File size"),
    url: yup.string().required().label("Url"),
    name: yup.string().required().min(5).max(20).label("Name"),
    label: yup.string().required().min(5).max(20).label("Label"),
    accessType: yup
        .string()
        .oneOf(["all", "paid"])
        .required()
        .label("Access Type")
});

var scroll = Scroll.animateScroll;

export default function AddIntegration(props) {
    const classes = useStyles();
    // const globalClasses = useGlobalStyles();
    const [integrationUrl, setIntegrationUrl] = useState("");
    const [integrationName, setIntegrationName] = useState("");
    const [integrationLabel, setIntegrationLabel] = useState("");
    const [accessType, setAccessType] = useState("");
    const [selectedFile, setselectedFile] = useState();

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [successMsg, setSuccessMsg] = useState("");

    const handlefileInput = (e) => {
        e.preventDefault();
        setError("");
        const input = e.currentTarget;
        const file = input.files[0];
        if (file) {
            const fileType = file.type;

            utils
                .toBase64(file)
                .then((base64File) => {
                    setselectedFile({
                        file: base64File,
                        fileSize: file.size,
                        fileType
                    });
                })
                .catch((error) => {});
        }
    };

    const submitForm = (e) => {
        e.preventDefault();
        const isFile = selectedFile && selectedFile.file;
        let integrationData = {
            name: integrationName,
            label: integrationLabel,
            url: integrationUrl,
            accessType,
            file: isFile ? selectedFile.file : "",
            fileSize: isFile ? selectedFile.fileSize : 0,
            fileType: isFile ? selectedFile.fileType.toLowerCase() : ""
        };
        console.log(
            "🚀 ~ file: AddIntegration.jsx ~ line 136 ~ submitForm ~ integrationData",
            integrationData
        );

        schema
            .validate(integrationData)
            .then(() => {
                setError("");
                setLoading(true);
                addIntegration(integrationData);
                scroll.scrollToTop();
            })
            .catch((e) => {
                console.log(
                    "🚀 ~ file: AddIntegration.jsx ~ line 150 ~ submitForm ~ e",
                    e
                );
                scroll.scrollToTop();
                setError(e.message);
            });
    };
    const addIntegration = (data) => {
        integrations
            .addNewIntegration(data)
            .then((res) => {
                setLoading(false);
                setSuccessMsg(config.defaultNotiStackMessage);
                props.addIntegration(res.data.integration);
                setselectedFile("");
                setIntegrationUrl("");
                setIntegrationName("");

                setTimeout(() => setSuccessMsg(""), 5000);
            })
            .catch((ex) => {
                setLoading(false);
                setError(ex.response?.data.message);
                scroll.scrollToTop();
            });
    };
    return (
        // <AdminPageContainer>
        <Accordion>
            <AccordionSummary
                aria-expanded={true}
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header-1"
            >
                <Typography className={classes.heading}>
                    Add Integration
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Box bgcolor="background.default" width="100%" padding={2}>
                    <form onSubmit={submitForm}>
                        {loading && (
                            <Box align="center">
                                <CircularProgress />
                            </Box>
                        )}
                        {error && <Alert severity="error">{error}</Alert>}
                        {successMsg && (
                            <Alert severity="success">{successMsg}</Alert>
                        )}

                        <Grid
                            container
                            direction="row"
                            justify="left"
                            alignItems="left"
                            spacing={2}
                        >
                            <Grid item lg={1} md={2} sm={3} xs={4}>
                                <div style={{ position: "relative" }}>
                                    <Avatar
                                        variant={"rounded"}
                                        className={clsx(classes.previewIcon)}
                                        src={
                                            selectedFile
                                                ? selectedFile.file
                                                : ""
                                        }
                                    ></Avatar>
                                    <Box className={classes.previewIconOverlay}>
                                        <Typography
                                            align="center"
                                            className={classes.changeAvatarText}
                                        >
                                            <Button
                                                component="label"
                                                style={{ fontSize: 8 }}
                                            >
                                                Change
                                                <input
                                                    type="file"
                                                    style={{
                                                        display: "none"
                                                    }}
                                                    onChange={handlefileInput}
                                                />
                                            </Button>
                                        </Typography>
                                    </Box>
                                </div>
                            </Grid>
                            <Grid lg={5} md={4} sm={9} xs={12} item>
                                <Box width={"100%"} mt={2}>
                                    <Typography variant="subtitle1">
                                        Name (for internal use only)
                                    </Typography>
                                    <TextField
                                        value={integrationName}
                                        placeholder="e.g. Youtube"
                                        onChange={(e) => {
                                            setError("");
                                            setIntegrationName(
                                                e.currentTarget.value
                                                    .trim()
                                                    .toLocaleLowerCase()
                                            );
                                        }}
                                        fullWidth
                                    />
                                </Box>
                            </Grid>
                            <Grid item lg={6} md={6} sm={12} xs={12}>
                                <Box width={"100%"} mt={2}>
                                    <Typography variant="subtitle1">
                                        Label
                                    </Typography>
                                    <TextField
                                        value={integrationLabel}
                                        placeholder="e.g Youtube videos"
                                        onChange={(e) => {
                                            setError("");
                                            setIntegrationLabel(
                                                e.currentTarget.value
                                            );
                                        }}
                                        fullWidth
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            direction="row"
                            justify="left"
                            alignItems="left"
                            spacing={2}
                        >
                            <Grid item lg={1} md={2} sm={3} xs={4}></Grid>

                            <Grid lg={5} md={4} sm={9} xs={12} item>
                                <Box width={"100%"} mt={2}>
                                    <Typography variant="subtitle1">
                                        Access Type
                                    </Typography>
                                    <Select
                                        fullWidth
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={accessType}
                                        onChange={(e) => {
                                            setAccessType(e.target.value);
                                        }}
                                    >
                                        {Object.keys(
                                            config.integrationAccess
                                        ).map((e) => {
                                            return (
                                                <MenuItem value={e}>
                                                    {e}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                </Box>
                            </Grid>
                            <Grid item lg={6} md={6} sm={12} xs={12}>
                                <Box width={"100%"} mt={2}>
                                    <Typography variant="subtitle1">
                                        URL
                                    </Typography>
                                    <TextField
                                        value={integrationUrl}
                                        placeholder="e.g. https://steamcommunity.com/"
                                        onChange={(e) => {
                                            setError("");
                                            setIntegrationUrl(
                                                e.currentTarget.value
                                                    .trim()
                                                    .toLowerCase()
                                            );
                                        }}
                                        fullWidth
                                    />
                                </Box>
                            </Grid>
                        </Grid>

                        <Box mt={2}>
                            <Button
                                color="primary"
                                type="submit"
                                variant="contained"
                                fullWidth
                            >
                                Save
                            </Button>
                        </Box>
                    </form>
                </Box>
            </AccordionDetails>
        </Accordion>
        // </AdminPageContainer>
    );
}
