/* eslint-disable react-hooks/exhaustive-deps */
import {
    Box,
    Button,
    CircularProgress,
    Container,
    Grid,
    MenuItem,
    Paper,
    Select,
    Typography
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import UserAppBar from "../../../components/core/UserAppBar/index";
import config from "../../../config";
import useGlobalStyles from "../../../hooks/useGlobalStyles";
import actionTypes from "../../../redux/actionTypes";
import auth from "../../../services/auth";
import userService from "../../../services/user";
import CheckCircleRoundedIcon from "@material-ui/icons/CheckCircleRounded";
import Beta from "../../../components/common/Beta";

import ConfirmationDialog from "../../../components/common/ConfirmationDialog";
import { Link } from "react-router-dom";
import LabelWithEmoji from "../../../components/common/LabelWithEmoji";
import PaymentDetails from "./components/PaymentDetails";
import { useSnackbar } from "notistack";
// import Management from "../management/ManagementAndCategories";
import CustomBadge from "../../../components/common/CustomBadge";
import Footer from "../../../components/common/Footer";

// const CONSTANT = config.constants;

export default function Settings(props) {
    const globalClasses = useGlobalStyles();
    // const isPlusOrProUser = auth.isPlusOrProUser();
    const isCurrentUserFreeOrPlus = auth.isCurrentUserPlusOrPro();
    const currentUser = useSelector((state) => state.auth.currentUser);

    const user = useSelector((state) => state.auth.user);
    const userSurveys = useSelector((state) => state.surveys.userSurveys);

    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const [loading, setLoading] = useState(false);
    // const [error, setError] = useState("");
    // const [successMsg, setSuccessMsg] = useState("");
    const [userSurvey, setUserSurvey] = useState(
        JSON.stringify(user.userSurveyId)
    );
    const [openConfirmationDialog, setOpenConfirmationDialog] =
        React.useState(false);
    const [openLogoutConfirmationDialog, setOpenLogoutConfirmationDialog] =
        React.useState(false);

    useEffect(() => {
        setUserSurvey(JSON.stringify(user.userSurveyId));
    }, []);

    const toggleLogoutDialog = () => {
        setOpenLogoutConfirmationDialog(!openLogoutConfirmationDialog);
    };
    const onLogout = () => {
        setLoading(true);
        auth.logout()
            .then(() => {
                dispatch({ type: actionTypes.USER_LOGOUT });

                props.history.push(config.routes.login);
            })
            .catch((ex) => {
                toggleConfirmationDialog();
            });
    };

    const handleUserSurvey = (e) => {
        e.preventDefault();
        setUserSurvey(e.target.value);
        const survey = JSON.parse(e.target.value);
        userService
            .updateUserBase({ userSurveyId: survey._id, userId: user._id })
            .then((res) => {
                dispatch({
                    type: actionTypes.SET_USER,
                    payload: { user: res.data.user }
                });
            })
            .catch((ex) => {});
    };

    const toggleConfirmationDialog = () => {
        setOpenConfirmationDialog(!openConfirmationDialog);
    };
    const deleteMyAccount = () => {
        setLoading(true);
        auth.deleteCurrentUser()
            .then((res) => {
                setLoading(false);
                dispatch({ type: actionTypes.SET_AUTH, payload: { auth: "" } });
                props.history.push(
                    config.routes.login +
                        "?info=Your account has been removed from Base"
                );
                enqueueSnackbar("Your base account has been removed", {
                    variant: "success"
                });
            })
            .catch((ex) => {
                setLoading(false);
                enqueueSnackbar(config.contactSupport, {
                    variant: "error"
                });
            });
    };
    return (
        <div>
            <UserAppBar />
            <Container maxWidth="md">
                <Box p={2}>
                    <Beta />
                </Box>
                <Box>
                    <Box>
                        {/* <Box mt={2}>
                            <Paper>
                                <Box p={4}>
                                    <Management />
                                </Box>
                            </Paper>
                        </Box> */}
                        <Box mt={2}>
                            <Paper>
                                <Box p={4}>
                                    <PaymentDetails />
                                </Box>
                            </Paper>
                        </Box>
                        <Box mt={2}>
                            <Paper>
                                <Box p={4}>
                                    <Box>
                                        <Typography
                                            color="textPrimary"
                                            variant="h6"
                                            gutterBottom
                                        >
                                            <LabelWithEmoji
                                                text="Survey"
                                                emoji="📝"
                                            />
                                        </Typography>
                                        <Typography
                                            color="textPrimary"
                                            variant="subtitle1"
                                            gutterBottom
                                        >
                                            This information is used to improve
                                            future product features and
                                            information
                                        </Typography>
                                        <Typography
                                            color="primary"
                                            variant="subtitle1"
                                            gutterBottom
                                        >
                                            I am a/an{" "}
                                            <Select
                                                value={userSurvey}
                                                onChange={handleUserSurvey}
                                                color="primary"
                                            >
                                                {userSurveys.map((s) => (
                                                    <MenuItem
                                                        key={s?._id}
                                                        value={JSON.stringify(
                                                            s
                                                        )}
                                                    >
                                                        {s.title}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </Typography>
                                    </Box>
                                    <Box mt={2}>
                                        <Box display="flex">
                                            <CheckCircleRoundedIcon
                                                color="primary"
                                                style={{
                                                    // fontSize: 20,
                                                    marginTop: 4
                                                }}
                                            />
                                            <Typography
                                                color="textPrimary"
                                                gutterBottom
                                                variant="h6"
                                            >
                                                &nbsp;
                                                {"Verified BASE".toUpperCase()}{" "}
                                            </Typography>
                                            {!isCurrentUserFreeOrPlus && (
                                                <CustomBadge badgeContent="PLUS" />
                                            )}
                                        </Box>

                                        <Typography
                                            color="textPrimary"
                                            variant="subtitle1"
                                            gutterBottom
                                        >
                                            Verfication Status:{" "}
                                            {currentUser?.isVerifiedBase ||
                                            currentUser?.managedBy?._id ||
                                            isCurrentUserFreeOrPlus
                                                ? "Your Base is verified"
                                                : "Not Verified"}
                                        </Typography>
                                        {/* <Typography style={{ fontSize: 14 }}>
                                            Please{" "}
                                            <a
                                                className={
                                                    globalClasses.aTagText
                                                }
                                                href="mailto:team@base.gg?subject=Base Verification"
                                            >
                                                contact us
                                            </a>{" "}
                                            to verify your base
                                        </Typography> */}
                                    </Box>
                                    <Box mt={2}>
                                        <Typography
                                            color="textPrimary"
                                            variant="h6"
                                            gutterBottom
                                        >
                                            <LabelWithEmoji
                                                text="About"
                                                emoji="📃"
                                            />
                                        </Typography>
                                        <Box
                                            style={{
                                                display: "flex"
                                            }}
                                        >
                                            <Box mr={2}>
                                                <Link
                                                    to={config.routes.privacy}
                                                    className={
                                                        globalClasses.aTag
                                                    }
                                                >
                                                    <Typography
                                                        variant={"subtitle1"}
                                                        color="textSecondary"
                                                    >
                                                        Privacy Policy
                                                    </Typography>
                                                </Link>
                                            </Box>

                                            <Box mr={2}>
                                                <Link
                                                    to={config.routes.tos}
                                                    className={
                                                        globalClasses.aTag
                                                    }
                                                >
                                                    <Typography
                                                        variant={"subtitle1"}
                                                        color="textSecondary"
                                                    >
                                                        Terms of Service
                                                    </Typography>
                                                </Link>
                                            </Box>
                                            <Box mr={2}>
                                                <a
                                                    href={config.routes.help}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    className={
                                                        globalClasses.aTag
                                                    }
                                                >
                                                    <Typography
                                                        variant={"subtitle1"}
                                                        color="textSecondary"
                                                    >
                                                        Help
                                                    </Typography>
                                                </a>
                                            </Box>

                                            {/* <Box mr={2}>
                                                <Typography
                                                    variant={"subtitle1"}
                                                    color="textSecondary"
                                                >
                                                    FAQ
                                                </Typography>
                                            </Box> */}

                                            {/* <Box mr={2}>
                                                <a
                                                    href="mailto:team@base.gg?subject=Base Member Inquiry"
                                                    className={
                                                        globalClasses.aTag
                                                    }
                                                >
                                                    <Typography
                                                        variant={"subtitle1"}
                                                        color="textSecondary"
                                                    >
                                                        Contact Us
                                                    </Typography>
                                                </a>
                                            </Box> */}
                                        </Box>
                                        <Box mt={2}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} sm={6}>
                                                    <Button
                                                        onClick={
                                                            toggleConfirmationDialog
                                                        }
                                                        fullWidth
                                                        color="secondary"
                                                        variant="contained"
                                                    >
                                                        Delete BASE
                                                    </Button>
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <Button
                                                        color="primary"
                                                        onClick={
                                                            toggleLogoutDialog
                                                        }
                                                        fullWidth
                                                        variant="contained"
                                                    >
                                                        Logout
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Box>
                                </Box>
                            </Paper>
                        </Box>

                        <Box>
                            {/* <Paper> */}

                            {/* </Paper> */}
                        </Box>
                        <ConfirmationDialog
                            open={openConfirmationDialog}
                            onClose={toggleConfirmationDialog}
                            onConfirm={deleteMyAccount}
                            confirmationDialogTitle={"Delete Base"}
                            confirmationDialogDescription={
                                <Box>
                                    {loading && (
                                        <Box align="center">
                                            <CircularProgress />
                                        </Box>
                                    )}
                                    {!loading && (
                                        <Typography>
                                            Are you sure you want to permanently
                                            delete your base?
                                        </Typography>
                                    )}
                                </Box>
                            }
                            disableButtons={loading}
                            disableBackDrop={loading}
                            confirmationBtnTitle={"Delete"}
                        />
                        <ConfirmationDialog
                            open={openLogoutConfirmationDialog}
                            onClose={toggleLogoutDialog}
                            onConfirm={onLogout}
                            confirmationDialogTitle={"Logout"}
                            confirmationDialogDescription={
                                <Box>
                                    {loading && (
                                        <Box align="center">
                                            <CircularProgress />
                                        </Box>
                                    )}
                                    {!loading && (
                                        <Typography>
                                            Are you sure you want to logout?
                                        </Typography>
                                    )}
                                </Box>
                            }
                            disableButtons={loading}
                            disableBackDrop={loading}
                            confirmationBtnTitle={"Logout"}
                        />
                    </Box>
                </Box>
                <Box p={2}>
                    <Footer />
                </Box>
            </Container>
        </div>
    );
}
