import {
    Box,
    Container,
    Grid,
    makeStyles,
    Paper,
    Typography
} from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";
import PublicAppbar from "../../components/core/PublicAppbar";
import UserAppBar from "../../components/core/UserAppBar/index";
// import useGlobalStyles from "../../hooks/useGlobalStyles";

const useStyle = makeStyles((theme) => ({
    fontSize: {
        fontSize: 14,
        fontWeight: 100,
        margin: theme.spacing(1, 0)
    }
}));
export default function TermsOfService() {
    // const globalClasses = useGlobalStyles();
    const classes = useStyle();
    const user = useSelector((state) => state.auth.user);
    return (
        <div>
            {user ? <UserAppBar /> : <PublicAppbar />}
            <Grid item md={12} lg={12} sm={12} xs={12}>
                <Container maxWidth="lg">
                    <Box my={4}>
                        <Paper>
                            <Box p={4}>
                                <Typography variant="h4">
                                    TERMS OF SERVICE
                                </Typography>

                                <Typography
                                    variant="subtitle1"
                                    className={classes.fontSize}
                                    align="left"
                                    gutterBottom
                                >
                                    Base.gg - Last updated and effective:
                                    December 12, 2020
                                </Typography>

                                <Typography
                                    variant="subtitle1"
                                    className={classes.fontSize}
                                    align="left"
                                    gutterBottom
                                >
                                    The following terms and conditions govern
                                    all use of Base.gg (also called “Base”), its
                                    content, services and applications (the
                                    “Services” or “Service”), including any
                                    other applications that link to this
                                    agreement.
                                </Typography>

                                <Typography
                                    variant="subtitle1"
                                    className={classes.fontSize}
                                    align="left"
                                    gutterBottom
                                >
                                    BY ACCESSING, USING, OR REGISTERING FOR THE
                                    SERVICES, YOU CONFIRM THAT YOU AGREE TO
                                    THESE TERMS (the “Agreement”). If you do not
                                    agree, you may not use the Services.
                                </Typography>

                                <Typography variant="h5">
                                    ABOUT THE SERVICES
                                </Typography>

                                <Typography
                                    variant="subtitle1"
                                    className={classes.fontSize}
                                    align="left"
                                    gutterBottom
                                >
                                    Base.gg, its parent and affiliates (“we” or
                                    “us”) make available the Services to you on
                                    the condition that you abide by this
                                    Agreement. The Services give users the
                                    ability to access and share their profiles
                                    and other content. We may, in our sole
                                    discretion, change any aspect of the
                                    Services or discontinue any Service without
                                    notice. The Services are based in the United
                                    States of America. The Services are not
                                    designed or customized for any other
                                    country; you may use them only if they
                                    comply with the laws of the country from
                                    which you are accessing our Services.
                                </Typography>

                                <Typography
                                    variant="subtitle1"
                                    className={classes.fontSize}
                                    align="left"
                                    gutterBottom
                                >
                                    Please read this Agreement carefully before
                                    creating, accessing or using the Services.
                                    You understand and agree that this Agreement
                                    governs your relationship with us and has
                                    the same effect as an agreement in writing.
                                </Typography>

                                <Typography
                                    variant="h5"
                                    align="left"
                                    gutterBottom
                                >
                                    YOUR BASE ACCOUNT AND SITE
                                </Typography>

                                <Typography
                                    variant="subtitle1"
                                    className={classes.fontSize}
                                    align="left"
                                    gutterBottom
                                >
                                    Registration is optional; however, you will
                                    need to register with us and create a
                                    username to use certain Services and
                                    features. A username is a unique identifier
                                    selected or supplied by you or provided by
                                    us and is used to identify you on our
                                    Services. To register a username and create
                                    an account on Base, you must sign in with a
                                    valid account with Google
                                    (https://www.google.com/). We may refuse or
                                    reject a username at our discretion.
                                </Typography>

                                <Typography
                                    variant="h5"
                                    align="left"
                                    gutterBottom
                                >
                                    SECURITY
                                </Typography>

                                <Typography
                                    variant="subtitle1"
                                    className={classes.fontSize}
                                    align="left"
                                    gutterBottom
                                >
                                    If you create a profile on the Services, you
                                    are responsible for maintaining the security
                                    of your account and profile, and you are
                                    fully responsible for all consequences and
                                    activities that occur under your account.
                                    You must not describe or assign keywords to
                                    your profile in a misleading or unlawful
                                    manner, including in a manner intended to
                                    trade on the name or reputation of others,
                                    and we may change or remove any description
                                    or keyword that it considers inappropriate
                                    or unlawful, or otherwise likely to cause us
                                    liability. You must immediately notify us of
                                    any unauthorized uses of your account or any
                                    other breaches of security.
                                </Typography>

                                <Typography
                                    variant="h5"
                                    align="left"
                                    gutterBottom
                                >
                                    PASSWORDS
                                </Typography>

                                <Typography
                                    variant="subtitle1"
                                    className={classes.fontSize}
                                    align="left"
                                    gutterBottom
                                >
                                    You are responsible for safeguarding the
                                    passwords that you use to access the
                                    Services, platform/network connections and
                                    for any activities or actions under your
                                    passwords. We encourage you to use “strong”
                                    passwords (passwords that use a combination
                                    of upper and lower case letters, numbers and
                                    symbols) with your all your accounts. We
                                    cannot and will not be liable for any loss
                                    or damage arising from your failure to
                                    comply with the above requirements.
                                </Typography>

                                <Typography
                                    variant="h5"
                                    align="left"
                                    gutterBottom
                                >
                                    RESPONSIBILITY OF CONTRIBUTORS
                                </Typography>

                                <Typography
                                    variant="subtitle1"
                                    className={classes.fontSize}
                                    align="left"
                                    gutterBottom
                                >
                                    If you create a profile or otherwise
                                    transmit or post materials (e.g., text,
                                    graphics, applications, etc.) on the
                                    Services (any such material, “Content”), you
                                    understand and agree that you are entirely
                                    responsible for such Content that is
                                    provided by you or through your account.
                                    This includes, for example, any personal
                                    information, such as address, the addresses
                                    of others, or your current location that you
                                    may make available about yourself on the
                                    Services. WE ARE NOT RESPONSIBLE FOR THE
                                    CONSEQUENCES OF SHARING OR POSTING ANY
                                    PERSONAL OR OTHER INFORMATION ON OUR
                                    SERVICES.
                                </Typography>

                                <Typography
                                    variant="h5"
                                    align="left"
                                    gutterBottom
                                >
                                    ACCESS
                                </Typography>

                                <Typography
                                    variant="subtitle1"
                                    className={classes.fontSize}
                                    align="left"
                                    gutterBottom
                                >
                                    If you are accessing our Services by a
                                    mobile device, your wireless carrier may
                                    charge you fees for internet browsing, and
                                    other wireless access or communications
                                    services. We do not guarantee that our
                                    mobile Services can be accessed through all
                                    wireless devices or service plans or are
                                    available in all geographical locations.
                                </Typography>
                                <Typography
                                    variant="subtitle1"
                                    className={classes.fontSize}
                                    align="left"
                                    gutterBottom
                                >
                                    If you elect to store authentication
                                    information, such as a username and
                                    password, where others may access it, we are
                                    not responsible for any loss of personal
                                    data or other consequences if someone other
                                    than you uses that information to access our
                                    services. If you lose a device, such as a
                                    laptop, desktop, or smartphone, or a device
                                    is stolen containing your username and
                                    password, it is up to you to take all the
                                    steps necessary to protect yourself.
                                </Typography>

                                <Typography
                                    variant="h5"
                                    align="left"
                                    gutterBottom
                                >
                                    RESTRICTIONS
                                </Typography>

                                <Typography
                                    variant="subtitle1"
                                    className={classes.fontSize}
                                    align="left"
                                    gutterBottom
                                >
                                    To use our Services, you must:
                                </Typography>
                                <ul>
                                    <li>
                                        <Typography
                                            variant="subtitle1"
                                            className={classes.fontSize}
                                            align="left"
                                        >
                                            Comply with applicable laws and
                                            regulations and not participate in,
                                            facilitate, or further illegal
                                            activities;
                                        </Typography>
                                    </li>
                                    <li>
                                        <Typography
                                            variant="subtitle1"
                                            className={classes.fontSize}
                                            align="left"
                                        >
                                            Immediately notify us if you learn
                                            of a security breach or other
                                            illegal activity on the Services;
                                        </Typography>
                                    </li>
                                    <li>
                                        <Typography
                                            variant="subtitle1"
                                            className={classes.fontSize}
                                            align="left"
                                        >
                                            Protect your username and password;
                                        </Typography>
                                    </li>
                                    <li>
                                        <Typography
                                            variant="subtitle1"
                                            className={classes.fontSize}
                                            align="left"
                                        >
                                            Not post content that contains
                                            explicit or graphic descriptions or
                                            accounts of sexual acts or is
                                            threatening, abusive, harassing,
                                            defamatory, libelous, deceptive,
                                            fraudulent, invasive of another’s
                                            privacy, or tortious;
                                        </Typography>
                                    </li>
                                    <li>
                                        <Typography
                                            variant="subtitle1"
                                            className={classes.fontSize}
                                            align="left"
                                        >
                                            Not engage in an activity that is
                                            harmful to us or our customers,
                                            advertisers, affiliates, vendors, or
                                            anyone else;
                                        </Typography>
                                    </li>
                                    <li>
                                        <Typography
                                            variant="subtitle1"
                                            className={classes.fontSize}
                                            align="left"
                                        >
                                            Not use any automated process to
                                            access or use the Services or any
                                            process, whether automated or
                                            manual, to capture data or content
                                            from any Service for any reason; and
                                        </Typography>
                                    </li>
                                    <li>
                                        <Typography
                                            variant="subtitle1"
                                            className={classes.fontSize}
                                            align="left"
                                        >
                                            Not use any Service or any process
                                            to damage, disable, impair, or
                                            otherwise attack our Services or the
                                            networks connected to the Services.
                                        </Typography>
                                    </li>
                                    <li>
                                        <Typography
                                            variant="subtitle1"
                                            className={classes.fontSize}
                                            align="left"
                                        >
                                            You may not post content intended to
                                            provide professional advice,
                                            including the provision of medical
                                            treatment, legal advice, or
                                            investment advice. You may not post
                                            content to solicit, recommend,
                                            endorse, or offer to buy or sell any
                                            securities or other financial
                                            instruments, tout stocks, or
                                            recommend that any particular
                                            security, portfolio of securities,
                                            transaction or investment strategy
                                            is suitable for you or any specific
                                            person.
                                        </Typography>
                                    </li>
                                </ul>
                                <Typography
                                    variant="subtitle1"
                                    className={classes.fontSize}
                                    align="left"
                                >
                                    We can take any technical, legal, and other
                                    actions that we deem, in our sole
                                    discretion, necessary and appropriate
                                    without notice to you to prevent violations
                                    and to enforce this Agreement.
                                </Typography>

                                <Typography
                                    variant="h5"
                                    align="left"
                                    gutterBottom
                                >
                                    YOUR REPRESENTATION TO US
                                </Typography>

                                <Typography
                                    variant="subtitle1"
                                    className={classes.fontSize}
                                    align="left"
                                    gutterBottom
                                >
                                    By making Content available, you represent
                                    and warrant that: the downloading, copying
                                    and use of the Content will not infringe the
                                    proprietary rights, including but not
                                    limited to the copyright, patent, trademark
                                    or trade secret rights, of any third party;
                                    the Content does not contain or install any
                                    viruses, worms, malware, Trojan horses or
                                    other harmful or destructive content; the
                                    Content is not spam, is not machine- or
                                    randomly-generated, and does not contain
                                    unethical or unwanted commercial content
                                    designed to drive traffic to third party
                                    sites or boost the search engine rankings of
                                    third party sites, or to further unlawful
                                    acts (such as phishing) or mislead
                                    recipients as to the source of the material
                                    (such as spoofing); the Content is not
                                    pornographic, libelous or defamatory, does
                                    not contain threats or incite violence
                                    towards individuals or entities, and does
                                    not violate the privacy or publicity rights
                                    of any third party; your profile is not
                                    getting advertised via unwanted electronic
                                    messages such as spam links on newsgroups,
                                    email lists, other blogs and websites, and
                                    similar unsolicited promotional methods;
                                    your profile is not named in a manner that
                                    misleads your readers into thinking that you
                                    are another person or company.
                                </Typography>

                                <Typography
                                    variant="h5"
                                    align="left"
                                    gutterBottom
                                >
                                    YOUR LICENSE
                                </Typography>

                                <Typography
                                    variant="subtitle1"
                                    className={classes.fontSize}
                                    align="left"
                                    gutterBottom
                                >
                                    By submitting Content on the Services or by
                                    linking to other profiles on the Services
                                    for inclusion on your profile, you grant us
                                    and our parent a world-wide, royalty-free,
                                    and non-exclusive license to reproduce,
                                    modify, adapt and publish the Content solely
                                    for the purpose of displaying, distributing
                                    and promoting your profile. If you delete
                                    Content, we will use reasonable efforts to
                                    remove it from the profile, but you
                                    acknowledge that caching or references to
                                    the Content may not be made immediately
                                    unavailable.
                                </Typography>

                                <Typography
                                    variant="subtitle1"
                                    className={classes.fontSize}
                                    align="left"
                                    gutterBottom
                                >
                                    Without limiting any of those
                                    representations or warranties, we have the
                                    right (though not the obligation) to, in our
                                    sole discretion (i) refuse or remove any
                                    content that, in our reasonable opinion,
                                    violates these terms or any policy or is in
                                    any way harmful or objectionable, or (ii)
                                    terminate or deny access to and use of an
                                    account to any individual or entity for any
                                    reason, in our sole discretion. We will have
                                    no obligation to provide a refund of any
                                    amounts previously paid.
                                </Typography>

                                <Typography
                                    variant="h5"
                                    align="left"
                                    gutterBottom
                                >
                                    USERNAME CONFLICTS
                                </Typography>

                                <Typography
                                    variant="subtitle1"
                                    className={classes.fontSize}
                                    align="left"
                                    gutterBottom
                                >
                                    You may not impersonate another person or
                                    claim a name for your profile that does not
                                    belong to you (i.e. “name squatting”). We
                                    are committed to ensure the rights of
                                    trademark owners and the right of
                                    individuals in the public sphere to claim
                                    their legal names and associated monikers on
                                    the Services. You understand and agree that
                                    you will forfeit a username and your account
                                    if the rightful owner protests your claim,
                                    or if such username is associated with a
                                    trademark or celebrity. If you have taken
                                    action to delete your account, the username
                                    you used will become available to others.
                                </Typography>

                                <Typography
                                    variant="h5"
                                    align="left"
                                    gutterBottom
                                >
                                    FEES AND PAYMENT
                                </Typography>

                                <Typography
                                    variant="subtitle1"
                                    className={classes.fontSize}
                                    align="left"
                                    gutterBottom
                                >
                                    We may offer from time-to-time optional
                                    premium paid services on the Services. By
                                    selecting to purchase a premium service, you
                                    agree to pay us the monthly or annual
                                    subscription fees indicated for that
                                    service. Payments will be charged on the day
                                    you sign up for a premium service and will
                                    cover the use of that service for a monthly
                                    or annual period as indicated. Premium
                                    service fees are not refundable. Payment for
                                    premium paid services will be handled
                                    through Stripe. We will be compliant to
                                    Stripe's Terms of Service when offering
                                    premium paid services. For more information
                                    on Stripe’s Terms of Service, please visit{" "}
                                    <a href="https://stripe.com/legal">
                                        https://stripe.com/legal
                                    </a>
                                </Typography>

                                <Typography
                                    variant="h5"
                                    align="left"
                                    gutterBottom
                                >
                                    TRIAL SERVICES
                                </Typography>

                                <Typography
                                    variant="subtitle1"
                                    className={classes.fontSize}
                                    align="left"
                                    gutterBottom
                                >
                                    Any trial promotion for a fee-based Service
                                    must be used within the specified time of
                                    the trial. You must cancel your account
                                    before the end of the trial period to avoid
                                    being charged a subscription fee. However,
                                    even during any free trial or other
                                    promotion, you will still be responsible for
                                    any purchases and surcharges incurred using
                                    your account and any sub- or
                                    linked-accounts. We reserve the right to
                                    limit you to one free trial or promotion of
                                    a fee-based Service and to prohibit the
                                    combining of free trials, promotions, and
                                    other offers.
                                </Typography>

                                <Typography
                                    variant="h5"
                                    align="left"
                                    gutterBottom
                                >
                                    RESPONSIBILITY OF PROFILE VISITORS
                                </Typography>

                                <Typography
                                    variant="subtitle1"
                                    className={classes.fontSize}
                                    align="left"
                                    gutterBottom
                                >
                                    We can remove Content for any reason, but we
                                    have no obligation to do so. The Content
                                    provided on the Services, including Content
                                    posted by users, is for general information,
                                    discussion and entertainment purposes only.
                                    We make no representations or guarantees
                                    about any aspect of the content on the
                                    Services and do not endorse any opinions
                                    expressed by any users. ALL CONTENT IS
                                    POSTED “AS IS” AND YOUR USE OR RELIANCE ON
                                    ANY CONTENT IS AT YOUR OWN RISK. WE HAVE NO
                                    LIABILITY TO YOU FOR THE CONSEQUENCES OF
                                    USING OR RELYING ON ANY CONTENT.
                                </Typography>

                                <Typography
                                    variant="h5"
                                    align="left"
                                    gutterBottom
                                >
                                    COPYRIGHT AND TRADEMARK CLAIMS
                                </Typography>

                                <Typography
                                    variant="subtitle1"
                                    className={classes.fontSize}
                                    align="left"
                                    gutterBottom
                                >
                                    Copyright and trademark owners can report
                                    alleged violations by sending an email to
                                    team@base.gg with subject line "Base
                                    Copyright/Trademark Claim - ".
                                </Typography>

                                <Typography
                                    variant="h5"
                                    align="left"
                                    gutterBottom
                                >
                                    INTELLECTUAL PROPERTY
                                </Typography>

                                <Typography
                                    variant="subtitle1"
                                    className={classes.fontSize}
                                    align="left"
                                    gutterBottom
                                >
                                    The Services and the Content provided on the
                                    Services are protected by copyright,
                                    trademark, patent, trade secret,
                                    international treaties, laws, and other
                                    proprietary rights, and also may have
                                    security components that protect digital
                                    information. You agree that you will not
                                    take any action to interfere with anyone’s
                                    rights in their content and you will not
                                    attempt to circumvent any mechanisms for
                                    preventing the unauthorized reproduction or
                                    distribution of Content. This Agreement does
                                    not transfer to you any of our or third
                                    party intellectual property. Nothing in this
                                    Agreement grants you a right or license to
                                    reproduce or otherwise use any of our or
                                    third party’s trademarks, service marks,
                                    logos and trade names.
                                </Typography>

                                <Typography
                                    variant="h5"
                                    align="left"
                                    gutterBottom
                                >
                                    CHANGES TO TERMS OF SERVICE
                                </Typography>

                                <Typography
                                    variant="subtitle1"
                                    className={classes.fontSize}
                                    align="left"
                                    gutterBottom
                                >
                                    We may modify this Terms of Service from
                                    time to time. If you do not agree to the
                                    changes, stop using our Services (and
                                    remember to cancel any fee-based services)
                                    before the changes take effect. Your use of
                                    a Service after the effective date of any
                                    changes means that you agree to the changes.
                                    We may also, in the future, offer new
                                    services and/or features through the
                                    Services. Such new features and/or services
                                    shall be subject to the terms and conditions
                                    of this Agreement and any supplemental terms
                                    that accompany the new features.
                                </Typography>

                                <Typography
                                    variant="h5"
                                    align="left"
                                    gutterBottom
                                >
                                    TERMINATION
                                </Typography>

                                <Typography
                                    variant="subtitle1"
                                    className={classes.fontSize}
                                    align="left"
                                    gutterBottom
                                >
                                    We may terminate your access to all or any
                                    part of the Services and terminate your
                                    account at any time, with or without cause,
                                    with or without notice, effective
                                    immediately. If you wish to terminate this
                                    Agreement or your account (if you have one),
                                    you may simply discontinue using the
                                    Services. All provisions of this Agreement
                                    which by their nature should survive
                                    termination shall survive termination,
                                    including, without limitation, ownership
                                    provisions, warranty disclaimers, indemnity
                                    and limitations of liability.
                                </Typography>

                                <Typography
                                    variant="h5"
                                    align="left"
                                    gutterBottom
                                >
                                    DISCLAIMER OF WARRANTIES
                                </Typography>

                                <Typography
                                    variant="subtitle1"
                                    className={classes.fontSize}
                                    align="left"
                                    gutterBottom
                                >
                                    WE SUPPLY THE SERVICES “AS IS,” “WITH ALL
                                    FAULTS,” AND “AS AVAILABLE.” WE DO NOT
                                    WARRANT OR GUARANTEE THAT ANY INFORMATION
                                    AVAILABLE USING OUR SERVICES IS ACCURATE OR
                                    RELIABLE OR YOUR USE OF THE SERVICES WILL BE
                                    UNINTERRUPTED, SECURE, OR FREE FROM ERROR.
                                    WE, OUR SUPPLIERS, AND AUTHORIZED
                                    DISTRIBUTORS GIVE NO EXPRESS WARRANTIES OR
                                    GUARANTEES NOR IMPLIED WARRANTIES OF
                                    MERCHANTABILITY, FITNESS FOR A PARTICULAR
                                    PURPOSE, ACCURACY, WORKMANLIKE EFFORT, AND
                                    NON-INFRINGMENT. YOUR ONLY REMEDY FOR ANY
                                    DEFECTIVE SOFTWARE IS REPLACEMENT OF THE
                                    SOFTWARE. IN ANY OTHER DISPUTE WITH US, YOUR
                                    ONLY REMEDY IS TO STOP USING YOUR ACCOUNT
                                    AND CANCEL ANY FEE-BASED SERVICES. IN NO
                                    EVENT WILL OUR LIABILITY TO YOU EXCEED THE
                                    TOTAL AMOUNT OF SERVICE FEES PAID DURING A
                                    ONE-MONTH PERIOD.
                                </Typography>

                                <Typography
                                    variant="h5"
                                    align="left"
                                    gutterBottom
                                >
                                    LIMITATION OF LIABILITY
                                </Typography>

                                <Typography
                                    variant="subtitle1"
                                    className={classes.fontSize}
                                    align="left"
                                    gutterBottom
                                >
                                    WE, OUR SUPPLIERS, AND AUTHORIZED
                                    DISTRIBUTORS ARE NOT LIABLE FOR ANY
                                    INDIRECT, SPECIAL, INCIDENTAL,
                                    CONSEQUENTIAL, OR EXEMPLARY DAMAGES ARISING
                                    FROM YOUR USE OF THE SERVICES.
                                </Typography>

                                <Typography
                                    variant="subtitle1"
                                    className={classes.fontSize}
                                    align="left"
                                    gutterBottom
                                >
                                    SOME STATES DO NOT ALLOW US TO LIMIT OUR
                                    LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL
                                    DAMAGES OR EXCLUDE CERTAIN WARRANTIES. IN
                                    THOSE STATES, BASE’S LIABILITY AND
                                    WARRANTIES ARE LIMITED TO THE EXTENT
                                    PERMITTED BY LAW.
                                </Typography>

                                <Typography
                                    variant="h5"
                                    align="left"
                                    gutterBottom
                                >
                                    GENERAL REPRESENTATION AND WARRANTY
                                </Typography>

                                <Typography
                                    variant="subtitle1"
                                    className={classes.fontSize}
                                    align="left"
                                    gutterBottom
                                >
                                    You represent and warrant that (i) your use
                                    of the Services will be in strict accordance
                                    with our Privacy Policy, with this Agreement
                                    and with all applicable laws and regulations
                                    (including without limitation any local laws
                                    or regulations in your country, state, city,
                                    or other governmental area, regarding online
                                    conduct and acceptable content, and
                                    including all applicable laws regarding the
                                    transmission of technical data exported from
                                    the United States or the country in which
                                    you reside) and (ii) your use of the
                                    Services will not infringe or misappropriate
                                    the intellectual property rights of any
                                    third party.
                                </Typography>

                                <Typography
                                    variant="h5"
                                    align="left"
                                    gutterBottom
                                >
                                    INDEMNIFICATION
                                </Typography>

                                <Typography
                                    variant="subtitle1"
                                    className={classes.fontSize}
                                    align="left"
                                    gutterBottom
                                >
                                    You agree to indemnify and hold harmless us,
                                    our parent, affiliates, contractors, and
                                    licensors, and their respective directors,
                                    officers, employees and agents from and
                                    against any and all claims and expenses,
                                    including attorneys’ fees, arising out of
                                    your use of the Profile, including but not
                                    limited to your violation of this Agreement.
                                </Typography>

                                <Typography
                                    variant="h5"
                                    align="left"
                                    gutterBottom
                                >
                                    MISCELLANEOUS
                                </Typography>

                                <Typography
                                    variant="subtitle1"
                                    className={classes.fontSize}
                                    align="left"
                                    gutterBottom
                                >
                                    This Agreement along with any supplemental
                                    terms accompanying certain Services
                                    constitute the entire agreement between you
                                    and us concerning the subject matter hereof,
                                    and they may only be modified by a written
                                    amendment signed by one of our authorized
                                    executives or if we post changes to these
                                    terms.
                                </Typography>

                                <Typography
                                    variant="subtitle1"
                                    className={classes.fontSize}
                                    align="left"
                                    gutterBottom
                                >
                                    You agree that the laws of the State of
                                    Delaware govern this Agreement and any claim
                                    or dispute that you may have against us,
                                    without regard to Delaware’s conflict of
                                    laws rules. The United Nations Convention on
                                    Contracts for the International Sale of
                                    Goods shall have no applicability. You
                                    further agree that any disputes or claims
                                    that you may have against us reside in and
                                    will be resolved by a state or federal court
                                    located in New Castle County, Delaware and
                                    you agree and submit to the exercise of
                                    personal jurisdiction of such courts for the
                                    purpose of litigating any such claim or
                                    action.
                                </Typography>

                                <Typography
                                    variant="subtitle1"
                                    className={classes.fontSize}
                                    align="left"
                                    gutterBottom
                                >
                                    PLEASE NOTE THAT BY AGREEING TO THESE TERMS
                                    OF USE, YOU ARE: (1) WAIVING CLAIMS THAT YOU
                                    MIGHT OTHERWISE HAVE AGAINST US BASED ON THE
                                    LAWS OF OTHER JURISDICTIONS, INCLUDING YOUR
                                    OWN; (2) IRREVOCABLY CONSENTING TO THE
                                    EXCLUSIVE JURISDICTION OF, AND VENUE IN,
                                    STATE OR FEDERAL COURTS IN THE STATE OF
                                    DELAWARE OVER ANY DISPUTES OR CLAIMS YOU
                                    HAVE WITH US; AND (3) SUBMITTING YOURSELF TO
                                    THE PERSONAL JURISDICTION OF COURTS LOCATED
                                    IN THE STATE OF DELAWARE FOR THE PURPOSE OF
                                    RESOLVING ANY SUCH DISPUTES OR CLAIMS.
                                </Typography>

                                <Typography
                                    variant="subtitle1"
                                    className={classes.fontSize}
                                    align="left"
                                    gutterBottom
                                >
                                    If any part of this Agreement is held
                                    invalid or unenforceable, that part will be
                                    construed to reflect the parties’ original
                                    intent, and the remaining portions will
                                    remain in full force and effect. A waiver by
                                    either party of any term or condition of
                                    this Agreement or any breach thereof, in any
                                    one instance, will not waive such term or
                                    condition or any subsequent breach thereof.
                                    You may assign your rights under this
                                    Agreement to any party that consents to, and
                                    agrees to be bound by, its terms and
                                    conditions; we may assign its rights under
                                    this Agreement without condition. This
                                    Agreement will be binding upon and will
                                    insure to the benefit of the parties, their
                                    successors and permitted assigns.
                                </Typography>

                                <Typography
                                    variant="h5"
                                    align="left"
                                    gutterBottom
                                >
                                    PRIVACY
                                </Typography>

                                <Typography
                                    variant="subtitle1"
                                    className={classes.fontSize}
                                    align="left"
                                    gutterBottom
                                >
                                    Any information that you provide to us is
                                    subject to our Privacy Policy, which governs
                                    our collection and use of your information.
                                    You understand that through your use of the
                                    Services you consent to the collection and
                                    use (as set forth in the Privacy Policy) of
                                    this information, including the transfer of
                                    this information to the United States and/or
                                    other countries for storage, processing and
                                    use by us.
                                </Typography>

                                <Typography
                                    variant="h5"
                                    align="left"
                                    gutterBottom
                                >
                                    ADDITIONAL TERMS
                                </Typography>

                                <Typography
                                    variant="subtitle1"
                                    className={classes.fontSize}
                                    align="left"
                                    gutterBottom
                                >
                                    Registrations, agreements, and terms
                                    presented by us electronically to you have
                                    the same effect as one in writing and are
                                    legally enforceable as a signed writing. You
                                    also consent to receive all communications
                                    regarding our Services electronically from
                                    us. The delivery of any communication from
                                    us is effective when sent by us, regardless
                                    of when you receive or read the
                                    communication. In addition, we are not
                                    responsible for communications that do not
                                    reach you if you have not provided us with
                                    your current contact information. If you
                                    decided not to receive notices from us
                                    electronically, we may cancel your account
                                    and terminate access to the Services.
                                </Typography>
                            </Box>
                        </Paper>
                    </Box>
                </Container>
            </Grid>
        </div>
    );
}
