import React, { useState } from "react";
import { Box, Collapse, Grid, Switch, Typography } from "@material-ui/core";

import userService from "../../../../services/user";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import actionTypes from "../../../../redux/actionTypes";

import * as Yup from "yup";
import { Alert } from "@material-ui/lab";
import { useSnackbar } from "notistack";
import config from "../../../../config";
import { makeStyles } from "@material-ui/styles";
import EditDashboardSectionHeader from "../components/editDashboardSectionHeader";

const validationSchema = Yup.object({
    sponsorLogoAnimationType: Yup.string().required().label("Animation Type")
});

const useStyles = makeStyles((theme) => ({
    selectedCarousel: {
        cursor: "pointer",
        border: `1px dashed ${theme.palette.primary.main}`,
        color: theme.palette.primary.main
    },
    unselectedCarousel: {
        cursor: "pointer",
        border: `1px dashed ${theme.palette.text.primary}`,
        color: theme.palette.text.primary
    }
}));
const animationTypes = [
    {
        label: "Carousel",
        value: "carousel"
    }
    // {
    //     label: "3D Carousel",
    //     value: "3dcarousel"
    // }
];
export default function EditSponsorLogoAnimation() {
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();

    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingAnimationStatus, setIsLoadingAnimationStatus] =
        useState(false);

    const user = useSelector((state) => state.auth.user);
    const currentSponsorLogoAnimation =
        user?.baseProfileSettings?.sponsorLogoAnimationType;

    const isSponsorLogoAnimation =
        user?.baseProfileSettings?.isSponsorLogoAnimation;

    const [selectedAnimation, setSelectedAnimation] = useState(
        currentSponsorLogoAnimation
    );

    const resetErrors = () => {
        setTimeout(() => {
            setError("");
        }, 3000);
    };

    const [error, setError] = useState("");

    const handleSponsorLogoAnimation = async () => {
        setIsLoadingAnimationStatus(true);
        const res = await userService.updateBaseProfileSettings({
            userId: user._id,
            isSponsorLogoAnimation: !isSponsorLogoAnimation
        });
        setIsLoadingAnimationStatus(false);
        dispatch({
            type: actionTypes.UPDATE_BASE_PROFILE_SETTINGS,
            payload: {
                isSponsorLogoAnimation:
                    res.data.baseProfileSettings?.isSponsorLogoAnimation
            }
        });
    };

    const saveSponsorLogoAnimation = async () => {
        if (selectedAnimation !== currentSponsorLogoAnimation) {
            validationSchema
                .validate({ sponsorLogoAnimationType: selectedAnimation })
                .then(async () => {
                    // setActionStatus("savingAnimationType");
                    setIsLoading(true);
                    const res = await userService.updateBaseProfileSettings({
                        userId: user._id,
                        sponsorLogoAnimationType: selectedAnimation
                    });
                    setIsLoading(false);

                    enqueueSnackbar(config.defaultNotiStackMessage, {
                        variant: "success"
                    });
                    dispatch({
                        type: actionTypes.UPDATE_BASE_PROFILE_SETTINGS,
                        payload: {
                            sponsorLogoAnimationType:
                                res.data.baseProfileSettings
                                    ?.sponsorLogoAnimationType
                        }
                    });
                })
                .catch((err) => {
                    setError(err.message);
                    setIsLoading(false);

                    resetErrors();
                });
        }
    };

    return (
        <Box mt={2}>
            <Box display="flex">
                <Box flexGrow={1} mt={0.5}>
                    <Typography color="textPrimary" variant="subtitle1">
                        Animation
                    </Typography>
                </Box>
                <Box>
                    <Switch
                        color={"primary"}
                        checked={isSponsorLogoAnimation}
                        onChange={handleSponsorLogoAnimation}
                        disabled={isLoadingAnimationStatus}
                    />
                </Box>
            </Box>
            <Collapse in={isSponsorLogoAnimation}>
                <Box py={2} pr={1}>
                    {error && (
                        <Box mb={1} mr={1}>
                            <Alert severity="error">{error}</Alert>
                        </Box>
                    )}

                    {/* <Box width="100%" position="relative">
                    <Box position="absolute" right={4} top={0}>
                        <IconButton
                            color="primary"
                            component="label"
                            size="small"

                            onClick={saveSponsorLogoAnimation}
                        >
                            {actionStatus === "" && <Check size="small" />}
                            {actionStatus === "savingAnimationType" && (
                                <CircularProgress size={14} />
                            )}
                        </IconButton>
                    </Box>
                </Box> */}
                    <Box mt={-2}>
                        <EditDashboardSectionHeader
                            onSave={saveSponsorLogoAnimation}
                            // headerText="More Animations Coming soon"
                            isLoading={isLoading}
                            // headerTextColor="textSecondary"
                            // variant="subtitle1"
                        />
                    </Box>
                    <Box mt={-4}>
                        <Grid container spacing={2}>
                            {animationTypes.map((el) => (
                                <Grid item lg={3} md={3} sm={3} xs={3}>
                                    <Box
                                        borderRadius={4}
                                        p={2}
                                        className={
                                            selectedAnimation === el.value
                                                ? classes.selectedCarousel
                                                : classes.unselectedCarousel
                                        }
                                        display="grid"
                                        alignItems="center"
                                        justifyContent="center"
                                        onClick={() =>
                                            setSelectedAnimation(el.value)
                                        }
                                    >
                                        <Typography variant="caption">
                                            {el.label}
                                        </Typography>
                                    </Box>
                                </Grid>
                            ))}
                        </Grid>
                    </Box>
                </Box>
            </Collapse>
        </Box>
    );
}
