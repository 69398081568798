import React, { useEffect, useState } from "react";
// import { makeStyles } from "@material-ui/core/styles";
// import Table from "@material-ui/core/Table";
// import TableBody from "@material-ui/core/TableBody";
// import TableCell from "@material-ui/core/TableCell";
// import TableContainer from "@material-ui/core/TableContainer";
// import TableHead from "@material-ui/core/TableHead";
// import TablePagination from "@material-ui/core/TablePagination";
// import TableRow from "@material-ui/core/TableRow";
// import TableSortLabel from "@material-ui/core/TableSortLabel";
import Paper from "@material-ui/core/Paper";
import { DragHandle } from "@material-ui/icons";

import {
    // Avatar,
    Box,
    Button,
    CircularProgress,
    // LinearProgress,
    TextField
    // Typography
} from "@material-ui/core";
import AdminPageContainer from "../../components/core/AdminPageContainer";
// import clsx from "clsx";
// import user from "../../services/user";
import config from "../../config";
import Alert from "@material-ui/lab/Alert";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
// import ConfirmationDialog from "../../components/common/ConfirmationDialog";
import surveyService from "../../services/survey";
// import { DragHandle } from "@material-ui/icons";

import * as Yup from "yup";

const schema = Yup.object({
    title: Yup.string().required(),
    position: Yup.number().required()
});

export default function UserSurvey(props) {
    // const classes = useStyles();

    const [surveyList, setSurveyList] = useState([]);
    const [survey, setSurvey] = useState({
        title: "",
        position: 0
    });
    const [error, setError] = useState("");
    const [message, setMessage] = useState("");

    const [saving, setSaving] = useState(false);
    const [loading, setLoading] = useState(true);

    const handleChange = (e) => {
        e.preventDefault();
        const value = e.target.value;
        setSurvey({
            title: value,
            position: surveyList.length
        });
    };
    const resetMessages = () => {
        setTimeout(() => {
            setError("");
            setMessage("");
        }, 3000);
    };
    const handleSubmit = () => {
        schema
            .validate(survey)
            .then(() => {
                setSaving(true);
                surveyService
                    .addSurvey(survey)
                    .then((res) => {
                        if (res?.data?.survey) {
                            setSurveyList([...surveyList, res.data.survey]);
                            setSurvey({ title: "", position: "" });
                        }
                        setSaving(false);
                        setMessage(config.defaultNotiStackMessage);
                        resetMessages();
                    })
                    .catch((ex) => {
                        setSaving(false);

                        setError(ex?.response?.data?.message);
                        resetMessages();
                    });
            })
            .catch((err) => {
                setError(err.message);
                resetMessages();
            });
    };

    useEffect(() => {
        surveyService
            .getSurveys()
            .then((res) => {
                if (res?.data?.surveys) {
                    setSurveyList(res?.data?.surveys);
                }
                setLoading(false);
            })
            .catch((ex) => {
                setLoading(false);
                setError(ex.response.data.message);
                resetMessages();
            });
    }, []);
    const onDragEnd = async (result) => {
        try {
            const { source, destination } = result;

            if (!destination) {
                return false;
            }
            if (
                destination.droppableId === source.droppableId &&
                destination.index === source.index
            ) {
                return false;
            }
            // const previousState = [
            //     ...user.baseProfileSettings.baseSponsorLogos
            // ];
            const updatedState = [...surveyList];
            const draggedItem = updatedState[source.index];
            // draggedItem.label = source.index;
            updatedState.splice(source.index, 1);
            updatedState.splice(destination.index, 0, draggedItem);
            setSurveyList(updatedState);
            const filteredUpdatedState = updatedState.map(({ _id }, i) => {
                return {
                    _id,
                    position: i
                };
            });
            // debugger;
            surveyService
                .arrangeSurvey({ surveys: filteredUpdatedState })
                .then((res) => {
                    setSurveyList(res?.data?.surveys);
                })
                .catch((ex) => {
                    setError(ex.response.data.message);
                    resetMessages();
                });
        } catch (ex) {}
    };
    return (
        <AdminPageContainer {...props}>
            <Paper>
                <Box p={2}>
                    {loading && (
                        <Box align="center">
                            <CircularProgress />
                        </Box>
                    )}
                    {error && <Alert severity="error">{error}</Alert>}
                    {message && <Alert severity="success">{message}</Alert>}

                    <Box display={"flex"} width={"30%"}>
                        <Box flexGrow={1}>
                            <TextField
                                required
                                value={survey.title}
                                // name="Survey"
                                // label="Survey"
                                onChange={handleChange}
                                placeholder=" e.g. Gamer"
                                fullWidth
                                // size="small"
                            />
                        </Box>
                        <Box ml={2}>
                            <Button
                                size="small"
                                // variant="contained"
                                color="primary"
                                onClick={handleSubmit}
                            >
                                {saving ? (
                                    <CircularProgress size={20} />
                                ) : (
                                    "Add"
                                )}
                            </Button>
                        </Box>
                    </Box>
                    {/* {surveyList &&
                    surveyList.map((el) => {
                        return <div>{el.title}</div>;
                    })} */}
                    <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="surveydnd">
                            {(provided, snapshot) => {
                                return (
                                    <div
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                        style={{
                                            width: "100%"
                                        }}
                                    >
                                        {surveyList &&
                                            surveyList?.map((el, i) => {
                                                return (
                                                    <Draggable
                                                        key={el._id}
                                                        draggableId={el._id}
                                                        index={i}
                                                    >
                                                        {(
                                                            provided,
                                                            snapshot
                                                        ) => {
                                                            return (
                                                                <div
                                                                    ref={
                                                                        provided.innerRef
                                                                    }
                                                                    {...provided.draggableProps}
                                                                    {...provided.dragHandleProps}
                                                                >
                                                                    <Box
                                                                        display="flex"
                                                                        p={0}
                                                                        width="100%"
                                                                    >
                                                                        <Box
                                                                            display="flex"
                                                                            alignItems="center"
                                                                            justifyContent="center"
                                                                        >
                                                                            <DragHandle color="disabled" />
                                                                        </Box>
                                                                        <Box
                                                                            width="100%"
                                                                            px={
                                                                                2
                                                                            }
                                                                        >
                                                                            {
                                                                                el.title
                                                                            }
                                                                        </Box>
                                                                    </Box>
                                                                </div>
                                                            );
                                                        }}
                                                    </Draggable>
                                                );
                                            })}
                                        {provided.placeholder}
                                    </div>
                                );
                            }}
                        </Droppable>
                    </DragDropContext>
                </Box>
            </Paper>
        </AdminPageContainer>
    );
}
