import React, { useState } from "react";
import { Box, Collapse, Switch, Typography } from "@material-ui/core";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { Alert } from "@material-ui/lab";
import { useSnackbar } from "notistack";

import actionTypes from "../../../../redux/actionTypes";
import ColorPickerField from "../../../../components/core/ColorPickerField";
import config from "../../../../config";
import EditDashboardSectionHeader from "../components/editDashboardSectionHeader";
// import authService from "../../../../services/auth";
import userService from "../../../../services/user";

const validationSchema = Yup.object({
    sponsorLogoBackgroundColor: Yup.string()
        .required()
        .label("Background Color")
});

export default function EditSponsorLogoBackground() {
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingBackgroundStatus, setIsLoadingBackgroundStatus] =
        useState(false);

    const user = useSelector((state) => state.auth.user);

    const sponsorLogoBgColor =
        user?.baseProfileSettings?.sponsorLogoBackgroundColor;

    const isSponsorLogoBackground =
        user?.baseProfileSettings?.isSponsorLogoBackground;

    const [sponsorLogoBackgroundColor, setSponsorLogoBackgroundColor] =
        useState(sponsorLogoBgColor);

    const resetErrors = () => {
        setTimeout(() => {
            setError("");
        }, 3000);
    };

    const [error, setError] = useState("");

    const handleSponsorLogoBackground = async () => {
        setIsLoadingBackgroundStatus(true);
        const res = await userService.updateBaseProfileSettings({
            userId: user._id,
            isSponsorLogoBackground: !isSponsorLogoBackground
        });
        setIsLoadingBackgroundStatus(false);
        dispatch({
            type: actionTypes.UPDATE_BASE_PROFILE_SETTINGS,
            payload: {
                isSponsorLogoBackground:
                    res.data.baseProfileSettings?.isSponsorLogoBackground
            }
        });
    };

    const saveSponsorLogoBackground = async () => {
        if (sponsorLogoBgColor !== sponsorLogoBackgroundColor) {
            validationSchema
                .validate({ sponsorLogoBackgroundColor })
                .then(async () => {
                    // setActionStatus("savingBgColor");
                    setIsLoading(true);
                    const res = await userService.updateBaseProfileSettings({
                        userId: user._id,
                        sponsorLogoBackgroundColor
                    });
                    setIsLoading(false);

                    enqueueSnackbar(config.defaultNotiStackMessage, {
                        variant: "success"
                    });
                    dispatch({
                        type: actionTypes.UPDATE_BASE_PROFILE_SETTINGS,
                        payload: {
                            sponsorLogoBackgroundColor:
                                res.data.baseProfileSettings
                                    ?.sponsorLogoBackgroundColor
                        }
                    });
                })
                .catch((err) => {
                    setError(err.message);
                    // setActionStatus("");
                    setIsLoading(isLoading);

                    resetErrors();
                });
        }
    };

    return (
        <Box mt={2}>
            <Box display="flex">
                <Box flexGrow={1} mt={0.5}>
                    <Typography color="textPrimary" variant="subtitle1">
                        Background
                    </Typography>
                </Box>
                <Box>
                    <Switch
                        color={"primary"}
                        checked={isSponsorLogoBackground}
                        onChange={handleSponsorLogoBackground}
                        disabled={isLoadingBackgroundStatus}
                    />
                </Box>
            </Box>
            <Collapse in={isSponsorLogoBackground}>
                <Box pr={1} py={2}>
                    {error && (
                        <Box mb={1} mr={1}>
                            <Alert severity="error">{error}</Alert>
                        </Box>
                    )}

                    <Box width="100%" position="relative">
                        <Box mt={-2}>
                            <EditDashboardSectionHeader
                                onSave={saveSponsorLogoBackground}
                                isLoading={isLoading}
                            />
                        </Box>
                        <Box mt={-4}>
                            <ColorPickerField
                                fieldLabel="Color"
                                color={sponsorLogoBackgroundColor}
                                enableAlpha
                                onColorPickerValueChange={(color) => {
                                    setError("");
                                    setSponsorLogoBackgroundColor(
                                        `#${color.hex}`
                                    );
                                }}
                                onFieldValueChange={(e) => {
                                    setError("");
                                    setSponsorLogoBackgroundColor(
                                        `${e.currentTarget.value}`
                                    );
                                }}
                            />
                        </Box>
                    </Box>
                </Box>
            </Collapse>
        </Box>
    );
}
