import {
    Box,
    Chip,
    // makeStyles,
    Typography
} from "@material-ui/core";
import React from "react";

// const useStyles = makeStyles((theme) => ({
//     badgeContainer: {
//         backgroundColor: theme.palette.background.default,
//         color: theme.palette.primary.main,
//         width: "fit-content",
//         margin: "0px 4px",
//         borderRadius: 8,
//         textAlign: "center",
//         verticalAlign: "middle",
//         lineHeight: 90
//     },
//     badgeText: {
//         fontSize: 12
//     }
// }));
export default function CustomBadge({ badgeContent = "", uppercase = false }) {
    // const classes = useStyles();
    return (
        <Box m={1 / 2}>
            <Chip
                size="small"
                label={
                    <Box pb={0.5}>
                        <Typography
                            color={"primary"}
                            // variant="caption"
                            // gutterBottom
                        >
                            {uppercase
                                ? badgeContent.toUpperCase()
                                : badgeContent}
                        </Typography>
                    </Box>
                }
            />
        </Box>
    );
}
