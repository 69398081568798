import React from "react";
import { Box, Typography } from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";

export default function PreviewMessage({ message }) {
    return (
        <Box display="flex">
            <Box paddingRight={0.5}>
                <InfoIcon color="primary" fontSize="small" />
            </Box>
            <Typography
                variant="subtitle1"
                color="primary"
                align="center"
                style={{ fontSize: 12 }}
            >
                {message}
            </Typography>
        </Box>
    );
}
