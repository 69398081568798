import config from "../config";
import axios from "../config/axios";
import { store } from "../redux/store";

const { free, plus, pro } = config.userType;

const getAuthInstance = () => {
    const auth = store.getState()?.auth;
    const { user, currentUser } = auth;
    return { user, currentUser };
};

const isCurrentUserSelected = () => {
    const { user, currentUser } = getAuthInstance();
    return user?._id === currentUser?._id;
};

const isCurrentUserPlus = () => {
    const { currentUser } = getAuthInstance();
    return currentUser?.userType === plus;
};
const isCurrentUserPro = () => {
    const { currentUser } = getAuthInstance();
    return currentUser?.userType === pro;
};
const isCurrentUserFreeOrPlus = () => {
    const { currentUser } = getAuthInstance();
    return currentUser?.userType === free || currentUser?.userType === plus;
};
const isCurrentUserPlusOrPro = () => {
    const { currentUser } = getAuthInstance();
    return currentUser?.userType === plus || currentUser?.userType === pro;
};
const isPlusOrProUser = () => {
    const { user } = getAuthInstance();
    return user?.userType === plus || user?.userType === pro;
};

const getCurrentUser = async () => {
    return axios.get(
        `${config.apiEndPoint}${config.apiRoutes.auth.currentUser}`
    );
};

const updateCurrentUser = async (user) => {
    return axios.put(
        `${config.apiEndPoint}${config.apiRoutes.auth.currentUser}`,
        user
    );
};

// eslint-disable-next-line no-unused-vars
const deleteCurrentUser = async (param = "?email=true") => {
    return axios.delete(
        `${config.apiEndPoint}${config.apiRoutes.auth.currentUser}${param}`
    );
};
const logout = async () => {
    return axios.get(`${config.apiEndPoint}${config.apiRoutes.auth.logout}`);
};

const authenticatedUser = () => {
    const encoded = localStorage.getItem(config.lsKeys.user);
    if (encoded) {
        const decoded = atob(encoded);
        return JSON.parse(decoded);
    } else {
        return {};
    }
};
const completeRegistration = async (userData) => {
    return axios.put(
        `${config.apiEndPoint}${config.apiRoutes.auth.completeRegistration}`,
        userData
    );
};

export default {
    isCurrentUserSelected,
    isCurrentUserPlus,
    isCurrentUserPro,
    isCurrentUserFreeOrPlus,
    isCurrentUserPlusOrPro,
    getCurrentUser,
    isPlusOrProUser,
    logout,
    authenticatedUser,
    completeRegistration,
    updateCurrentUser,
    deleteCurrentUser
};
