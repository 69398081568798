import React, { useEffect } from "react";
import queryString from "query-string";
import config from "../../config";
import axios from "../../config/axios";
import auth from "../../services/auth";
import userService from "../../services/user";
import surveyService from "../../services/survey";

import { useDispatch } from "react-redux";
import actionTypes from "../../redux/actionTypes";
import baseIcon from "../../res/images/base-icon1.png";
import { Avatar, Box, Grid } from "@material-ui/core";
import management from "../../services/management";

export default function Authenticate(props) {
    const dispatch = useDispatch();
    const url = queryString.parse(window.location.search);
    useEffect(() => {
        const loadAppData = () => {
            getAppAccountByCategories();
            getSurveys();
            getAppIntegrations();
        };

        const getSurveys = () => {
            surveyService
                .getSurveysForUser()
                .then((res) => {
                    dispatch({
                        type: actionTypes.SET_SURVEYS,
                        payload: { surveys: res.data.surveys }
                    });
                })
                .catch((ex) => {
                    // setLoading(false);
                    // setError(ex?.response?.data?.message);
                });
        };

        const getAppAccountByCategories = () => {
            userService
                .getAccountsByCategory()
                .then((res) => {
                    dispatch({
                        type: actionTypes.SET_ACCOUNT_CATEGORIES,
                        payload: { accounts: res.data.accounts }
                    });
                })
                .catch((ex) => {
                    // setLoading(false);
                    // setError(ex?.response?.data?.message);
                });
        };

        const getAppIntegrations = () => {
            userService
                .getIntegrations()
                .then((res) => {
                    console.log(res);
                    dispatch({
                        type: actionTypes.SET_INTEGRATIONS,
                        payload: { integrations: res.data.integrations }
                    });
                })
                .catch((ex) => {
                    // setLoading(false);
                    // setError(ex?.response?.data?.message);
                });
        };

        const getUserManagement = () => {
            management
                .getManagedBase()
                .then((res) => {
                    debugger;
                    if (res.data.management) {
                        dispatch({
                            type: actionTypes.SET_MANAGEMENT,
                            payload: { ...res.data.management }
                        });
                    }
                })
                .catch((ex) => {
                    console.log(ex);
                });
        };
        const getManagementCategories = () => {
            management.getManagementCategories().then((res) => {
                const managementCategories = res.data.managementCategories;
                dispatch({
                    type: actionTypes.SET_MANAGEMENT_CATEGORY,
                    paload: managementCategories
                });
            });
        };
        const getUser = () => {
            auth.getCurrentUser().then((res) => {
                const currentUser = res.data.user;
                if (currentUser.isSuspendedBase) {
                    // window.location.href = config.routes.suspended;
                    props.history.push(config.routes.suspended);
                    return false;
                }
                dispatch({
                    type: actionTypes.UPDATE_AUTH,
                    payload: {
                        auth: {
                            currentUser: currentUser,
                            user: currentUser
                        }
                    }
                });

                if (currentUser.userType === config.userType.admin) {
                    props.history.push(config.routes.admin);
                } else {
                    loadAppData();
                    if (currentUser.baseUrl) {
                        getUserManagement();
                        if (currentUser.userType === config.userType.pro) {
                            getManagementCategories();
                            console.log("🚀 ~ here ");
                        }
                        props.history.push(config.routes.dashboard);
                    } else {
                        props.history.push(config.routes.completeRegistration);
                    }
                }
            });
        };
        // above function is called here in if condition
        if (url && url.token && url.refreshToken) {
            // localStorage.setItem(config.lsKeys.token, url.token);
            axios.setHeaders(url.token);
            getUser();
            dispatch({
                type: actionTypes.SET_TOKEN,
                payload: { token: url.token }
            });
            dispatch({
                type: actionTypes.SET_REFRESH_TOKEN,
                payload: { refreshToken: url.refreshToken }
            });
            dispatch({
                type: actionTypes.SET_IS_TOKEN_EXPIRED,
                payload: { isTokenExpired: false }
            });
        } else {
            props.history.push(config.routes.login);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <Box width={"100%"}>
            <Grid
                container
                alignItems="center"
                justifyContent="center"
                direction="row"
                style={{ minHeight: "100vh" }}
            >
                <Grid item xs={4}>
                    <Box align="center">
                        <Avatar
                            src={baseIcon}
                            className="heatbeat-animation"
                            style={{ height: "100px", width: "100px" }}
                            // style={{ border: "1px dotted #5BAABA " }}
                        />
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
}
