import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import {
    Avatar,
    Box,
    Button,
    CircularProgress,
    Grid,
    InputAdornment,
    TextField
} from "@material-ui/core";
import * as Yup from "yup";
import * as Scroll from "react-scroll";
// import useGlobalStyles from "../../../../../../hooks/useGlobalStyles";
import clsx from "clsx";
// import { ColorPicker } from "material-ui-color";
import utils from "../../../../../../utils";
import Alert from "@material-ui/lab/Alert";
import userService from "../../../../../../services/user";
import actionTypes from "../../../../../../redux/actionTypes";
import { useDispatch, useSelector } from "react-redux";
import baseIcon from "../../../../../../res/images/base-icon1.png";
import config from "../../../../../../config";
import ColorPickerField from "../../../../../../components/core/ColorPickerField";
import { Fragment } from "react";
import { useSnackbar } from "notistack";
const BORDER = config.accountStyle.border;
const FILETYPES = ["image/jpeg", "image/jpg", "image/png"];

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%"
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular
    },
    previewIcon: {
        border: `1px dashed ${theme.palette.primary.main}`,
        width: theme.spacing(11.5),
        height: theme.spacing(11.5)
        // marginTop: theme.spacing(0.7)
    },
    previewIconOverlay: {
        backgroundColor: "#000",
        opacity: "0.5",
        position: "absolute",
        bottom: 0.5,
        right: 0.5,
        height: "50%",
        width: theme.spacing(11),
        borderBottomRightRadius: 4,
        borderBottomLeftRadius: 4
    },
    saveBtn: {
        backgroundColor: theme.palette.primary.dark,
        padding: ({ loading }) => loading && 1.4,
        color: theme.palette.background.paper,
        "&:hover": {
            backgroundColor: theme.palette.primary.dark
        }
    }
}));

let schema = Yup.object().shape({
    userId: Yup.string().required(),
    avatarImage: Yup.object({
        file: Yup.string().required(),
        fileType: Yup.string().required().oneOf(FILETYPES),
        fileSize: Yup.number()
            .lessThan(
                config.constants.iconFileSize * 1000000,
                config.validationErrors.fileSizeError +
                    config.constants.iconFileSize +
                    "  MB"
            )
            .required()
    })
        .notRequired()
        .default(undefined),
    customAccountText: Yup.string().required().label("Custom Account Text"),
    accountUrl: Yup.string().required().label("Custom Url"),
    accountTextColor: Yup.string().required().label("Custom Text Color"),
    accountBackgroundColor: Yup.string()
        .required()
        .label("Cystom Account color")
});
var scroll = Scroll.animateScroll;
export default function AddCustomUserAccount(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    // const globalClasses = useGlobalStyles();
    const user = useSelector((state) => state.auth.user);
    const [accountUrl, setAccountUrl] = useState("");
    const [customAccountText, setCustomAccountText] = useState("");
    const [selectedAccountImage, setSelectedAccountImage] = useState();
    const [accountTextColor, setAccountTextColor] = useState(
        config.colorPicker.defaultText
    );
    const [accountBackgroundColor, setAccountBackgroundColor] = useState(
        config.colorPicker.defaultBackground
    );

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    // const [successMsg, setSuccessMsg] = useState("");
    const baseAccountSettings = user.baseAccountSettings;

    const resetErrors = () => {
        setTimeout(() => {
            setError("");
        }, 3000);
    };

    const handlefileInput = (e) => {
        e.preventDefault();
        setError("");
        const input = e.currentTarget;
        const file = input.files[0];
        if (file) {
            const fileType = file.type;

            utils
                .toBase64(file)
                .then((base64File) => {
                    setSelectedAccountImage({
                        file: base64File,
                        fileSize: file.size,
                        fileType
                    });
                })
                .catch((error) => {
                    setError(error.message);
                    resetErrors();
                });
        }
    };

    const submitForm = (e) => {
        e.preventDefault();
        const isFile = selectedAccountImage && selectedAccountImage.file;
        let accountData = {
            userId: user._id,
            accountUrl,
            customAccountText,
            accountName: customAccountText,
            accountTextColor,
            accountBackgroundColor
        };
        if (isFile) {
            accountData.accountImage = selectedAccountImage;
        }

        schema
            .validate(accountData)
            .then(() => {
                setError("");
                setLoading(true);
                addAccount(accountData);
                scroll.scrollToTop();
            })
            .catch((e) => {
                scroll.scrollToTop();
                setError(e.message);
                resetErrors();
            });
    };

    const addAccount = (data) => {
        userService
            .addCustomUserAccount(data)
            .then((res) => {
                const accounts = res.data.accounts;

                enqueueSnackbar(config.defaultNotiStackMessage, {
                    variant: "success"
                });
                setLoading(false);
                setSelectedAccountImage("");
                setAccountUrl("");
                setCustomAccountText("");
                setAccountTextColor("");
                setAccountBackgroundColor("");
                dispatch({
                    type: actionTypes.UPDATE_USER,
                    payload: { accounts }
                });
                props.closeAddAccount();
            })
            .catch((ex) => {
                enqueueSnackbar(ex?.response?.data?.message, {
                    variant: "error"
                });

                setLoading(false);
                // setError(ex.response?.data.message);
                // resetErrors();
            });
    };
    return (
        // <Grid container>
        //     <Grid item md={12} lg={12} sm={12} xs={12}>
        <Box>
            <form onSubmit={submitForm}>
                <Box>
                    {/* {loading && (
                            <Box align="center">
                                <CircularProgress />
                            </Box>
                        )} */}
                    {error && (
                        <Fragment>
                            <Alert severity="error">{error}</Alert>
                            <br />
                        </Fragment>
                    )}
                    {/* {successMsg && <Alert severity="success">{successMsg}</Alert>} */}

                    <Box mb={3}>
                        <Typography variant="subtitle1" color="textSecondary">
                            Custom URL
                        </Typography>
                        <Box mt={1}>
                            <TextField
                                value={accountUrl}
                                placeholder="e.g. www.mywebsite.com"
                                onChange={(e) => {
                                    setError("");
                                    setAccountUrl(
                                        e.currentTarget.value
                                            .trim()
                                            .toLowerCase()
                                    );
                                }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Typography color="primary">
                                                https://
                                            </Typography>
                                        </InputAdornment>
                                    )
                                }}
                                fullWidth
                                variant="outlined"
                            />
                        </Box>
                    </Box>
                    <Box mb={3}>
                        <Box
                            // container
                            // direction="row"
                            // justify="left"
                            // alignItems="left"
                            // spacing={4}
                            display={"flex"}
                            // width="100%"
                        >
                            <Box mr={2}>
                                <div style={{ position: "relative" }}>
                                    <Avatar
                                        variant={"rounded"}
                                        className={clsx(classes.previewIcon)}
                                        src={
                                            selectedAccountImage
                                                ? selectedAccountImage.file
                                                : baseIcon
                                        }
                                    ></Avatar>
                                    <Box className={classes.previewIconOverlay}>
                                        <Box mt={0.5}>
                                            <Typography align="center">
                                                <Button
                                                    component="label"
                                                    style={{ fontSize: 10 }}
                                                >
                                                    Change
                                                    <input
                                                        type="file"
                                                        style={{
                                                            display: "none"
                                                        }}
                                                        onChange={
                                                            handlefileInput
                                                        }
                                                    />
                                                </Button>
                                            </Typography>
                                        </Box>
                                    </Box>
                                </div>
                            </Box>
                            <Box width="100%">
                                <Typography
                                    variant="subtitle1"
                                    color="textSecondary"
                                >
                                    Custom Text
                                </Typography>
                                <Box mt={1}>
                                    <TextField
                                        variant="outlined"
                                        value={customAccountText}
                                        placeholder="e.g. My Website
                                        "
                                        onChange={(e) => {
                                            setError("");
                                            setCustomAccountText(
                                                e.currentTarget.value
                                            );
                                        }}
                                        fullWidth
                                    />
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    {/* <Box mb={3}>
              <Typography variant="subtitle1">Custom Text Color</Typography>

              <Box style={{ display: "flex" }}>
                <div style={{ marginTop: 8 }}>
                  <ColorPicker
                    value={accountTextColor}
                    hideTextfield
                    onChange={(color) => {
                      setError("");
                      setAccountTextColor(`#${color.hex}`);
                    }}
                  />
                </div>
                <TextField
                  value={accountTextColor}

                  onChange={(e) => {
                    setError("");
                    setAccountTextColor(`${e.currentTarget.value}`);
                  }}
                />
              </Box>
            </Box> */}

                    {/* <Box mb={3}>
              <Typography variant="subtitle1">Custom Account Color</Typography>

              <Box style={{ display: "flex" }}>
                <div style={{ marginTop: 8 }}>
                  <ColorPicker
                    value={accountBackgroundColor}
                    hideTextfield
                    onChange={(color) => {
                      setError("");
                      setAccountBackgroundColor(`#${color.hex}`);
                    }}
                  />
                </div>
                <TextField
                  value={accountBackgroundColor}

                  onChange={(e) => {
                    setError("");
                    setAccountBackgroundColor(`${e.currentTarget.value}`);
                  }}
                />
              </Box>
            </Box> */}

                    {baseAccountSettings.accountStyle !== BORDER && (
                        <Grid container spacing={4}>
                            <Grid item sm={6} md={6} lg={6} xs={6}>
                                <ColorPickerField
                                    fieldLabel={"Custom Text Color"}
                                    color={accountTextColor}
                                    onColorPickerValueChange={(color) => {
                                        setError("");
                                        setAccountTextColor(`#${color.hex}`);
                                    }}
                                    onFieldValueChange={(e) => {
                                        setError("");
                                        setAccountTextColor(
                                            `${e.currentTarget.value}`
                                        );
                                    }}
                                />
                            </Grid>
                            <Grid item sm={6} md={6} lg={6} xs={6}>
                                <ColorPickerField
                                    fieldLabel={"Custom Account Color"}
                                    color={accountBackgroundColor}
                                    onColorPickerValueChange={(color) => {
                                        setError("");
                                        setAccountBackgroundColor(
                                            `#${color.hex}`
                                        );
                                    }}
                                    onFieldValueChange={(e) => {
                                        setError("");
                                        setAccountBackgroundColor(
                                            `${e.currentTarget.value}`
                                        );
                                    }}
                                />
                            </Grid>
                        </Grid>
                    )}
                    {baseAccountSettings.accountStyle === BORDER && (
                        <Grid container spacing={4}>
                            <Grid item sm={12} md={12} lg={12} xs={12}>
                                <ColorPickerField
                                    fieldLabel={"Custom Account Color"}
                                    color={accountBackgroundColor}
                                    onColorPickerValueChange={(color) => {
                                        setError("");
                                        setAccountBackgroundColor(
                                            `#${color.hex}`
                                        );
                                    }}
                                    onFieldValueChange={(e) => {
                                        setError("");
                                        setAccountBackgroundColor(
                                            `${e.currentTarget.value}`
                                        );
                                    }}
                                />
                            </Grid>
                        </Grid>
                    )}
                </Box>
                <Box mt={1}>
                    <Button
                        color="primary"
                        type="submit"
                        fullWidth
                        // className={classes.saveBtn}
                        disabled={loading}
                    >
                        {loading ? (
                            <Box
                                align="center"
                                mt={0.5}
                                className={classes.saveBtn}
                            >
                                <CircularProgress
                                    size={16}
                                    // className={classes.saveBtn}
                                ></CircularProgress>
                            </Box>
                        ) : (
                            "Save"
                        )}
                    </Button>
                </Box>
            </form>
        </Box>
        //     </Grid>
        // </Grid>
    );
}
