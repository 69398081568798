import { Box, Grid, Typography, Button } from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";
import config from "../../config";

export default function PageNotFound() {
    return (
        <div>
            <Grid
                container
                direction="column"
                alignItems="center"
                justifyContent="center"
                spacing={2}
            >
                <Grid item>
                    <Box textAlign="center" style={{ marginTop: "30vh" }}>
                        <Typography variant="h1" color="primary">
                            404!
                        </Typography>
                        <Typography variant="h5" color="primary">
                            PAGE NOT FOUND
                        </Typography>
                        <br />
                        <Link
                            to={config.routes.login}
                            style={{ textDecoration: "none" }}
                        >
                            <Button variant="contained" color="primary">
                                Back to home
                            </Button>
                        </Link>
                    </Box>
                </Grid>
            </Grid>
        </div>
    );
}
