import { Box, Grid, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import GamingImage from "../../../res/images/landingPage/gaming-img.png";

const useStyles = makeStyles((theme) => {
    return {
        title: {
            // fontFamily: "Darker Grotesque",
            fontSize: "3.75rem",
            fontWeight: 800,
            letterSpacing: "0em",
            textAlign: "left",
            color: "#EEEEEE",
            [theme.breakpoints.down("sm")]: {
                fontSize: "2.75rem"
            }
        },
        body: {
            // fontFamily: "Darker Grotesque",
            // // fontSize: "1.375rem",
            fontWeight: 400,
            letterSpacing: "0em",
            // textAlign: "left",
            color: "#9D9D9D"
            // [theme.breakpoints.down("sm")]: {
            //     fontSize: "1rem"
            // }
        },
        gridContainer: {
            paddingTop: 40,
            [theme.breakpoints.up("md")]: {
                paddingTop: 80
            }
        },
        vector: {
            position: "absolute",
            width: "30%",
            height: 202.24,
            right: -110,
            bottom: 0,
            // left: 1526.3,
            // top: 100,
            background: "#6BCCDB",
            /* Blur - 320 */
            filter: "blur(160px)",
            transform: "matrix(-0.98, -0.19, -0.19, 0.98, 0, 0)",
            [theme.breakpoints.down("sm")]: {
                width: "50%",
                right: 0
                // height: 100,
            }
        }
    };
});

const Gaming = () => {
    const classes = useStyles();
    return (
        <Box style={{ position: "relative" }}>
            <Box>
                <Grid
                    container
                    spacing={6}
                    alignItems="center"
                    className={classes.gridContainer}
                >
                    <Grid item xs={12} md={5}>
                        <Typography className={classes.title}>
                            Made for Gaming
                        </Typography>
                        <Box mt={1}>
                            <Typography
                                className={classes.body}
                                color="textSecondary"
                                variant="h5"
                            >
                                Base is built for creators, streamers,
                                tournament organizers, eSports organizations and
                                more
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={7}>
                        <img
                            src={GamingImage}
                            alt="gamer"
                            style={{ maxWidth: "100%", height: "auto" }}
                        />
                    </Grid>
                </Grid>
            </Box>
            {/* <Box className={classes.vector}></Box> */}
        </Box>
    );
};

export default Gaming;
