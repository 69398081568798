import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from "@material-ui/core";
import React from "react";

export default function ConfirmationDialog({
    open = false,
    disableBackDrop = false,
    disableButtons = false,
    confirmationDialogTitle = "",
    confirmationDialogDescription = "",
    closeBtnText = "",
    confirmationBtnTitle = "",
    onClose = () => {},
    onConfirm = () => {}
}) {
    const handleClose = (event, reason) => {
        if (reason === "backdropClick" && disableBackDrop) {
            return false;
        } else {
            if (!disableButtons) {
                onClose();
            }
        }
    };
    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                fullWidth
                // disableBackdropClick={disableBackDrop}
                // disableEscapeKeyDown={disableBackDrop}
            >
                <DialogTitle>{confirmationDialogTitle}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {confirmationDialogDescription}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        autoFocus
                        onClick={onClose}
                        color="primary"
                        disabled={disableButtons}
                    >
                        {closeBtnText ? closeBtnText : "Cancel"}
                    </Button>
                    <Button
                        onClick={onConfirm}
                        color="primary"
                        disabled={disableButtons}
                    >
                        {confirmationBtnTitle}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
