import { Box, Typography, Switch, Collapse } from "@material-ui/core";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

import actionTypes from "../../../redux/actionTypes";
import authService from "../../../services/auth";
import userService from "../../../services/user";

import LabelWithEmoji from "../../../components/common/LabelWithEmoji";
// import AvatarShapeSelector from "../../../components/core/AvatarShapeSelector";
import HeaderUI from "../components/HeaderUI";
import config from "../../../config";
import BackgroundUI from "../components/BackgroundUI";
import AccountIconDesignUI from "../components/AccountIconDesignUI";
import AccountDesignUI from "../components/AccountDesignUI";
// import SaveActionButton from "../components/SaveActionButton";
import BannerUI from "../components/BannerUI";
import BaseBrandingUI from "../components/BaseBrandingUI";
import TextDesignUI from "../components/TextDesignUI";

export default function ManagedDesign() {
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const currentUser = useSelector((s) => s.auth.currentUser);
    const managedBaseDesign = currentUser?.managedBaseDesign;
    const baseProfileSettings = managedBaseDesign?.baseProfileSettings;
    const baseAccountSettings = managedBaseDesign?.baseAccountSettings;

    // const [selectedAvatarShape, setSelectedAvatarShape] = useState(
    //     baseProfileSettings?.avatarShape || "circle"
    // );

    const [loading, setLoading] = useState(false);

    const isManagedDesignActive = currentUser?.isManagedDesignActive;

    const isCurrentUserPro = authService.isCurrentUserPro();

    const [actionStatus, setActionStatus] = useState({
        avatarShape: false,
        textDesign: false,
        accountDesign: false,
        accountIconDesign: false,
        accountIconStatus: false,
        background: false,
        deleteHeader: false,
        saveHeader: false,
        activeHeader: false,
        bannerStatus: false,
        updateBanner: false,
        baseBranding: false
    });

    const handleManagementStatusChange = (e) => {
        setLoading(true);
        authService
            .updateCurrentUser({
                isManagedDesignActive: e.target.checked
            })
            .then((res) => {
                setLoading(false);
                dispatch({
                    type: actionTypes.UPDATE_CURRENT_USER,
                    payload: {
                        ...res.data.user
                    }
                });
            })
            .catch((ex) => {
                setLoading(false);
            });
    };

    const handleHeaderStatus = (e) => {
        setActionStatus((ps) => ({ ...ps, activeHeader: true }));
        userService
            .updateBaseProfileSettingsById({
                // userId: user._id,
                baseProfileSettingsId: baseProfileSettings._id,
                isBaseHeaderActive: !baseProfileSettings?.isBaseHeaderActive
            })
            .then((res) => {
                const baseProfileSettings = res.data.baseProfileSettings;
                dispatch({
                    type: actionTypes.UPDATE_CURRENT_USER_MANAGED_BASE_DESIGN,
                    payload: { baseProfileSettings }
                });
                setActionStatus((ps) => ({ ...ps, activeHeader: false }));
            })
            .catch((ex) => {
                enqueueSnackbar(ex?.response?.data?.message, {
                    variant: "error"
                });
                setActionStatus((ps) => ({ ...ps, activeHeader: false }));
            });
    };

    // const handleSaveAvatarShape = () => {
    //     if (selectedAvatarShape !== baseProfileSettings.avatarShape) {
    //         setActionStatus((ps) => ({ ...ps, avatarShape: true }));
    //         userService
    //             .updateBaseProfileSettingsById({
    //                 baseProfileSettingsId: baseProfileSettings._id,
    //                 avatarShape: selectedAvatarShape
    //             })
    //             .then((res) => {
    //                 enqueueSnackbar(config.defaultNotiStackMessage, {
    //                     variant: "success"
    //                 });
    //                 setActionStatus((ps) => ({ ...ps, avatarShape: false }));
    //                 const baseProfileSettings = res.data.baseProfileSettings;
    //                 dispatch({
    //                     type: actionTypes.UPDATE_CURRENT_USER_MANAGED_BASE_DESIGN,
    //                     payload: { baseProfileSettings }
    //                 });
    //             })
    //             .catch((ex) => {
    //                 enqueueSnackbar(ex?.response?.data?.message, {
    //                     variant: "error"
    //                 });
    //                 setActionStatus((ps) => ({ ...ps, avatarShape: false }));
    //             });
    //     }
    // };

    const handleSaveHeader = (croppedImage) => {
        if (croppedImage && croppedImage.file) {
            setActionStatus((ps) => ({ ...ps, saveHeader: true }));
            userService
                .updateBaseProfileSettingsById({
                    baseProfileSettingsId: baseProfileSettings._id,
                    baseHeaderImage: { ...croppedImage }
                })
                .then((res) => {
                    enqueueSnackbar(config.defaultNotiStackMessage, {
                        variant: "success"
                    });
                    setActionStatus((ps) => ({ ...ps, saveHeader: false }));
                    const baseProfileSettings = res.data.baseProfileSettings;
                    dispatch({
                        type: actionTypes.UPDATE_CURRENT_USER_MANAGED_BASE_DESIGN,
                        payload: { baseProfileSettings }
                    });
                })
                .catch((ex) => {
                    enqueueSnackbar(ex?.response?.data?.message, {
                        variant: "error"
                    });
                    setActionStatus((ps) => ({ ...ps, saveHeader: false }));
                });
        }
    };

    const handleDeleteHeader = () => {
        setActionStatus((ps) => ({ ...ps, deleteHeader: true }));
        userService
            .updateBaseProfileSettingsById({
                baseProfileSettingsId: baseProfileSettings._id,
                baseHeaderImageUrl: baseProfileSettings?.baseHeaderImage,
                deleteBaseHeader: true
            })
            .then((res) => {
                enqueueSnackbar(config.defaultNotiStackMessage, {
                    variant: "success"
                });
                setActionStatus((ps) => ({ ...ps, deleteHeader: false }));
                const baseProfileSettings = res.data.baseProfileSettings;
                dispatch({
                    type: actionTypes.UPDATE_CURRENT_USER_MANAGED_BASE_DESIGN,
                    payload: { baseProfileSettings }
                });
            })
            .catch((ex) => {
                enqueueSnackbar(ex?.response?.data?.message, {
                    variant: "error"
                });
                setActionStatus((ps) => ({ ...ps, deleteHeader: false }));
            });
    };

    const handleSaveBackground = (newBaseProfileSetting) => {
        if (
            newBaseProfileSetting.backgroundType === "image" &&
            newBaseProfileSetting?.backgroundImage?.file
        ) {
            newBaseProfileSetting.backgroundImageUrl =
                baseProfileSettings.backgroundImage;
        }
        setActionStatus((ps) => ({ ...ps, background: true }));
        userService
            .updateBaseProfileSettingsById({
                baseProfileSettingsId: baseProfileSettings._id,
                ...newBaseProfileSetting
            })
            .then((res) => {
                enqueueSnackbar(config.defaultNotiStackMessage, {
                    variant: "success"
                });
                setActionStatus((ps) => ({ ...ps, background: false }));
                const baseProfileSettings = res.data.baseProfileSettings;
                dispatch({
                    type: actionTypes.UPDATE_BASE_PROFILE_SETTINGS,
                    payload: { baseProfileSettings }
                });
            })
            .catch((ex) => {
                enqueueSnackbar(ex?.response?.data?.message, {
                    variant: "error"
                });
                setActionStatus((ps) => ({ ...ps, background: false }));
            });
    };

    const handleAccountIconStatus = (e) => {
        setActionStatus((ps) => ({ ...ps, accountIconStatus: true }));
        userService
            .updateAccountSettingsById({
                baseAccountSettingsId: baseAccountSettings._id,
                isAccountIcon: !baseAccountSettings?.isAccountIcon
            })
            .then((res) => {
                const baseAccountSettings = res.data.baseAccountSettings;
                setActionStatus((ps) => ({ ...ps, accountIconStatus: false }));
                dispatch({
                    type: actionTypes.UPDATE_CURRENT_USER_MANAGED_BASE_DESIGN,
                    payload: { baseAccountSettings }
                });
            })
            .catch((ex) => {
                setActionStatus((ps) => ({ ...ps, accountIconStatus: false }));
                enqueueSnackbar(ex.responsse.data.message, {
                    variant: "error"
                });
            });
    };

    const handleSaveAccountDesign = ({
        accountIconShape,
        accountIconPosition
    }) => {
        setActionStatus((ps) => ({ ...ps, accountIconDesign: true }));
        userService
            .updateAccountSettingsById({
                baseAccountSettingsId: baseAccountSettings._id,
                accountIconShape,
                accountIconPosition
            })
            .then((res) => {
                enqueueSnackbar(config.defaultNotiStackMessage, {
                    variant: "success"
                });

                setActionStatus((ps) => ({ ...ps, accountIconDesign: false }));

                const baseAccountSettings = res.data.baseAccountSettings;
                dispatch({
                    type: actionTypes.UPDATE_CURRENT_USER_MANAGED_BASE_DESIGN,
                    payload: { baseAccountSettings }
                });
            })
            .catch((ex) => {
                enqueueSnackbar(ex.responsse.data.message, {
                    variant: "error"
                });
                setActionStatus((ps) => ({ ...ps, accountIconDesign: false }));
            });
    };

    const handleAccountDesign = ({ accountShape, accountStyle }) => {
        setActionStatus((ps) => ({ ...ps, accountDesign: true }));
        userService
            .updateAccountSettingsById({
                baseAccountSettingsId: baseAccountSettings._id,
                accountShape,
                accountStyle
            })
            .then((res) => {
                setActionStatus((ps) => ({ ...ps, accountDesign: false }));
                enqueueSnackbar(config.defaultNotiStackMessage, {
                    variant: "success"
                });
                const baseAccountSettings = res.data.baseAccountSettings;
                dispatch({
                    type: actionTypes.UPDATE_USER,
                    payload: {
                        baseAccountSettings
                    }
                });
                setActionStatus((ps) => ({ ...ps, accountDesign: false }));
            })
            .catch((ex) => {
                enqueueSnackbar(ex?.response?.data?.message, {
                    variant: "error"
                });
                setActionStatus((ps) => ({ ...ps, accountDesign: false }));
            });
    };

    const handleSaveTextDesign = ({ textColor }) => {
        setActionStatus((ps) => ({ ...ps, textDesign: true }));
        userService
            .updateBaseProfileSettingsById({
                baseProfileSettingsId: baseProfileSettings._id,
                textColor
            })
            .then((res) => {
                enqueueSnackbar(config.defaultNotiStackMessage, {
                    variant: "success"
                });
                setActionStatus((ps) => ({ ...ps, textDesign: false }));
                const baseProfileSettings = res.data.baseProfileSettings;
                dispatch({
                    type: actionTypes.UPDATE_CURRENT_USER_MANAGED_BASE_DESIGN,
                    payload: { baseProfileSettings }
                });
            })
            .catch((ex) => {
                enqueueSnackbar(ex?.response?.data?.message, {
                    variant: "error"
                });
                setActionStatus((ps) => ({ ...ps, textDesign: false }));
            });
    };

    const handleBannerStatus = (e) => {
        setActionStatus((ps) => ({ ...ps, bannerStatus: true }));
        userService
            .updateBaseProfileSettingsById({
                baseProfileSettingsId: baseProfileSettings._id,
                isBaseBannerActive: !baseProfileSettings?.isBaseBannerActive
            })
            .then((res) => {
                const baseProfileSettings = res.data.baseProfileSettings;
                setActionStatus((ps) => ({ ...ps, bannerStatus: false }));

                dispatch({
                    type: actionTypes.UPDATE_CURRENT_USER_MANAGED_BASE_DESIGN,
                    payload: { baseProfileSettings }
                });
            })
            .catch((ex) => {
                setActionStatus((ps) => ({ ...ps, bannerStatus: false }));
                // setIsBaseBannerActive(!isBaseBannerActive);
            });
    };

    const handleUpdateBanner = ({
        baseBannerColor,
        baseBannerText,
        baseBannerTextColor
    }) => {
        setActionStatus((ps) => ({ ...ps, updateBanner: true }));
        userService
            .updateBaseProfileSettingsById({
                baseProfileSettingsId: baseProfileSettings._id,
                baseBannerColor,
                baseBannerText,
                baseBannerTextColor
            })
            .then((res) => {
                enqueueSnackbar(config.defaultNotiStackMessage, {
                    variant: "success"
                });
                const baseProfileSettings = res.data.baseProfileSettings;
                dispatch({
                    type: actionTypes.UPDATE_CURRENT_USER_MANAGED_BASE_DESIGN,
                    payload: { baseProfileSettings }
                });
                setActionStatus((ps) => ({ ...ps, updateBanner: false }));
            })
            .catch((ex) => {
                enqueueSnackbar(ex?.response?.data?.message, {
                    variant: "error"
                });
                setActionStatus((ps) => ({ ...ps, updateBanner: false }));
            });
    };

    const managementHeaderUI = (
        <Box>
            <Box display={"flex"}>
                <Box display="flex" flexGrow={1}>
                    <Typography color="textPrimary" variant="h6">
                        <LabelWithEmoji text="Management Design" emoji="💼" />
                    </Typography>
                    {/* <CustomBadge badgeContent={"COMING SOON"}></CustomBadge> */}
                </Box>
                <Box>
                    <Switch
                        checked={
                            isManagedDesignActive === undefined
                                ? false
                                : isManagedDesignActive
                        }
                        onChange={handleManagementStatusChange}
                        disabled={loading}
                        color="primary"
                    />
                </Box>
            </Box>
        </Box>
    );

    const handleBaseBranding = (e) => {
        setActionStatus((ps) => ({ ...ps, baseBranding: true }));
        userService
            .updateBaseProfileSettingsById({
                baseProfileSettingsId: baseProfileSettings._id,
                isBaseBranding: !baseProfileSettings?.isBaseBranding
            })
            .then((res) => {
                const baseProfileSettings = res.data.baseProfileSettings;
                setActionStatus((ps) => ({ ...ps, baseBranding: false }));

                dispatch({
                    type: actionTypes.UPDATE_CURRENT_USER_MANAGED_BASE_DESIGN,
                    payload: { baseProfileSettings }
                });
            })
            .catch((ex) => {
                setActionStatus((ps) => ({ ...ps, baseBranding: false }));
            });
    };

    const managedBaseDesignUI = (
        <Box>
            {/* <Box bgcolor="background.secondary" p={3}>
                <SaveActionButton
                    label={
                        <Typography variant="h6" color="textPrimary">
                            <LabelWithEmoji text="Avatar" emoji="😎" />
                        </Typography>
                    }
                    isLoading={actionStatus.avatarShape}
                    onSave={handleSaveAvatarShape}
                />
                <AvatarShapeSelector
                    showAvatarSelector={false}
                    setSelectedAvatarShape={setSelectedAvatarShape}
                    selectedAvatarShape={selectedAvatarShape}
                />
            </Box> */}
            <Box bgcolor="background.secondary" p={3} mt={2}>
                <HeaderUI
                    enableFeature={true}
                    disableHeaderSwitch={actionStatus.activeHeader}
                    handleHeaderStatus={handleHeaderStatus}
                    handleDeleteHeader={handleDeleteHeader}
                    handleSaveHeader={handleSaveHeader}
                    isHeaderActive={baseProfileSettings?.isBaseHeaderActive}
                    baseHeader={baseProfileSettings?.baseHeaderImage}
                    isSavingHeader={actionStatus.saveHeader}
                    isDeletingHeader={actionStatus.deleteHeader}
                />
            </Box>
            <Box bgcolor="background.secondary" p={3} mt={2}>
                <BackgroundUI
                    allowVideoBackground={true}
                    backgroundType={baseProfileSettings?.backgroundType}
                    backgroundColor={baseProfileSettings?.backgroundColor}
                    backgroundImage={baseProfileSettings?.backgroundImage}
                    backgroundVideo={baseProfileSettings?.backgroundVideo}
                    backgroundMobileVideo={
                        baseProfileSettings?.backgroundMobileVideo
                    }
                    isLoading={actionStatus.background}
                    onBackgroundSave={handleSaveBackground}
                />
            </Box>
            <Box bgcolor="background.secondary" p={3} mt={2}>
                <AccountIconDesignUI
                    disableIconSwitch={actionStatus.accountIconStatus}
                    isLoading={actionStatus.accountIconDesign}
                    onAccountIconsStatusChange={handleAccountIconStatus}
                    onAccounIconDesignUpdate={handleSaveAccountDesign}
                    isAccountIcon={baseAccountSettings?.isAccountIcon}
                    accountIconShape={baseAccountSettings?.accountIconShape}
                    accountIconPosition={
                        baseAccountSettings?.accountIconPosition
                    }
                />
            </Box>
            <Box bgcolor="background.secondary" p={3} mt={2}>
                <AccountDesignUI
                    isLoading={actionStatus.accountDesign}
                    accountShape={baseAccountSettings?.accountShape}
                    accountStyle={baseAccountSettings?.accountStyle}
                    onAccountDesignUpdate={handleAccountDesign}
                />
            </Box>
            <Box bgcolor="background.secondary" p={3} mt={2}>
                <TextDesignUI
                    textColor={baseProfileSettings?.textColor}
                    onTextDesignUpdate={handleSaveTextDesign}
                    isLoading={actionStatus.textDesign}
                />
            </Box>
            <Box bgcolor="background.secondary" p={3} mt={2}>
                <BannerUI
                    enableFeature={true}
                    disableBannerSwitch={actionStatus.bannerStatus}
                    isLoading={actionStatus.updateBanner}
                    isBaseBannerActive={baseProfileSettings?.isBaseBannerActive}
                    baseBannerColor={baseProfileSettings?.baseBannerColor}
                    baseBannerText={baseProfileSettings?.baseBannerText}
                    onBannerStatusChange={handleBannerStatus}
                    onBannerUpdate={handleUpdateBanner}
                />
            </Box>
            <Box bgcolor="background.secondary" p={3} mt={2}>
                <BaseBrandingUI
                    enableFeature={true}
                    disableBaaseBrandingSwitch={actionStatus.baseBranding}
                    handleBaseBranding={handleBaseBranding}
                    isBaseBranding={baseProfileSettings?.isBaseBranding}
                />
            </Box>
        </Box>
    );

    return (
        <Box>
            {managementHeaderUI}
            <Collapse in={isManagedDesignActive && isCurrentUserPro}>
                {managedBaseDesignUI}
            </Collapse>
        </Box>
    );
}
