import { createStore } from "redux";
import { persistStore } from "redux-persist"; // allows browser to cache our store depends on certainer cofiguration
import rootReducer from "./reducers/index";

const initialState = {};

export const store = createStore(
    rootReducer,
    initialState,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);
export const persistor = persistStore(store);
// export default { store, persistor };
