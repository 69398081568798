/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
    Avatar,
    Box,
    Button,
    Container,
    Grid,
    InputAdornment,
    makeStyles,
    Paper,
    Select,
    TextField,
    Typography,
    CircularProgress,
    Switch,
    MenuItem
} from "@material-ui/core";
import clsx from "clsx";
import config from "../../config/index";

import * as yup from "yup";
import Alert from "@material-ui/lab/Alert";
import * as Scroll from "react-scroll";

// import useGlobalStyles from "../../hooks/useGlobalStyles";
import AdminPageContainer from "../../components/core/AdminPageContainer";
import userService from "../../services/user";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
    avatar: {
        width: theme.spacing(15),
        height: theme.spacing(15),
        [theme.breakpoints.down("xs")]: {
            width: theme.spacing(13),
            height: theme.spacing(13)
        }
    },
    roundedAvatar: {
        borderRadius: 16
    },
    shapesContainer: {
        marginTop: theme.spacing(2),
        [theme.breakpoints.down("xs")]: {
            paddingLeft: theme.spacing(2)
        }
    },
    shapeArea: {
        backgroundColor: theme.palette.secondary.main,
        // border:'1px solid #fff',
        borderWidth: 2,
        borderStyle: "solid",
        borderColor: theme.palette.text.primary,
        width: theme.spacing(5),
        height: theme.spacing(5),
        marginBottom: theme.spacing(1),
        outlineWidth: 1,
        outlineColor: theme.palette.secondary.main,
        outlineStyle: "dotted",
        cursor: "pointer",
        [theme.breakpoints.down("xs")]: {
            width: theme.spacing(4),
            height: theme.spacing(4)
        }
    },
    selectedShapeArea: {
        cursor: "pointer",
        backgroundColor: theme.palette.primary.main,
        borderWidth: 2,
        borderStyle: "solid",
        borderColor: theme.palette.secondary.main,
        width: theme.spacing(5),
        height: theme.spacing(5),
        marginBottom: theme.spacing(1),
        outlineWidth: 1,
        outlineColor: theme.palette.primary.main,
        outlineStyle: "dotted",
        [theme.breakpoints.down("xs")]: {
            width: theme.spacing(4),
            height: theme.spacing(4)
        }
    },
    shapesTitle: {
        fontSize: 12,
        fontWeight: 100
    },
    changeAvatarText: {
        fontSize: 12,
        fontWeight: 100,
        marginTop: theme.spacing(1)
    },
    avatarOverlay: {
        backgroundColor: "#000",
        opacity: "0.5",
        position: "absolute",
        bottom: 0,
        height: "50%",
        width: theme.spacing(15),
        [theme.breakpoints.down("xs")]: {
            width: theme.spacing(13)
        }
    },
    roundedOverlay: {
        borderBottomRightRadius: 16,
        borderBottomLeftRadius: 16
    },
    circleOverlay: {
        borderBottomRightRadius: theme.spacing(15),
        borderBottomLeftRadius: theme.spacing(15)
    }
}));
var scroll = Scroll.animateScroll;

// const BORDER = config.accountStyle.border;
// const FILL = config.accountStyle.fill;
// const CONSTANT = config.constants;
const CIRCLE = config.shape.circle;
const ROUNDED = config.shape.rounded;
export default function EditUser(props) {
    const userSurveys = useSelector((state) => state.surveys.userSurveys);

    const classes = useStyles();
    // const globalClasses = useGlobalStyles();
    const [userId, setUserId] = useState();
    const [selectedFile, setselectedFile] = useState();
    const [avatarUrl, setAvatarUrl] = useState("");
    const [avatarShape, setAvatarShape] = useState("circle");
    const [baseUrl, setbaseUrl] = useState("");
    const [displayName, setDisplayName] = useState("");
    const [bio, setBio] = useState("");
    const [userSurvey, setUserSurvey] = useState(
        JSON.stringify(userSurveys[0])
    );
    const [isVerifiedBase, setIsVerifiedBase] = useState(false);
    const [error, setError] = useState("");
    const [successMsg, setSuccessMsg] = useState("");
    const [loading, setLoading] = useState(false);

    const FILETYPES = ["image/jpeg", "image/jpg", "image/png"];

    let schema = yup.object().shape({
        // file: yup
        //     .string()
        //     .min(5)
        //     .notRequired()
        //     .default(undefined)
        //     .label("File"),
        // fileType: yup
        //     .string()
        //     .oneOf(
        //         ["image/png", "image/jpg", "image/jpeg"],
        //         "File must be an image"
        //     )
        //     .notRequired()
        //     .default(undefined)
        //     .label("File"),
        // fileSize: yup
        //     .number()
        //     .max(2000000, "File size must be less or equal to 2MB")
        //     .notRequired()
        //     .default(undefined)
        //     .label("File"),
        userAvatar: yup
            .object({
                file: yup.string().required(),
                fileType: yup.string().required().oneOf(FILETYPES),
                fileSize: yup
                    .number()
                    .lessThan(config.constants.avtarFileSize * 1000000)
            })
            .notRequired()
            .default(undefined),
        avatarShape: yup.string().required().label("Shape"),
        baseUrl: yup.string().required().min(5).max(20).label("Url"),
        displayName: yup.string().required().min(5).max(20).label("Name"),
        bio: yup.string().required().min(5).max(280).label("Bio"),
        userId: yup.string().required().label("User")
    });

    useEffect(() => {
        const user = props.location?.state;
        if (user) {
            setUserId(user._id);
            setAvatarShape(user.avatarShape);
            setAvatarUrl(user.avatarUrl);
            setbaseUrl(user.baseUrl);
            setDisplayName(user.displayName);
            setBio(user.bio);
            setUserSurvey(user.userSurvey);
            setIsVerifiedBase(user.isVerifiedBase);
        } else {
            props.history.push(config.routes.adminUsers);
        }
    }, []);

    const handlefileInput = (e) => {
        e.preventDefault();
        const input = e.currentTarget;
        const file = input.files[0];
        if (file) {
            const fileType = file.type;

            toBase64(file)
                .then((base64File) => {
                    setselectedFile({
                        file: base64File,
                        fileSize: file.size,
                        fileType
                    });
                })
                .catch((error) => {});
        }
    };

    const toBase64 = (file) =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });

    const submitForm = (e) => {
        e.preventDefault();
        let userData;
        if (selectedFile && selectedFile.file) {
            userData = {
                userId,
                bio,
                displayName,
                baseUrl,
                avatarShape,
                userSurveyId: JSON.parse(userSurvey).id,
                isVerifiedBase,
                userAvatar: {
                    file: selectedFile.file,
                    fileSize: selectedFile.fileSize,
                    fileType: selectedFile.fileType.toLowerCase()
                }
            };
        } else {
            userData = {
                userId,
                bio,
                displayName,
                baseUrl,
                avatarShape,
                userSurveyId: JSON.parse(userSurvey).id,
                isVerifiedBase
            };
        }

        schema
            .validate(userData)
            .then(() => {
                setError("");
                setLoading(true);
                updateUser(userData);
            })
            .catch((e) => {
                setError(e.message);
                scroll.scrollToTop();
            });
    };

    const updateUser = (data) => {
        userService
            .updateUserById(data)
            .then((res) => {
                setLoading(false);
                setSuccessMsg(config.defaultNotiStackMessage);
                scroll.scrollToTop();
            })
            .catch((ex) => {
                setLoading(false);
                setError(ex?.response?.data?.message);
                scroll.scrollToTop();
            });
    };
    return (
        <AdminPageContainer {...props}>
            <Container maxWidth="sm">
                <form
                    className={classes.root}
                    noValidate
                    autoComplete="off"
                    onSubmit={submitForm}
                >
                    <Grid container direction="column" spacing={2}>
                        <Grid item md={12} xs={12}>
                            <Paper>
                                <Typography color="primary" gutterBottom>
                                    AVATAR
                                </Typography>
                                {error && (
                                    <Alert severity="error">{error}</Alert>
                                )}
                                {successMsg && (
                                    <Alert severity="success">
                                        {successMsg}
                                    </Alert>
                                )}
                                <br />
                                <Grid
                                    container
                                    direction="row"
                                    justify="left"
                                    alignItems="left"
                                >
                                    <Grid item lg={4} md={4} sm={4} xs={4}>
                                        <div style={{ position: "relative" }}>
                                            <Avatar
                                                variant={
                                                    avatarShape === CIRCLE
                                                        ? "circular"
                                                        : avatarShape
                                                }
                                                className={clsx(
                                                    classes.avatar,
                                                    avatarShape === ROUNDED &&
                                                        classes.roundedAvatar
                                                )}
                                                src={
                                                    selectedFile
                                                        ? selectedFile.file
                                                        : avatarUrl
                                                }
                                            ></Avatar>
                                            <Box
                                                variant={
                                                    avatarShape === CIRCLE
                                                        ? "circular"
                                                        : avatarShape
                                                }
                                                className={clsx(
                                                    classes.avatarOverlay,
                                                    avatarShape === ROUNDED &&
                                                        classes.roundedOverlay,
                                                    avatarShape === CIRCLE &&
                                                        classes.circleOverlay
                                                )}
                                            >
                                                <Typography
                                                    align="center"
                                                    className={
                                                        classes.changeAvatarText
                                                    }
                                                >
                                                    <Button
                                                        component="label"
                                                        className={
                                                            classes.shapesTitle
                                                        }
                                                    >
                                                        Change Avatar
                                                        <input
                                                            type="file"
                                                            style={{
                                                                display: "none"
                                                            }}
                                                            onChange={
                                                                handlefileInput
                                                            }
                                                        />
                                                    </Button>
                                                </Typography>
                                            </Box>
                                        </div>
                                    </Grid>
                                    <Grid
                                        lg={8}
                                        md={8}
                                        sm={8}
                                        xs={8}
                                        item
                                        className={classes.shapesContainer}
                                    >
                                        <Typography
                                            color="textPrimary"
                                            variant="subtitle1"
                                            gutterBottom
                                        >
                                            Avatar Shape
                                        </Typography>
                                        <Grid
                                            container
                                            direction="row"
                                            spacing={2}
                                        >
                                            <Grid item align={"center"}>
                                                <Box
                                                    onClick={() =>
                                                        setAvatarShape(
                                                            config.shape.square
                                                        )
                                                    }
                                                    className={clsx(
                                                        avatarShape !==
                                                            config.shape
                                                                .square &&
                                                            classes.shapeArea,
                                                        avatarShape ===
                                                            config.shape
                                                                .square &&
                                                            classes.selectedShapeArea
                                                    )}
                                                />

                                                <Typography
                                                    className={
                                                        classes.shapesTitle
                                                    }
                                                    color="textPrimary"
                                                >
                                                    Square
                                                </Typography>
                                            </Grid>
                                            <Grid item align={"center"}>
                                                <Box
                                                    borderRadius={16}
                                                    className={clsx(
                                                        avatarShape !==
                                                            ROUNDED &&
                                                            classes.shapeArea,
                                                        avatarShape ===
                                                            ROUNDED &&
                                                            classes.selectedShapeArea
                                                    )}
                                                    onClick={() =>
                                                        setAvatarShape(ROUNDED)
                                                    }
                                                />
                                                <Typography
                                                    className={
                                                        classes.shapesTitle
                                                    }
                                                    color="textPrimary"
                                                >
                                                    Rounded
                                                </Typography>
                                            </Grid>
                                            <Grid item align={"center"}>
                                                <Box
                                                    borderRadius="50%"
                                                    className={clsx(
                                                        avatarShape !==
                                                            CIRCLE &&
                                                            classes.shapeArea,
                                                        avatarShape ===
                                                            CIRCLE &&
                                                            classes.selectedShapeArea
                                                    )}
                                                    onClick={() =>
                                                        setAvatarShape(CIRCLE)
                                                    }
                                                />

                                                <Typography
                                                    className={
                                                        classes.shapesTitle
                                                    }
                                                    color="textPrimary"
                                                >
                                                    Circle
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                        <Grid item md={12} xs={12}>
                            <Paper>
                                <Typography color="primary" gutterBottom>
                                    BASE DETAILS
                                </Typography>
                                <div>
                                    <Typography variant="subtitle1">
                                        Url
                                    </Typography>
                                    <TextField
                                        required
                                        value={baseUrl}
                                        placeholder="username"
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <Typography color="primary">
                                                        base.gg/
                                                    </Typography>
                                                </InputAdornment>
                                            )
                                        }}
                                        onChange={(e) =>
                                            setbaseUrl(
                                                e.currentTarget.value
                                                    .trim()
                                                    .toLowerCase()
                                            )
                                        }
                                        fullWidth
                                    />
                                    <br />
                                    <br />
                                    <Typography variant="subtitle1">
                                        Name
                                    </Typography>
                                    <TextField
                                        value={displayName}
                                        required
                                        fullWidth
                                        placeholder="e.g. full name"
                                        onChange={(e) =>
                                            setDisplayName(
                                                e.currentTarget.value
                                            )
                                        }
                                    />
                                    <br />
                                    <br />
                                    <Typography variant="subtitle1">
                                        Bio
                                    </Typography>

                                    <TextField
                                        required
                                        multiline
                                        fullWidth
                                        value={bio}
                                        placeholder="write something about yourself"
                                        onChange={(e) =>
                                            setBio(e.currentTarget.value)
                                        }
                                    />
                                </div>
                            </Paper>
                        </Grid>
                        <Grid item md={12} xs={12}>
                            <Paper>
                                <Typography color="primary" gutterBottom>
                                    USER SURVEY
                                </Typography>
                                <Typography
                                    color="textPrimary"
                                    variant="subtitle1"
                                    gutterBottom
                                >
                                    This information is used to improve future
                                    product features and information
                                </Typography>
                                <Typography
                                    color="textPrimary"
                                    variant="subtitle1"
                                    gutterBottom
                                >
                                    I am a/an{" "}
                                    <Select
                                        value={userSurvey}
                                        onChange={(e) =>
                                            // setUserSurvey(e.target.value)
                                            {
                                                setUserSurvey(e.target.value);
                                            }
                                        }
                                    >
                                        {userSurveys.map((s, index) => (
                                            <MenuItem value={JSON.stringify(s)}>
                                                {s.title}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </Typography>
                                {/* <Typography
                                    color="textPrimary"
                                    variant="subtitle1"
                                    gutterBottom
                                >
                                    I am a/an{" "}
                                    <Select

                                        value={userSurvey}
                                        onChange={(e) =>
                                            setUserSurvey(e.target.value)
                                        }
                                    >
                                        {CONSTANT.userSurvey.map((s) => (
                                            <MenuItem value={s}>{s}</MenuItem>
                                        ))}
                                    </Select>
                                </Typography> */}
                            </Paper>
                        </Grid>
                        <Grid item md={12} xs={12}>
                            <Paper>
                                <Box>
                                    <Box display="flex">
                                        <Box flexGrow={1}>
                                            <Typography color="primary">
                                                {"Verified base".toUpperCase()}
                                            </Typography>
                                        </Box>
                                        <Box display="flex">
                                            <Switch
                                                checked={isVerifiedBase}
                                                onChange={(e) =>
                                                    setIsVerifiedBase(
                                                        !isVerifiedBase
                                                    )
                                                }
                                                color="primary"
                                            />
                                        </Box>
                                    </Box>
                                </Box>
                            </Paper>
                        </Grid>
                        <Grid item md={12} xs={12}>
                            <Paper>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    fullWidth
                                    type="submit"
                                >
                                    {!loading ? (
                                        "Next"
                                    ) : (
                                        <Box align="center" marginTop={1}>
                                            <CircularProgress
                                                size={24}
                                                color="secondary"
                                            />
                                        </Box>
                                    )}
                                </Button>
                            </Paper>
                        </Grid>
                    </Grid>
                </form>
            </Container>
        </AdminPageContainer>
    );
}
