import React from "react";
import { Route, Redirect } from "react-router-dom";
import config from "../../config";

export default function PublicRoutes({
    component: Component,
    restricted,
    user,
    ...rest
}) {
    // const user = useSelector((state) => state.auth.user);

    return (
        // Show the component only when the user is logged in
        // Otherwise, redirect the user to /signin page

        <Route
            {...rest}
            exact
            render={(props) =>
                user && user._id && restricted ? (
                    <Redirect to={config.routes.dashboard} />
                ) : (
                    <Component {...props} user={user} />
                )
            }
        />
    );
}
