/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import * as Yup from "yup";

import config from "../../../config";
import ShapeSelector from "../../../components/core/ShapeSelector";
import AccountStyleSelector from "../../../components/core/AccountStyleSelector";
import SaveActionButton from "./SaveActionButton";
import LabelWithEmoji from "../../../components/common/LabelWithEmoji";

const CIRCLE = config.shape.circle;
const SQUARE = config.shape.square;
const ROUNDED = config.shape.rounded;

const FILL = config.accountStyle.fill;
const BORDER = config.accountStyle.border;

var validationSchema = Yup.object({
    selectedAccountShape: Yup.string()
        .required()
        .oneOf([CIRCLE, SQUARE, ROUNDED]),
    selectedAccountStyle: Yup.string().required().oneOf([FILL, BORDER])
});

export default function AccountDesignUI({
    disableAction = false,
    accountShape,
    accountStyle,
    onAccountDesignUpdate = () => {},
    isLoading = false
}) {
    const [selectedAccountShape, setSelectedAccountShape] = useState(CIRCLE);
    const [selectedAccountStyle, setSelectedAccountStyle] = useState(FILL);
    const [error, setError] = useState("");

    useEffect(() => {
        setStates();
    }, []);

    useEffect(() => {
        setStates();
    }, [accountShape, accountStyle]);

    const setStates = () => {
        setSelectedAccountShape(accountShape);
        setSelectedAccountStyle(accountStyle);
    };

    const resetErrroMessage = () => {
        setTimeout(() => {
            setError("");
        }, 3000);
    };

    const handleAccountDesign = (e) => {
        e.preventDefault();
        if (
            selectedAccountShape !== accountShape ||
            selectedAccountStyle !== accountStyle
        ) {
            validationSchema
                .validate({ selectedAccountShape, selectedAccountStyle })
                .then(() => {
                    onAccountDesignUpdate({
                        accountShape: selectedAccountShape,
                        accountStyle: selectedAccountStyle
                    });
                })
                .catch((err) => {
                    setError(err.message);
                    resetErrroMessage();
                });
        }
    };

    return (
        // <div>
        <Box>
            <SaveActionButton
                label={
                    <Typography color="textPrimary" variant="h6">
                        <LabelWithEmoji text="Account Design" emoji="📒" />
                    </Typography>
                }
                isLoading={isLoading}
                onSave={handleAccountDesign}
            />
            {error && (
                <Box mt={1}>
                    <Alert severity="error">{error}</Alert>
                </Box>
            )}
            <Grid container spacing={2}>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                    <ShapeSelector
                        setSelectedAccountShape={setSelectedAccountShape}
                        selectedAccountShape={selectedAccountShape}
                    />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                    <AccountStyleSelector
                        setSelectedAccountStyle={setSelectedAccountStyle}
                        selectedAccountStyle={selectedAccountStyle}
                    />
                </Grid>
            </Grid>
        </Box>
        // </div>
    );
}
