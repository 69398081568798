import React from "react";
import AdminPageContainer from "../../components/core/AdminPageContainer";
export default function AdminDashboard(props) {
    return (
        <div>
            {/* <pre style={{ backgroundColor: "#fff" }}>
        {JSON.stringify(props.user, null, 2)}
      </pre>
      <Button onClick={onLogout} style={{ color: "#fff" }}>Logout</Button> */}
            <AdminPageContainer {...props}></AdminPageContainer>
        </div>
    );
}
