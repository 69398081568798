import React, { useState } from "react";
import {
    Box,
    Button,
    Container,
    Grid,
    InputAdornment,
    makeStyles,
    Paper,
    Select,
    TextField,
    Typography,
    CircularProgress,
    AppBar,
    Toolbar,
    MenuItem
} from "@material-ui/core";
// import clsx from "clsx";
import config from "../../config";
// import useGlobalStyles from "../../hooks/useGlobalStyles";
import * as Yup from "yup";
import Alert from "@material-ui/lab/Alert";
import * as Scroll from "react-scroll";
import auth from "../../services/auth";
import { useDispatch } from "react-redux";
import actionTypes from "../../redux/actionTypes";
import baseLogo from "../../res/images/basegg-logo1.png";
import baseIcon from "../../res/images/base-icon.png";

// import utils from "../../utils";
import AvatarShapeSelector from "../../components/core/AvatarShapeSelector";
import LabelWithEmoji from "../../components/common/LabelWithEmoji";
import { useSelector } from "react-redux";
import Beta from "../../components/common/Beta";
import ConfirmationDialog from "../../components/common/ConfirmationDialog";
import { useSnackbar } from "notistack";

const useStyles = makeStyles((theme) => ({}));
var scroll = Scroll.animateScroll;
// const errors = config.validationErrors;
const CONSTANT = config.constants;
const FILETYPES = ["image/jpeg", "image/jpg", "image/png"];

const AVATAR_SHAPES = [
    config.shape.circle,
    config.shape.rounded,
    config.shape.square
];

let schema = Yup.object().shape({
    avatarImage: Yup.object({
        file: Yup.string().required(),
        fileType: Yup.string().required().oneOf(FILETYPES),
        fileSize: Yup.number()
            .lessThan(
                config.constants.avtarFileSize * 1000000,
                config.validationErrors.fileSizeError +
                    config.constants.avtarFileSize,
                +"  MB"
            )
            .notRequired()
    })
        .notRequired()
        .default(undefined)
        .label("Avatar"),
    avatarShape: Yup.string().required().oneOf(AVATAR_SHAPES).label("Shape"),
    baseUrl: Yup.string()
        .required()
        .min(CONSTANT.nameMinCharacter)
        .max(CONSTANT.nameMaxCharacter)
        .label("Url"),
    displayName: Yup.string()
        .required()
        .min(CONSTANT.nameMinCharacter)
        .max(CONSTANT.nameMaxCharacter)
        .label("Name"),
    bio: Yup.string()
        .notRequired()
        .default("")
        .max(CONSTANT.bioMaxCharacter)
        .label("Bio"),
    userSurveyId: Yup.string()
        .required()
        .default("")
        // .max(CONSTANT.bioMaxCharacter)
        .label("Survey")
});

export default function ProfileUpdate(props) {
    const classes = useStyles();
    // const globalClasses = useGlobalStyles();
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    const [avatarImage, setAvatarImage] = useState();
    // const [avatarUrl, setAvatarUrl] = useState("");
    const [avatarShape, setAvatarShape] = useState("square");
    const [baseUrl, setbaseUrl] = useState("");
    const [displayName, setDisplayName] = useState("");
    const [bio, setBio] = useState("");
    const userSurveys = useSelector((state) => state.surveys.userSurveys);
    const [userSurvey, setUserSurvey] = useState();
    // JSON.stringify(userSurveys[0])
    const [openConfirmationDialog, setOpenConfirmationDialog] =
        React.useState(false);

    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const [removingUser, setRemovingUser] = useState(false);

    React.useEffect(() => {
        const timeout = setTimeout(() => {
            const toTitleCase = (str) =>
                str.replace(/\b\S/g, (t) => t.toUpperCase());
            if (displayName === "") {
                setDisplayName(toTitleCase(baseUrl));
            }
        }, 2000);
        // if this effect run again, because `baseUrl` changed, we remove the previous timeout
        return () => clearTimeout(timeout);
    }, [baseUrl, displayName]);

    // React.useEffect(() => {
    //     setUserSurvey(JSON.stringify(userSurveys[0]));
    // }, []);

    // React.useEffect(() => {
    //     setUserSurvey(JSON.stringify(userSurveys[0]));
    // }, [userSurveys]);

    const submitForm = (e) => {
        e.preventDefault();
        var userData = {
            avatarImage,
            bio,
            displayName,
            baseUrl,
            avatarShape
            // userSurveyId: userSurvey ? JSON.parse(userSurvey)._id : ""
        };
        if (userSurvey) {
            userData.userSurveyId = JSON.parse(userSurvey)?._id;
        }
        schema
            .validate(userData)
            .then(() => {
                setError("");
                setLoading(true);
                updateProfile(userData);
            })
            .catch((e) => {
                setError(e.message);
                scroll.scrollToTop();
            });
    };

    const updateProfile = (data) => {
        auth.completeRegistration(data)
            .then((res) => {
                setLoading(false);
                const user = res.data.user;

                dispatch({
                    type: actionTypes.UPDATE_CURRENT_USER,
                    payload: user
                });
                dispatch({ type: actionTypes.UPDATE_USER, payload: user });
                dispatch({
                    type: actionTypes.SET_MANAGEMENT,
                    payload: {
                        management: {
                            managedBases: null,
                            managementRequests: [],
                            categoryList: []
                        }
                    }
                });
                // localStorage.setItem(config.lsKeys.user, btoa(JSON.stringify(user)));
                props.history.push(config.routes.dashboard);
            })
            .catch((ex) => {
                setLoading(false);
                setError(ex?.response?.data?.message);
                scroll.scrollToTop();
            });
    };
    // const onLogout = () => {
    //     setLoading(true);
    //     auth.logout().then(() => {
    //         dispatch({ type: actionTypes.SET_AUTH, payload: { auth: "" } });
    //         props.history.push(config.routes.login);
    //     });
    // };

    const toggleConfirmationDialog = () => {
        setOpenConfirmationDialog(!openConfirmationDialog);
    };
    const deleteMyAccount = () => {
        setRemovingUser(true);
        auth.deleteCurrentUser("?email=false")
            .then((res) => {
                setRemovingUser(false);
                dispatch({ type: actionTypes.SET_AUTH, payload: { auth: "" } });
                props.history.push(config.routes.login);
                enqueueSnackbar(
                    "Base.gg registration not completed. You will have to restart the registration process at next login.",
                    {
                        variant: "success"
                    }
                );
            })
            .catch((ex) => {
                setRemovingUser(false);
                enqueueSnackbar(config.contactSupport, {
                    variant: "error"
                });
            });
    };
    // const handleUrl = (e) => {
    //   setbaseUrl(e.currentTarget.value.trim().toLowerCase());
    //   if (displayName === "") {
    //     setDisplayName(e.currentTarget.value);
    //   }
    // };
    return (
        <div>
            <Box flexGrow={1}>
                <AppBar position="static" color="background.default">
                    <Toolbar>
                        {/* <Link to={config.routes.profile}> */}
                        <img
                            src={baseLogo}
                            alt="base-logo"
                            height={20}
                            className={classes.avatarSize}
                        />
                        {/* </Link> */}

                        <Box flexGrow={1} />
                        <div>
                            <Button onClick={deleteMyAccount}>Logout</Button>
                        </div>
                    </Toolbar>
                </AppBar>
            </Box>

            <Container maxWidth="md">
                <Box p={2}>
                    <Beta />
                </Box>
                <form
                    className={classes.root}
                    noValidate
                    autoComplete="off"
                    onSubmit={submitForm}
                >
                    <Box>
                        <Box mt={2}>
                            <Box my={4} mb={3}>
                                {/* <Box mb={0.5}>
                                    <Typography
                                        color="textSecondary"
                                        variant="subtitle"
                                        gutterBottom
                                    >
                                        WELCOME TO QUICK SETUP
                                    </Typography>
                                </Box> */}
                                <Typography
                                    color="textPrimary"
                                    variant="h4"
                                    gutterBottom
                                    style={{ fontWeight: "bold" }}
                                >
                                    <LabelWithEmoji
                                        text="WELCOME TO YOUR BASE"
                                        // emoji="😎"
                                    />
                                </Typography>
                            </Box>
                            <Paper>
                                <Box p={4}>
                                    <Box mb={1}>
                                        {error && (
                                            <Alert severity="error">
                                                {error}
                                            </Alert>
                                        )}
                                    </Box>
                                    <Typography
                                        color="textPrimary"
                                        variant="h5"
                                        gutterBottom
                                    >
                                        <b>
                                            <LabelWithEmoji
                                                text="Avatar"
                                                emoji="😎"
                                            />
                                        </b>
                                    </Typography>

                                    <AvatarShapeSelector
                                        setSelectedAvatar={setAvatarImage}
                                        setSelectedAvatarShape={setAvatarShape}
                                        selectedAvatarShape={avatarShape}
                                        selectedAvatar={avatarImage}
                                        avatarUrl={baseIcon}
                                    />
                                </Box>
                                <Box px={4} p={2}>
                                    <Typography
                                        color="textPrimary"
                                        variant="h6"
                                        gutterBottom
                                    >
                                        <LabelWithEmoji
                                            text="Base Details"
                                            emoji="✅"
                                        />
                                    </Typography>

                                    <div>
                                        <Box mb={3}>
                                            <Typography
                                                variant="subtitle1"
                                                color="textSecondary"
                                            >
                                                Base URL*
                                            </Typography>
                                            <TextField
                                                required
                                                variant="outlined"
                                                value={baseUrl}
                                                placeholder="username"
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <Typography color="primary">
                                                                base.gg/
                                                            </Typography>
                                                        </InputAdornment>
                                                    )
                                                }}
                                                onChange={(e) =>
                                                    setbaseUrl(
                                                        e.currentTarget.value
                                                            .trim()
                                                            .toLowerCase()
                                                    )
                                                }
                                                fullWidth
                                            />
                                        </Box>
                                        <Box mb={3}>
                                            <Typography
                                                variant="subtitle1"
                                                color="textSecondary"
                                            >
                                                Name*
                                            </Typography>
                                            <TextField
                                                value={displayName}
                                                required
                                                variant="outlined"
                                                fullWidth
                                                placeholder="Enter your name"
                                                onChange={(e) =>
                                                    setDisplayName(
                                                        e.currentTarget.value
                                                    )
                                                }
                                            />
                                        </Box>
                                        <Box mb={3}>
                                            <Typography
                                                variant="subtitle1"
                                                color="textSecondary"
                                            >
                                                Bio
                                            </Typography>

                                            <TextField
                                                variant="outlined"
                                                required
                                                multiline
                                                fullWidth
                                                value={bio}
                                                minRows={4}
                                                placeholder="Write something about yourself"
                                                onChange={(e) =>
                                                    setBio(
                                                        e.currentTarget.value
                                                    )
                                                }
                                            />
                                        </Box>
                                    </div>
                                </Box>
                                <Box px={4} p={2}>
                                    <Typography
                                        color="textPrimary"
                                        variant="h6"
                                        gutterBottom
                                    >
                                        <LabelWithEmoji
                                            text="Survey*"
                                            emoji="📝"
                                        />
                                    </Typography>
                                    <Typography
                                        color="textSecondary"
                                        variant="subtitle1"
                                        gutterBottom
                                    >
                                        This information is used to improve
                                        future product features and information
                                    </Typography>
                                    <Typography
                                        color="primary"
                                        variant="subtitle1"
                                        gutterBottom
                                    >
                                        I am a/an{" "}
                                        <Select
                                            value={userSurvey}
                                            // color="primary"
                                            onChange={(e) =>
                                                // setUserSurvey(e.target.value)
                                                {
                                                    setUserSurvey(
                                                        e.target.value
                                                    );
                                                }
                                            }
                                        >
                                            {userSurveys.map((s, index) => (
                                                <MenuItem
                                                    key={s?._id}
                                                    value={JSON.stringify(s)}
                                                >
                                                    {s.title}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </Typography>
                                </Box>
                                <Box p={2}>
                                    <Grid
                                        container
                                        spacing={2}
                                        direction="row"
                                        justifyContent="center"
                                        alignItems="center"
                                    >
                                        <Grid
                                            item
                                            lg={2}
                                            md={2}
                                            sm={12}
                                            xs={12}
                                        >
                                            <Button
                                                color="secondary"
                                                variant="contained"
                                                fullWidth
                                                onClick={
                                                    toggleConfirmationDialog
                                                }
                                                // type="submit"
                                            >
                                                Delete
                                                {/* <Box
                                                    align="center"
                                                    marginTop={1}
                                                >
                                                    <CircularProgress
                                                        size={24}
                                                        color="inherit"
                                                    />
                                                </Box> */}
                                                {/* {!removingUser ? (
                                                    "Delete"
                                                ) : (
                                                    
                                                )} */}
                                            </Button>
                                        </Grid>
                                        <Grid
                                            item
                                            lg={2}
                                            md={2}
                                            sm={12}
                                            xs={12}
                                        >
                                            <Button
                                                color="primary"
                                                variant="contained"
                                                fullWidth
                                                type="submit"
                                            >
                                                {!loading ? (
                                                    "Next"
                                                ) : (
                                                    <Box
                                                        align="center"
                                                        marginTop={1}
                                                    >
                                                        <CircularProgress
                                                            size={24}
                                                            color="inherit"
                                                        />
                                                    </Box>
                                                )}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Paper>
                        </Box>

                        {/* <Box mt={2}>
                            <Paper>
                                <Box p={4}>
                                    <Typography
                                        color="textPrimary"
                                        variant="h6"
                                        gutterBottom
                                    >
                                        <LabelWithEmoji
                                            text="Survey"
                                            emoji="📝"
                                        />
                                    </Typography>
                                    <Typography
                                        color="textPrimary"
                                        variant="subtitle1"
                                        gutterBottom
                                    >
                                        This information is used to improve
                                        future product features and information
                                    </Typography>
                                    <Typography
                                        color="textPrimary"
                                        variant="subtitle1"
                                        gutterBottom
                                    >
                                        I am a/an{" "}
                                        <Select
                                            value={userSurvey}
                                            onChange={(e) =>
                                                // setUserSurvey(e.target.value)
                                                {
                                                    setUserSurvey(
                                                        e.target.value
                                                    );
                                                }
                                            }
                                        >
                                            {userSurveys.map((s, index) => (
                                                <MenuItem
                                                    value={JSON.stringify(s)}
                                                >
                                                    {s.title}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </Typography>
                                </Box>
                            </Paper>
                        </Box> */}

                        {/* <Box my={2}>
                            <Paper>
                                <Box p={4}>
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        fullWidth
                                        type="submit"
                                    >
                                        {!loading ? (
                                            "Next"
                                        ) : (
                                            <Box align="center" marginTop={1}>
                                                <CircularProgress
                                                    size={24}
                                                    color="inherit"
                                                />
                                            </Box>
                                        )}
                                    </Button>
                                </Box>
                            </Paper>
                        </Box> */}
                    </Box>
                </form>

                <ConfirmationDialog
                    open={openConfirmationDialog}
                    onClose={toggleConfirmationDialog}
                    onConfirm={deleteMyAccount}
                    confirmationDialogTitle={"Delete Base"}
                    confirmationDialogDescription={
                        <Box>
                            {removingUser && (
                                <Box align="center">
                                    <CircularProgress />
                                </Box>
                            )}
                            {!removingUser && (
                                <Typography>
                                    Are you sure you want to permanently delete
                                    your base?
                                </Typography>
                            )}
                        </Box>
                    }
                    disableButtons={loading}
                    disableBackDrop={loading}
                    confirmationBtnTitle={"Delete"}
                />
            </Container>
        </div>
    );
}
