import { Box, ThemeProvider } from "@material-ui/core";
import React from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import AppRouter from "./AppRouter";
import theme from "./config/theme";
// import HandleExpiredToken from "./pages/auth/HandleExpiredToken";
import { store, persistor } from "./redux/store";
import { BrowserRouter } from "react-router-dom";
import Slide from "@material-ui/core/Slide";
import { SnackbarProvider } from "notistack";
import { Close } from "@material-ui/icons";
import CssBaseline from "@material-ui/core/CssBaseline";

function App() {
    // const token = localStorage.getItem(config.lsKeys.token);
    // axios.setHeaders(token);
    const notistackRef = React.createRef();
    const onClickDismiss = (key) => () => {
        notistackRef.current.closeSnackbar(key);
    };
    return (
        <BrowserRouter>
            <Provider store={store}>
                <ThemeProvider theme={theme}>
                    <CssBaseline />
                    <SnackbarProvider
                        ref={notistackRef}
                        maxSnack={3}
                        anchorOrigin={{
                            vertical: "top",
                            horizontal: "right"
                        }}
                        TransitionComponent={Slide}
                        action={(key) => (
                            <Box
                                mt={0.6}
                                mr={0.5}
                                onClick={onClickDismiss(key)}
                                style={{ cursor: "pointer" }}
                            >
                                <Close style={{ fontSize: 20 }} />
                            </Box>
                        )}
                    >
                        <PersistGate persistor={persistor}>
                            {/* <HandleExpiredToken /> */}

                            <AppRouter />
                        </PersistGate>
                    </SnackbarProvider>
                </ThemeProvider>
            </Provider>
        </BrowserRouter>
    );
}

export default App;
