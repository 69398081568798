import config from "../config";
import axios from "../config/axios";

const addUserAccount = async (account) => {
    return axios.post(
        `${config.apiEndPoint}${config.apiRoutes.user.baseAccount}`,
        account
    );
};
const updateUserAccount = async (account) => {
    return axios.put(
        `${config.apiEndPoint}${config.apiRoutes.user.baseAccount}`,
        account
    );
};
const addCustomUserAccount = async (account) => {
    return axios.post(
        `${config.apiEndPoint}${config.apiRoutes.user.customAccount}`,
        account
    );
};
const editCustomUserAccount = async (account) => {
    return axios.put(
        `${config.apiEndPoint}${config.apiRoutes.user.customAccount}`,
        account
    );
};
const deleteUserAccount = async (id, userId) => {
    return axios.delete(
        `${config.apiEndPoint}${config.apiRoutes.user.account}`,
        { data: { userAccountId: id, userId } }
    );
};
const handleUserAccountEdit = async (account) => {
    if (account.isCustomAccount) {
        return editCustomUserAccount(account);
    }
    if (!account.isCustomAccount) {
        return updateUserAccount(account);
    }
};

const updateAccountSettings = async (newSettings) => {
    return axios.put(
        `${config.apiEndPoint}${config.apiRoutes.user.accountSettings}`,
        newSettings
    );
};
const updateAccountSettingsById = async (newSettings) => {
    return axios.put(
        `${config.apiEndPoint}${config.apiRoutes.user.managedBaseDesign.accountSettings}`,
        newSettings
    );
};
const getUserBase = async (userId) => {
    return axios.get(
        `${config.apiEndPoint}${config.apiRoutes.user.base}?userId=${userId}`
    );
};
const updateUserBase = async (profile) => {
    return axios.put(
        `${config.apiEndPoint}${config.apiRoutes.user.base}`,
        profile
    );
};
const updateBaseProfileSettings = async (newSettings) => {
    return axios.put(
        `${config.apiEndPoint}${config.apiRoutes.user.profileSettings}`,
        newSettings
    );
};
const updateBaseProfileSettingsById = async (newSettings) => {
    return axios.put(
        `${config.apiEndPoint}${config.apiRoutes.user.managedBaseDesign.profileSettings}`,
        newSettings
    );
};
const getAccountsByCategory = async () => {
    return axios.get(
        `${config.apiEndPoint}${config.apiRoutes.user.appAccount}?by=category`
    );
};

const getSurveys = async () => {
    return axios.get(`${config.apiEndPoint}${config.apiRoutes.user.survey}`);
};

const getIntegrations = async () => {
    return axios.get(
        `${config.apiEndPoint}${config.apiRoutes.user.integration}`
    );
};
const getUserIntegrationDataById = (id) => {
    return axios.get(
        `${config.apiEndPoint}${config.apiRoutes.user.integrationData}?id=${id}`
    );
};
const updateUserIntegration = (userIntegration) => {
    return axios.put(
        `${config.apiEndPoint}${config.apiRoutes.user.integration}`,
        userIntegration
    );
};
const deleteUserIntegration = async (id) => {
    return axios.delete(
        `${config.apiEndPoint}${config.apiRoutes.user.integration}`,
        { data: { userIntegrationId: id } }
    );
};
// admin
const getAllUsers = async () => {
    return axios.get(`${config.apiEndPoint}${config.apiRoutes.user.default}`);
};
const updateUserById = async (updatedUser) => {
    return axios.put(
        `${config.apiEndPoint}${config.apiRoutes.user.default}`,
        updatedUser
    );
};
const deleteUserById = async (id) => {
    return axios.delete(
        `${config.apiEndPoint}${config.apiRoutes.user.default}`,
        {
            data: { id }
        }
    );
};
const manageUserStatusById = async (data) => {
    return axios.put(
        `${config.apiEndPoint}${config.apiRoutes.user.userStatus}`,
        data
    );
};
// const addManagementCategory = async (data) => {
//     return axios.post(
//         `${config.apiEndPoint}${config.apiRoutes.user.managementCategory}`,
//         data
//     );
// };
// const getManagementCategories = async () => {
//     return axios.get(
//         `${config.apiEndPoint}${config.apiRoutes.user.managementCategory}`
//     );
// };

export default {
    addUserAccount,
    addCustomUserAccount,
    editCustomUserAccount,
    handleUserAccountEdit,
    updateAccountSettings,
    getUserBase,
    updateUserBase,
    updateBaseProfileSettings,
    getAccountsByCategory,
    getAllUsers,
    updateUserById,
    deleteUserById,
    updateUserAccount,
    deleteUserAccount,
    manageUserStatusById,
    getSurveys,
    getIntegrations,
    getUserIntegrationDataById,
    updateUserIntegration,
    deleteUserIntegration,
    updateBaseProfileSettingsById,
    updateAccountSettingsById
    // addManagementCategory,
    // getManagementCategories
};
