import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defining what type of storage we want it is actual broswer local storage
import authReducer from "./authReducer";
import accountCategoriesReducer from "./accountCategories";
import surveysReducer from "./surveysReducer";
import integrationsReducer from "./integrationsReducer";
import actionTypes from "../actionTypes";

const persistConfig = {
    key: "root",
    storage,
    whiteList: ["auth", "accountCategories", "userSurveys", "integrations"]
};
// export default combineReducers({
//     auth:authReducer,
// })
const rootReducer = (state, action) => {
    if (action.type === actionTypes.USER_LOGOUT) {
        window.localStorage.removeItem("persist:root");
        state = undefined;
        window.localStorage.removeItem("persist:root");
    }
    return appReducer(state, action);
};
const appReducer = combineReducers({
    auth: authReducer,
    accountCategories: accountCategoriesReducer,
    surveys: surveysReducer,
    integrations: integrationsReducer
});

export default persistReducer(persistConfig, rootReducer);
