import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import {
    Avatar,
    Box,
    CircularProgress,
    Grid,
    IconButton,
    TextField,
    useMediaQuery
} from "@material-ui/core";
import * as Yup from "yup";
// import * as Scroll from "react-scroll";
// import useGlobalStyles from "../../../../../hooks/useGlobalStyles";
import clsx from "clsx";
// import { ColorPicker } from "material-ui-color";
import utils from "../../../../../utils";
import Alert from "@material-ui/lab/Alert";
import { useDispatch } from "react-redux";
import baseIcon from "../../../../../res/images/base-icon1.png";
import config from "../../../../../config";
import { Fragment } from "react";
import { useSnackbar } from "notistack";
import baseProfileSetting from "../../../../../services/baseProfileSetting";
import actionTypes from "../../../../../redux/actionTypes";
import ConfirmationDialog from "../../../../../components/common/ConfirmationDialog";
import { useTheme } from "@material-ui/styles";
import { Check, DeleteForever } from "@material-ui/icons";
import { useSelector } from "react-redux";

const CIRCLE = config.shape.circle;
const ROUNDED = config.shape.rounded;
const FILETYPES = ["image/jpeg", "image/jpg", "image/png"];

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%"
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular
    },
    sponsorLogoContainer: {
        position: "relative",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: 5,
        [theme.breakpoints.down("xs")]: {
            // marginTop: 10
        }
    },
    previewIcon: {
        border: `1px dashed ${theme.palette.primary.main}`,
        width: theme.spacing(14),
        height: theme.spacing(14),
        [theme.breakpoints.down("xs")]: {
            width: theme.spacing(13.5),
            height: theme.spacing(13.5),
            margin: 0
        }
    },
    previewIconOverlay: {
        backgroundColor: "#000",
        opacity: "0.5",
        position: "absolute",
        bottom: 2,
        height: "45%",
        width: theme.spacing(14.5),
        // width: "100%",

        borderBottomRightRadius: 4,
        borderBottomLeftRadius: 4,
        // marginRight: 8,
        [theme.breakpoints.down("xs")]: {
            width: theme.spacing(13.5),
            bottom: 0
        },
        cursor: "pointer",
        "&:hover": {
            backgroundColor: "rgba(11, 11, 11, 0.8)"
        }
    },
    saveBtn: {
        backgroundColor: theme.palette.primary.dark,
        padding: ({ loading }) => loading && 1.4,
        color: theme.palette.background.paper,
        // width: theme.spacing(14.5),
        "&:hover": {
            backgroundColor: theme.palette.primary.dark
        }
    },
    previewIconDesktop: {
        border: `1px dashed ${theme.palette.primary.main}`,
        width: theme.spacing(10.5),
        height: theme.spacing(10.5),
        [theme.breakpoints.down("xs")]: {
            width: theme.spacing(7),
            height: theme.spacing(7),
            margin: 0
        }
    },
    previewIconOverlayDesktop: {
        backgroundColor: "#000",
        opacity: "0.5",
        position: "absolute",
        bottom: 0,
        height: "45%",
        width: theme.spacing(10.5),
        cursor: "pointer",

        // width: "100%",

        borderBottomRightRadius: 4,
        borderBottomLeftRadius: 4,
        // marginRight: 8,
        [theme.breakpoints.down("xs")]: {
            width: theme.spacing(7),
            bottom: 0
        },
        "&:hover": {
            backgroundColor: "rgba(11, 11, 11, 0.8)"
        }
    },
    roundedAvatar: {
        borderRadius: 16
    },
    roundedOverlay: {
        borderBottomRightRadius: 16,
        borderBottomLeftRadius: 16
    },
    circleOverlay: {
        borderBottomRightRadius: theme.spacing(17),
        borderBottomLeftRadius: theme.spacing(17)
    },
    changeBtn: {
        cursor: "pointer",
        color: "#fff",
        fontSize: "10px",
        "&:hover": {
            color: "#fff"
        },
        [theme.breakpoints.down("sm")]: {
            fontSize: "12px"
        }
    }
}));
//
const imageValidation = Yup.object({
    fileType: Yup.string().required().oneOf(FILETYPES).label("File"),
    fileSize: Yup.number()
        .lessThan(
            config.constants.baseLogoFileSize * 1000000,
            config.validationErrors.fileSizeError +
                config.constants.baseLogoFileSize +
                " MB"
        )
        .label("File size")
});

const addSponsorLogoOSchema = Yup.object({
    userId: Yup.string().required(),
    url: Yup.string().required(config.validationErrors.urlError),
    // .matches(config.regx.sponsorLogoWebsiteUrl, config.validationErrors.urlError)
    description: Yup.string()
        .notRequired()
        .max(config.constants.sponorLogoDescMaxChar),
    sponsorLogo: imageValidation.required()
});

const editSponsorLogoSchema = Yup.object({
    userId: Yup.string().required(),
    sponsorLogoId: Yup.string().required(),

    url: Yup.string().required(config.validationErrors.urlError),
    // .matches(config.regx.sponsorLogoWebsiteUrl, config.validationErrors.urlError)
    description: Yup.string()
        .notRequired()
        .max(config.constants.sponorLogoDescMaxChar),
    sponsorLogo: imageValidation.notRequired().default(undefined)
});
// var scroll = Scroll.animateScroll;

export default function SponsorLogo({
    sponsorLogoId,
    operation,
    url,
    description,
    sponsorLogoUrl,
    onAddSuccess
}) {
    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch();
    // const globalClasses = useGlobalStyles();

    const user = useSelector((s) => s.auth.user);
    const sponsorLogoShape = user.baseProfileSettings?.sponsorLogoShape;
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    const { enqueueSnackbar } = useSnackbar();
    // const user = useSelector((state) => state.auth.user);
    const [sponsorLogoWebsiteUrl, setSponsorLogoWebsiteUrl] = useState("");
    const [sponsorLogoWebsitedesc, setSponsorLogoWebsitedesc] = useState("");

    const [selectedSponsorLogo, setSelectedSponsorLogo] = useState();
    const [openConfirmationDialog, setOpenConfirmationDialog] =
        React.useState(false);
    const [saveButtonText, setSaveButtonText] = useState({
        operation: "",
        status: ""
    });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    // const [successMsg, setSuccessMsg] = useState("");
    // const baseAccountSettings = user.baseAccountSettings;
    const setSponsorLogoData = () => {
        if (url) {
            setSponsorLogoWebsiteUrl(url);
        }
        if (description) {
            setSponsorLogoWebsitedesc(description);
        }
    };
    useEffect(() => {
        // window.addEventListener("resize", () => {
        //     handleIsMobile();
        // });
        setSponsorLogoData();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        setSponsorLogoData();
        // eslint-disable-next-line
    }, [url, description, sponsorLogoUrl, operation]);

    // const handleIsMobile = () => {
    //     setIsMobile(
    //         theme.breakpoints.down("sm")
    //         useMediaQuery(theme.breakpoints.down("sm"), {
    //             noSsr: true
    //         })
    //     );
    // };

    const resetErrors = () => {
        setTimeout(() => {
            setError("");
        }, 3000);
    };

    const handlefileInput = (e) => {
        e.preventDefault();
        setError("");
        const input = e.currentTarget;
        const file = input.files[0];
        if (file) {
            const fileType = file.type;

            imageValidation
                .validate({ fileSize: file.size, fileType })
                .then(() => {
                    utils
                        .toBase64(file)
                        .then((base64File) => {
                            setSelectedSponsorLogo({
                                file: base64File,
                                fileSize: file.size,
                                fileType
                            });
                        })
                        .catch((error) => {
                            setError(error.message);
                            resetErrors();
                        });
                })
                .catch((ex) => {
                    setError(ex.message);
                    resetErrors();
                });
        }
    };

    const handlSaveSponsorLogo = (e) => {
        e.preventDefault();
        if (operation === "add") {
            addSponsorLogo();
        }
        if (operation === "edit" && sponsorLogoId) {
            editSponsorLogo();
        }
    };
    const resetAddSponsorLogofields = () => {
        onAddSuccess();
        setSponsorLogoWebsiteUrl("");
        setSponsorLogoWebsitedesc("");
        setSelectedSponsorLogo("");
    };

    const addSponsorLogo = () => {
        const splData = {
            userId: user._id,
            url: sponsorLogoWebsiteUrl,
            description: sponsorLogoWebsitedesc,
            sponsorLogo: selectedSponsorLogo
        };
        addSponsorLogoOSchema
            .validate(splData)
            .then(async () => {
                setSaveButtonText({
                    operation: "saveSponsorLogo",
                    status: "Saving..."
                });
                try {
                    const res = await baseProfileSetting.addSponsorLogo(
                        splData
                    );

                    resetAddSponsorLogofields();
                    dispatch({
                        type: actionTypes.UPDATE_BASE_PROFILE_SETTINGS,
                        payload: { baseSponsorLogos: res.data.baseSponsorLogos }
                    });
                    enqueueSnackbar(config.defaultNotiStackMessage, {
                        variant: "success"
                    });
                    setSaveButtonText({
                        operation: "saveSponsorLogo",
                        status: "Saved!"
                    });
                    setTimeout(() => {
                        setSaveButtonText({ operation: "", status: "" });
                    }, 2000);
                } catch (ex) {
                    enqueueSnackbar(ex?.response?.data?.message, {
                        variant: "error"
                    });
                    setSaveButtonText({
                        operation: "saveSponsorLogo",
                        status: "Failed"
                    });
                    setTimeout(() => {
                        setSaveButtonText({ operation: "", status: "" });
                    }, 2000);
                }
            })
            .catch((err) => {
                setError(err.message);
                resetErrors();
            });
    };

    const editSponsorLogo = () => {
        const _splData = {
            userId: user._id,
            sponsorLogoId,
            url: sponsorLogoWebsiteUrl,
            description: sponsorLogoWebsitedesc,
            sponsorLogo: selectedSponsorLogo
        };
        editSponsorLogoSchema
            .validate(_splData)
            .then(async () => {
                setSaveButtonText({
                    operation: "saveSponsorLogo",
                    status: "Saving..."
                });
                try {
                    const res = await baseProfileSetting.editSponsorLogo(
                        _splData
                    );
                    dispatch({
                        type: actionTypes.UPDATE_BASE_PROFILE_SETTINGS,
                        payload: { baseSponsorLogos: res.data.baseSponsorLogos }
                    });
                    enqueueSnackbar(res?.data?.message, { variant: "success" });
                    setSaveButtonText({
                        operation: "saveSponsorLogo",
                        status: "Saved!"
                    });
                    setTimeout(() => {
                        setSaveButtonText({ operation: "", status: "" });
                    }, 2000);
                } catch (ex) {
                    enqueueSnackbar(ex?.response?.data?.message, {
                        variant: "error"
                    });
                    setSaveButtonText({
                        operation: "saveSponsorLogo",
                        status: "Failed"
                    });
                    setTimeout(() => {
                        setSaveButtonText({ operation: "", status: "" });
                    }, 2000);
                }
            })
            .catch((err) => {
                setError(err.message);
                resetErrors();
            });
    };
    const delteSponsorLogo = () => {
        setLoading(true);

        baseProfileSetting
            .deleteSponsorLogo(sponsorLogoId, user._id)
            .then((res) => {
                // enqueueSnackbar(config.defaultNotiStackMessage, {
                //     variant: "success"
                // });
                // setLoading(false);
                dispatch({
                    type: actionTypes.UPDATE_BASE_PROFILE_SETTINGS,
                    payload: { ...res.data.baseProfileSettings }
                });
                toggleConfirmationDialog();
            })
            .catch((ex) => {
                setLoading(false);
                toggleConfirmationDialog();
                enqueueSnackbar(ex?.response?.data?.message, {
                    variant: "error"
                });
            });
    };

    const toggleConfirmationDialog = () => {
        setOpenConfirmationDialog(!openConfirmationDialog);
    };
    const AvatarAndPreview = () => (
        <div className={classes.sponsorLogoContainer}>
            <Avatar
                variant={
                    sponsorLogoShape === CIRCLE ? "circular" : sponsorLogoShape
                }
                className={clsx(
                    isMobile && classes.previewIcon,
                    !isMobile && classes.previewIconDesktop,
                    classes.avatar,
                    sponsorLogoShape === ROUNDED && classes.roundedAvatar
                )}
                src={
                    selectedSponsorLogo
                        ? selectedSponsorLogo.file
                        : sponsorLogoUrl || baseIcon
                }
            ></Avatar>
            <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                variant={
                    sponsorLogoShape === CIRCLE ? "circular" : sponsorLogoShape
                }
                className={clsx(
                    sponsorLogoShape === ROUNDED && classes.roundedOverlay,
                    sponsorLogoShape === CIRCLE && classes.circleOverlay,

                    isMobile && classes.previewIconOverlay,
                    !isMobile && classes.previewIconOverlayDesktop
                )}
            >
                {/* <Typography align="center"> */}
                <Box component="label" className={classes.changeBtn}>
                    Change
                    <input
                        type="file"
                        style={{
                            display: "none"
                        }}
                        onChange={handlefileInput}
                    />
                </Box>
                {/* </Typography> */}
            </Box>
        </div>
    );
    return (
        <Grid container>
            <ConfirmationDialog
                open={openConfirmationDialog}
                onClose={toggleConfirmationDialog}
                onConfirm={delteSponsorLogo}
                confirmationDialogTitle={"Remove Sponsor logo"}
                confirmationDialogDescription={
                    <Box>
                        {loading && (
                            <Box align="center">
                                <CircularProgress />
                            </Box>
                        )}
                        {!loading && (
                            <Typography>
                                "Are you sure you want to remove this sponsor
                                logo?
                            </Typography>
                        )}
                    </Box>
                }
                confirmationBtnTitle={"Remove"}
                disableButtons={loading}
                disableBackDrop={loading}
            />
            {isMobile && (
                <Grid item md={12} lg={12} sm={12} xs={12}>
                    <form>
                        <Box>
                            {error && (
                                <Fragment>
                                    <Alert severity="error">{error}</Alert>
                                    <br />
                                </Fragment>
                            )}

                            <Box mb={3} display="flex" width="100%">
                                <AvatarAndPreview />

                                <Box p={0} ml={1.5} pt={0.5} width="100%">
                                    <Box pb={1}>
                                        <Box
                                            display="flex"
                                            justifyContent="space-between"
                                        >
                                            <Typography variant="subtitle2">
                                                URL
                                            </Typography>
                                            <Box mt={"-5px"}>
                                                <IconButton
                                                    color="primary"
                                                    component="label"
                                                    size="small"
                                                    onClick={
                                                        handlSaveSponsorLogo
                                                    }
                                                    disabled={
                                                        saveButtonText.operation ===
                                                        "saveSponsorLogo"
                                                    }
                                                >
                                                    {saveButtonText.operation ===
                                                    "saveSponsorLogo" ? (
                                                        <CircularProgress
                                                            size={14}
                                                        />
                                                    ) : (
                                                        <Check fontSize="small" />
                                                    )}
                                                </IconButton>
                                                {operation === "edit" && (
                                                    <IconButton
                                                        size="small"
                                                        color="error"
                                                        component="label"
                                                        onClick={
                                                            toggleConfirmationDialog
                                                        }
                                                    >
                                                        <DeleteForever fontSize="small" />
                                                    </IconButton>
                                                )}
                                            </Box>
                                        </Box>
                                        <TextField
                                            value={sponsorLogoWebsiteUrl}
                                            placeholder="e.g. Straeam"
                                            onChange={(e) => {
                                                setError("");
                                                setSponsorLogoWebsiteUrl(
                                                    e.currentTarget.value
                                                );
                                            }}
                                            fullWidth
                                        />
                                    </Box>
                                    <Box>
                                        <Typography variant="subtitle2">
                                            Label
                                        </Typography>
                                        <TextField
                                            value={sponsorLogoWebsitedesc}
                                            placeholder="e.g. Straeam"
                                            onChange={(e) => {
                                                setError("");
                                                setSponsorLogoWebsitedesc(
                                                    e.currentTarget.value
                                                );
                                            }}
                                            fullWidth
                                        />
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </form>
                </Grid>
            )}
            {!isMobile && (
                <Grid item md={12} lg={12} sm={12} xs={12}>
                    <form>
                        <Box>
                            {error && (
                                <Fragment>
                                    <Alert severity="error">{error}</Alert>
                                    <br />
                                </Fragment>
                            )}
                            <Grid container>
                                <Grid iten md={2} lg={2} sm={3} xs={3}>
                                    <AvatarAndPreview />
                                </Grid>

                                <Grid item md={10} lg={10} sm={7} xs={7}>
                                    <Box pt={0.5}>
                                        <Grid container spacing={1}>
                                            <Grid
                                                item
                                                md={6}
                                                lg={6}
                                                sm={6}
                                                xs={6}
                                            >
                                                <Box>
                                                    <Typography
                                                        variant="subtitle1"
                                                        color="textSecondary"
                                                    >
                                                        URL
                                                    </Typography>
                                                    <TextField
                                                        variant="outlined"
                                                        value={
                                                            sponsorLogoWebsiteUrl
                                                        }
                                                        placeholder="e.g. Straeam"
                                                        onChange={(e) => {
                                                            setError("");
                                                            setSponsorLogoWebsiteUrl(
                                                                e.currentTarget
                                                                    .value
                                                            );
                                                        }}
                                                        fullWidth
                                                    />
                                                </Box>
                                            </Grid>
                                            <Grid
                                                item
                                                md={6}
                                                lg={6}
                                                sm={6}
                                                xs={6}
                                            >
                                                <Box position="relative">
                                                    <Typography
                                                        variant="subtitle1"
                                                        color="textSecondary"
                                                    >
                                                        Label
                                                    </Typography>
                                                    <TextField
                                                        variant="outlined"
                                                        value={
                                                            sponsorLogoWebsitedesc
                                                        }
                                                        placeholder="e.g. Straeam"
                                                        onChange={(e) => {
                                                            setError("");
                                                            setSponsorLogoWebsitedesc(
                                                                e.currentTarget
                                                                    .value
                                                            );
                                                        }}
                                                        fullWidth
                                                    />
                                                    <Box
                                                        mt={"-5px"}
                                                        display="flex"
                                                        position="absolute"
                                                        right={0}
                                                        top={0}
                                                    >
                                                        <IconButton
                                                            color="primary"
                                                            component="label"
                                                            size="small"
                                                            onClick={
                                                                handlSaveSponsorLogo
                                                            }
                                                            disabled={
                                                                saveButtonText.operation ===
                                                                "saveSponsorLogo"
                                                            }
                                                        >
                                                            {saveButtonText.operation ===
                                                            "saveSponsorLogo" ? (
                                                                <CircularProgress
                                                                    size={14}
                                                                />
                                                            ) : (
                                                                <Check fontSize="small" />
                                                            )}
                                                        </IconButton>
                                                        {operation ===
                                                            "edit" && (
                                                            <IconButton
                                                                size="small"
                                                                color="error"
                                                                component="label"
                                                                onClick={
                                                                    toggleConfirmationDialog
                                                                }
                                                            >
                                                                <DeleteForever fontSize="small" />
                                                            </IconButton>
                                                        )}
                                                    </Box>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </form>
                </Grid>
            )}
        </Grid>
    );
}
