import {
    Box,
    Container,
    // Grid,
    makeStyles,
    Paper,
    Typography
} from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";
import PublicAppbar from "../../components/core/PublicAppbar";
import UserAppBar from "../../components/core/UserAppBar/index";
// import useGlobalStyles from "../../hooks/useGlobalStyles";
// import PublicUser from "./PublicUser";

const useStyle = makeStyles((theme) => ({
    fontSize: {
        fontSize: 14,
        fontWeight: 100,
        margin: theme.spacing(1, 0)
    }
}));
export default function Privacy() {
    // const globalClasses = useGlobalStyles();
    const classes = useStyle();
    const user = useSelector((state) => state.auth.user);
    return (
        <div>
            {/* <Grid item md={12} lg={12} sm={12} xs={12}> */}
            {user ? <UserAppBar /> : <PublicAppbar />}
            <Container maxWidth="lg">
                <Box my={4}>
                    <Paper>
                        <Box p={4}>
                            <Typography variant="h4">PRIVACY POLICY</Typography>
                            <Typography
                                variant="subtitle1"
                                className={classes.fontSize}
                                align="left"
                                gutterBottom
                            >
                                Base.gg - Last updated and effective: December
                                12, 2020
                            </Typography>
                            <Typography
                                variant="subtitle1"
                                className={classes.fontSize}
                                align="left"
                                gutterBottom
                            >
                                This Privacy Policy describes Base.gg’s
                                (“Base.gg,” “we,” “us” or “our”) policies and
                                procedures on the collection, use and disclosure
                                of your information in connection with Base.gg
                                services (collectively, the “Service”,
                                “Services”, “Base” or “Base”).
                            </Typography>
                            <Typography
                                variant="subtitle1"
                                className={classes.fontSize}
                                align="left"
                                gutterBottom
                            >
                                When using any of our Services you consent to
                                the collection, transfer, manipulation, storage,
                                disclosure and other uses of your information as
                                described in this Privacy Policy. Irrespective
                                of which country that you reside in or create
                                information from, your information may be used
                                by Base in the United States or any other
                                country where Base operates.
                            </Typography>
                            <Typography
                                variant="subtitle1"
                                className={classes.fontSize}
                                align="left"
                                gutterBottom
                            >
                                If you have any questions or comments about this
                                Privacy Policy, please contact us at
                                team@base.gg (Please add this prefix in the
                                subject "Base Privacy Policy - ")
                            </Typography>
                            <Typography variant="h5">
                                INFORMATION WE COLLECT
                            </Typography>
                            <Typography
                                variant="subtitle1"
                                className={classes.fontSize}
                                align="left"
                                gutterBottom
                            >
                                We collect and use your information to provide
                                our Services and improve them over time.
                            </Typography>
                            <Typography
                                variant="subtitle1"
                                className={classes.fontSize}
                                align="left"
                                gutterBottom
                            >
                                Information Collected Upon Registration: When
                                you create or reconfigure a Base account, you
                                provide some personal information, such as your
                                username and email address. Some of this
                                information, for example, your username, may be
                                listed publicly on our Services, including on
                                your profile page and in search results.
                            </Typography>
                            <Typography
                                variant="subtitle1"
                                className={classes.fontSize}
                                align="left"
                                gutterBottom
                            >
                                Additional Information: You may provide us with
                                additional information to make public, such as a
                                short biography, your location, contact
                                information or a picture. We may use your
                                contact information to send you information
                                about our Services or to market to you. You may
                                unsubscribe from these messages by following the
                                instructions contained within the messages or
                                the instructions on our website. If you email
                                us, we may keep your message, email address and
                                contact information to respond to your request.
                                Providing the additional information described
                                in this section is entirely optional.
                            </Typography>
                            <Typography
                                variant="subtitle1"
                                className={classes.fontSize}
                                align="left"
                                gutterBottom
                            >
                                Location Information: You may choose to note
                                your location in your Base profile.
                            </Typography>
                            <Typography
                                variant="subtitle1"
                                className={classes.fontSize}
                                align="left"
                                gutterBottom
                            >
                                Log Data: Our servers automatically record
                                information (“Log Data”) created by your use of
                                the Services. Log Data may include information
                                such as your IP address, browser type, the
                                referring domain, pages visited, and search
                                terms.{" "}
                            </Typography>
                            <Typography
                                variant="subtitle1"
                                className={classes.fontSize}
                                align="left"
                                gutterBottom
                            >
                                Links: Base may keep track of how you interact
                                with links by redirecting clicks or through
                                other means. We do this to help improve our
                                Services, and to be able to share aggregate
                                click statistics such as how many times a
                                particular link was clicked on.
                            </Typography>
                            <Typography
                                variant="subtitle1"
                                className={classes.fontSize}
                                align="left"
                                gutterBottom
                            >
                                Cookies: Like many websites, we use “cookie”
                                technology to collect additional website usage
                                data and to improve our Services, but we do not
                                require cookies for many parts of our Services
                                such as searching and looking at public user
                                profiles or lists. A cookie is a small data file
                                that is transferred to your computer’s hard
                                disk. Base may use both session cookies and
                                persistent cookies to better understand how you
                                interact with our Services, to monitor aggregate
                                usage by our users and web traffic routing on
                                our Services, and to improve our Services. Most
                                Internet browsers automatically accept cookies.
                                You can instruct your browser, by editing its
                                options, to stop accepting cookies or to prompt
                                you before accepting a cookie from the websites
                                you visit.
                            </Typography>
                            <Typography
                                variant="subtitle1"
                                className={classes.fontSize}
                                align="left"
                                gutterBottom
                            >
                                Metadata: Metadata is usually technical data
                                that is associated with User Content. For
                                example, Metadata can describe how, when and by
                                whom a piece of user content was collected and
                                how that content is formatted. Users can add or
                                may have Metadata added to their user content
                                including bio, location, connections and other
                                Metadata. This makes your user content more
                                searchable by others and more interactive.
                            </Typography>
                            <Typography
                                variant="subtitle1"
                                className={classes.fontSize}
                                align="left"
                                gutterBottom
                            >
                                Third Party Services: Base uses services hosted
                                by third parties to help provide our Services,
                                and to help us understand the use of our
                                Services, such as Google Analytics. These
                                services may collect information sent by your
                                browser as part of a web page request, such as
                                cookies or your IP request.{" "}
                            </Typography>
                            <Typography
                                variant="h5"
                                // className={classes.fontSize}
                                align="left"
                                gutterBottom
                            >
                                WHERE INFORMATION IS PROCESSED
                            </Typography>
                            <Typography
                                variant="subtitle1"
                                className={classes.fontSize}
                                align="left"
                                gutterBottom
                            >
                                Base.gg is based in the United States. No matter
                                where you are located, you consent to the
                                processing and transferring of your information
                                in and to the U.S. and other countries. The laws
                                of the U.S. and other countries governing data
                                collection and use may not be as comprehensive
                                or protective as the laws of the country where
                                you live.
                            </Typography>
                            <Typography
                                variant="h5"
                                // className={classes.fontSize}
                                align="left"
                                gutterBottom
                            >
                                HOW WE USE YOUR INFORMATION
                            </Typography>
                            <Typography
                                variant="subtitle1"
                                className={classes.fontSize}
                                align="left"
                                gutterBottom
                            >
                                In addition to some of the specific uses of
                                information we describe in this Privacy Policy,
                                we may use information that we receive to:
                            </Typography>
                            <ul>
                                <li>
                                    <Typography
                                        variant="subtitle1"
                                        className={classes.fontSize}
                                        align="left"
                                    >
                                        Help you efficiently access your
                                        information after you sign in
                                    </Typography>
                                </li>
                                <li>
                                    <Typography
                                        variant="subtitle1"
                                        className={classes.fontSize}
                                        align="left"
                                    >
                                        Remember information so you will not
                                        have to re-enter it during your visit or
                                        the next time you visit Base
                                    </Typography>
                                </li>
                                <li>
                                    <Typography
                                        variant="subtitle1"
                                        className={classes.fontSize}
                                        align="left"
                                    >
                                        Provide personalized content and
                                        information to you
                                    </Typography>
                                </li>
                                <li>
                                    <Typography
                                        variant="subtitle1"
                                        className={classes.fontSize}
                                        align="left"
                                    >
                                        Provide, improve, test, and monitor the
                                        effectiveness of Base
                                    </Typography>
                                </li>
                                <li>
                                    <Typography
                                        variant="subtitle1"
                                        className={classes.fontSize}
                                        align="left"
                                    >
                                        Develop and test new products and
                                        features
                                    </Typography>
                                </li>
                                <li>
                                    <Typography
                                        variant="subtitle1"
                                        className={classes.fontSize}
                                        align="left"
                                    >
                                        Monitor metrics such as total number of
                                        visitors, traffic, and demographic
                                        patterns
                                    </Typography>
                                </li>
                                <li>
                                    <Typography
                                        variant="subtitle1"
                                        className={classes.fontSize}
                                        align="left"
                                    >
                                        Diagnose or fix technology problems
                                    </Typography>
                                </li>
                            </ul>
                            <Typography variant="h5" align="left">
                                INFORMATION SHARING AND DISCLOSURE
                            </Typography>
                            <Typography
                                variant="subtitle1"
                                className={classes.fontSize}
                                align="left"
                            >
                                We do not disclose your private information
                                except in the limited circumstances described
                                here.
                            </Typography>
                            <Typography
                                variant="subtitle1"
                                className={classes.fontSize}
                                align="left"
                            >
                                Service Providers: We engage certain trusted
                                third parties to perform functions and provide
                                services to us (Google Analytics). We may share
                                your personal information with these third
                                parties, but only to the extent necessary to
                                perform these functions and provide such
                                services, and only pursuant to obligations
                                mirroring the protections of this Privacy
                                Policy.
                            </Typography>
                            <Typography
                                variant="subtitle1"
                                className={classes.fontSize}
                                align="left"
                            >
                                Law and Harm: We may disclose your information
                                if we believe that it is reasonably necessary to
                                comply with a law, regulation or legal request;
                                to protect the safety of any person; to address
                                fraud, security or technical issues; or to
                                protect Base’s rights or property.
                            </Typography>
                            <Typography
                                variant="subtitle1"
                                className={classes.fontSize}
                                align="left"
                            >
                                Business Transfers: In the event that Base is
                                involved in a bankruptcy, merger, acquisition,
                                reorganization or sale of assets, your
                                information may be sold or transferred as part
                                of that transaction. The promises in this
                                privacy policy will apply to your information as
                                transferred to the new entity.
                            </Typography>
                            <Typography
                                variant="subtitle1"
                                className={classes.fontSize}
                                align="left"
                            >
                                Non-Private or Non-Personal Information: We may
                                share or disclose your non-private, aggregated
                                or otherwise non-personal information, such as
                                your public bio or the number of users who
                                clicked on a particular link (even if only one
                                did).
                            </Typography>
                            <Typography variant="h5" align="left">
                                MODIFYING YOUR PERSONAL INFORMATION
                            </Typography>
                            <Typography
                                variant="subtitle1"
                                align="left"
                                className={classes.fontSize}
                            >
                                If you are a registered user of our Services, we
                                provide you with tools to access or modify the
                                personal information you provided to us and
                                associated with your account.
                            </Typography>
                            <Typography variant="h5" align="left">
                                UNSOLICITED INFORMATION
                            </Typography>
                            <Typography
                                variant="subtitle1"
                                align="left"
                                className={classes.fontSize}
                            >
                                You may provide us with ideas for new products
                                or modifications to existing products, and other
                                unsolicited submissions (collectively,
                                “Unsolicited Information”). All Unsolicited
                                Information shall be deemed to be
                                non-confidential and we shall be free to
                                reproduce, use, disclose, and distribute such
                                Unsolicited Information to others without
                                limitation or attribution.
                            </Typography>
                            <Typography variant="h5" align="left">
                                OUR POLICY TOWARDS CHILDREN
                            </Typography>
                            <Typography
                                variant="subtitle1"
                                align="left"
                                className={classes.fontSize}
                            >
                                Our Services are not directed to people under
                                13. If you become aware that your child has
                                provided us with personal information without
                                your consent, please contact us at team@base.gg.
                                We do not knowingly collect personal information
                                from children under 13. If we become aware that
                                a child under 13 has provided us with personal
                                information, we take steps to remove such
                                information and terminate the child’s account.
                            </Typography>
                            <Typography variant="h5" align="left">
                                OTHER WEBSITES AND SERVICES
                            </Typography>
                            <Typography
                                variant="subtitle1"
                                align="left"
                                className={classes.fontSize}
                            >
                                We are not responsible for the practices
                                employed by any websites or services linked to
                                or from Base, including the information or
                                content contained within them. Please remember
                                that when you use a link to go from our Service
                                to another website or service, our Privacy
                                Policy does not apply to those third-party
                                websites or services. Your browsing and
                                interaction on any third-party website or
                                service, including those that have a link on our
                                website, are subject to that third party’s own
                                rules and policies. In addition, you agree that
                                we are not responsible and do not have control
                                over any third-parties that you authorize to
                                access your content. If you are using a
                                third-party website or service and you allow
                                them to access your content you do so at your
                                own risk.{" "}
                            </Typography>
                            <Typography variant="h5" align="left">
                                DATA RETENTION
                            </Typography>
                            <Typography
                                variant="subtitle1"
                                align="left"
                                className={classes.fontSize}
                            >
                                We generally retain personal data for so long as
                                it may be relevant to the purposes identified
                                herein. To dispose of personal data, we delete
                                it or take other appropriate steps. Data may
                                persist in copies made for backup and business
                                continuity purposes for additional time.
                            </Typography>
                            <Typography variant="h5" align="left">
                                SECURITY POLICY
                            </Typography>
                            <Typography
                                variant="subtitle1"
                                align="left"
                                className={classes.fontSize}
                            >
                                We take reasonable steps to protect the
                                information provided via the Service from loss,
                                misuse, and unauthorized access, disclosure,
                                alteration, or destruction. However, no Internet
                                or email transmission is ever fully secure or
                                error free. In particular, email sent to or from
                                the Services may not be secure. Therefore, you
                                should take special care in deciding what
                                information you send to us via email. Please
                                keep this in mind when disclosing any
                                information via the Internet.
                            </Typography>
                            <Typography variant="h5" align="left">
                                DATA ACCESS REQUEST
                            </Typography>
                            <Typography
                                variant="subtitle1"
                                align="left"
                                className={classes.fontSize}
                            >
                                If you would like to submit a data access
                                request, you can do so by emailing us at
                                team@base.gg with the subject “Base Data Access
                                Request - “. We will then start the process and
                                provide you a link to access the personal data
                                that Base has on you within 30 days.
                            </Typography>
                            <Typography variant="h5" align="left">
                                YOUR CALIFORNIA PRIVACY RIGHTS
                            </Typography>
                            <Typography
                                variant="subtitle1"
                                align="left"
                                className={classes.fontSize}
                            >
                                Consumers residing in California are afforded
                                certain additional rights with respect to their
                                personal information under the California
                                Consumer Privacy Act or (“CCPA”) and the “Shine
                                the Light” Law. If you are a California
                                resident, this section applies to you.
                            </Typography>
                            <Typography variant="h6" align="left">
                                California Consumer Privacy Act
                            </Typography>
                            <Typography
                                variant="subtitle1"
                                align="left"
                                className={classes.fontSize}
                            >
                                Our Collection and Use of Personal Information:
                                We collect the following categories of personal
                                information: identifiers (such as your username,
                                the email address you use to sign up, survey
                                question you answer and your bio, avatar, name,
                                if you’ve chosen to provide it); commercial
                                information (a record of what you’ve bought from
                                Base, if anything); financial data (payment
                                information, if you’ve bought anything from
                                Base); internet or other network information
                                (how you interact with the application);
                                location information (because your IP address
                                may indicate your general location); inference
                                data about you; and other information that
                                identifies or can be reasonably associated with
                                you. For examples of the precise data points we
                                collect and the sources of such collection,
                                please see the “INFORMATION WE COLLECT” section
                                above. We collect personal information for the
                                business and commercial purposes described in
                                “HOW WE USE YOUR INFORMATION” above.
                            </Typography>
                            <Typography
                                variant="subtitle1"
                                align="left"
                                className={classes.fontSize}
                            >
                                Disclosure of Personal Information: We may share
                                your personal information with third parties as
                                described in the “INFORMATION SHARING AND
                                DISCLOSURE” section above. We disclose the
                                categories of personal information mentioned
                                above for business or commercial purposes.
                            </Typography>
                            <Typography
                                variant="subtitle1"
                                align="left"
                                className={classes.fontSize}
                            >
                                No Sale of Personal Information: The CCPA sets
                                forth certain obligations for businesses that
                                sell personal information. We do not sell the
                                personal information of our users
                            </Typography>
                            <Typography
                                variant="subtitle1"
                                align="left"
                                className={classes.fontSize}
                            >
                                Exercising Your Consumer Rights: If you are a
                                California resident, you have the right to
                                request (1) more information about the
                                categories and specific pieces of personal
                                information we have collected and disclosed for
                                a business purpose in the last 12 months, (2)
                                deletion of your personal information, and (3)
                                to opt out of sales of your personal
                                information, if applicable. Details on how to
                                make these requests are in the “DATA ACCESS
                                REQUEST” section above. We will not discriminate
                                against you if you exercise your rights under
                                the CCPA.
                            </Typography>
                            <Typography
                                variant="h6"
                                align="left"
                                className={classes.fontSize}
                            >
                                Requests Received: None
                            </Typography>
                            <Typography
                                variant="h5"
                                align="left"
                                // className={classes.fontSize}
                            >
                                CHANGES TO THIS POLICY
                            </Typography>
                            <Typography
                                variant="subtitle1"
                                align="left"
                                className={classes.fontSize}
                            >
                                We may revise this Privacy Policy from time to
                                time. The most current version of the policy
                                will govern our use of your information and will
                                always be at https://base.gg/privacy. If we make
                                a change to this policy that, in our sole
                                discretion, is material, we will notify you via
                                an Base update or e-mail to the email associated
                                with your account. By continuing to access or
                                use the
                            </Typography>
                            <Typography
                                variant="subtitle1"
                                align="left"
                                className={classes.fontSize}
                            >
                                Services after those changes become effective,
                                you agree to be bound by the revised Privacy
                                Policy.
                            </Typography>
                            <Typography
                                variant="h5"
                                align="left"
                                // className={classes.fontSize}
                            >
                                CONTACTING US
                            </Typography>
                            <Typography
                                variant="subtitle1"
                                align="left"
                                className={classes.fontSize}
                            >
                                If there are any questions regarding this
                                privacy policy, you may contact us using the
                                information below.
                            </Typography>
                            <Typography
                                variant="subtitle1"
                                align="left"
                                className={classes.fontSize}
                            >
                                team@base.gg (Please add this prefix in the
                                subject "Base Privacy Policy - ")
                            </Typography>
                        </Box>
                    </Paper>
                </Box>
            </Container>
            {/* </Grid> */}
        </div>
    );
}
