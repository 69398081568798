const config = require("../config");

const BORDER = config.accountStyle.border;

const toBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });

const getAccountColors = (account, accountSetting) => {
    let isCustomAccount = account && account?.accountId?.isCustomAccount;
    const baseAccountBackgroundColor =
        account?.accountId?.accountBackgroundColor;
    const baseAccountTextColor =
        accountSetting?.accountStyle === BORDER
            ? baseAccountBackgroundColor
            : account?.accountId?.accountTextColor;
    let userModifiedBackgroundColor = account?.accountBackgroundColor;
    if (!userModifiedBackgroundColor) {
        userModifiedBackgroundColor =
            account?.accountId?.accountBackgroundColor;
    }
    let userModifiedTextColor =
        accountSetting?.accountStyle === BORDER
            ? userModifiedBackgroundColor || baseAccountBackgroundColor
            : account?.accountTextColor;
    if (!userModifiedTextColor) {
        userModifiedTextColor =
            accountSetting?.accountStyle === BORDER
                ? userModifiedBackgroundColor || baseAccountBackgroundColor
                : account?.accountId?.accountTextColor;
    }

    if (isCustomAccount) {
        return {
            accountTextColor: baseAccountTextColor,
            accountBackgroundColor: baseAccountBackgroundColor
        };
    } else {
        return {
            accountTextColor: userModifiedTextColor,
            accountBackgroundColor: userModifiedBackgroundColor
        };
    }
};

const getAccountText = (account) => {
    let accountText = "";
    const isEntryInCustomAccountText =
        account?.accountId?.isEntryInCustomAccountText;
    // account Name
    const accountName = account?.accountId?.accountName;
    // userIdentifier
    const userIdentifier = account?.userIdentifier;
    const isCustomUserAccount = account?.accountId?.isCustomAccount;
    const userCustomAccountText = account?.customAccountText;

    if (isCustomUserAccount) {
        accountText = userCustomAccountText;
    } else {
        if (isEntryInCustomAccountText) {
            if (userCustomAccountText) {
                accountText = userCustomAccountText;
            } else {
                accountText = accountName;
            }
        } else {
            accountText = `${accountName}: ${userIdentifier}`;
        }
    }
    return accountText;
};

const formatUnixTimestampToLocal = (unix_timestamp) => {
    var date = new Date(unix_timestamp * 1000);
    // // alert(date);
    // // Hours part from the timestamp
    // var hours = date.getHours();
    // // Minutes part from the timestamp
    // var minutes = "0" + date.getMinutes();
    // // Seconds part from the timestamp
    // var seconds = "0" + date.getSeconds();
    // var day = date.getDay();
    // var month = date.getMonth();
    // var year = date.getFullYear();
    // // return `${month}-${day}-${year}  ${hours}:${minutes}`;
    return `${date.toLocaleDateString("en-US", {
        year: "numeric",
        month: "numeric",
        day: "numeric"
    })}
        ${date.toLocaleString("en-US", {
            hour: "numeric",
            minute: "numeric",
            hour12: true
        })}
    `;
};

module.exports = {
    toBase64,
    getAccountColors,
    getAccountText,
    formatUnixTimestampToLocal
};
