/* eslint-disable react-hooks/exhaustive-deps */
import { Box } from "@material-ui/core";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import config from "../../../../config";
import actionTypes from "../../../../redux/actionTypes";
import userService from "../../../../services/user";
import { useSnackbar } from "notistack";
import AccountIconDesignUI from "../../components/AccountIconDesignUI";

export default function EditAccountIcon() {
    const { enqueueSnackbar } = useSnackbar();
    const user = useSelector((state) => state.auth.user);
    const baseAccountSettings = user.baseAccountSettings;
    const dispatch = useDispatch();
    const isManagedDesignActive = user?.managedBy?.isManagedDesignActive;

    const [actionStatus, setActionStatus] = useState({
        saving: false,
        status: false
    });

    const handleShowIcon = () => {
        if (!isManagedDesignActive) {
            setActionStatus((ps) => ({ ...ps, status: true }));
            userService
                .updateAccountSettings({
                    userId: user._id,
                    isAccountIcon: !baseAccountSettings?.isAccountIcon
                })
                .then((res) => {
                    const baseAccountSettings = res.data.baseAccountSettings;
                    setActionStatus((ps) => ({ ...ps, status: false }));
                    dispatch({
                        type: actionTypes.UPDATE_USER,
                        payload: { baseAccountSettings }
                    });
                })
                .catch((ex) => {
                    enqueueSnackbar(ex.responsse.data.message, {
                        variant: "error"
                    });
                    setActionStatus((ps) => ({ ...ps, status: false }));
                });
        }
    };

    const saveAccountIconSetting = ({
        accountIconShape,
        accountIconPosition
    }) => {
        if (!isManagedDesignActive) {
            setActionStatus((ps) => ({ ...ps, saving: true }));
            userService
                .updateAccountSettings({
                    userId: user._id,
                    accountIconShape,
                    accountIconPosition
                })
                .then((res) => {
                    enqueueSnackbar(config.defaultNotiStackMessage, {
                        variant: "success"
                    });
                    setActionStatus((ps) => ({ ...ps, saving: false }));

                    const baseAccountSettings = res.data.baseAccountSettings;
                    dispatch({
                        type: actionTypes.UPDATE_USER,
                        payload: { baseAccountSettings }
                    });
                })
                .catch((ex) => {
                    enqueueSnackbar(ex.responsse.data.message, {
                        variant: "error"
                    });
                    setActionStatus((ps) => ({ ...ps, saving: false }));
                });
        }
    };
    return (
        <Box>
            <AccountIconDesignUI
                disableIconSwitch={actionStatus.status}
                isLoading={actionStatus.saving}
                isAccountIcon={baseAccountSettings?.isAccountIcon}
                accountIconShape={baseAccountSettings?.accountIconShape}
                accountIconPosition={baseAccountSettings?.accountIconPosition}
                onAccountIconsStatusChange={handleShowIcon}
                onAccounIconDesignUpdate={saveAccountIconSetting}
            />
        </Box>
    );
}
