import { Box, InputAdornment, TextField, Typography } from "@material-ui/core";
import { ColorPicker } from "material-ui-color";
import React from "react";

export default function ColorPickerField({
    fieldLabel,
    color,
    onColorPickerValueChange,
    onFieldValueChange,
    enableAlpha,
    variant = "outlined"
}) {
    return (
        <div>
            <Box mb={0}>
                {fieldLabel && (
                    <Box>
                        <Typography variant="subtitle1" color="textSecondary">
                            {fieldLabel}
                        </Typography>
                    </Box>
                )}
                <Box style={{ display: "flex" }}>
                    {/* <div style={{ marginTop: 8, position: "relative" }}>
                        <ColorPicker
                            value={color}
                            hideTextfield
                            disableAlpha={!enableAlpha}
                            onChange={onColorPickerValueChange}
                        />
                        <div className={classes.droperIcon}>
                            <ColorizeIcon className={classes.iconSize} />
                        </div>
                    </div> */}
                    <Box mt={0.5} width={"100%"}>
                        <TextField
                            fullWidth
                            value={color}
                            onChange={onFieldValueChange}
                            inputProps={{
                                maxLength: 9
                            }}
                            variant={variant}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <div
                                            style={{
                                                position: "relative"
                                            }}
                                        >
                                            <ColorPicker
                                                borderWidth={0}
                                                value={color}
                                                hideTextfield
                                                disableAlpha={!enableAlpha}
                                                onChange={
                                                    onColorPickerValueChange
                                                }
                                            />
                                        </div>
                                    </InputAdornment>
                                )
                            }}
                        />
                    </Box>
                </Box>
            </Box>
        </div>
    );
}
