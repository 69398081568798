import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Paper from "@material-ui/core/Paper";
import {
    Box,
    Button,
    CircularProgress,
    Grid,
    InputAdornment,
    LinearProgress,
    TextField,
    Typography
} from "@material-ui/core";
import AdminPageContainer from "../../components/core/AdminPageContainer";
import clsx from "clsx";
import bannedUrls from "../../services/bannedUrls";
// import useGlobalStyles from "../../hooks/useGlobalStyles";
import Alert from "@material-ui/lab/Alert";
import ConfirmationDialog from "../../components/common/ConfirmationDialog";
import config from "../../config";

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === "desc"
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = [
    //   { id: 'name', numeric: false, disablePadding: true, label: 'Dessert (100g serving)' },
    {
        id: "url",
        numeric: false,
        disablePadding: false,
        label: "Url",
        width: 20
    },
    // {
    //   id: "createdAt",
    //   numeric: false,
    //   disablePadding: false,
    //   label: "Created At",
    // },
    {
        id: "action",
        numeric: false,
        disablePadding: false,
        label: "Action"
    }
];

function EnhancedTableHead(props) {
    const { classes, order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        padding={headCell.disablePadding ? "none" : "defalt"}
                        sortDirection={orderBy === headCell.id ? order : false}
                        className={clsx(
                            classes.tableCell,
                            classes.tableHeadCells
                        )}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : "asc"}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === "desc"
                                        ? "sorted descending"
                                        : "sorted ascending"}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%"
    },
    paper: {
        width: "100%",
        padding: theme.spacing(4),
        marginBottom: theme.spacing(2)
    },
    table: {
        minWidth: 750
    },
    tableCell: {
        // border: `1px solid ${theme.palette.primary.main}`,
        border: "none"
    },
    tableHeadCells: {
        // color: theme.palette.primary.main,
        border: "none"
    },
    visuallyHidden: {
        border: 0,
        clip: "rect(0 0 0 0)",
        height: 1,
        margin: -1,
        overflow: "hidden",
        padding: 0,
        position: "absolute",
        top: 20,
        width: 1
    },

    editButton: {
        color: theme.palette.error.light,
        borderColor: theme.palette.error.light
    },
    addBtnContainer: {
        marginTop: 30,
        [theme.breakpoints.down("sm")]: {
            marginTop: 0
        }
    }
}));

export default function BannedUrls(props) {
    const classes = useStyles();
    // const globalClasses = useGlobalStyles();
    const [order, setOrder] = React.useState("asc");
    const [orderBy, setOrderBy] = React.useState("calories");
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    const [bannedUrlList, setBannedUrlList] = useState([]);
    const [baseUrl, setBaseUrl] = useState("");
    const [loading, setLoading] = useState({ status: true, type: "linear" });
    const [error, setError] = useState("");
    const [successMsg, setSuccessMsg] = useState("");
    const [selectedUrlToDelete, setSelectedUrlToDelete] = React.useState("");
    const [openConfirmationDialog, setOpenConfirmationDialog] =
        React.useState(false);

    useEffect(() => {
        bannedUrls
            .getAllBannedUrls()
            .then((res) => {
                setBannedUrlList(res.data.urls);
                setLoading({ status: false });
            })
            .catch((err) => {
                setLoading({ status: false });
                setError(err.message);
            });
    }, []);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const addBannedUrl = () => {
        setLoading({ status: true, type: "circular" });
        bannedUrls
            .addNewBannedUrl(baseUrl)
            .then((res) => {
                setSuccessMsg(config.defaultNotiStackMessage);
                setBannedUrlList([res.data.url, ...bannedUrlList]);
                setLoading({ status: false });
                setBaseUrl("");
                setTimeout(() => setSuccessMsg(""), 5000);
            })
            .catch((ex) => {
                setLoading({ status: false });
                setError(ex?.response?.data?.message);
                setTimeout(() => setError(""), 5000);
            });
    };

    const handleDeleteUrl = (url) => {
        setSelectedUrlToDelete(url);
        setOpenConfirmationDialog(true);
    };

    const deleteUrl = () => {
        setOpenConfirmationDialog(false);
        if (selectedUrlToDelete && selectedUrlToDelete._id) {
            const id = selectedUrlToDelete._id;
            setLoading({ status: true, type: "circular" });
            bannedUrls
                .deleteBannedUrlById(id)
                .then((res) => {
                    setSuccessMsg(config.defaultNotiStackMessage);
                    setLoading({ status: false });
                    const updatedList = bannedUrlList.filter(
                        (url) => url._id !== id
                    );
                    setBannedUrlList(updatedList);
                    setTimeout(() => setSuccessMsg(""), 5000);
                })
                .catch((ex) => {
                    setLoading({ status: false });
                    setError(ex?.response?.data?.message);
                    setTimeout(() => setError(""), 5000);
                });
        }
    };

    const handleClose = () => {
        setSelectedUrlToDelete("");
        setOpenConfirmationDialog(false);
    };
    return (
        <AdminPageContainer {...props}>
            <div className={classes.root}>
                {loading && loading.status && loading.type === "linear" && (
                    <LinearProgress />
                )}
                <Paper className={classes.paper}>
                    {loading && loading.status && loading.type === "circular" && (
                        <Box align="center">
                            <CircularProgress />
                        </Box>
                    )}
                    {error && <Alert severity="error">{error}</Alert>}
                    {successMsg && (
                        <Alert severity="success">{successMsg}</Alert>
                    )}
                    <br />
                    <Typography
                        component="h1"
                        variant="h5"
                        color="primary"
                        gutterBottom
                    >
                        {"Banned base Urls".toLocaleUpperCase()}
                    </Typography>
                    <br />
                    <Grid container spacing={2}>
                        <Grid item md={10} sm={12} xs={12}>
                            <Typography variant="subtitle1">Url</Typography>
                            <TextField
                                required
                                fullWidth
                                value={baseUrl}
                                placeholder="username"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Typography color="primary">
                                                base.gg/
                                            </Typography>
                                        </InputAdornment>
                                    )
                                }}
                                onChange={(e) =>
                                    setBaseUrl(
                                        e.currentTarget.value
                                            .trim()
                                            .toLowerCase()
                                    )
                                }
                            />
                        </Grid>
                        <Grid item md={2} sm={12} xs={12}>
                            <Box className={classes.addBtnContainer}>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    fullWidth
                                    disabled={!baseUrl || loading.status}
                                    onClick={addBannedUrl}
                                >
                                    ADD URL
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                    <br />
                    <br />
                    <TableContainer>
                        <Table
                            className={classes.table}
                            aria-labelledby="tableTitle"
                            aria-label="enhanced table"
                            size="small"
                        >
                            <EnhancedTableHead
                                classes={classes}
                                order={order}
                                orderBy={orderBy}
                                onRequestSort={handleRequestSort}
                                rowCount={bannedUrlList.length}
                            />
                            <TableBody>
                                {stableSort(
                                    bannedUrlList,
                                    getComparator(order, orderBy)
                                )
                                    .slice(
                                        page * rowsPerPage,
                                        page * rowsPerPage + rowsPerPage
                                    )
                                    .map((bannedUrl) => {
                                        return (
                                            <TableRow
                                                tabIndex={-1}
                                                key={bannedUrl.name}
                                            >
                                                <TableCell
                                                    className={
                                                        classes.tableCell
                                                    }
                                                >
                                                    {bannedUrl.url}
                                                </TableCell>
                                                <TableCell
                                                    className={
                                                        classes.tableCell
                                                    }
                                                >
                                                    <Button
                                                        variant={"outlined"}
                                                        onClick={() =>
                                                            handleDeleteUrl(
                                                                bannedUrl
                                                            )
                                                        }
                                                    >
                                                        Remove
                                                    </Button>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={bannedUrlList.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                </Paper>
            </div>
            <ConfirmationDialog
                open={openConfirmationDialog}
                onClose={handleClose}
                onConfirm={deleteUrl}
                confirmationDialogTitle={"Delete Url"}
                confirmationDialogDescription={
                    "Are you sure you want to delete this url?"
                }
                confirmationBtnTitle={"Delete"}
            />
        </AdminPageContainer>
    );
}
