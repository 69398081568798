import React, { useEffect, useState } from "react";
import {
    Avatar,
    Box,
    CircularProgress,
    Container,
    makeStyles,
    Paper
} from "@material-ui/core";
import { useSelector } from "react-redux";
import clsx from "clsx";

import config, { userType } from "../../../config";
import userService from "../../../services/user";
import baseIcon from "../../../res/images/base-icon1.png";
import ConfirmationDialog from "../../common/ConfirmationDialog";
import { useDispatch } from "react-redux";
import actionTypes from "../../../redux/actionTypes";
import { Link } from "react-router-dom";
import useGlobalStyles from "../../../hooks/useGlobalStyles";
import AddIcon from "@material-ui/icons/Add";
// import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    avatarSize: {
        width: theme.spacing(6),
        height: theme.spacing(6),
        borderWidth: 2,
        borderStyle: "solid",
        borderColor: theme.palette.secondary.main,
        // outlineWidth: 1,
        // outlineColor: theme.palette.primary.main,
        // outlineStyle: "solid",
        [theme.breakpoints.down("sm")]: {
            width: theme.spacing(5),
            height: theme.spacing(5)
        }
    },
    avatarOutline: {
        width: theme.spacing(6.5),
        height: theme.spacing(6.5),
        borderWidth: 1,
        borderStyle: "solid",
        borderColor: theme.palette.primary.main,

        // outlineWidth: 1,
        // outlineColor: theme.palette.primary.main,
        // outlineStyle: "solid",
        [theme.breakpoints.down("sm")]: {
            width: theme.spacing(5.5),
            height: theme.spacing(5.5)
        }
    },
    avatarOutlineDotted: {
        width: theme.spacing(6.5),
        height: theme.spacing(6.5),
        borderWidth: 1,
        borderStyle: "solid",
        borderColor: theme.palette.primary.main,
        color: theme.palette.primary.main,

        // outlineWidth: 1,
        // outlineColor: theme.palette.primary.main,
        // outlineStyle: "solid",
        [theme.breakpoints.down("sm")]: {
            width: theme.spacing(5.5),
            height: theme.spacing(5.5)
        }
    },

    roundedAvatar: {
        borderRadius: 10
    },
    circularShape: {
        borderRadius: "50%"
    },

    baseList: {
        display: "flex",
        flexDirection: "row",
        overflowX: "scroll",
        overflowY: "hidden",
        // backgroundColor: "#202020",
        "&::-webkit-scrollbar": {
            height: 0
        }
    }
}));

export default function ManagementList({ toggleManagedUserList }) {
    const classes = useStyles();
    const globalClasses = useGlobalStyles();
    const history = useHistory();

    const dispatch = useDispatch();

    const currentUser = useSelector((state) => state.auth.currentUser);
    const user = useSelector((state) => state.auth.user);
    // const theme = useTheme();
    const managedBases = useSelector((s) => s.auth?.management?.managedBases);
    const isProUser = currentUser.userType === config.userType.pro;

    const [selectedBase, setSelectedBase] = useState("");
    const [isSwitchingBase, setSwitchingBase] = useState(false);

    useEffect(() => {
        // console.log(
        //     "🚀 ~ file: ManagementList.jsx ~ line 94 ~ ManagementList ~ selectedBase",
        //     selectedBase
        // );
    }, [selectedBase]);

    const switchToBase = () => {
        if (user.id !== selectedBase._id) {
            setSwitchingBase(true);
            userService
                .getUserBase(selectedBase._id)
                .then((res) => {
                    const user = res.data.user;
                    // if (user.isSuspendedBase) {
                    //     // window.location.href = config.routes.suspended;
                    //     props.history.push(config.routes.suspended);
                    //     return false;
                    // }
                    if (
                        user.userType === userType.admin ||
                        user.userType === userType.pro
                    ) {
                        return false;
                    }
                    setSwitchingBase(false);
                    setSelectedBase("");

                    dispatch({ type: actionTypes.SET_USER, payload: { user } });
                    toggleManagedUserList();
                    history.push(config.routes.dashboard);
                })
                .catch((ex) => {
                    console.log(ex);
                    setSelectedBase("");
                    toggleManagedUserList();
                });
        }
    };
    return (
        <div>
            {isProUser && (
                <Container maxWidth="md">
                    <Box mt={1}>
                        <Paper>
                            <Box
                                p={2}
                                className={classes.baseList}
                                // maxWidth={300}
                            >
                                {managedBases &&
                                    Object.keys(managedBases).length > 0 &&
                                    Object.keys(managedBases)?.map((key) => {
                                        return managedBases[key]?.map(
                                            (e, idx) => (
                                                <Box
                                                    key={idx}
                                                    mx={1}
                                                    onClick={() =>
                                                        setSelectedBase(
                                                            e.managedUser
                                                        )
                                                    }
                                                >
                                                    <Avatar
                                                        variant={
                                                            currentUser.avatarShape ===
                                                            config.shape.circle
                                                                ? "circular"
                                                                : currentUser.avatarShape
                                                        }
                                                        src={
                                                            e.managedUser
                                                                ?.avatarUrl ||
                                                            baseIcon
                                                        }
                                                        className={clsx(
                                                            classes.avatarOutline,
                                                            classes.avatarSize,
                                                            currentUser.avatarShape ===
                                                                config.shape
                                                                    .rounded &&
                                                                classes.roundedAvatar
                                                        )}
                                                        // style={{
                                                        //     backgroundColor:
                                                        //         "#" +
                                                        //         Math.floor(
                                                        //             Math.random() * 16777215
                                                        //         ).toString(16)
                                                        // }}
                                                    />
                                                </Box>
                                            )
                                        );
                                    })}
                                <Box mx={1}>
                                    <Link
                                        to={config.routes.management}
                                        className={globalClasses.aTag}
                                    >
                                        <Box
                                            display={"flex"}
                                            alignItems="center"
                                            justifyContent={"center"}
                                            fontSize={20}
                                            fontWeight="100"
                                            variant={
                                                currentUser.avatarShape ===
                                                config.shape.circle
                                                    ? "circular"
                                                    : currentUser.avatarShape
                                            }
                                            // src={makeid(Math.random()) || baseIcon}
                                            className={clsx(
                                                classes.avatarOutlineDotted,
                                                classes.avatarSize,
                                                currentUser.avatarShape ===
                                                    config.shape.rounded &&
                                                    classes.roundedAvatar,
                                                currentUser.avatarShape ===
                                                    config.shape.circle &&
                                                    classes.circularShape
                                            )}
                                        >
                                            <AddIcon />
                                        </Box>
                                    </Link>
                                </Box>
                            </Box>
                        </Paper>
                    </Box>
                    <ConfirmationDialog
                        open={selectedBase ? true : false}
                        disableBackDrop={isSwitchingBase}
                        disableButtons={isSwitchingBase}
                        confirmationDialogTitle="Are you sure you?"
                        confirmationDialogDescription={`Are you sure you want to switch to base ${selectedBase?.baseUrl}? `}
                        closeBtnText="Cancel"
                        confirmationBtnTitle={
                            <Box display={"flex"}>
                                {`Switch to ${selectedBase?.baseUrl}`}
                                {isSwitchingBase && (
                                    <Box px={1}>
                                        <CircularProgress size={18} />
                                    </Box>
                                )}
                            </Box>
                        }
                        onClose={() => {
                            setSelectedBase("");
                        }}
                        onConfirm={switchToBase}
                    ></ConfirmationDialog>
                </Container>
            )}
        </div>
    );
}
