// import _ from "lodash";
import actionType from "../actionTypes";

const initialState = {
    accountCategoriesList: [],

    loading: false
};

export default function (state = initialState, action) {
    switch (action.type) {
        case actionType.SET_ACCOUNT_CATEGORIES: {
            return {
                ...state,
                accountCategoriesList: action.payload.accounts
            };
        }
        default: {
            return state;
        }
    }
}
