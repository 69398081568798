import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Paper from "@material-ui/core/Paper";
import {
    Avatar,
    Box,
    Button,
    CircularProgress,
    LinearProgress,
    Typography
} from "@material-ui/core";
import AdminPageContainer from "../../components/core/AdminPageContainer";
import clsx from "clsx";
import user from "../../services/user";
import config from "../../config";
import Alert from "@material-ui/lab/Alert";
// import useGlobalStyles from "../../hooks/useGlobalStyles";
import ConfirmationDialog from "../../components/common/ConfirmationDialog";

// function createData(name, calories, fat, carbs, protein) {
//   return { name, calories, fat, carbs, protein };
// }

// const rows = [
//   createData("Cupcake", 305, 3.7, 67, 4.3),
//   createData("Donut", 452, 25.0, 51, 4.9),
//   createData("Eclair", 262, 16.0, 24, 6.0),
//   createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
//   createData("Gingerbread", 356, 16.0, 49, 3.9),
//   createData("Honeycomb", 408, 3.2, 87, 6.5),
//   createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
//   createData("Jelly Bean", 375, 0.0, 94, 0.0),
//   createData("KitKat", 518, 26.0, 65, 7.0),
//   createData("Lollipop", 392, 0.2, 98, 0.0),
//   createData("Marshmallow", 318, 0, 81, 2.0),
//   createData("Nougat", 360, 19.0, 9, 37.0),
//   createData("Oreo", 437, 18.0, 63, 4.0),
// ];

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === "desc"
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = [
    //   { id: 'name', numeric: false, disablePadding: true, label: 'Dessert (100g serving)' },
    {
        id: "avatarUrl",
        numeric: false,
        disablePadding: false,
        label: "Avatar",
        width: 20
    },
    { id: "name", numeric: false, disablePadding: false, label: "Name" },
    { id: "email", numeric: false, disablePadding: false, label: "Email" },
    { id: "baseUrl", numeric: false, disablePadding: false, label: "Base Url" },
    {
        id: "userType",
        numeric: false,
        disablePadding: false,
        label: "User Type"
    },
    {
        id: "userSurvey",
        numeric: false,
        disablePadding: false,
        label: "User Survey"
    },
    { id: "action", numeric: false, disablePadding: false, label: "Action" }
];

function EnhancedTableHead(props) {
    const { classes, order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        padding={headCell.disablePadding ? "none" : "default"}
                        sortDirection={orderBy === headCell.id ? order : false}
                        className={clsx(
                            classes.tableCell,
                            classes.tableHeadCells
                        )}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : "asc"}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === "desc"
                                        ? "sorted descending"
                                        : "sorted ascending"}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%"
    },
    paper: {
        width: "100%",
        padding: theme.spacing(4),
        marginBottom: theme.spacing(2)
    },
    table: {
        minWidth: 750
    },
    tableCell: {
        border: `1px solid ${theme.palette.primary.main}`
    },
    tableHeadCells: {
        color: theme.palette.primary.main
    },
    visuallyHidden: {
        border: 0,
        clip: "rect(0 0 0 0)",
        height: 1,
        margin: -1,
        overflow: "hidden",
        padding: 0,
        position: "absolute",
        top: 20,
        width: 1
    },

    editButton: {
        color: theme.palette.error.light,
        borderColor: theme.palette.error.light
    }
}));

export default function UsersList(props) {
    const classes = useStyles();
    // const globalClasses = useGlobalStyles();
    const [order, setOrder] = React.useState("asc");
    const [orderBy, setOrderBy] = React.useState("calories");
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(100);

    const [usersList, setUsersList] = useState([]);
    const [loading, setLoading] = useState({ status: true, type: "linear" });
    const [error, setError] = useState("");
    const [successMsg, setSuccessMsg] = useState("");

    const [openConfirmationDialog, setOpenConfirmationDialog] =
        React.useState(false);
    const [openBlockUserDialog, setBlockUserDialog] = React.useState(false);
    const [selectedUserToDelete, setSelectedUserToDelete] = React.useState("");
    const [selectedUserToBlock, setSelectedUserToBlock] = React.useState("");

    useEffect(() => {
        user.getAllUsers()
            .then((res) => {
                setUsersList(res.data.users);
                setLoading({ status: false });
            })
            .catch((err) => {
                setError(err.message);
                setLoading({ status: false });
            });
    }, []);
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleDeleteUser = (user) => {
        setSelectedUserToDelete(user);
        setOpenConfirmationDialog(true);
    };
    const handleBlockUser = (user) => {
        setSelectedUserToBlock(user);
        setBlockUserDialog(true);
    };

    const handleClose = () => {
        setSelectedUserToDelete("");
        setOpenConfirmationDialog(false);
    };

    const deleteUser = () => {
        setOpenConfirmationDialog(false);
        if (selectedUserToDelete && selectedUserToDelete._id) {
            const id = selectedUserToDelete._id;
            setLoading({ status: true, type: "circular" });
            user.deleteUserById(id)
                .then((res) => {
                    setSuccessMsg(config.defaultNotiStackMessage);
                    setLoading({ status: false });
                    const updatedList = usersList.filter(
                        (user) => user._id !== id
                    );
                    setUsersList(updatedList);
                    setTimeout(() => setSuccessMsg(""), 5000);
                })
                .catch((ex) => {
                    setLoading({ status: false });
                    setError(ex?.response?.data?.message);
                    setTimeout(() => setError(""), 5000);
                });
        }
    };
    /*
      NOTE:
      this function will block / Unblock user  
    */
    const handleUserStatus = () => {
        setBlockUserDialog(false);
        if (selectedUserToBlock && selectedUserToBlock._id) {
            const id = selectedUserToBlock._id;
            const suspend = selectedUserToBlock.isSuspendedBase ? false : true;

            setLoading({ suspend: true, type: "circular" });
            const data = {
                userId: id,
                suspend: suspend
            };

            user.manageUserStatusById(data)
                .then((res) => {
                    setSuccessMsg(config.defaultNotiStackMessage);
                    setLoading({ status: false });
                    const updatedList = usersList.map((user) =>
                        user._id === id
                            ? { ...user, isSuspendedBase: suspend }
                            : user
                    );
                    setUsersList(updatedList);
                    setTimeout(() => setSuccessMsg(""), 5000);
                })
                .catch((ex) => {
                    setLoading({ status: false });
                    setError(ex?.response?.data?.message);
                    setTimeout(() => setError(""), 5000);
                });
        }
    };

    return (
        <AdminPageContainer {...props}>
            <div className={classes.root}>
                {loading && loading.status && loading.type === "linear" && (
                    <LinearProgress />
                )}
                <Paper className={classes.paper}>
                    {loading && loading.status && loading.type === "circular" && (
                        <Box align="center">
                            <CircularProgress />
                        </Box>
                    )}
                    {error && <Alert severity="error">{error}</Alert>}
                    {successMsg && (
                        <Alert severity="success">{successMsg}</Alert>
                    )}

                    <br />
                    <Typography
                        component="h1"
                        variant="h4"
                        color="primary"
                        gutterBottom
                    >
                        Users
                    </Typography>
                    <TableContainer>
                        <Table
                            className={classes.table}
                            aria-labelledby="tableTitle"
                            aria-label="enhanced table"
                            size="small"
                        >
                            <EnhancedTableHead
                                classes={classes}
                                order={order}
                                orderBy={orderBy}
                                onRequestSort={handleRequestSort}
                                rowCount={usersList.length}
                            />
                            <TableBody>
                                {stableSort(
                                    usersList,
                                    getComparator(order, orderBy)
                                )
                                    .slice(
                                        page * rowsPerPage,
                                        page * rowsPerPage + rowsPerPage
                                    )
                                    .map((user) => {
                                        return (
                                            <TableRow
                                                tabIndex={-1}
                                                key={user.name}
                                            >
                                                <TableCell
                                                    className={
                                                        classes.tableCell
                                                    }
                                                    width={10}
                                                >
                                                    <Avatar
                                                        src={user.avatarUrl}
                                                    />
                                                </TableCell>
                                                <TableCell
                                                    className={
                                                        classes.tableCell
                                                    }
                                                >
                                                    {user.displayName}
                                                </TableCell>
                                                <TableCell
                                                    className={
                                                        classes.tableCell
                                                    }
                                                >
                                                    {user.email}
                                                </TableCell>
                                                <TableCell
                                                    className={
                                                        classes.tableCell
                                                    }
                                                >
                                                    {user.baseUrl}
                                                </TableCell>
                                                <TableCell
                                                    className={
                                                        classes.tableCell
                                                    }
                                                    width={150}
                                                >
                                                    {user.userType === 2
                                                        ? "FREE"
                                                        : "PRO"}
                                                </TableCell>
                                                <TableCell
                                                    className={
                                                        classes.tableCell
                                                    }
                                                    width={150}
                                                >
                                                    {user.userSurvey}
                                                </TableCell>
                                                <TableCell
                                                    className={
                                                        classes.tableCell
                                                    }
                                                    width={150}
                                                >
                                                    <Box
                                                        style={{
                                                            display: "flex"
                                                        }}
                                                    >
                                                        <Button
                                                            variant="outlined"
                                                            onClick={() =>
                                                                props.history.push(
                                                                    {
                                                                        pathname:
                                                                            config
                                                                                .routes
                                                                                .adminEditUser,
                                                                        state: user
                                                                    }
                                                                )
                                                            }
                                                        >
                                                            Edit
                                                        </Button>
                                                        <Button
                                                            onClick={() =>
                                                                handleDeleteUser(
                                                                    user
                                                                )
                                                            }
                                                        >
                                                            Delete
                                                        </Button>
                                                        <Button
                                                            onClick={() =>
                                                                handleBlockUser(
                                                                    user
                                                                )
                                                            }
                                                        >
                                                            {user.isSuspendedBase
                                                                ? "UnBlock"
                                                                : "Block"}
                                                        </Button>
                                                    </Box>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[100, 150, 200]}
                        component="div"
                        count={usersList.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                </Paper>
                <ConfirmationDialog
                    open={openConfirmationDialog}
                    onClose={handleClose}
                    onConfirm={deleteUser}
                    confirmationDialogTitle={"Delete User"}
                    confirmationDialogDescription={
                        "Are you sure you want to delete this user?"
                    }
                    confirmationBtnTitle={"Delete"}
                />
                <ConfirmationDialog
                    open={openBlockUserDialog}
                    onClose={() => setBlockUserDialog(false)}
                    onConfirm={handleUserStatus}
                    confirmationDialogTitle={"Block User"}
                    confirmationDialogDescription={`Are you sure you want to ${
                        selectedUserToBlock.isSuspendedBase
                            ? "unblock"
                            : "block"
                    } this user?`}
                    confirmationBtnTitle={
                        selectedUserToBlock.isSuspendedBase
                            ? "Unblock"
                            : "Block"
                    }
                />
            </div>
        </AdminPageContainer>
    );
}
