import { Grid, Typography, makeStyles } from "@material-ui/core";
import React from "react";

import Image from "../../../res/images/landingPage/quick-step.png";

const useStyles = makeStyles((theme) => ({
    imagePadding: {
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(0)
        }
    },
    title: {
        fontFamily: "Darker Grotesque",
        fontSize: "3.75rem",
        fontWeight: 800,
        letterSpacing: "0em",
        textAlign: "left",
        color: "#EEEEEE",
        [theme.breakpoints.down("sm")]: {
            fontSize: "2.75rem"
        }
    },
    body: {
        fontFamily: "Darker Grotesque",
        // fontSize: "1.375rem",
        fontWeight: 400,
        letterSpacing: "0em",
        // textAlign: "left",
        color: "#9D9D9D"

        // [theme.breakpoints.down("sm")]: {
        //     fontSize: "1rem"
        // }
    },
    gridContainer: {
        marginTop: theme.spacing(10),
        [theme.breakpoints.down("sm")]: {
            margin: theme.spacing(10, 0)
        },

        // [theme.breakpoints.up("md")]: {
        //     paddingTop: 110
        // },
        [theme.breakpoints.down("sm")]: {
            flexDirection: "column-reverse"
        }
    }
}));

const Setup = () => {
    const classes = useStyles();

    return (
        <Grid container spacing={2} className={classes.gridContainer}>
            <Grid item xs={12} md={6}>
                <div style={{ display: "flex" }}>
                    <img
                        src={Image}
                        alt=""
                        style={{ maxWidth: "100%" }}
                        className={classes.imagePadding}
                    />
                </div>
            </Grid>
            <Grid item xs={12} md={6}>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center"
                    }}
                >
                    <Typography className={classes.title}>
                        Quick Setup
                    </Typography>
                    <Typography variant="h5" className={classes.body}>
                        Base supports most major websites and platforms. No need
                        to memorize URLs, upload icons, configure colors or keep
                        up with changes, it’s taken care of.
                    </Typography>
                </div>
            </Grid>
        </Grid>
    );
};

export default Setup;
