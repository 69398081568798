import React from "react";
import { useDispatch, useSelector } from "react-redux";
import SponsorLogo from "./Components/SponsorLogo";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Box } from "@material-ui/core";
import { DragHandle } from "@material-ui/icons";
import actionTypes from "../../../../redux/actionTypes";
// import authService from "../../../../services/auth";
import userService from "../../../../services/user";

export default function EditSponsorLogo() {
    const user = useSelector((state) => state.auth.user);
    const dispatch = useDispatch();
    const baseSponsorLogos = user?.baseProfileSettings?.baseSponsorLogos;
    const onDragEnd = async (result) => {
        try {
            const { source, destination } = result;

            if (!destination) {
                return false;
            }
            if (
                destination.droppableId === source.droppableId &&
                destination.index === source.index
            ) {
                return false;
            }
            // const previousState = [
            //     ...user.baseProfileSettings.baseSponsorLogos
            // ];
            const updatedState = [...user.baseProfileSettings.baseSponsorLogos];
            const draggedItem = updatedState[source.index];
            updatedState.splice(source.index, 1);
            updatedState.splice(destination.index, 0, draggedItem);

            dispatch({
                type: actionTypes.UPDATE_BASE_PROFILE_SETTINGS,
                payload: {
                    baseSponsorLogos: updatedState
                }
            });
            await userService.updateBaseProfileSettings({
                userId: user._id,
                baseSponsorLogos: updatedState
            });
        } catch (ex) {}
    };
    return (
        <div>
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable
                    droppableId="sponsorLogosdnd"
                    // style={{ padding: 20 }}
                >
                    {(provided, snapshot) => {
                        return (
                            <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                style={{
                                    width: "100%"
                                    // backgroundColor: snapshot.isDraggingOver
                                    //     ? "#67A8D0"
                                    //     : "#141828"
                                }}
                            >
                                {baseSponsorLogos?.map((el, i) => {
                                    return (
                                        <Draggable
                                            key={el._id}
                                            draggableId={el._id}
                                            index={i}
                                        >
                                            {(provided, snapshot) => {
                                                return (
                                                    <div
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        // style={{
                                                        //     width: "100%",
                                                        //     userSelect: "none"
                                                        //     backgroundColor: snapshot.isDragging
                                                        //         ? "#263B4A"
                                                        //         : "#456C86"
                                                        // }}
                                                    >
                                                        <Box
                                                            display="flex"
                                                            p={0}
                                                            width="100%"
                                                        >
                                                            <Box
                                                                display="flex"
                                                                alignItems="center"
                                                                justifyContent="center"
                                                            >
                                                                <DragHandle color="disabled" />
                                                            </Box>
                                                            <Box
                                                                width="100%"
                                                                pb={2}
                                                            >
                                                                <SponsorLogo
                                                                    operation="edit"
                                                                    url={el.url}
                                                                    description={
                                                                        el.description
                                                                    }
                                                                    sponsorLogoUrl={
                                                                        el.sponsorLogoUrl
                                                                    }
                                                                    sponsorLogoId={
                                                                        el._id
                                                                    }
                                                                />
                                                            </Box>
                                                        </Box>
                                                    </div>
                                                );
                                            }}
                                        </Draggable>
                                    );
                                })}
                                {provided.placeholder}
                            </div>
                        );
                    }}
                </Droppable>
            </DragDropContext>
            {/* {baseSponsorLogos.map((el) => {
                return (
                    <SponsorLogo
                        operation="edit"
                        url={el.url}
                        description={el.description}
                        sponsorLogoUrl={el.sponsorLogoUrl}
                        sponsorLogoId={el._id}
                    />
                );
            })} */}
        </div>
    );
}
