import config from "../config";
import axios from "../config/axios";

const addSponsorLogo = async (sponsorLogo) => {
    return axios.post(
        `${config.apiEndPoint}${config.apiRoutes.baseProfileSettings.sponsorLogo}`,
        sponsorLogo
    );
};
const editSponsorLogo = async (sponsorLogo) => {
    return axios.put(
        `${config.apiEndPoint}${config.apiRoutes.baseProfileSettings.sponsorLogo}`,
        sponsorLogo
    );
};
const deleteSponsorLogo = async (sponsorLogoId, userId) => {
    return axios.delete(
        `${config.apiEndPoint}${config.apiRoutes.baseProfileSettings.sponsorLogo}`,
        {
            data: { sponsorLogoId, userId }
        }
    );
};

export default {
    addSponsorLogo,
    editSponsorLogo,
    deleteSponsorLogo
};
