import config from "../config";
import axios from "../config/axios";

const getAllBannedUrls = async () => {
    return axios.get(
        `${config.apiEndPoint}${config.apiRoutes.bannedUrls.default}`
    );
};
const addNewBannedUrl = async (url) => {
    return axios.post(
        `${config.apiEndPoint}${config.apiRoutes.bannedUrls.default}`,
        { url }
    );
};
const deleteBannedUrlById = async (id) => {
    return axios.delete(
        `${config.apiEndPoint}${config.apiRoutes.bannedUrls.default}`,
        { data: { id } }
    );
};

export default {
    getAllBannedUrls,
    addNewBannedUrl,
    deleteBannedUrlById
};
