import React, { useEffect, useState } from "react";

import {
    Typography,
    Grid,
    Box,
    CircularProgress,
    Avatar,
    Button,
    TextField,
    Select,
    MenuItem
} from "@material-ui/core";
// import { ColorPicker } from "material-ui-color";

// import { useParams } from "react-router-dom/cjs/react-router-dom.min";
// import useGlobalStyles from "../../../hooks/useGlobalStyles";
import integrationOps from "../../../services/integrations";
import { toBase64 } from "../../../utils";
import { Alert } from "@material-ui/lab";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
// import config from "../../../config";
// import AdminPageContainer from "../../components/core/AdminPageContainer";
import * as yup from "yup";
import config from "../../../config";

// const CONSTANTS = config.constants;

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%"
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular
    },
    previewIcon: {
        border: `1px dashed ${theme.palette.primary.main}`,
        width: theme.spacing(9),
        height: theme.spacing(9)
        // [theme.breakpoints.down("xs")]: {
        //   width: theme.spacing(8),
        //   height: theme.spacing(8),
        // },
    },
    previewIconOverlay: {
        backgroundColor: "#000",
        opacity: "0.5",
        position: "absolute",
        bottom: 0,
        height: "50%",
        width: theme.spacing(9),
        // [theme.breakpoints.down("xs")]: {
        //   width: theme.spacing(8),
        // },
        borderBottomRightRadius: 4,
        borderBottomLeftRadius: 4
    }
}));

let schema = yup.object().shape({
    integrationId: yup.string().required().min(5).label("integrationId"),
    integrationImage: yup
        .object({
            file: yup
                .string()
                .min(5)
                .notRequired()
                .default(undefined)
                .label("File"),
            fileType: yup
                .string()
                .oneOf(
                    ["image/png", "image/jpg", "image/jpeg"],
                    "File must be an image"
                )
                .notRequired()
                .default(undefined)
                .label("File"),
            fileSize: yup
                .number()
                .max(2000000, "File size must be less or equal to 2MB")
                .notRequired()
                .default(undefined)
                .label("File")
        })
        .notRequired(),
    url: yup.string().required().label("Url"),
    name: yup.string().required().min(5).max(20).label("Name"),
    label: yup.string().required().min(5).max(20).label("Label"),
    accessType: yup
        .string()
        .oneOf(["all", "paid"])
        .required()
        .label("Access Type")
});

export default function EditIntegration({
    integration,
    onSuccess
    // accountCategoryList
}) {
    // const params = useParams();
    const classes = useStyles();
    // const globalClasses = useGlobalStyles();

    // const [account, setAccount] = useState({});
    const [error, setError] = useState("");
    const [successMsg, setSuccessMsg] = useState("");
    const [integrationData, setIntegrationData] = useState({});
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        // const id = params.accountId;
        // accountsOps
        //     .getAccountById(id)
        //     .then((res) => {
        //         setLoading(false);
        //         setAccount(res?.data?.account);
        //         setAccountData(res?.data?.account);
        //     })
        //     .catch((ex) => {
        //         setLoading(false);
        //         setError(ex?.response?.data?.message);
        //     });
        setIntegrationData(integration);
        // eslint-disable-next-line
    }, []);

    const handlefileInput = (e) => {
        e.preventDefault();
        const input = e.currentTarget;
        const file = input.files[0];
        if (file) {
            const fileType = file.type;

            toBase64(file)
                .then((base64File) => {
                    setIntegrationData({
                        ...integrationData,
                        integrationImage: {
                            file: base64File,
                            fileSize: file.size,
                            fileType
                        }
                    });
                })
                .catch((error) => {});
        }
    };

    const handleChange = (e) => {
        var val = e.target.value;
        const fieldName = e.target.name;
        setError("");
        if (fieldName === "url" || fieldName === "name") {
            val = e.currentTarget.value.trim().toLowerCase();
        }
        setIntegrationData({ ...integrationData, [fieldName]: val });
    };

    const submitForm = (e) => {
        e.preventDefault();
        integrationData.integrationId = integration._id;
        // integrationData.accountCategoryId = integrationData.accountCategory.id;

        delete integrationData._id;
        // delete integrationData.accountCategory;

        console.log(integrationData);
        // alert(JSON.stringify(accountData, null, 2));

        schema
            .validate(integrationData)
            .then(async () => {
                try {
                    setLoading(true);
                    const res = await integrationOps.editIntegrationById(
                        integrationData
                    );
                    const integration = res?.data?.integration;
                    setIntegrationData(integration);
                    setSuccessMsg(res?.data?.message);
                    onSuccess(integration);
                    setLoading(false);
                } catch (ex) {
                    setLoading(false);
                    setError(ex?.response?.data?.message);
                }
            })
            .catch((err) => {
                setLoading(false);
                setError(err.message);
            });
        // alert(JSON.stringify(accountData, null, 2));
    };

    return (
        <Grid container>
            <Grid item md={12} lg={12} sm={12} xs={12}>
                <Box bgcolor="background.default" width="100%" padding={2}>
                    <form onSubmit={submitForm}>
                        {loading && (
                            <Box align="center">
                                <CircularProgress size={28} />
                            </Box>
                        )}
                        {error && (
                            <Box mb={1}>
                                <Alert severity="error">{error}</Alert>
                            </Box>
                        )}
                        {successMsg && (
                            <Box mb={1}>
                                <Alert severity="success">{successMsg}</Alert>
                            </Box>
                        )}

                        <Box mb={2}>
                            <Grid
                                container
                                direction="row"
                                justify="left"
                                alignItems="left"
                                spacing={2}
                            >
                                <Grid item lg={1} md={2} sm={3} xs={4}>
                                    <div style={{ position: "relative" }}>
                                        <Avatar
                                            variant={"rounded"}
                                            className={clsx(
                                                classes.previewIcon
                                            )}
                                            src={
                                                integrationData
                                                    ?.integrationImage?.file ||
                                                integrationData.imageUrl
                                            }
                                        ></Avatar>
                                        <Box
                                            className={
                                                classes.previewIconOverlay
                                            }
                                        >
                                            <Typography
                                                align="center"
                                                className={
                                                    classes.changeAvatarText
                                                }
                                            >
                                                <Button
                                                    component="label"
                                                    style={{ fontSize: 8 }}
                                                >
                                                    Change
                                                    <input
                                                        type="file"
                                                        style={{
                                                            display: "none"
                                                        }}
                                                        accept="image/png, image/jpg, image/jpeg"
                                                        onChange={
                                                            handlefileInput
                                                        }
                                                    />
                                                </Button>
                                            </Typography>
                                        </Box>
                                    </div>
                                </Grid>
                                <Grid lg={5} md={4} sm={9} xs={12} item>
                                    <Box width={"100%"} mt={2}>
                                        <Typography variant="subtitle1">
                                            integration
                                        </Typography>
                                        <TextField
                                            required
                                            value={
                                                integrationData?.name ||
                                                integration?.name
                                            }
                                            name="name"
                                            onChange={handleChange}
                                            placeholder="e.g. Straeam"
                                            fullWidth
                                        />
                                    </Box>
                                </Grid>
                                <Grid item lg={6} md={6} sm={12} xs={12}>
                                    <Box width={"100%"} mt={2}>
                                        <Typography variant="subtitle1">
                                            Label
                                        </Typography>
                                        <TextField
                                            name="label"
                                            value={
                                                integrationData?.label ||
                                                integration?.label
                                            }
                                            placeholder="e.g Youtube videos"
                                            onChange={handleChange}
                                            fullWidth
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                            <Grid
                                container
                                direction="row"
                                justify="left"
                                alignItems="left"
                                spacing={2}
                            >
                                <Grid item lg={1} md={2} sm={3} xs={4}></Grid>
                                <Grid lg={5} md={4} sm={9} xs={12} item>
                                    <Box width={"100%"} mt={2}>
                                        <Typography variant="subtitle1">
                                            Access Type
                                        </Typography>
                                        <Select
                                            fullWidth
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={
                                                integrationData?.accessType ||
                                                integration?.accessType
                                            }
                                            onChange={(e) => {
                                                setIntegrationData({
                                                    ...integrationData,
                                                    accessType: e.target.value
                                                });
                                            }}
                                        >
                                            {Object.keys(
                                                config.integrationAccess
                                            ).map((e) => {
                                                return (
                                                    <MenuItem value={e}>
                                                        {e}
                                                    </MenuItem>
                                                );
                                            })}
                                        </Select>
                                    </Box>
                                </Grid>
                                <Grid item lg={6} md={6} sm={12} xs={12}>
                                    <Box width={"100%"} mt={2}>
                                        <Typography variant="subtitle1">
                                            URL
                                        </Typography>
                                        <TextField
                                            required
                                            value={
                                                integrationData?.url ||
                                                integration?.url
                                            }
                                            placeholder="e.g. https://steamcommunity.com/"
                                            name="url"
                                            onChange={handleChange}
                                            fullWidth
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>

                        <Box mt={2}>
                            <Button
                                color="primary"
                                type="submit"
                                variant="contained"
                                fullWidth
                                size="small"
                            >
                                Save
                            </Button>
                        </Box>
                    </form>
                </Box>
            </Grid>
        </Grid>
    );
}
