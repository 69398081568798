export default {
    USER_LOGOUT: "USER_LOGOUT",
    SET_AUTH: "SET_AUTH",
    UPDATE_AUTH: "UPDATE_AUTH",
    SET_CURRENT_USER: "SET_CURRENT_USER",
    UPDATE_CURRENT_USER: "UPDATE_CURRENT_USER",
    UPDATE_CURRENT_USER_MANAGED_BASE_DESIGN:
        "UPDATE_CURRENT_USER_MANAGED_BASE_DESIGN",
    SET_USER: "SET_USER",
    UPDATE_USER: "UPDATE_USER",
    // SET_BASE: "SET_BASE",
    // UPDATE_BASE: "UPDATE_BASE",
    SET_TOKEN: "SET_TOKEN",
    SET_REFRESH_TOKEN: "SET_REFRESH_TOKEN",
    SET_IS_TOKEN_EXPIRED: "SET_IS_TOKEN_EXPIRED",
    SET_ACCOUNT_CATEGORIES: "SET_ACCOUNT_CATEGORIES",
    UPDATE_BASE_PROFILE_SETTINGS: "UPDATE_BASE_PROFILE_SETTINGS",
    SET_SURVEYS: "SET_SURVEYS",
    SET_INTEGRATIONS: "SET_INTEGRATIONS",
    SET_MANAGEMENT: "SET_MANAGEMENT",
    ADD_MANAGEMENT_REQUEST: "ADD_MANAGEMENT_REQUEST",
    ADD_MANAGEMENT_CATEGORY: "ADD_MANAGEMENT_CATEGORY",
    SET_MANAGEMENT_CATEGORY: "SET_MANAGEMENT_CATEGORY",
    REMOVE_MANAGEMENT_REQUEST: "REMOVE_MANAGEMENT_REQUEST",

    SET_MANAGED_BASE: "SET_MANAGED_BASE",
    REMOVE_MANAGED_BASE: "REMOVE_MANAGED_BASE"
};
