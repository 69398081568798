/* eslint-disable react-hooks/exhaustive-deps */
import { Box, makeStyles } from "@material-ui/core";
import React, { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import UserAppBar from "../../../components/core/UserAppBar/index";
import ProfileSection from "./ProfileSection";

const useStyles = makeStyles((theme) => ({
    customBaseBackground: {
        backgroundSize: "cover !important",
        background: ({ backgroundColor, backgroundType, backgroundImage }) =>
            backgroundType === "color"
                ? backgroundColor
                : backgroundType === "image" &&
                  `url(${backgroundImage}) no-repeat`,
        [theme.breakpoints.down("xl")]: {
            height: "fit-content"
        },
        [theme.breakpoints.up("xl")]: {
            height: "100%"
        }
    }
}));

export default function Profile() {
    const user = useSelector((state) => state.auth.user);
    const isManagedDesignActive = user?.managedBy?.isManagedDesignActive;
    const [iframeHeight, setIframeHeight] = useState(window.innerHeight);
    const [iframeWidth, setIframeWidth] = useState(window.innerWidth);

    const [baseProfileSettings, setBaseProfileSettings] = useState(
        user.baseProfileSettings
    );
    const [baseAccountSettings, setBaseAccountSettings] = useState(
        user.baseAccountSettings
    );
    useEffect(() => {
        if (!user?.baseProfileSettings) {
            setBaseProfileSettings(user?.baseSetting);
        }
        if (!user?.baseAccountSettings) {
            setBaseAccountSettings(user?.accountSetting);
        }
        setManaggedBaseDesign();
        window.addEventListener("resize", () => {
            setIframeHeight(window.innerHeight);
            setIframeWidth(window.innerWidth);
        });
    }, []);

    useEffect(() => {
        setManaggedBaseDesign();
    }, [isManagedDesignActive]);

    const setManaggedBaseDesign = () => {
        if (user?.managedBy?._id && isManagedDesignActive) {
            setBaseProfileSettings(
                user.managedBy.managedBaseDesign.baseProfileSettings
            );
            setBaseAccountSettings(
                user.managedBy.managedBaseDesign.baseAccountSettings
            );
        }
    };
    const { textColor, backgroundColor, backgroundType, backgroundImage } =
        baseProfileSettings;

    const classes = useStyles({
        textColor,
        backgroundColor,
        backgroundType,
        backgroundImage,
        iframeHeight,
        iframeWidth
    });

    return (
        <Box className={classes.customBaseBackground} height={"100%"}>
            <UserAppBar />

            {/* <br /> */}
            <ProfileSection
                user={{ ...user, baseAccountSettings, baseProfileSettings }}
                // pb={10}
                extraHeight={true}
            />
        </Box>
    );
}
