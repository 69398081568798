import { Box } from "@material-ui/core";
import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useSnackbar } from "notistack";

import IconShapeSelector from "../../../../components/core/ShapeSelector";
import config from "../../../../config";
// import auth from "../../../../services/auth";
import userService from "../../../../services/user";
import EditDashboardSectionHeader from "../components/editDashboardSectionHeader";
import actionTypes from "../../../../redux/actionTypes";

export default function EditSponsorLogoShape() {
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();

    const user = useSelector((s) => s.auth.user);
    const sponsorLogoShape = user.baseProfileSettings.sponsorLogoShape;

    const [logoShape, setLogoShape] = useState(sponsorLogoShape);
    const [isLoading, setIsLoading] = useState(false);

    // const [saveButtonText, setSaveButtonText] = useState({
    //     operation: "",
    //     status: ""
    // });

    const saveSponsorLogoShape = () => {
        if (logoShape !== sponsorLogoShape) {
            // setSaveButtonText({
            //     operation: "saveLogoShape",
            //     status: "Saving..."
            // });
            setIsLoading(true);
            userService
                .updateBaseProfileSettings({
                    userId: user._id,
                    sponsorLogoShape: logoShape
                })
                .then((res) => {
                    enqueueSnackbar(config.defaultNotiStackMessage, {
                        variant: "success"
                    });
                    // setSaveButtonText({
                    //     operation: "saveLogoShape",
                    //     status: "Saved!"
                    // });

                    const baseProfileSettings = res.data.baseProfileSettings;
                    dispatch({
                        type: actionTypes.UPDATE_USER,
                        payload: { baseProfileSettings }
                    });
                    setIsLoading(false);
                    // setTimeout(() => {
                    //     setSaveButtonText({ operation: "", status: "" });
                    // }, 2000);
                })
                .catch((ex) => {
                    enqueueSnackbar(ex?.response?.data?.message, {
                        variant: "error"
                    });
                    setIsLoading(false);

                    // setSaveButtonText({
                    //     operation: "saveLogoShape",
                    //     status: "Failed"
                    // });

                    // //   setIsAccountIcon(!isAccountIcon);
                    // setTimeout(() => {
                    //     setSaveButtonText({ operation: "", status: "" });
                    // }, 2000);
                });
        }
    };
    return (
        <Box>
            {/* <Box display="flex" flexDirection="row-reverse" mr={0.5}>
                <IconButton
                    color="primary"
                    component="label"
                    size="small"
                    // onClick={onSave}

                    disabled={loading}
                >
                    {loading ? <CircularProgress size={14} /> : <Check />}
                </IconButton>
            </Box> */}
            <EditDashboardSectionHeader
                onSave={saveSponsorLogoShape}
                headerText=""
                isLoading={isLoading}
            />
            {/* <Box mt={-4}> */}
            <IconShapeSelector
                setSelectedAccountShape={setLogoShape}
                selectedAccountShape={logoShape}
                label="Logo Shape"
                // mt={0}
                // mb={0.5}
            />
            {/* </Box> */}
        </Box>
    );
}
