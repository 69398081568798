import React, { useCallback, useEffect, useState } from "react";
import {
    // makeStyles,
    Switch,
    Collapse,
    Typography,
    Box,
    // Paper,
    IconButton
} from "@material-ui/core";
// import Alert from "@material-ui/lab/Alert";
import { useDispatch, useSelector } from "react-redux";
import CustomBadge from "../../../../components/common/CustomBadge";
// import config from "../../../../config";
// import useGlobalStyles from "../../../../hooks/useGlobalStyles";
import actionTypes from "../../../../redux/actionTypes";
import auth from "../../../../services/auth";
import userService from "../../../../services/user";
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";
// import { useSnackbar } from "notistack";
// import BaseBanner from "../editBaseBranding/BaseBanner";
// import EditProfileSectionHeader from "../../editProfile/components/EditProfileSectionHeader";
// import ColorPickerField from "../../../../components/core/ColorPickerField";
// import * as Yup from "yup";
import AddSponsorLogo from "./AddSponorLogo";
import EditSponsorLogo from "./EditSponsorLogo";
import EditSponsorLogoBackground from "./EditSponsorLogoBackground";
// import clsx from "clsx";
import EditSponsorLogoAnimation from "./EditSponsorLogoAnimation";
import LabelWithEmoji from "../../../../components/common/LabelWithEmoji";
import EditSponsorLogoShape from "./EditSponsorLogoShape";

// let schema = Yup.object().shape({
//     baseBannerText: Yup.string()
//         .required()
//         .max(config.constants.bannerTextMaxCharacter)
//         .label("Banner Text"),
//     baseBannerColor: Yup.string().notRequired().label("Banner Color"),
//     baseBannerTextColor: Yup.string().notRequired().label("Banner Text Color")
// });

export default function EditBaseSponsorLogos() {
    // const globalClasses = useGlobalStyles();
    // const classes = useStyles();
    // const { enqueueSnackbar } = useSnackbar();

    const user = useSelector((state) => state.auth.user);
    const isPlusOrProUser = auth.isPlusOrProUser();
    const dispatch = useDispatch();
    const [isAddLogo, setIsAddLogo] = useState(false);
    const [disableSponsorLogoSwitch, setDisableSponsorLogoSwitch] =
        useState(false);
    const [isBaseSponsorLogoActive, setIsBaseSponsorLogoActive] =
        useState(true);
    // const [saveButtonText, setSaveButtonText] = useState({
    //     operation: "",
    //     status: ""
    // });
    // const [error, setError] = useState("");
    const setUserStates = useCallback(() => {
        setIsBaseSponsorLogoActive(
            user.baseProfileSettings.isBaseSponsorLogoActive
        );
    }, [user.baseProfileSettings]);

    const toggleAddAccount = () => {
        setIsAddLogo(!isAddLogo);
    };

    // const setUserStates = () => {
    //     setIsBaseSponsorLogoActive(user.baseProfileSettings.isBaseSponsorLogoActive);
    //     setBaseBannerText(user.baseProfileSettings.baseBannerText);
    //     setBaseBannerColor(user.baseProfileSettings.baseBannerColor);
    //     setBaseBannerTextColor(user.baseProfileSettings.baseBannerTextColor);
    // };
    // useEffect(() => {
    //     setUserStates();
    // }, [setUserStates]);

    useEffect(() => {
        setUserStates();
    }, [user, setUserStates]);

    const handleBaseSponsorLogoStatus = (e) => {
        setDisableSponsorLogoSwitch(true);
        userService
            .updateBaseProfileSettings({
                userId: user._id,
                isBaseSponsorLogoActive: e.target.checked
            })
            .then((res) => {
                const baseProfileSettings = res.data.baseProfileSettings;
                setDisableSponsorLogoSwitch(false);
                dispatch({
                    type: actionTypes.UPDATE_USER,
                    payload: { baseProfileSettings }
                });
            })
            .catch((ex) => {
                setDisableSponsorLogoSwitch(false);
                // setIsBaseSponsorLogoActive(!isBaseSponsorLogoActive);
            });
    };
    // let switchChecked = true
    // let switchChecked = true

    // const resetErrors = () => {
    //     setTimeout(() => {
    //         setError("");
    //     }, 3000);
    // };
    return (
        <Box p={2}>
            <Box display="flex">
                <Box flexGrow={1}>
                    {!isPlusOrProUser ? (
                        <Box display="flex" mt={0.5}>
                            <Typography color="textPrimary" variant="h6">
                                <LabelWithEmoji text="Logos" emoji="📰" />
                            </Typography>
                            <CustomBadge
                                badgeContent={"PLUS"}
                                uppercase={true}
                            ></CustomBadge>
                        </Box>
                    ) : (
                        <Box mt={0.5}>
                            <Typography color="textPrimary" variant="h6">
                                <LabelWithEmoji text="Logos" emoji="📰" />
                            </Typography>
                        </Box>
                    )}
                </Box>
                <Box>
                    {isPlusOrProUser && isBaseSponsorLogoActive && (
                        <IconButton
                            // size="small"

                            color="primary"
                            // style={{ marginTop: -10 }}
                            onClick={toggleAddAccount}
                        >
                            {isAddLogo ? <CloseIcon /> : <AddIcon />}
                        </IconButton>
                    )}
                    <Switch
                        checked={
                            (isPlusOrProUser && isBaseSponsorLogoActive) ||
                            !isPlusOrProUser
                        }
                        onChange={handleBaseSponsorLogoStatus}
                        disabled={!isPlusOrProUser || disableSponsorLogoSwitch}
                        color="primary"
                    />
                </Box>
            </Box>
            <Collapse in={isPlusOrProUser && isAddLogo}>
                <Box pt={2} pb={2} pr={1.5}>
                    <AddSponsorLogo
                        onComplete={() => setIsAddLogo(!isAddLogo)}
                    />
                </Box>
            </Collapse>
            <Collapse in={isPlusOrProUser && isBaseSponsorLogoActive}>
                <Box pr={1.5} pl={0.5}>
                    <EditSponsorLogo />
                </Box>
                <Box>
                    <EditSponsorLogoShape />
                </Box>
                <Box>
                    <EditSponsorLogoAnimation />
                </Box>
                <Box>
                    <EditSponsorLogoBackground />
                </Box>
            </Collapse>
        </Box>
    );
}
