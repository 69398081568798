import { Box, makeStyles, Typography } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
    textColor: {
        color: ({ baseBannerTextColor }) => baseBannerTextColor
    }
}));
export default function ProfileBaseBanner({
    isPlusOrProUser,
    isBaseBannerActive,
    baseBannerText,
    baseBannerColor,
    baseBannerTextColor
}) {
    const classes = useStyles({ baseBannerTextColor });
    return (
        <div>
            {isPlusOrProUser && isBaseBannerActive && (
                <Box bgcolor={baseBannerColor} p={2} textAlign={"center"} >
                    {baseBannerText && (
                        <Typography className={classes.textColor}>
                            {baseBannerText}
                        </Typography>
                    )}
                </Box>
            )}
        </div>
    );
}
