import React from "react";
import utils from "../../utils/index";
import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    Grid,
    Typography,
    makeStyles
} from "@material-ui/core";
import clsx from "clsx";
import config from "../../config";
// import defaultHeaderImg from "../../res/images/defaultHeader.jpg";
import * as Yup from "yup";
import { useState } from "react";
import { Alert } from "@material-ui/lab";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import PanoramaOutlinedIcon from "@material-ui/icons/PanoramaOutlined";
import ImageCroper from "../ImageCroper/Index";

const filetypes = ["image/jpeg", "image/jpg", "image/png"];

const validationSchema = Yup.object({
    fileType: Yup.string().required().oneOf(filetypes),
    fileSize: Yup.number().lessThan(
        config.constants.headerFileSize * 1000000,
        config.validationErrors.fileSizeError +
            config.constants.headerFileSize +
            " MB"
    )
});

const useStyles = makeStyles((theme) => ({
    headerImageContainer: {
        position: "relative",
        "&:hover .deleteBtnOverlay": {
            display: "block"
            // transform: "translate(-50%, -50%)"
            // transform: "scale(1)"
        }
    },
    headerImage: {
        width: "100%",
        borderRadius: 4,
        border: "none",
        height: theme.spacing(20),
        [theme.breakpoints.down("xs")]: {
            width: "100%",
            height: theme.spacing(15)
        }
    },
    avatarOverlay: {
        backgroundColor: "#000",
        opacity: "0.4",
        position: "absolute",
        bottom: "4.5px",
        width: "100%",
        height: "30%",
        [theme.breakpoints.down("xs")]: {
            width: "100%"
            // height: "30%"
        }
    },
    deleteBtnOverlay: {
        display: "none",
        backgroundColor: "#0009",
        // opacity: "0.4",
        position: "absolute",
        top: "0",
        width: "100%",
        height: "100%",
        // height: "70%",
        [theme.breakpoints.down("xs")]: {
            width: "100%"
            // height: "30%"
        }
    },
    changeAvatarBtn: {
        paddingTop: "15px",
        paddingBottom: "12px",
        [theme.breakpoints.down("sm")]: {
            paddingTop: "10px",
            paddingBottom: "5px"
        }
    },
    selectImageContainer: {
        display: "flex",
        width: "100%",
        height: "100%",
        alignItems: "center",
        justifyContent: "center",
        borderWidth: 1,
        borderColor: theme.palette.text.hint,
        borderStyle: "dashed",
        borderSpacing: 10,
        padding: theme.spacing(4)
    }
}));

export default function HeaderImageDropzone({
    // setSelectedFile,
    // selectedFile,
    previewImg,
    onImageChange,
    croppedImage,
    onDelete,
    isDeleting
    // resetCropedFileState
}) {
    const classes = useStyles();

    const [imageToCrop, setImagetoCrop] = useState();
    // const [croppedImage, setCroppedImage] = useState();

    const [validationError, setValidationError] = useState("");

    const resetErrroMessage = () => {
        setTimeout(() => {
            setValidationError("");
        }, 3000);
    };
    const resetFileState = () => {
        setImagetoCrop({});
    };
    const resetCropedFileState = () => {
        // setCroppedImage({});
        onImageChange({});
    };
    const handleFileInput = (e) => {
        e.preventDefault();
        // setError("");
        const input = e.currentTarget;
        const file = input.files[0];
        validationSchema
            .validate({ fileSize: file.size, fileType: file.type })
            .then(() => {
                utils
                    .toBase64(file)
                    .then((base64File) => {
                        setImagetoCrop({
                            file: base64File,
                            fileSize: file.size,
                            fileType: file.type
                        });
                    })
                    .catch((error) => {
                        setValidationError(error.message);
                        resetErrroMessage();
                    });
            })

            .catch((err) => {
                setValidationError(err.message);
                resetErrroMessage();
            });
    };

    const handleDelete = () => {
        console.log(
            "🚀 ~ file: HeaderImageDropzone.jsx:138 ~ handleDelete ~ selectedFile?.file:",
            previewImg
        );
        if (croppedImage) {
            resetCropedFileState();
        } else {
            onDelete();
        }
    };
    return (
        <div>
            <Grid container direction="row">
                <Grid item lg={12} md={12} sm={12} xs={12}>
                    {validationError && (
                        <Alert severity="error">{validationError}</Alert>
                    )}

                    <div className={classes.headerImageContainer}>
                        {previewImg || croppedImage ? (
                            <img
                                className={clsx(classes.headerImage)}
                                src={previewImg || croppedImage}
                                alt="preview"
                            />
                        ) : (
                            <Box className={classes.selectImageContainer}>
                                <Box textAlign={"center"}>
                                    <PanoramaOutlinedIcon
                                        style={{
                                            fontSize: "3rem"
                                        }}
                                    />
                                    <Typography variant="subtitle2">
                                        Drop Your Image Here. or{" "}
                                        <Button
                                            component="label"
                                            color="primary"
                                            style={{
                                                textTransform: "capitalize"
                                            }}
                                        >
                                            Browse
                                            <input
                                                type="file"
                                                style={{ display: "none" }}
                                                onChange={handleFileInput}
                                                accept="image/png, image/jpg, image/jpeg"
                                                onClick={(event) => {
                                                    setValidationError("");
                                                    event.target.value = null;
                                                }}
                                            />
                                        </Button>
                                    </Typography>
                                    <Typography
                                        variant="caption"
                                        color="textSecondary"
                                    >
                                        Supported PNG, JPG and JPEG
                                    </Typography>
                                </Box>
                            </Box>
                        )}
                        <Box
                            className={clsx(
                                classes.deleteBtnOverlay,
                                (previewImg || croppedImage) &&
                                    "deleteBtnOverlay"
                            )}
                        >
                            <Box
                                display={"flex"}
                                width={"100%"}
                                height={"100%"}
                                alignItems="center"
                                justifyContent="center"
                            >
                                <Button
                                    // component="label"
                                    // className={classes.changeAvatarBtn}
                                    // fullWidth
                                    size="small"
                                    variant="contained"
                                    color={"secondary"}
                                    style={{
                                        textTransform: "capitalize",
                                        minWidth: 100
                                    }}
                                    disabled={isDeleting}
                                    startIcon={
                                        isDeleting ? (
                                            <CircularProgress
                                                size={18}
                                                color="inherit"
                                            />
                                        ) : (
                                            <DeleteOutlineIcon />
                                        )
                                    }
                                    onClick={handleDelete}
                                >
                                    {isDeleting ? "deleting..." : "delete"}
                                </Button>
                            </Box>
                        </Box>
                        {/* <Box className={clsx(classes.avatarOverlay)}>
                            <Button
                                component="label"
                                className={classes.changeAvatarBtn}
                                fullWidth
                            >
                                Change Header
                                <input
                                    type="file"
                                    style={{ display: "none" }}
                                    onChange={handleFileInput}
                                    accept="image/png, image/jpg, image/jpeg"
                                    onClick={(event) => {
                                        setValidationError("");
                                        event.target.value = null;
                                    }}
                                />
                            </Button>
                        </Box> */}
                    </div>
                </Grid>
            </Grid>
            <Dialog
                open={imageToCrop && imageToCrop.file ? true : false}
                onClose={resetFileState}
                fullWidth
            >
                <ImageCroper
                    image={imageToCrop}
                    setCroppedImage={onImageChange}
                    resetFileState={resetFileState}
                />
            </Dialog>
        </div>
    );
}
