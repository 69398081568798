import React from "react";
import {
    Grid,
    CircularProgress,
    Box,
    Typography,
    IconButton
} from "@material-ui/core";
import { useDispatch } from "react-redux";
// import clsx from "clsx";

import ConfirmationDialog from "../../../../../../../components/common/ConfirmationDialog";
// import useGlobalStyles from "../../../../../../../hooks/useGlobalStyles";
import actionTypes from "../../../../../../../redux/actionTypes";
import userService from "../../../../../../../services/user";
import { useSnackbar } from "notistack";
import config from "../../../../../../../config";
import DeleteIcon from "@material-ui/icons/DeleteForever";
import { useSelector } from "react-redux";

export default function AccountRemove({ selectedAccountToDelete }) {
    const dispatch = useDispatch();
    // const globalClasses = useGlobalStyles();
    const user = useSelector((state) => state.auth.user);

    const { enqueueSnackbar } = useSnackbar();
    const [openConfirmationDialog, setOpenConfirmationDialog] =
        React.useState(false);

    const [loading, setLoading] = React.useState("");

    const deleteSelectedUserAccount = () => {
        setLoading(true);
        userService
            .deleteUserAccount(selectedAccountToDelete, user._id)
            .then((res) => {
                enqueueSnackbar(config.defaultNotiStackMessage, {
                    variant: "success"
                });
                setLoading(false);
                dispatch({
                    type: actionTypes.SET_USER,
                    payload: { user: res.data.user }
                });
                setOpenConfirmationDialog(false);
            })
            .catch((ex) => {
                enqueueSnackbar(ex?.response?.data?.message, {
                    variant: "error"
                });
                setLoading(false);
            });
    };

    const toggleConfirmationDialog = () => {
        setOpenConfirmationDialog(!openConfirmationDialog);
    };

    return (
        <div>
            <Grid container direction="row-reverse">
                <Grid item>
                    <IconButton
                        onClick={() => toggleConfirmationDialog()}
                        // color="error"
                    >
                        <DeleteIcon />
                    </IconButton>
                </Grid>
            </Grid>
            <ConfirmationDialog
                open={openConfirmationDialog}
                onClose={toggleConfirmationDialog}
                onConfirm={deleteSelectedUserAccount}
                confirmationDialogTitle={"Remove Account"}
                confirmationDialogDescription={
                    <Box>
                        {loading && (
                            <Box align="center">
                                <CircularProgress />
                            </Box>
                        )}
                        {!loading && (
                            <Typography>
                                "Are you sure you want to remove this account?
                            </Typography>
                        )}
                    </Box>
                }
                confirmationBtnTitle={"Remove"}
                disableButtons={loading}
                disableBackDrop={loading}
            />
        </div>
    );
}
