import { Box, Typography, Link, Chip, Paper } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";

import TableRow from "@material-ui/core/TableRow";

import { makeStyles } from "@material-ui/core/styles";

import subscription from "../../../../services/subscription";

import { formatUnixTimestampToLocal } from "../../../../utils";

const useStyles = makeStyles((theme) => ({
    table: {
        width: "100%",
        overflow: "scroll",
        [theme.breakpoints.down("md")]: {
            maxWidth: 200
        }
    },
    tableCell: {
        padding: "5px 10px"
    },
    paidChip: {
        backgroundColor: "#43A047"
    },
    otherChip: {
        backgroundColor: "#970002"
    }
}));

export default function SubscriptionAndInvoices() {
    const classes = useStyles();

    const [invoices, setInvoices] = useState([]);

    useEffect(() => {
        subscription.getCustomerInvoices().then((res) => {
            setInvoices(res.data.invoices);
        });
        return () => {
            setInvoices([]);
        };
    }, []);

    return (
        <Paper className="container">
            <Table
                className={classes.table}
                aria-labelledby="tableTitle"
                aria-label="invoice table"
                size="small"
            >
                <TableBody>
                    <TableRow tabIndex={-1}>
                        <TableCell className={classes.tableCell}>
                            <Box>
                                <Typography>
                                    <b>Amount</b>
                                </Typography>
                            </Box>
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                            <b>Billing reason</b>
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                            <b>Status</b>
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                            <b>Created</b>
                        </TableCell>
                        {/* <TableCell className={classes.tableCell}>
                            <b>Created</b>
                        </TableCell> */}
                    </TableRow>
                    {invoices.map((inv) => {
                        return (
                            <TableRow tabIndex={-1} key={inv.id}>
                                <TableCell className={classes.tableCell}>
                                    <Box>
                                        <Typography>
                                            {`$${
                                                inv.amount_paid / 100
                                            }  ${inv.currency.toUpperCase()}`}
                                        </Typography>
                                    </Box>
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                    {`${inv.billing_reason.split("_")[0]}`}
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                    <Chip
                                        className={
                                            inv.status === "paid"
                                                ? classes.paidChip
                                                : classes.otherChip
                                        }
                                        size="small"
                                        // color="#43A047"
                                        label={inv.status}
                                    />
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                    <Link
                                        href={inv.hosted_invoice_url}
                                        target="_blank"
                                        rel="noopener"
                                    >
                                        {`${formatUnixTimestampToLocal(
                                            inv.created
                                        )}`}
                                    </Link>
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </Paper>
    );
}
