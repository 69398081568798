import React from "react";
import { Box } from "@material-ui/core";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import actionTypes from "../../../../redux/actionTypes";
import auth from "../../../../services/auth";
import userService from "../../../../services/user";
import BaseBrandingUI from "../../components/BaseBrandingUI";
import config from "../../../../config";
import { useSnackbar } from "notistack";

export default function EditBaseBranding() {
    const user = useSelector((state) => state.auth.user);
    const isBaseBranding = user.baseProfileSettings.isBaseBranding;

    const isPlusOrProUser = auth.isPlusOrProUser();
    const dispatch = useDispatch();
    const [disableBaaseBrandingSwitch, setDisableBaseBrandingSwitch] =
        useState(false);

    const { enqueueSnackbar } = useSnackbar();

    const handleBaseBranding = (e) => {
        setDisableBaseBrandingSwitch(true);
        userService
            .updateBaseProfileSettings({
                userId: user._id,
                // isBaseBranding: e.target.checked
                isBaseBranding: !isBaseBranding
            })
            .then((res) => {
                const baseProfileSettings = res.data.baseProfileSettings;
                setDisableBaseBrandingSwitch(false);
                dispatch({
                    type: actionTypes.UPDATE_USER,
                    payload: { baseProfileSettings }
                });
                enqueueSnackbar(config.defaultNotiStackMessage, {
                    variant: "success"
                });
            })
            .catch((ex) => {
                setDisableBaseBrandingSwitch(false);
            });
    };

    return (
        <Box>
            <BaseBrandingUI
                enableFeature={isPlusOrProUser}
                isBaseBranding={isBaseBranding}
                disableBaaseBrandingSwitch={disableBaaseBrandingSwitch}
                handleBaseBranding={handleBaseBranding}
            />
        </Box>
        // <Box display="flex" mt={1}>
        //     <Box flexGrow={1}>
        //         {!isPlusOrProUser ? (
        //             <Box display="flex">
        //                 <Typography color="textPrimary" variant="h6">
        //                     <LabelWithEmoji text="Base Branding" emoji="❌" />
        //                 </Typography>
        //                 <CustomBadge
        //                     badgeContent={"plus"}
        //                     uppercase={true}
        //                 ></CustomBadge>
        //             </Box>
        //         ) : (
        //             <Typography color="textPrimary" variant="h6">
        //                 <LabelWithEmoji text="Base Branding" emoji="❌" />
        //             </Typography>
        //         )}
        //     </Box>
        //     <Box>
        //         <Switch
        //             checked={
        //                 (isPlusOrProUser && isBaseBranding) || !isPlusOrProUser
        //             }
        //             onChange={handleBaseBranding}
        //             disabled={!isPlusOrProUser || disableBaaseBrandingSwitch}
        //             color="primary"
        //         />
        //     </Box>
        // </Box>
    );
}
