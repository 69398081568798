/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { Box } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import actionTypes from "../../../../redux/actionTypes";
import userService from "../../../../services/user";
import { useSnackbar } from "notistack";
import config from "../../../../config";
import TextDesign from "../../components/TextDesignUI";

export default function EditBaseText() {
    const { enqueueSnackbar } = useSnackbar();

    const user = useSelector((state) => state.auth.user);
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);

    const baseProfileSettings = user.baseProfileSettings;
    const isManagedDesignActive = user?.managedBy?.isManagedDesignActive;

    const handleSaveTextDesign = ({ textColor }) => {
        if (!isManagedDesignActive) {
            setIsLoading(true);
            userService
                .updateBaseProfileSettings({
                    userId: user._id,
                    textColor
                })
                .then((res) => {
                    enqueueSnackbar(config.defaultNotiStackMessage, {
                        variant: "success"
                    });

                    setIsLoading(false);

                    const baseProfileSettings = res.data.baseProfileSettings;
                    dispatch({
                        type: actionTypes.UPDATE_USER,
                        payload: { baseProfileSettings }
                    });
                })
                .catch((ex) => {
                    enqueueSnackbar(ex?.response?.data?.message, {
                        variant: "error"
                    });
                    setIsLoading(false);
                });
        }
    };

    return (
        <Box>
            <TextDesign
                textColor={baseProfileSettings?.textColor}
                onTextDesignUpdate={handleSaveTextDesign}
                isLoading={isLoading}
            />
        </Box>
    );
}
