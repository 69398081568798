import config from "../config";
import axios from "../config/axios";

const addAccountCategory = async (ac) => {
    return axios.post(
        `${config.apiEndPoint}${config.apiRoutes.accountCategory.default}`,
        ac
    );
};
const updateAccountCategory = async (ac) => {
    return axios.put(
        `${config.apiEndPoint}${config.apiRoutes.accountCategory.default}`,
        ac
    );
};
const getAccountCategories = async () => {
    return axios.get(
        `${config.apiEndPoint}${config.apiRoutes.accountCategory.default}`
    );
};
const arrangeAc = async (data) => {
    return axios.put(
        `${config.apiEndPoint}${config.apiRoutes.accountCategory.arrangeAc}`,
        data
    );
};
export default {
    addAccountCategory,
    updateAccountCategory,
    getAccountCategories,
    arrangeAc
};
