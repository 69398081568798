/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Container, Paper } from "@material-ui/core";
import React from "react";
// import { useDispatch, useSelector } from "react-redux";
import UserAppBar from "../../../components/core/UserAppBar/index";

// import useGlobalStyles from "../../../hooks/useGlobalStyles";

import auth from "../../../services/auth";

import AddManagedBase from "./AddManagedBase";
import ManagementDesign from "./ManagedDesign";
import Beta from "../../../components/common/Beta";
// import Footer from "../../../components/common/Footer";
// import actionTypes from "../../../redux/actionTypes";
// import ManagementCategories from "./ManagementCategories";

// const CONSTANT = config.constants;

export default function Management(props) {
    // const globalClasses = useGlobalStyles();

    const isCurrentUserPro = auth.isCurrentUserPro();
    // const user = useSelector((state) => state.auth.user);
    // const currentUser = useSelector((s) => s.auth.currentUser);

    // const dispatch = useDispatch();
    // const { enqueueSnackbar } = useSnackbar();

    return (
        <div>
            <UserAppBar />
            <Container maxWidth="md">
                <Box p={2}>
                    <Beta />
                </Box>
                <Box>
                    <Box>
                        <Box mt={2}>
                            <Paper>
                                <Box p={4}>
                                    <AddManagedBase />
                                    {/* <ManagementCategories /> */}
                                </Box>
                            </Paper>
                        </Box>
                        {/* </Collapse> */}
                        {isCurrentUserPro && (
                            <Box mt={2}>
                                <Paper>
                                    <Box p={4}>
                                        <ManagementDesign />
                                    </Box>
                                </Paper>
                            </Box>
                        )}
                    </Box>
                </Box>
                {/* <Box p={2}>
                    <Footer />
                </Box> */}
            </Container>
        </div>
    );
}
