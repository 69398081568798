import {
    Box,
    CircularProgress,
    Collapse,
    Grid,
    // IconButton,
    Switch,
    Typography
} from "@material-ui/core";
import {
    Button
    // Dialog,
    // DialogActions,
    // DialogContent,
    // DialogTitle
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
// import { useHistory } from "react-router";
import ConfirmationDialog from "../../../../components/common/ConfirmationDialog";
import LabelWithEmoji from "../../../../components/common/LabelWithEmoji";
// import config from "../../../../config";
import useGlobalStyles from "../../../../hooks/useGlobalStyles";
import subs from "../../../../services/subscription";
// import PaymentPage from "./PaymentPage";
// import CloseIcon from "@material-ui/icons/Close";
import { useDispatch } from "react-redux";
import actionTypes from "../../../../redux/actionTypes";
import { useSnackbar } from "notistack";
import SubscriptionAndInvoices from "./SubscriptionAndInvoices";
import { formatUnixTimestampToLocal } from "../../../../utils";
// import Carousel from "react-multi-carousel";
import auth from "../../../../services/auth";
import { CheckCircle } from "@material-ui/icons";
import subscription from "../../../../services/subscription";
import { Alert } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
    list: {
        fontFamily: "Arial",
        fontSize: 12,
        textAlign: "left",
        lineHeight: 1.8,
        fontWeight: 500,
        padding: theme.spacing(0, 1),
        wordSpacing: 2,
        listStyleType: "none"
    },
    paymentDetailsContainer: {
        position: "relative",
        backgroundColor: theme.palette.background.secondary,
        textAlign: "center",
        minHeight: 380,

        // maxHeight: 400,
        padding: theme.spacing(2, 1),
        borderRadius: 8,
        color: theme.palette.text.secondary,
        cursor: "pointer",
        // [theme.breakpoints.up("md")]: {
        minWidth: 200,

        // maxHeight: 400,
        // },
        [theme.breakpoints.up("md")]: {
            width: "100%",
            maxWidth: 260
            // maxHeight: 400,
        },
        [theme.breakpoints.down("md")]: {
            width: "100%"
            // maxWidth: 200
            // maxHeight: 400,
        },
        [theme.breakpoints.down("sm")]: {
            width: "100%",
            maxWidth: 300
            // maxHeight: 400,
        }
    },
    currentPaymentDetailsContainer: {
        // backgroundColor: "#212B2C",
        background: "rgba(107, 204, 219, 0.1)",
        borderColor: theme.palette.primary.main,
        border: `1px solid rgba(107, 204, 219, 0.1)`,

        // color: config.colorPicker.defaultBackground,
        cursor: "pointer"
    }
    // label
}));

// const responsive = {
//     desktop: {
//         breakpoint: {
//             max: 3000,
//             min: 1024
//         },
//         items: 3
//     },
//     tablet: {
//         breakpoint: {
//             max: 1024,
//             min: 464
//         },
//         items: 3
//     },
//     mobile: {
//         breakpoint: {
//             max: 464,
//             min: 0
//         },
//         items: 3
//     }
// };
// const stillCarousel = {
//     additionalTransfrom: 0,
//     arrows: true,
//     autoPlaySpeed: 3000,
//     centerMode: false,
//     className: "",
//     containerClass: "container",
//     dotListClass: "",
//     draggable: true,
//     focusOnSelect: false,
//     infinite: true,
//     itemClass: "",
//     keyBoardControl: true,
//     minimumTouchDrag: 80,
//     renderButtonGroupOutside: false,
//     renderDotsOutside: true,
//     responsive: responsive,
//     // showDots
//     sliderClass: "",
//     slidesToSlide: 1,
//     swipeable: true
// };

export default function PaymentDetails() {
    const { enqueueSnackbar } = useSnackbar();

    const globalClasses = useGlobalStyles();
    const classes = useStyles();
    // const history = useHistory();
    const [alert, setAlert] = useState({
        serverity: "",
        message: ""
    });
    const dispatch = useDispatch();
    const [plans, setPlans] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    // const [isContinueToPayment, setIsContinueToPayment] = useState(false);
    const [isUpdatingSubscription, setIsUpdatingSubscription] = useState(false);
    const [showUpAndDowngradeDialog, setUpAndDowngradeDialog] = useState({
        show: false,
        action: ""
    });
    const [selectedPlan, setSelectedPlan] = useState({});
    const [isCancelSubscription, setisCancelSubscription] = useState(false);
    const [isResumeSubscription, setisResumeSuscription] = useState(false);
    const [isCancelingSubscription, setisCancelingSubscription] =
        useState(false);
    const [isResumingSubscription, setisResumingSubscription] = useState(false);
    // const [isDisablePaymentDialog, setIsDisablePaymentDialog] = useState(false);
    const [showSubsAndInvoice, handleShowSubsAndInvoice] = useState(false);

    // const [verifying, setVerifying] = useState(false);

    const currentUser = useSelector((state) => state.auth.currentUser);
    const currentSubscription = currentUser?.subscriptions?.data[0];
    const isSubCanceled = currentSubscription?.cancel_at_period_end;
    const isCurrentUserPlus = auth.isCurrentUserPlus();
    const isCurrentUserPro = auth.isCurrentUserPro();
    const [sessionId, setSessionId] = useState(null);
    // const redirectToSubscription = () => {
    //     if (!isCurrentUserPlus) {
    //         history.push(config.routes.subscription);
    //     }
    // };
    useEffect(() => {
        // Check to see if this is a redirect back from Checkout
        const query = new URLSearchParams(window.location.search);

        if (query.get("success")) {
            setSessionId(query.get("session_id"));

            handleAlert("success", "");
        }

        if (query.get("canceled")) {
            handleAlert("info", query.get("message"));
        }
    }, [sessionId]);

    useEffect(() => {
        subs.getSubscriptionPlans().then((res) => {
            const sortedPlans = res.data.plans.sort((a, b) => {
                // sort by price
                return a?.unit_amount - b?.unit_amount;
            });
            // const filteredPlans = sortedPlans.filter((plan) => {
            //     return plan.product.name !== "Base - Pro";
            // });

            setPlans(sortedPlans);
            setIsLoading(false);
        });
    }, [currentUser]);

    const handlePayment = (isCurrentPlan, plan) => {
        debugger;
        if (!isCurrentPlan && plan.product.name !== "Base - Free") {
            // setIsContinueToPayment(true);
            setSelectedPlan(plan);

            if (isCurrentUserPlus && plan.product.name === "Base - Pro") {
                setUpAndDowngradeDialog({ show: true, action: "upgrade" });
            } else if (
                isCurrentUserPro &&
                plan.product.name === "Base - Plus"
            ) {
                setUpAndDowngradeDialog({ show: true, action: "downgrade" });
            } else {
                subscription
                    .subscribeUser({ price_id: plan.id })
                    .then((res) => {
                        window.location.href = res.data.sessionUrl;
                        // const user = res?.data?.user;
                        // dispatch({
                        //     type: actionTypes.UPDATE_CURRENT_USER,
                        //     payload: user
                        // });
                        // enqueueSnackbar(res?.data?.message, {
                        //     variant: "success"
                        // });
                        // setIsContinueToPayment(false);
                    })
                    .catch((err) => {
                        // setIsContinueToPayment(false);
                        enqueueSnackbar(err?.response?.data?.message, {
                            variant: "error"
                        });
                    });
            }
        } else if (!isCurrentPlan) {
            toggleCancelSubscriptionDialog();
        }
    };

    const handleUpdateSubscription = async () => {
        setIsUpdatingSubscription(true);

        try {
            const res = await subs.updateSubscription({
                planId: selectedPlan.id,
                action: showUpAndDowngradeDialog.action
            });
            const user = res?.data?.user;

            dispatch({
                type: actionTypes.UPDATE_CURRENT_USER,
                payload: { ...user }
            });

            enqueueSnackbar(res?.data?.message, {
                variant: "success"
            });

            setIsUpdatingSubscription(false);
            setUpAndDowngradeDialog({ show: false, action: "" });
        } catch (ex) {
            enqueueSnackbar(ex?.response?.data?.message, {
                variant: "error"
            });
            setIsUpdatingSubscription(false);
            setUpAndDowngradeDialog({ show: false, action: "" });
        }
    };
    // const handleClose = (event, reason) => {
    //     if (reason !== "backdropClick" || reason !== "escapeKeyDown") {
    //         setIsContinueToPayment(false);
    //     }
    // };
    const toggleCancelSubscriptionDialog = () => {
        setisCancelSubscription(!isCancelSubscription);
    };
    const toggleResumeSubscriptionDialog = () => {
        setisResumeSuscription(!isResumeSubscription);
    };

    const handleCancelSubscription = async () => {
        setisCancelingSubscription(true);
        try {
            const res = await subs.cancelSubscription();
            const user = res?.data?.user;

            dispatch({
                type: actionTypes.UPDATE_CURRENT_USER,
                payload: user
            });
            enqueueSnackbar(res?.data?.message, {
                variant: "success"
            });
            setisCancelingSubscription(false);
            toggleCancelSubscriptionDialog();
        } catch (ex) {
            setisCancelingSubscription(false);
            toggleCancelSubscriptionDialog();
            enqueueSnackbar(ex?.response?.data?.message, {
                variant: "error"
            });
        }
    };

    const handleResumeSubscription = async () => {
        setisResumingSubscription(true);
        try {
            const res = await subs.resumeSubscription();
            const subscriptions = res?.data?.subscriptions;

            dispatch({
                type: actionTypes.UPDATE_CURRENT_USER,
                payload: { subscriptions }
            });
            enqueueSnackbar(res?.data?.message, {
                variant: "success"
            });
            setisResumingSubscription(false);
            toggleResumeSubscriptionDialog();
        } catch (ex) {
            setisResumingSubscription(false);
            toggleResumeSubscriptionDialog();
            enqueueSnackbar(ex?.response?.data?.message, {
                variant: "error"
            });
        }
    };

    // const handleVerifySubscription = () => {
    //     setVerifying(true);
    //     subs.verifySubscription()
    //         .then((resp) => {
    //             if (resp?.data?.status) {
    //                 if (resp?.data?.user) {
    //                     dispatch({
    //                         type: actionTypes.UPDATE_CURRENT_USER,
    //                         payload: resp.data.user
    //                     });
    //                     enqueueSnackbar("You are successfully subscribed", {
    //                         variant: "success"
    //                     });
    //                 }
    //             }
    //             setVerifying(false);
    //         })
    //         .catch((ex) => {
    //             enqueueSnackbar(config.contactSupport, {
    //                 variant: "error"
    //             });
    //             setVerifying(false);
    //         });
    // };
    // const getSelectedAnimation = () => {
    //     let selectedAnimation = stillCarousel;

    //     return selectedAnimation;
    // };
    const handleAlert = (severity, message) => {
        setAlert({
            message: message,
            severity: severity
        });
    };

    return (
        <div>
            <Box>
                <Box display="flex">
                    <Typography color="textPrimary" variant="h6">
                        <LabelWithEmoji text="Plan Details" emoji="📝" />
                    </Typography>
                </Box>

                <Box mt={1}>
                    {alert.message && (
                        <Alert severity={alert.severity}>
                            {alert.message}{" "}
                        </Alert>
                    )}

                    <Typography color="textPrimary" variant="subtitle1">
                        {`Your current plan is ${
                            isCurrentUserPro
                                ? "Pro"
                                : isCurrentUserPlus
                                ? "Plus"
                                : "Free"
                        } ${
                            currentSubscription?.id
                                ? `and it will be ended on ${formatUnixTimestampToLocal(
                                      currentSubscription?.current_period_end
                                  )}`
                                : ""
                        }`}
                    </Typography>
                    {isSubCanceled && (
                        <Typography color="textPrimary" variant="subtitle1">
                            {`You have cancelled subscription on  ${formatUnixTimestampToLocal(
                                currentSubscription.canceled_at
                            )}`}
                        </Typography>
                    )}
                </Box>
                {isLoading && (
                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <CircularProgress size={20} />
                    </Box>
                )}
                <Collapse in={!isLoading}>
                    <Box pr={1} pt={2}>
                        <Grid container spacing={2}>
                            {/* <Carousel {...getSelectedAnimation()}> */}
                            {plans.map((el) => {
                                let desc = el?.product?.description?.split("*");
                                var isCurrentPlan = false;
                                if (
                                    el.product.name === "Base - Plus" &&
                                    isCurrentUserPlus
                                ) {
                                    isCurrentPlan = true;
                                } else if (
                                    el.product.name === "Base - Free" &&
                                    !isCurrentUserPlus &&
                                    !isCurrentUserPro
                                ) {
                                    isCurrentPlan = true;
                                } else if (
                                    el.product.name === "Base - Pro" &&
                                    isCurrentUserPro
                                ) {
                                    isCurrentPlan = true;
                                }

                                return (
                                    <Grid
                                        item
                                        md={plans?.length < 3 ? 6 : 4}
                                        sm={plans?.length < 3 ? 6 : 4}
                                        // sm={4}

                                        xs={12}
                                        // maxWidth={200}
                                        // mx={1}
                                        key={el.id}
                                        onClick={() =>
                                            handlePayment(isCurrentPlan, el)
                                        }
                                    >
                                        <Box
                                            display={"flex"}
                                            justifyContent={"center"}
                                            alignItems={"center"}
                                        >
                                            <Box
                                                // maxWidth={200}

                                                // onClick={redirectToSubscription}
                                                className={clsx(
                                                    classes.paymentDetailsContainer,
                                                    isCurrentPlan &&
                                                        classes.currentPaymentDetailsContainer
                                                )}
                                            >
                                                <Box textAlign={"center"}>
                                                    <Typography variant="h6">
                                                        <b>
                                                            {
                                                                el.product.name.split(
                                                                    "-"
                                                                )[1]
                                                            }
                                                        </b>
                                                    </Typography>
                                                    <Typography variant="h3">
                                                        {/* <b> */}
                                                        {`$${
                                                            el.unit_amount === 0
                                                                ? 0
                                                                : el.unit_amount /
                                                                  100
                                                        }`}
                                                        {/* </b> */}
                                                    </Typography>
                                                    <Typography variant="subtitle">
                                                        {`Monthly`}
                                                    </Typography>
                                                </Box>
                                                <Box
                                                    display={"flex"}
                                                    alignItems={"center"}
                                                    justifyContent={"center"}
                                                >
                                                    <ul
                                                        className={clsx(
                                                            classes.list
                                                        )}
                                                    >
                                                        {desc?.map((d) => (
                                                            <li id={d} key={d}>
                                                                <Box
                                                                    display={
                                                                        "flex"
                                                                    }
                                                                >
                                                                    <CheckCircle
                                                                        color="primary"
                                                                        style={{
                                                                            fontSize: 12,
                                                                            marginRight: 2,
                                                                            marginTop: 2
                                                                        }}
                                                                    />

                                                                    {d}
                                                                </Box>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </Box>
                                                <Box
                                                    width={"100%"}
                                                    padding={1}
                                                    position={"absolute"}
                                                    left={0}
                                                    bottom={0}
                                                >
                                                    <Button
                                                        fullWidth
                                                        variant="contained"
                                                        color="primary"
                                                        size="small"
                                                        startIcon={
                                                            <CheckCircle
                                                                color="textPrimary"
                                                                fontSize="small"
                                                            />
                                                        }
                                                    >
                                                        Select Plan
                                                    </Button>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Grid>
                                );
                            })}
                            {/* </Carousel> */}
                        </Grid>
                        {(isCurrentUserPlus || isCurrentUserPro) && (
                            <Box pt={2}>
                                <Box display="flex" width={"100%"}>
                                    <Box flexGrow={1}>
                                        <Typography variant="subtitle1">
                                            Subscription & invoices
                                        </Typography>
                                    </Box>
                                    <Box>
                                        <Switch
                                            checked={showSubsAndInvoice}
                                            onChange={() =>
                                                handleShowSubsAndInvoice(
                                                    !showSubsAndInvoice
                                                )
                                            }
                                            color="primary"
                                        />
                                    </Box>
                                </Box>
                            </Box>
                        )}
                        <Collapse in={showSubsAndInvoice}>
                            {/* <Box>fsdfgsf</Box> */}
                            {<SubscriptionAndInvoices />}
                        </Collapse>
                    </Box>
                    {currentUser &&
                        currentUser.subscriptions?.data?.length > 0 &&
                        !isSubCanceled > 0 && (
                            <Box p={1}>
                                <Button
                                    className={clsx(
                                        globalClasses.textTransformNone
                                    )}
                                    onClick={toggleCancelSubscriptionDialog}
                                >
                                    Cancel Subscription{" "}
                                </Button>
                            </Box>
                        )}
                    {currentUser && isCurrentUserPlus && isSubCanceled > 0 && (
                        <Box p={1}>
                            <Button
                                className={clsx(
                                    globalClasses.textTransformNone
                                )}
                                onClick={toggleResumeSubscriptionDialog}
                            >
                                Resume Subscription{" "}
                            </Button>
                        </Box>
                    )}
                    {/* {currentUser &&
                        !isCurrentUserPlus &&
                        currentSubscription?.status === "active" &&
                        currentSubscription?.plan?.amount === 399 && (
                            <Box p={1}>
                                <Button
                                    className={clsx(
                                        globalClasses.textTransformNone
                                    )}
                                    onClick={handleVerifySubscription}
                                    disabled={verifying}
                                >
                                    {verifying
                                        ? "Please wait..."
                                        : "Subscribed, but don't have features?"}
                                </Button>
                            </Box>
                        )} */}
                    {/* <Dialog
                        open={isContinueToPayment}
                        onClose={handleClose}
                        fullWidth
                        maxWidth="xs"
                        BackdropProps={{
                            style: { background: "rgba(0, 0, 0, 0.70)" }
                        }}
                    >
                        <Box display={"flex"} width={"100%"}>
                            <Box flexGrow={2}>
                                <DialogTitle>{`${selectedPlan?.product?.name}`}</DialogTitle>
                            </Box>
                            <Box p={1}>
                                <IconButton
                                    onClick={handleClose}
                                    disabled={isDisablePaymentDialog}
                                >
                                    <CloseIcon size="small" />
                                </IconButton>
                            </Box>
                        </Box>
                        <DialogContent>
                            <Typography>{`You will be charged $${
                                selectedPlan.unit_amount / 100
                            } monthly`}</Typography>

                            <PaymentPage
                                selectedPlan={selectedPlan}
                                setIsDisablePaymentDialog={
                                    setIsDisablePaymentDialog
                                }
                                closePaymentDialog={handleClose}
                            />
                        </DialogContent>
                        <DialogActions></DialogActions>
                    </Dialog> */}
                    <ConfirmationDialog
                        open={isCancelSubscription}
                        onClose={toggleCancelSubscriptionDialog}
                        closeBtnText={"No"}
                        onConfirm={handleCancelSubscription}
                        confirmationDialogTitle={"Cancel Subscription"}
                        disableButtons={isCancelingSubscription}
                        confirmationDialogDescription={
                            <Box>
                                {isCancelingSubscription && (
                                    <Box align="center">
                                        <CircularProgress />
                                    </Box>
                                )}
                                {!isCancelingSubscription && (
                                    <Typography>
                                        Are you sure you want to cancel
                                        subscription?
                                    </Typography>
                                )}
                            </Box>
                        }
                        confirmationBtnTitle={"Yes"}
                        disableBackDrop={true}
                    />
                    <ConfirmationDialog
                        open={isResumeSubscription}
                        onClose={toggleResumeSubscriptionDialog}
                        closeBtnText={"No"}
                        onConfirm={handleResumeSubscription}
                        confirmationDialogTitle={"Resume Subscription"}
                        disableButtons={isResumingSubscription}
                        confirmationDialogDescription={
                            <Box>
                                {isResumingSubscription && (
                                    <Box align="center">
                                        <CircularProgress />
                                    </Box>
                                )}
                                {!isResumingSubscription && (
                                    <Typography>
                                        Are you sure you want to resume
                                        subscription?
                                    </Typography>
                                )}
                            </Box>
                        }
                        confirmationBtnTitle={"Yes"}
                        disableBackDrop={true}
                    />
                    <ConfirmationDialog
                        open={showUpAndDowngradeDialog.show}
                        onClose={() => {
                            setUpAndDowngradeDialog({
                                show: false,
                                selectedPlan: null
                            });
                        }}
                        closeBtnText={"Cancel"}
                        onConfirm={handleUpdateSubscription}
                        confirmationDialogTitle={showUpAndDowngradeDialog.title}
                        disableButtons={isUpdatingSubscription}
                        confirmationDialogDescription={
                            <Box>
                                {isUpdatingSubscription && (
                                    <Box align="center">
                                        <CircularProgress />
                                    </Box>
                                )}
                                {!isUpdatingSubscription && (
                                    <Typography>
                                        {` Are you sure you want to ${showUpAndDowngradeDialog.action} to ${selectedPlan?.product?.name}
                                        plan?`}
                                    </Typography>
                                )}
                            </Box>
                        }
                        confirmationBtnTitle={showUpAndDowngradeDialog.action}
                        disableBackDrop={true}
                    />
                </Collapse>
            </Box>
        </div>
    );
}
