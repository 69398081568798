import {
    Box,
    // Button,
    Collapse,
    IconButton,
    // makeStyles,
    Tab,
    Tabs,
    Typography
} from "@material-ui/core";
import React, { useState } from "react";
// import DragHandleIcon from "@material-ui/icons/DragHandle";
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";
import useGlobalStyles from "../../../../../hooks/useGlobalStyles";
import baseIcon from "../../../../../res/images/base-icon1.png";
import AddCustomUserAccount from "./addCustomUserAccount.js/AddCustomUserAccount";
// import clsx from "clsx";
import AddUserAccount from "./AccountCategoryList/addUserAccount/AddUserAccount";
import UserAccountList from "../../accounts/userAccountList/UserAccountList";
import AccountCategoryList from "./AccountCategoryList/AccountCategoryList";
// import AccordionCustomHeader from "../../../../components/core/AccordionCustomHeader";

// import config from "../../../../../config";
import LabelWithEmoji from "../../../../../components/common/LabelWithEmoji";
import AccountHeader from "../components/AccountHeader";
// import { Button } from "react-scroll";

// const useStyles = makeStyles((theme) => ({
//     toggleMargin: {
//         marginTop: theme.spacing(2)
//     },
//     absoluteSwitch: {
//         top: theme.spacing(3.2),
//         right: theme.spacing(2),
//         zIndex: 10
//     },
//     tabBtn: {
//         backgroundColor: theme.palette.background.paper,
//         "&:hover": {
//             backgroundColor: theme.palette.background.paper
//         }
//     }
// }));

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`wrapped-tabpanel-${index}`}
            aria-labelledby={`wrapped-tab-${index}`}
            {...other}
        >
            {value === index && <div>{children}</div>}
        </div>
    );
}
function a11yProps(index) {
    return {
        id: `wrapped-tab-${index}`,
        "aria-controls": `wrapped-tabpanel-${index}`
    };
}

export default function AddAccount() {
    // const classes = useStyles();
    const globalClasses = useGlobalStyles();
    const [selectedAccount, setSelectedAccount] = React.useState({});
    const [toggleAddAccount, setToggleAddAccount] = useState(false);
    // const user = useSelector((state) => state.auth.user);
    // const baseAccountSettings = user.baseAccountSettings;
    // const [accountList, setAccountList] = useState([]);
    const [tabValue, setTabValue] = React.useState("one");

    const handleAddAccountToggle = () => {
        setToggleAddAccount(!toggleAddAccount);
        resetSelectedAccount();
    };

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };
    const resetSelectedAccount = () => {
        setSelectedAccount({});
    };
    const closeAddAccount = () => {
        resetSelectedAccount();
        handleAddAccountToggle();
    };

    return (
        <div>
            <Box>
                <Box display="flex" mb={2}>
                    <Box flexGrow={1} alignItems="center">
                        <Typography color="textPrimary" variant="h6">
                            <LabelWithEmoji text="Accounts" emoji="🗺️" />
                        </Typography>
                    </Box>
                    <Box>
                        {/* <Button
                            // size="small"
                            variant="contained"
                            color="primary"
                            onClick={handleAddAccountToggle}
                            startIcon={
                                toggleAddAccount ? <CloseIcon /> : <AddIcon />
                            }
                        >
                            {toggleAddAccount ? "Close" : "Add"}
                        </Button> */}
                        <IconButton
                            // size="small"
                            // variant="filled"
                            // color="primary"
                            className={globalClasses.containedIconButton}
                            onClick={handleAddAccountToggle}
                            startIcon={
                                toggleAddAccount ? <CloseIcon /> : <AddIcon />
                            }
                        >
                            {toggleAddAccount ? <CloseIcon /> : <AddIcon />}
                        </IconButton>
                    </Box>
                </Box>

                {/* Collapse for new account and custom account */}
                <Collapse in={toggleAddAccount && !selectedAccount._id}>
                    <Box display="flex" width={"100%"} mb={2}>
                        {/* <Box mt={1} mr={1}>
                            <DragHandleIcon color="disabled" />
                        </Box> */}

                        <Box width={"100%"}>
                            <AccountHeader
                                label={"New Account"}
                                avatar={baseIcon}
                                bgColor={"#000"}
                                hasSwitch={true}
                                isSwitchChecked={toggleAddAccount}
                                onSwitchChange={handleAddAccountToggle}
                            />

                            <Box
                                mt={2}
                                ml={5}
                                // pl={
                                //     baseAccountSettings?.accountIconPosition ===
                                //     config.iconPosition.left
                                //         ? 5
                                //         : 0
                                // }
                                // bgcolor={"background.default"}
                            >
                                <Tabs
                                    value={tabValue}
                                    onChange={handleTabChange}
                                    aria-label="dashboard account and custom accounts"
                                    indicatorColor="primary"
                                    // textColor="primary"
                                    variant="fullWidth"
                                >
                                    <Tab
                                        value="one"
                                        label="Add Account"
                                        {...a11yProps("one")}
                                        // className={classes.tabBtn}
                                    />
                                    <Tab
                                        value="two"
                                        label="Custom URL"
                                        {...a11yProps("two")}
                                        // className={classes.tabBtn}
                                    />
                                </Tabs>
                                <TabPanel value={tabValue} index="one">
                                    <AccountCategoryList
                                        setSelectedAccount={setSelectedAccount}
                                    />
                                </TabPanel>
                                <TabPanel value={tabValue} index="two">
                                    <AddCustomUserAccount
                                        closeAddAccount={closeAddAccount}
                                    />
                                </TabPanel>
                            </Box>
                        </Box>
                    </Box>
                </Collapse>
                <Box mb={2} pl={2}>
                    <AddUserAccount
                        selectedAccount={selectedAccount}
                        closeAddAccount={closeAddAccount}
                        resetSelectedAccount={resetSelectedAccount}
                    />
                    <UserAccountList
                        toggleAddAccount={toggleAddAccount}
                        closeAddAccount={closeAddAccount}
                    />
                </Box>
            </Box>
        </div>
    );
}
