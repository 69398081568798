/* eslint-disable react-hooks/exhaustive-deps */
import {
    Avatar,
    Box,
    Button,
    CircularProgress,
    Grid,
    // InputAdornment,
    makeStyles,
    TextField,
    Typography
} from "@material-ui/core";
import * as Yup from "yup";
import clsx from "clsx";
import React, { useEffect } from "react";
// import useGlobalStyles from "../../../../../../../hooks/useGlobalStyles";
import utils from "../../../../../../../utils";
import userService from "../../../../../../../services/user";
import config from "../../../../../../../config";
import { useDispatch, useSelector } from "react-redux";
import actionTypes from "../../../../../../../redux/actionTypes";
import Alert from "@material-ui/lab/Alert";
import AccountRemove from "../userAccountDetails/AccountRemove";
import ColorPickerField from "../../../../../../../components/core/ColorPickerField";
import baseIcon from "../../../../../../../res/images/base-icon1.png";
import { useSnackbar } from "notistack";

const BORDER = config.accountStyle.border;
const FILETYPES = ["image/jpeg", "image/jpg", "image/png"];

const useStyles = makeStyles((theme) => ({
    previewIcon: {
        border: `1px dashed ${theme.palette.primary.main}`,
        width: theme.spacing(7.5),
        height: theme.spacing(7.5),
        marginTop: theme.spacing(0.7)
    },
    previewIconOverlay: {
        backgroundColor: "#000",
        opacity: "0.5",
        position: "absolute",
        bottom: 0,
        height: "50%",
        width: theme.spacing(7.8),
        borderBottomRightRadius: 4,
        borderBottomLeftRadius: 4
    },
    saveBtn: {
        backgroundColor: theme.palette.primary.dark,
        padding: ({ loading }) => loading && 1.4,
        color: theme.palette.background.paper,
        "&:hover": {
            backgroundColor: theme.palette.primary.dark
        }
    }
}));
const validationSchema = Yup.object({
    userId: Yup.string().required(),
    accountId: Yup.string().required(),
    userAccountId: Yup.string().required(),
    userIdentifier: Yup.string().required().max(62),
    customAccountText: Yup.string().required().max(25),
    accountTextColor: Yup.string()
        .required()
        .matches(config.regx.color, config.validationErrors.colorError),
    accountBackgroundColor: Yup.string()
        .required()
        .matches(config.regx.color, config.validationErrors.colorError),
    accountImageIcon: Yup.object({
        file: Yup.string().required(),
        fileType: Yup.string().required().oneOf(FILETYPES),
        fileSize: Yup.number().lessThan(
            config.constants.iconFileSize * 1000000,
            config.validationErrors.fileSizeError +
                config.constants.iconFileSize,
            +"  MB"
        )
    })
        .default(undefined)
        .typeError("accounyImageIcon" + config.validationErrors.fileTypeError)
});
export default function EditCustomUserAccount({
    accountName,
    accountUrl,
    baseAccountUserFieldLabel,
    userAccountIdentifier,
    userCustomAccountText,
    baseAccountTextColor,
    baseAccountBackgroundColor,
    accountImageUrl,
    userAccountId,
    accountId,
    selectedAccountToEditId,
    onSaveSuccess
}) {
    // const globalClasses = useGlobalStyles();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const user = useSelector((state) => state.auth.user);

    const [accountImageIcon, setAccountImageIcon] = React.useState();

    const [userIdentifier, setUserIdentifier] = React.useState(
        userAccountIdentifier
    );
    const [customAccountText, setCustomAccountText] = React.useState(
        userCustomAccountText
    );
    const [accountTextColor, setAccountTextColor] =
        React.useState(baseAccountTextColor);
    const [accountBackgroundColor, setAccountBackgroundColor] = React.useState(
        baseAccountBackgroundColor
    );
    const [error, setError] = React.useState("");
    const [loading, setLoading] = React.useState(false);
    const classes = useStyles({ loading });

    const baseAccountSettings = user.baseAccountSettings;

    const handlefileInput = (e) => {
        e.preventDefault();
        setError("");
        const input = e.currentTarget;
        const file = input.files[0];
        if (file) {
            const fileType = file.type;
            //console.log(file);
            utils
                .toBase64(file)
                .then((base64File) => {
                    setAccountImageIcon({
                        file: base64File,
                        fileSize: file.size,
                        fileType
                    });
                })
                .catch((error) => {
                    //console.log(error);
                });
        }
    };

    const submitEditAccount = (e) => {
        e.preventDefault();
        let data = {
            userId: user._id,
            userIdentifier,
            isCustomAccount: true,
            accountTextColor,
            accountBackgroundColor,
            customAccountText,
            userAccountId,
            accountId
        };

        if (accountImageIcon && accountImageIcon.file) {
            if (
                accountImageIcon.fileSize >
                config.constants.iconFileSize * 1024 * 1024
            ) {
                // setError(
                //     config.validationErrors.fileSizeError +
                //         config.constants.iconFileSize +
                //         "MB"
                // );
                // return false;
                data.accountImageIcon = accountImageIcon;
            }
            // data = {
            //     ...data,
            //     file: accountImageIcon.file,
            //     fileSize: accountImageIcon.fileSize,
            //     fileType: accountImageIcon.fileType.toLowerCase()
            // };
        }

        //console.log(data);
        validationSchema
            .validate(data)
            .then(() => {
                updateAccount(data);
            })
            .catch((err) => {
                setError(err.message);
                setTimeout(() => {
                    setError("");
                }, 3000);
            });
    };

    const updateAccount = (data) => {
        setLoading(true);
        userService
            .handleUserAccountEdit(data)
            .then((res) => {
                enqueueSnackbar(config.defaultNotiStackMessage, {
                    variant: "success"
                });
                onSaveSuccess();
                setLoading(false);
                dispatch({
                    type: actionTypes.UPDATE_USER,
                    payload: { accounts: res.data.accounts }
                });
            })
            .catch((ex) => {
                enqueueSnackbar(ex?.response?.data?.message, {
                    variant: "error"
                });
                // setError(ex.response?.data.message);
                setLoading(false);
                // setTimeout(() => setError(""), 3000);
            });
    };

    useEffect(() => {
        setUserIdentifier(userAccountIdentifier);
        setCustomAccountText(userCustomAccountText);
        setAccountTextColor(baseAccountTextColor);
        setAccountBackgroundColor(baseAccountBackgroundColor);
    }, []);
    useEffect(() => {
        setUserIdentifier(userAccountIdentifier);
        setCustomAccountText(userCustomAccountText);
        setAccountTextColor(baseAccountTextColor);
        setAccountBackgroundColor(baseAccountBackgroundColor);
    }, [selectedAccountToEditId]);

    return (
        <Box
            width="100%"
            padding={0}
            pl={
                baseAccountSettings.accountIconPosition ===
                config.iconPosition.left
                    ? 6
                    : 0
            }
        >
            {error && <Alert severity="error">{error}</Alert>}

            <AccountRemove selectedAccountToDelete={userAccountId} />
            <form onSubmit={submitEditAccount}>
                <Box mb={3}>
                    <Typography variant="subtitle1">
                        {baseAccountUserFieldLabel}
                    </Typography>
                    <TextField
                        // required
                        value={userIdentifier}
                        // defaultValue={baseAccountUserFieldLabel}
                        fullWidth
                        onChange={(e) =>
                            setUserIdentifier(e.currentTarget.value)
                        }
                        // InputProps={{
                        //     startAdornment: (
                        //         <InputAdornment position="start">
                        //             <Typography color="primary">
                        //                 {accountUrl}
                        //             </Typography>
                        //         </InputAdornment>
                        //     )
                        // }}
                    />
                </Box>
                <Grid container>
                    <Grid item lg={2} md={2} sm={2} xs={3}>
                        <div style={{ position: "relative" }}>
                            <Avatar
                                variant={"rounded"}
                                className={clsx(classes.previewIcon)}
                                src={
                                    (accountImageIcon &&
                                        accountImageIcon.file) ||
                                    accountImageUrl ||
                                    baseIcon
                                }
                            ></Avatar>
                            <Box className={classes.previewIconOverlay}>
                                <Typography align="center">
                                    <Button
                                        component="label"
                                        style={{ fontSize: 8 }}
                                    >
                                        Change
                                        <input
                                            type="file"
                                            style={{ display: "none" }}
                                            onChange={handlefileInput}
                                        />
                                    </Button>
                                </Typography>
                            </Box>
                        </div>
                    </Grid>
                    <Grid item lg={10} md={10} sm={10} xs={9}>
                        <Box mb={3}>
                            <Typography variant="subtitle1">
                                Custom Text
                            </Typography>
                            <TextField
                                placeholder={"e.g. My Website"}
                                fullWidth
                                // defaultValue={userCustomAccountText}
                                value={customAccountText}
                                onChange={(e) =>
                                    setCustomAccountText(e.currentTarget.value)
                                }
                            />
                        </Box>
                    </Grid>
                </Grid>
                {/* <Box mb={3}>
          <Typography variant="subtitle1">Account Text Color</Typography>
          <Box style={{ display: "flex" }}>
            <div style={{ marginTop: 8 }}>
              <ColorPicker
                // defaultValue={}
                value={accountTextColor}
                hideTextfield
                onChange={(color) => {
                  setError("");
                  setAccountTextColor(`#${color.hex}`);
                }}
              />
            </div>
            <TextField
              value={accountTextColor}

              onChange={(e) => {
                setError("");
                setAccountBackgroundColor(`${e.currentTarget.value}`);
              }}
            />
          </Box>
        </Box> */}

                {/* <Box mb={3}>
          <Typography variant="subtitle1">Account Background Color</Typography>
          <Box style={{ display: "flex" }}>
            <div style={{ marginTop: 8 }}>
              <ColorPicker
                value={accountBackgroundColor}
                hideTextfield
                onChange={(color) => {
                  setError("");
                  setAccountBackgroundColor(`#${color.hex}`);
                }}
              />
            </div>
            <TextField
              value={accountBackgroundColor}

              onChange={(e) => {
                setError("");
                setAccountBackgroundColor(`${e.currentTarget.value}`);
              }}
            />
          </Box>
        </Box> */}

                {baseAccountSettings.accountStyle !== BORDER && (
                    <Grid container spacing={4}>
                        <Grid item sm={6} md={6} lg={6} xs={6}>
                            <ColorPickerField
                                fieldLabel={"Account Text Color"}
                                color={accountTextColor}
                                onColorPickerValueChange={(color) => {
                                    setError("");
                                    setAccountTextColor(`#${color.hex}`);
                                }}
                                onFieldValueChange={(e) => {
                                    setError("");
                                    setAccountBackgroundColor(
                                        `${e.currentTarget.value}`
                                    );
                                }}
                            />
                        </Grid>
                        <Grid item sm={6} md={6} lg={6} xs={6}>
                            <ColorPickerField
                                fieldLabel={"Account Background Color"}
                                color={accountBackgroundColor}
                                onColorPickerValueChange={(color) => {
                                    setError("");
                                    setAccountBackgroundColor(`#${color.hex}`);
                                }}
                                onFieldValueChange={(e) => {
                                    setError("");
                                    setAccountBackgroundColor(
                                        `${e.currentTarget.value}`
                                    );
                                }}
                            />
                        </Grid>
                    </Grid>
                )}
                {baseAccountSettings.accountStyle === BORDER && (
                    <Grid container spacing={4}>
                        <Grid item sm={12} md={12} lg={12} xs={12}>
                            <ColorPickerField
                                fieldLabel={"Account Background Color"}
                                color={accountBackgroundColor}
                                onColorPickerValueChange={(color) => {
                                    setError("");
                                    setAccountBackgroundColor(`#${color.hex}`);
                                }}
                                onFieldValueChange={(e) => {
                                    setError("");
                                    setAccountBackgroundColor(
                                        `${e.currentTarget.value}`
                                    );
                                }}
                            />
                        </Grid>
                    </Grid>
                )}
                <Button
                    color="primary"
                    type="submit"
                    fullWidth
                    // className={classes.saveBtn}
                    disabled={loading}
                >
                    {loading ? (
                        <Box
                            align="center"
                            mt={0.5}
                            // className={classes.saveBtn}
                        >
                            <CircularProgress
                                size={16}
                                // className={classes.saveBtn}
                            ></CircularProgress>
                        </Box>
                    ) : (
                        "Save"
                    )}
                </Button>
            </form>
        </Box>
    );
}
