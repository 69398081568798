import { Box, Grid, makeStyles, Typography } from "@material-ui/core";
import React from "react";

import twitter from "../../../res/images/landingPage/twitter.png";
import insta from "../../../res/images/landingPage/insta.png";
import config from "../../../config";

const useStyles = makeStyles((theme) => ({
    boxPadding: {
        [theme.breakpoints.up("md")]: {
            paddingTop: 50,
            paddingBottom: 83
        },
        [theme.breakpoints.up("md")]: {
            paddingTop: 25,
            paddingBottom: 40
        }
    },
    gridItem: {
        padding: 0
    },
    gridContainer: {
        marginTop: theme.spacing(8),
        paddingBottom: theme.spacing(4)
        // padding: theme.spacing(2, 0),
        // paddingTop: 0
        // [theme.breakpoints.up("md")]: {
        //     paddingTop: 110
        // }
    },

    gridCont: {
        paddingTop: 54,
        [theme.breakpoints.up("md")]: {
            paddingTop: 50
        }
    },

    typography: {
        position: "absolute",

        fontFamily: "Darker Grotesque",
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "20px",

        textAlign: "center",
        color: "#0C0C0C"
    },
    boxContainer: {
        // background:
        //     "radial-gradient(245.97% 245.97% at -57.31% 50%, #E7FF50 0%, #4AD0EE 23.29%, #A8C8E0 47.92%, #9882FD 75%, #7A50FF 100%, #96185F 100%)",
        backgroundColor: theme.palette.primary.main,
        padding: theme.spacing(2),
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        borderRadius: "20px"
    },
    bold: {
        fontWeight: "bold"
    },
    footerLinks: {
        color: "#EEEEEE",
        fontFamily: "Darker Grotesque",
        fontSize: 18,
        fontWeight: 700,
        whiteSpace: "nowrap",
        letterSpacing: "0em",
        textAlign: "left",
        textDecoration: "none"
    }
}));

const Footer = () => {
    const classes = useStyles();
    return (
        <footer className={classes.gridContainer}>
            {/* <Box className={classes.boxContainer}>
                <Typography
                    variant="body1"
                    align="center"
                    style={{
                        fontFamily: "Darker Grotesque",
                        fontSize: "20px",
                        fontWeight: 500,
                        letterSpacing: "0em",
                        color: "#0C0C0C"
                    }}
                >
                    Questions, comments or suggestions? Please reach out to us
                    by email at{" "}
                    <strong className={classes.bold}>team@base.gg</strong>.
                </Typography>
            </Box> */}

            <Grid
                container
                className={classes.gridCont}
                spacing={2}
                justifyContent="space-between"
            >
                <Grid item className={classes.gridItem} xs={12} sm={12} md={6}>
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-start",
                            gap: 20
                        }}
                    >
                        <img src={twitter} alt="twitter" />
                        <img src={insta} alt="insta" />
                    </div>
                </Grid>
                <Grid item className={classes.gridItem} xs={12} sm={12} md={6}>
                    <Box>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={12}>
                                <Grid container spacing={0}>
                                    <Grid item xs={12} sm={12} md={4}>
                                        <Typography
                                            variant="subtitle1"
                                            component="a"
                                            className={classes.footerLinks}
                                            href={config.routes.tos}
                                        >
                                            TERMS OF SERVICE
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={4}>
                                        <Typography
                                            variant="subtitle1"
                                            component="a"
                                            className={classes.footerLinks}
                                            href={config.routes.privacy}
                                        >
                                            PRIVACY POLICY
                                        </Typography>
                                    </Grid>
                                    {/* <Grid item xs={12} sm={12} md={4}>
                                        <Typography
                                            variant="subtitle1"
                                            component="a"
                                            className={classes.footerLinks}
                                        >
                                            <a
                                                style={{
                                                    textDecoration: "none",
                                                    color: "#EEEEEE"
                                                }}
                                                href="mailto:team@base.gg?subject=Base Website Inquiry"
                                            >
                                                CONTACT US
                                            </a>
                                        </Typography>
                                    </Grid> */}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
            <Box className={classes.boxPadding}>
                <Box>
                    <Typography
                        style={{
                            color: "#9D9D9D",

                            fontFamily: "Darker Grotesque",
                            fontSize: 18,
                            fontWeight: 500,

                            letterSpacing: "0em",
                            textAlign: "left"
                        }}
                    >
                        Copyright © Base.gg All Rights Reserved.
                    </Typography>
                </Box>
                <Box>
                    <Typography
                        style={{
                            color: "#9D9D9D",

                            fontFamily: "Darker Grotesque",
                            fontSize: 18,
                            fontWeight: 500,

                            letterSpacing: "0em",
                            textAlign: "left"
                        }}
                    >
                        All other trademarks and logos are property of their
                        respective owners.
                    </Typography>
                </Box>
            </Box>
        </footer>
    );
};

export default Footer;
