/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Box } from "@material-ui/core";

import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import config from "../../../../config";
import actionTypes from "../../../../redux/actionTypes";
import auth from "../../../../services/auth";
import userService from "../../../../services/user";
import { useSnackbar } from "notistack";
import BackgroundUI from "../../components/BackgroundUI";

export default function EditBaseBackground() {
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    const user = useSelector((state) => state.auth.user);
    const baseProfileSettings = user.baseProfileSettings;

    const isPlusOrProUser = auth.isPlusOrProUser();
    const isManagedDesignActive = user?.managedBy?.isManagedDesignActive;

    const [actionStatus, setActionStatus] = useState({
        background: false
    });

    const handleSaveBackground = (newBaseProfileSetting) => {
        if (!isManagedDesignActive) {
            newBaseProfileSetting = {
                ...newBaseProfileSetting,
                userId: user._id
            };
            if (
                newBaseProfileSetting.backgroundType === "image" &&
                newBaseProfileSetting?.backgroundImage?.file
            ) {
                newBaseProfileSetting.backgroundImageUrl =
                    baseProfileSettings.backgroundImage;
            }
            setActionStatus((ps) => ({ ...ps, background: true }));
            userService
                .updateBaseProfileSettings(newBaseProfileSetting)
                .then((res) => {
                    enqueueSnackbar(config.defaultNotiStackMessage, {
                        variant: "success"
                    });
                    setActionStatus((ps) => ({ ...ps, background: false }));

                    const baseProfileSettings = res.data.baseProfileSettings;
                    dispatch({
                        type: actionTypes.UPDATE_USER,
                        payload: { baseProfileSettings }
                    });
                })
                .catch((ex) => {
                    enqueueSnackbar(ex?.response?.data?.message, {
                        variant: "error"
                    });
                    setActionStatus((ps) => ({ ...ps, background: false }));
                });
        }
    };
    return (
        <Box>
            <BackgroundUI
                allowVideoBackground={isPlusOrProUser}
                backgroundType={baseProfileSettings?.backgroundType}
                backgroundColor={baseProfileSettings?.backgroundColor}
                backgroundImage={baseProfileSettings?.backgroundImage}
                backgroundVideo={baseProfileSettings?.backgroundVideo}
                backgroundMobileVideo={
                    baseProfileSettings?.backgroundMobileVideo
                }
                isLoading={actionStatus.background}
                onBackgroundSave={handleSaveBackground}
            />
        </Box>
    );
}
