import { Grid, Typography, makeStyles, Box } from "@material-ui/core";
import React from "react";

import standoutImg from "../../../res/images/landingPage/stand-out.png";
import Imagebg from "../../../res/images/landingPage/stand-out-bg.png";

const useStyles = makeStyles((theme) => ({
    imagePadding: {
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(0)
        }
    },
    title: {
        // fontFamily: "Darker Grotesque",
        fontSize: "3.75rem",
        fontWeight: 800,
        letterSpacing: "0em",
        textAlign: "left",
        color: "#EEEEEE",
        [theme.breakpoints.down("sm")]: {
            fontSize: "2.75rem"
        }
    },
    body: {
        // fontFamily: "Darker Grotesque",
        // fontSize: "1.375rem",
        fontWeight: 400,
        letterSpacing: "0em",
        // textAlign: "left",
        color: "#9D9D9D"

        // [theme.breakpoints.down("sm")]: {
        //     fontSize: "1rem"
        // }
    },
    gridContainer: {
        margin: theme.spacing(30, 0),
        // paddingTop: 60,
        [theme.breakpoints.down("sm")]: {
            margin: theme.spacing(10, 0)
        }
    },
    boxContainer: {
        position: "relative",
        backgroundImage: Imagebg,
        backgroundPosition: "center",
        backgroundSize: "cover",
        height: "100%"
    },
    vector: {
        position: "absolute",
        width: "30%",
        height: 200,
        left: -200,
        top: 200,

        background: "#6BCCDB",
        /* Blur - 320 */

        filter: "blur(160px)",
        transform: "matrix(-0.98, -0.19, -0.19, 0.98, 0, 0)",
        [theme.breakpoints.down("sm")]: {
            width: "50%",
            left: 0
            // height: 100,
        }
    }
}));

const Standout = () => {
    const classes = useStyles();

    return (
        <Box className={classes.boxContainer}>
            <Grid container spacing={0} className={classes.gridContainer}>
                <Grid item xs={12} md={6}>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center"
                        }}
                    >
                        <Typography className={classes.title}>
                            Stand Out
                        </Typography>
                        <Typography
                            // color="textSecondary"
                            variant="h5"
                            className={classes.body}
                        >
                            Use a short, memorable link in all your profiles and
                            connect with your audience across various platforms
                        </Typography>
                    </div>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Box display="flex" justifyContent="center" mt={2}>
                        <img
                            src={standoutImg}
                            alt=""
                            style={{ maxWidth: "100%" }}
                            className={classes.imagePadding}
                        />
                    </Box>
                </Grid>
            </Grid>
            {/* <Box className={classes.vector}></Box> */}
        </Box>
    );
};

export default Standout;
