import config from "../../config/index";
import auth from "../../services/auth";

import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import clsx from "clsx";
import Drawer from "@material-ui/core/Drawer";
import CssBaseline from "@material-ui/core/CssBaseline";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
// import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
// import InboxIcon from "@material-ui/icons/MoveToInbox";
// import MailIcon from "@material-ui/icons/Mail";
import { AccountCircle, PeopleAltOutlined } from "@material-ui/icons";
import { Menu, MenuItem } from "@material-ui/core";
import { Link } from "react-router-dom";
import LinkIcon from "@material-ui/icons/Link";
import actionTypes from "../../redux/actionTypes";
import { useDispatch } from "react-redux";
const drawerWidth = config.drawerWidth;

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex"
    },
    appBar: {
        backgroundColor: theme.palette.background.paper,
        transition: theme.transitions.create(["margin", "width"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        })
    },
    appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["margin", "width"], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen
        })
    },
    title: {
        flexGrow: 1
    },
    hide: {
        display: "none"
    },
    drawer: {
        // width: drawerWidth,
        flexShrink: 1
    },
    drawerPaper: {
        width: drawerWidth
    },
    drawerHeader: {
        display: "flex",
        alignItems: "center",
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: "flex-end"
    },
    content: {
        width: "100%",
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        // marginLeft: drawerWidth,
        marginRight: 0
    },
    contentShift: {
        transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen
        }),
        marginLeft: drawerWidth
    },
    navLink: {
        textDecoration: "none",
        color: theme.palette.primary.main
    },
    listItemText: {
        color: theme.palette.primary.main,

        // fontSize: "0.9rem",
        opacity: 0.8
    },
    sidebarIcon: {
        color: theme.palette.primary.main,
        // fontSize: "1.3rem",
        marginLeft: theme.spacing(1),
        borderRadius: 20
    }
}));

export default function AdminPageContainer(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const menuOpen = Boolean(anchorEl);
    const [open, setOpen] = React.useState(true);

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };
    useEffect(() => {
        const uiBasedonScreenSize = () => {
            if (window.innerWidth < 600) {
                setOpen(false);
                // setIsMobile(true);
            }
            if (window.innerWidth > 600) {
                setOpen(true);
                // setIsMobile(false);
            }
        };
        window.addEventListener("resize", uiBasedonScreenSize);
        uiBasedonScreenSize();
    }, []);
    const onLogout = () => {
        auth.logout()
            .then(() => {
                // localStorage.removeItem(config.lsKeys.token);
                // localStorage.removeItem(config.lsKeys.user);
                dispatch({ type: actionTypes.SET_AUTH, payload: { auth: "" } });
                props.history.push(config.routes.login);
                props.history.push(config.routes.login);
            })
            .catch((ex) => {
                dispatch({ type: actionTypes.SET_AUTH, payload: { auth: "" } });
                props.history.push(config.routes.login);
                props.history.push(config.routes.login);
            });
    };
    return (
        <div>
            <div className={classes.root}>
                <CssBaseline />
                <AppBar
                    position="fixed"
                    className={clsx(classes.appBar, {
                        [classes.appBarShift]: open
                    })}
                >
                    <Toolbar>
                        <IconButton
                            color="primary"
                            aria-label="open drawer"
                            edge="start"
                            onClick={handleDrawerOpen}
                            className={clsx(open && classes.hide)}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Typography
                            variant="h6"
                            noWrap
                            className={classes.title}
                            color="primary"
                        >
                            Base.gg
                        </Typography>
                        <div>
                            <IconButton
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleMenu}
                                color="primary"
                            >
                                <AccountCircle />
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorEl}
                                anchorOrigin={{
                                    vertical: "top",
                                    horizontal: "right"
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "right"
                                }}
                                open={menuOpen}
                                onClose={handleClose}
                            >
                                <MenuItem onClick={handleClose}>
                                    Profile
                                </MenuItem>
                                <MenuItem onClick={onLogout}>Logout</MenuItem>
                            </Menu>
                        </div>
                    </Toolbar>
                </AppBar>
                <main
                    className={clsx(classes.content, {
                        [classes.contentShift]: open
                    })}
                >
                    <div className={classes.drawerHeader} />
                    {props.children}
                </main>
                <Drawer
                    className={classes.drawer}
                    variant="persistent"
                    anchor="left"
                    open={open}
                    classes={{
                        paper: classes.drawerPaper
                    }}
                >
                    <div className={classes.drawerHeader}>
                        <IconButton onClick={handleDrawerClose} color="primary">
                            {<ChevronRightIcon />}
                        </IconButton>
                    </div>
                    <Divider />
                    <List>
                        <Link
                            to={config.routes.adminUsers}
                            className={classes.navLink}
                        >
                            <ListItem button className={classes.listItem}>
                                <ListItemIcon>
                                    <PeopleAltOutlined
                                        className={classes.sidebarIcon}
                                    />
                                </ListItemIcon>
                                <ListItemText
                                    primary="Users"
                                    classes={{ primary: classes.listItemText }}
                                />
                            </ListItem>
                        </Link>
                        <Link
                            to={config.routes.adminBannedUrls}
                            className={classes.navLink}
                        >
                            <ListItem button className={classes.listItem}>
                                <ListItemIcon>
                                    <LinkIcon className={classes.sidebarIcon} />
                                </ListItemIcon>
                                <ListItemText
                                    primary="Banned Urls"
                                    classes={{ primary: classes.listItemText }}
                                />
                            </ListItem>
                        </Link>
                        <Link
                            to={config.routes.adminAccounts}
                            className={classes.navLink}
                        >
                            <ListItem button className={classes.listItem}>
                                <ListItemIcon>
                                    <AccountCircle
                                        className={classes.sidebarIcon}
                                    />
                                </ListItemIcon>
                                <ListItemText
                                    primary="Accounts"
                                    classes={{ primary: classes.listItemText }}
                                />
                            </ListItem>
                        </Link>
                        <Link
                            to={config.routes.accountCategory}
                            className={classes.navLink}
                        >
                            <ListItem button className={classes.listItem}>
                                <ListItemIcon>
                                    <PeopleAltOutlined
                                        className={classes.sidebarIcon}
                                    />
                                </ListItemIcon>
                                <ListItemText
                                    primary="Categories"
                                    classes={{ primary: classes.listItemText }}
                                />
                            </ListItem>
                        </Link>
                        <Link
                            to={config.routes.userSurvey}
                            className={classes.navLink}
                        >
                            <ListItem button className={classes.listItem}>
                                <ListItemIcon>
                                    <PeopleAltOutlined
                                        className={classes.sidebarIcon}
                                    />
                                </ListItemIcon>
                                <ListItemText
                                    primary="Survey"
                                    classes={{ primary: classes.listItemText }}
                                />
                            </ListItem>
                        </Link>
                        <Link
                            to={config.routes.adminIntegrations}
                            className={classes.navLink}
                        >
                            <ListItem button className={classes.listItem}>
                                <ListItemIcon>
                                    <PeopleAltOutlined
                                        className={classes.sidebarIcon}
                                    />
                                </ListItemIcon>
                                <ListItemText
                                    primary="Integrations"
                                    classes={{ primary: classes.listItemText }}
                                />
                            </ListItem>
                        </Link>
                    </List>
                    <Divider />
                    <List>
                        <Link
                            to={config.routes.adminCustomAccounts}
                            className={classes.navLink}
                        >
                            <ListItem button className={classes.listItem}>
                                <ListItemIcon>
                                    <AccountCircle
                                        className={classes.sidebarIcon}
                                    />
                                </ListItemIcon>
                                <ListItemText
                                    primary="Custom Accounts"
                                    classes={{ primary: classes.listItemText }}
                                />
                            </ListItem>
                        </Link>
                    </List>
                </Drawer>
            </div>
        </div>
    );
}
