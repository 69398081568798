import axios from "axios";

import actionTypes from "../redux/actionTypes";
import { store } from "../redux/store";
import config from "./index";

axios.defaults.timeout = config.requestTimeoutDuration;
// axios.defaults.maxBodyLength = 10000000000000000000000;
// axios.defaults.maxContentLength = 10000000000000000000000;

axios.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        return new Promise(async (resolve, reject) => {
            const originalRequest = error?.config;
            const state = store.getState();
            const refreshToken = state.auth.refreshToken;
            console.log("originalRequest.url", originalRequest?.url);
            if (!refreshToken) {
                store.dispatch({
                    type: actionTypes.SET_IS_TOKEN_EXPIRED,
                    payload: { isTokenExpired: true }
                });
                // store.dispatch({
                //     type: actionTypes.SET_AUTH,
                //     payload: { auth: {} }
                // });
                // history.push(config.routes.login + "/");
            }
            if (
                !refreshToken ||
                (error?.response?.status === 401 &&
                    originalRequest.url ===
                        config.apiEndPoint + config.routes.refreshToken)
            ) {
                // if the refresh token has also expired, redirect to login page
                store.dispatch({
                    type: actionTypes.SET_AUTH,
                    payload: { auth: {} }
                });
                // history.push(config.routes.login + "/");
            }

            if (
                error?.response &&
                error?.response?.status === 401 &&
                !originalRequest._retry &&
                originalRequest.url !==
                    config.apiEndPoint + config.routes.refreshToken
            ) {
                originalRequest._retry = true;
                const response = await axios
                    .get(config.apiEndPoint + config.routes.refreshToken, {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: "Bearer " + refreshToken
                        }
                    })
                    .then((res) => {
                        const newAccessToken = res.data?.accessToken;
                        const newRefreshToken = res.data?.refreshToken;
                        setHeaders(newAccessToken);
                        originalRequest.headers["Authorization"] =
                            "Bearer " + newAccessToken;
                        store.dispatch({
                            type: actionTypes.SET_TOKEN,
                            payload: { token: newAccessToken }
                        });
                        store.dispatch({
                            type: actionTypes.SET_REFRESH_TOKEN,
                            payload: { refreshToken: newRefreshToken }
                        });

                        return axios(originalRequest);
                    })
                    .catch((error) => {
                        store.dispatch({
                            type: actionTypes.SET_IS_TOKEN_EXPIRED,
                            payload: { isTokenExpired: true }
                        });
                        // store.dispatch({
                        //     type: actionTypes.SET_AUTH,
                        //     payload: { auth: {} }
                        // });
                        // history.push(config.routes.login);
                        return reject(error);
                    });
                resolve(response);
                // } else {
                //   store.dispatch({
                //     type: actionTypes.SET_IS_TOKEN_EXPIRED,
                //     payload: { isTokenExpired: true },
                //   });
            }

            return reject(error);
        });
        // if (error.response?.status === 401) {
        //   store.dispatch({
        //     type: actionTypes.SET_IS_TOKEN_EXPIRED,
        //     payload: { isTokenExpired: true },
        //   });
        // }
        // return Promise.reject(error);
    }
);

function setHeaders(jwt) {
    axios.defaults.headers.common["Authorization"] = "Bearer " + jwt;
    axios.defaults.headers.common["Content-Type"] = "application/json";
}

function resetHeaders() {
    axios.defaults.headers.common["Authorization"] = "";
    axios.defaults.headers.common["Content-Type"] = "";
}
function removeHeaders() {
    delete axios.defaults.headers.common["Authorization"];
    delete axios.defaults.headers.common["Content-Type"];
    delete axios.defaults.headers["Content-Type"];
    delete axios.defaults.headers.post["Content-Type"];
}
function printHeaders() {
    // console.log(axios.defaults);
}

export default {
    get: axios.get,
    post: axios.post,
    put: axios.put,
    delete: axios.delete,
    setHeaders,
    resetHeaders,
    removeHeaders,
    printHeaders
};
