/* eslint-disable react-hooks/exhaustive-deps */
import { Grid, Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { useDispatch, useSelector } from "react-redux";
import actionTypes from "../../../../../redux/actionTypes";

import userService from "../../../../../services/user";
import UserAccount from "./userAccount/UserAccount";

export default function UserAccountList(props) {
    // const classes = useStyles();
    // const globalClasses = useGlobalStyles();
    const dispatch = useDispatch();
    const [selectedAccountToEdit, setSelectedAccountToEdit] = React.useState(
        {}
    );
    const [disableDrop, setDisableDrop] = React.useState(false);

    const user = useSelector((state) => state.auth.user);

    const onDragEnd = (result) => {
        const { source, destination } = result;
        if (!destination) {
            return false;
        }
        if (
            destination.droppableId === source.droppableId &&
            destination.index === source.index
        ) {
            return false;
        }
        const previousState = [...user.accounts];
        const userAccounts = [...user.accounts];
        const draggedItem = userAccounts[source.index];
        userAccounts.splice(source.index, 1);
        userAccounts.splice(destination.index, 0, draggedItem);

        dispatch({
            type: actionTypes.SET_USER,
            payload: { user: { ...user, accounts: userAccounts } }
        });
        setDisableDrop(true);
        userService
            .updateUserBase({ accounts: userAccounts, userId: user._id })
            .then((res) => {
                setDisableDrop(false);
            })
            .catch((ex) => {
                setDisableDrop(false);
                dispatch({
                    type: actionTypes.SET_USER,
                    payload: { user: { ...user, accounts: previousState } }
                });
            });
    };
    useEffect(() => {}, [user]);

    // when add new accound click reset the selected account to edit
    useEffect(() => {
        if (selectedAccountToEdit._id && props.toggleAddAccount) {
            setSelectedAccountToEdit({});
        }
    }, [props]);

    // when account is selected to edit close add account toggle
    useEffect(() => {
        if (selectedAccountToEdit._id && props.toggleAddAccount) {
            props.closeAddAccount();
        }
    }, [selectedAccountToEdit]);

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable
                droppableId={"dragableUserAccounts"}
                isDropDisabled={disableDrop}
            >
                {(provided) => (
                    <Grid
                        container
                        {...provided.droppableProps}
                        innerRef={provided.innerRef}
                        spacing={user?.accounts?.length === 0 ? 0 : 2}
                    >
                        {user?.accounts?.length === 0 &&
                            !props.toggleAddAccount && (
                                <Grid item md={12} xs={12}>
                                    <Typography
                                        color="textSecondary"
                                        // align="center"
                                        variant="subtitle1"
                                        // style={{ fontSize: 12 }}
                                    >
                                        Your base doesn't have any accounts.
                                        Click on Add icon to get started!
                                    </Typography>
                                </Grid>
                            )}
                        {/* <br /> */}

                        {user.accounts &&
                            user.accounts.map((account, index) => {
                                return (
                                    <UserAccount
                                        index={index}
                                        key={account?._id}
                                        account={account}
                                        selectedAccountToEdit={
                                            selectedAccountToEdit
                                        }
                                        setSelectedAccountToEdit={
                                            setSelectedAccountToEdit
                                        }
                                    />
                                );
                            })}
                        {provided.placeholder}
                    </Grid>
                )}
            </Droppable>
        </DragDropContext>
    );
}
