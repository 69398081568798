if (process.env.NODE_ENV === "production") {
    console.log = function () {};
}

module.exports = {
    routes: {
        publicUser: "/:baseUrl",
        login: "/",
        pageNotFound: "/app/pageNotFound",
        error: "/app/error",
        auth: "/auth/user",
        refreshToken: "/auth/refreshToken",
        dashboard: "/user/dashboard",
        completeRegistration: "/user/completeRegistration",
        settings: "/user/settings",
        profile: "/user/profile",
        management: "/user/management",
        editProfile: "/user/editProfile",
        suspended: "/user/suspended",

        admin: "/app/admin",
        adminUsers: "/app/admin/users",
        adminBannedUrls: "/app/admin/banned-urls",
        adminEditUser: "/app/admin/edit",
        userSurvey: "/app/admin/user-survey",
        accountCategory: "/app/admin/account-category",

        adminAccounts: "/app/admin/accounts",
        adminIntegrations: "/app/admin/integrations",

        adminCustomAccounts: "/app/admin/custom-accounts",

        privacy: "/about/privacy",
        tos: "/about/terms",
        help: "https://help.base.gg"
    },
    regx: {
        color: "^#(?:[0-9a-fA-F]{3}){1,2}$",
        url: "/((https?)://)?(www.)?[a-z0-9]+(.[a-z]{2,}){1,3}(#?/?[a-zA-Z0-9#]+)*/?(?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,"
    },
    userType: {
        admin: 1,
        free: 2,
        plus: 3,
        pro: 4
    },
    integrationAccess: {
        all: "all",
        paid: "paid"
    },
    managedBaseStatuses: {
        pending: "pending",
        accepted: "accepted",
        cancelled: "cancelled",
        cancelledByProUser: "cancelledByProUser"
    },
    apiEndPoint: process.env.REACT_APP_API_ENDPOINT,
    lsKeys: {
        token: "token",
        user: "user"
    },

    apiRoutes: {
        public: {
            user: "/public/user"
        },
        auth: {
            google: "/auth/google",
            discord: "/auth/discord",
            twitch: "/auth/twitch",
            facebook: "/auth/facebook",
            // googleAdmin: "/auth/admin/google",
            googleCallback: "/auth/google/callback",
            discordCallback: "/auth/discord/callback",
            twitchCallback: "/auth/twitch/callback",
            // googleAdminCallback: "/auth/admin/google/callback",
            currentUser: "/auth/currentUser",
            currentUserBaseProfileSettings:
                "/auth/currentUser/baseProfileSettings",
            currentUserBaseAccountSettings:
                "/auth/currentUser/baseAccountSettings",
            logout: "/auth/logout",
            completeRegistration: "/auth/completeRegistration"
        },
        user: {
            updateUserProfile: "/user/updateUserProfile",
            addUserAccount: "/user/addUserAccount",
            account: "/user/account",
            accountSettings: "/user/accountSettings",
            profileSettings: "/user/profileSettings",
            managedBaseDesign: {
                profileSettings: "/user/managedBaseDesign/profileSettings",
                accountSettings: "/user/managedBaseDesign/accountSettings"
            },
            base: "/user/base",
            appAccount: "/user/account/app",
            // accountStatus: "/user/account/status",
            baseAccount: "/user/account/base",
            customAccount: "/user/account/custom",

            survey: "/user/survey",
            integration: "/user/integration",
            management: "/user/management",
            managementCategory: "/user/management/category",
            integrationData: "/user/integration/data",

            userStatus: "/admin/user/status",
            default: "/admin/user"
        },
        subscription: {
            plans: "/subscription/plans",
            subscribe: "/subscription/plan/subscribe",
            cancel: "/subscription/cancel",
            verify: "/subscription/verify",

            update: "/subscription/update",
            resume: "/subscription/resume",
            customerInvoice: "/subscription/customer/invoice"
        },
        baseProfileSettings: {
            sponsorLogo: "/user/base-settings/sponsor-logo"
        },
        intergration: {
            default: "/admin/integration",
            youtube: "/integration/youtube"
        },
        bannedUrls: {
            default: "/admin/bannedUrl"
        },
        accounts: {
            default: "/admin/account"
        },
        survey: {
            default: "/admin/survey",
            arrangeSurvey: "/admin/arrange-survey",
            getUserSurveys: "/user/survey"
        },
        accountCategory: {
            default: "/admin/account-category",
            arrangeAc: "/admin/arrange-account-category"
            // getUserSurveys: "/user/survey"
        }
    },
    shape: {
        circle: "circle",
        rounded: "rounded",
        square: "square"
    },
    iconPosition: {
        inside: "inside",
        left: "left",
        overlap: "overlap"
    },
    accountStyle: {
        fill: "fill",
        border: "border"
    },
    colorPicker: {
        defaultText: "#ffffff",
        // defaultBackground: "#2a3358"
        defaultBackground: "#000000"
    },
    backgroundType: {
        color: "color",
        image: "image",
        video: "video"
    },
    defaultBannerColor: {
        backgroundColor: "#669AEB",
        textColor: "#ffffff"
    },
    defaultNotiStackMessage: "Changes saved successfully",
    contactSupport: "Something went wrong please contact our support ",

    drawerWidth: 240,
    validationErrors: {
        fileTypeError: "File must be in JPEG, JPG and PNG Formate.",
        fileSizeError: "File size must be less or equal to ",
        colorError: "color must be in hex code representation",
        urlError: "Please enter correct URL."
    },
    managedDesignMessage:
        "💼 This Base is being managed.\nPlease contact the Manager or disable Management.",
    constants: {
        baseBgImageFileSize: 10, //in MB
        avtarFileSize: 5, //in MB
        iconFileSize: 5, //in MB
        headerFileSize: 5, //in MB
        baseLogoFileSize: 5, //in MB

        bioMaxCharacter: 160,
        sponorLogoDescMaxChar: 160,

        bannerTextMaxCharacter: 160,

        nameMinCharacter: 2,
        nameMaxCharacter: 20,
        userSurvey: [
            "Brand",
            "Brand Manager",
            "Content Creator",
            "Cosplayer",
            "eSports Organization",
            "eSports Pro",
            "Event Organizer",
            "Game Developer",
            "Game Publisher",
            "Gamer",
            "Gaming Media (Other)",
            "Influencer",
            "Journalist",
            "Marketer",
            "Musician",
            "Other",
            "Streamer",
            "Talent Manager",
            "Tournament Organizer"
        ],
        accountCategories: [
            "Anime",
            "Events",
            "Featured",
            "Gaming",
            "Merch",
            "Music",
            "Other",
            "Social",
            "Streaming",
            "Subscription",
            "Tip",
            "Plateform",
            "Info",
            "Video"
        ]
    }
};
