import React from "react";
import {
    Avatar,
    Box,
    Grid,
    makeStyles,
    Typography,
    useMediaQuery,
    useTheme
} from "@material-ui/core";
import clsx from "clsx";
import * as Yup from "yup";
import config from "../../config";
import utils from "../../utils/index";
import { Alert } from "@material-ui/lab";
import { useState } from "react";
// import { useSelector } from "react-redux";
import auth from "../../services/auth";
import { CheckCircle } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
    avatar: {
        width: theme.spacing(15),
        height: theme.spacing(15),
        borderWidth: 2,
        borderStyle: "solid",
        borderColor: theme.palette.background.paper,
        [theme.breakpoints.down("xs")]: {
            width: theme.spacing(13),
            height: theme.spacing(13)
        }
    },
    avatarOutline: {
        padding: 1,
        width: theme.spacing(15.5),
        height: theme.spacing(15.5),
        borderWidth: 1,
        borderStyle: "solid",
        borderColor: theme.palette.primary.main,
        [theme.breakpoints.down("xs")]: {
            width: theme.spacing(13.5),
            height: theme.spacing(13.5)
        }
    },
    avatarOutlineCircle: {
        width: theme.spacing(15.5),
        height: theme.spacing(15.5),
        borderWidth: 1,
        borderStyle: "solid",
        borderColor: theme.palette.primary.main,
        borderRadius: 100,
        [theme.breakpoints.down("xs")]: {
            width: theme.spacing(13.5),
            height: theme.spacing(13.5)
        }
    },
    roundedAvatar: {
        borderRadius: 8
    },
    avatarOverlay: {
        backgroundColor: "#000",
        opacity: "0.5",
        position: "absolute",
        // bottom: 5,
        bottom: 0,
        zIndex: 2,
        // left: 5,
        height: "50%",
        width: theme.spacing(15),
        [theme.breakpoints.down("xs")]: {
            width: theme.spacing(13)
        },
        cursor: "pointer",

        "&:hover": {
            cursor: "pointer",
            backgroundColor: "rgba(11, 11, 11, 0.8)"
        }
    },
    roundedOverlay: {
        borderBottomRightRadius: 16,
        borderBottomLeftRadius: 16
    },
    circleOverlay: {
        borderBottomRightRadius: theme.spacing(15),
        borderBottomLeftRadius: theme.spacing(15)
    },
    shapesContainer: {
        marginTop: theme.spacing(2),
        [theme.breakpoints.down("xs")]: {
            // paddingLeft: theme.spacing(2),
            marginTop: theme.spacing(1)
        }
    },
    shapeArea: {
        position: "relative",
        // backgroundColor: theme.palette.secondary.main,
        // border:'1px solid #fff',
        borderWidth: 2,
        borderStyle: "solid",
        // borderColor: theme.palette.text.primary,
        width: theme.spacing(5),
        height: theme.spacing(5),
        marginBottom: theme.spacing(1),
        outlineWidth: 2,
        // outlineColor: theme.palette.secondary.main,
        // outlineStyle: "dotted",
        cursor: "pointer",
        [theme.breakpoints.down("xs")]: {
            width: theme.spacing(4),
            height: theme.spacing(4)
        }
    },
    selectedShapeArea: {
        cursor: "pointer",
        backgroundColor: theme.palette.primary.main,
        borderWidth: 2,
        borderStyle: "solid",
        borderColor: theme.palette.secondary.main,
        width: theme.spacing(5),
        outlineWidth: 1,
        outlineColor: theme.palette.primary.main,
        outlineStyle: "dotted",
        height: theme.spacing(5),
        marginBottom: theme.spacing(1),
        [theme.breakpoints.down("xs")]: {
            width: theme.spacing(4),
            height: theme.spacing(4)
        }
    },
    shapesTitle: {
        fontSize: 12,
        fontFamily: "Helvetica",
        fontWeight: 100,
        backgroundColor: "transparent",
        width: "100%",
        // height: "90%",
        display: "grid",
        placeItems: "center",
        [theme.breakpoints.down("xs")]: {
            fontSize: 11
        },
        // minHeight: "100vh",
        // height: "50%",

        "&:hover": {
            color: "#fff"
        }
        // "&:focus": {
        //     backgroundColor: "transparent"
        // },
        // "&:visited": {
        //     backgroundColor: "transparent"
        // },
        // "&:active": {
        //     backgroundColor: "transparent"
        // }
    },
    changeAvatarText: {
        fontSize: 12,
        fontWeight: 100,
        marginTop: theme.spacing(1)
    },

    check: {
        position: "absolute",
        top: 6,
        left: 6,
        [theme.breakpoints.down("xs")]: {
            top: 2,
            left: 2
        }
    }
}));

const SQUARE = config.shape.square;
const CIRCLE = config.shape.circle;
const ROUNDED = config.shape.rounded;

const FILETYPES = ["image/jpeg", "image/jpg", "image/png"];

export default function AvatarShapeSelector({
    // handleFileInput,
    setSelectedAvatar = () => {},
    setSelectedAvatarShape = () => {},
    selectedAvatarShape = "",
    selectedAvatar = "",
    avatarUrl = "",
    showAvatarSelector = true
}) {
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const [validationError, setValidationError] = useState("");
    // const user = useSelector((state) => state.auth.user);
    const isPlusOrProUser = auth.isPlusOrProUser();

    const resetErrroMessage = () => {
        setTimeout(() => {
            setValidationError("");
        }, 3000);
    };

    const handleFileInput = (e) => {
        e.preventDefault();
        if (isPlusOrProUser) {
            FILETYPES.push("image/gif");
        }
        var validationSchema = Yup.object({
            avatarImage: Yup.object({
                fileType: Yup.string()
                    .required()
                    .oneOf(FILETYPES, config.validationErrors.fileTypeError),
                fileSize: Yup.number()
                    .lessThan(
                        config.constants.avtarFileSize * 1000000,
                        config.validationErrors.fileSizeError +
                            config.constants.avtarFileSize +
                            " MB"
                    )
                    .required()
            })
                .notRequired()
                .default(undefined)
        });
        const input = e.currentTarget;
        const file = input.files[0];

        validationSchema
            .validate({
                avatarImage: { fileSize: file.size, fileType: file.type }
            })
            .then(() => {
                utils
                    .toBase64(file)
                    .then((base64File) => {
                        setSelectedAvatar({
                            file: base64File,
                            fileSize: file.size,
                            fileType: file.type
                        });
                    })
                    .catch((error) => {
                        setValidationError(error.message);
                        resetErrroMessage();
                    });
            })

            .catch((err) => {
                setValidationError(err.message);
                resetErrroMessage();
            });
    };
    const checkUI = (
        <Box className={classes.check}>
            <CheckCircle color="primary" />
        </Box>
    );
    // account
    return (
        <div>
            {validationError && (
                <div>
                    <Alert severity="error">{validationError}</Alert>
                    <br />
                </div>
            )}
            <Box display={"flex"}>
                {showAvatarSelector && (
                    <Box mr={isMobile ? 2 : 4}>
                        <Box
                            variant={
                                selectedAvatarShape === CIRCLE
                                    ? "circular"
                                    : selectedAvatarShape
                            }
                            className={clsx(
                                // classes.avatar,
                                classes.avatarOutline,

                                selectedAvatarShape === ROUNDED &&
                                    classes.roundedAvatar,
                                selectedAvatarShape === CIRCLE &&
                                    classes.avatarOutlineCircle
                            )}
                        >
                            <Box position={"relative"}>
                                <Box
                                    align="center"
                                    variant={
                                        selectedAvatarShape === CIRCLE
                                            ? "circular"
                                            : selectedAvatarShape
                                    }
                                    className={clsx(
                                        classes.avatarOverlay,
                                        selectedAvatarShape === ROUNDED &&
                                            classes.roundedOverlay,
                                        selectedAvatarShape === CIRCLE &&
                                            classes.circleOverlay
                                    )}
                                >
                                    {/* <Typography
                                align="center"
                                className={classes.changeAvatarText}
                            > */}
                                    <Box
                                        component="label"
                                        className={classes.shapesTitle}
                                        height="90%"
                                    >
                                        Change Avatar
                                        <input
                                            type="file"
                                            style={{ display: "none" }}
                                            accept={FILETYPES.toString()}
                                            onChange={handleFileInput}
                                            onClick={(event) => {
                                                setValidationError("");
                                                event.target.value = null;
                                            }}
                                        />
                                    </Box>
                                    {/* </Typography> */}
                                </Box>
                                <Avatar
                                    variant={
                                        selectedAvatarShape === CIRCLE
                                            ? "circular"
                                            : selectedAvatarShape
                                    }
                                    className={clsx(
                                        classes.avatar,
                                        selectedAvatarShape === ROUNDED &&
                                            classes.roundedAvatar
                                    )}
                                    src={
                                        selectedAvatar
                                            ? selectedAvatar.file
                                            : avatarUrl
                                    }
                                ></Avatar>
                            </Box>
                        </Box>
                    </Box>
                )}
                <Box className={classes.shapesContainer}>
                    <Typography
                        color="textPrimary"
                        variant="subtitle1"
                        gutterBottom
                    >
                        Avatar Shape
                    </Typography>
                    <Grid container direction="row" spacing={2}>
                        <Grid item align={"center"}>
                            <Box
                                onClick={() =>
                                    setSelectedAvatarShape(config.shape.square)
                                }
                                className={classes.shapeArea}
                                // className={clsx(
                                //     selectedAvatarShape !==
                                //         config.shape.square &&
                                //         classes.shapeArea,
                                //     selectedAvatarShape ===
                                //         config.shape.square &&
                                //         classes.selectedShapeArea
                                // )}
                            >
                                {selectedAvatarShape === SQUARE && checkUI}
                            </Box>

                            <Typography
                                className={classes.shapesTitle}
                                color="textPrimary"
                            >
                                Square
                            </Typography>
                        </Grid>
                        <Grid item align={"center"}>
                            <Box
                                borderRadius={8}
                                // variant="rounded"
                                className={classes.shapeArea}
                                // className={clsx(
                                //     selectedAvatarShape !== ROUNDED &&
                                //         classes.shapeArea,
                                //     selectedAvatarShape === ROUNDED &&
                                //         classes.selectedShapeArea
                                // )}
                                onClick={() => setSelectedAvatarShape(ROUNDED)}
                            >
                                {selectedAvatarShape === ROUNDED && checkUI}
                            </Box>
                            <Typography
                                className={classes.shapesTitle}
                                color="textPrimary"
                            >
                                Rounded
                            </Typography>
                        </Grid>
                        <Grid item align={"center"}>
                            <Box
                                borderRadius="50%"
                                className={classes.shapeArea}
                                // className={clsx(
                                //     selectedAvatarShape !== CIRCLE &&
                                //         classes.shapeArea,
                                //     selectedAvatarShape === CIRCLE &&
                                //         classes.selectedShapeArea
                                // )}
                                onClick={() => setSelectedAvatarShape(CIRCLE)}
                            >
                                {selectedAvatarShape === CIRCLE && checkUI}
                            </Box>

                            <Typography
                                className={classes.shapesTitle}
                                color="textPrimary"
                            >
                                Circle
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </div>
    );
}
