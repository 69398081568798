import {
    Box,
    Button,
    Grid,
    makeStyles,
    Typography,
    useMediaQuery,
    useTheme
} from "@material-ui/core";
import React from "react";
// import oneBase from "../../../res/images/landingPage/onebase.png";
import gaming from "../../../res/images/landingPage/hero-image.png";

import google from "../../../res/images/landingPage/Google.png";
import twitch from "../../../res/images/landingPage/twitch.png";
// import discord from "../../../res/images/landingPage/discord.png";
import facebook from "../../../res/images/landingPage/facebook.png";
import config from "../../../config";

const useStyles = makeStyles((theme) => ({
    boxx: {
        background:
            "radial-gradient(100% 326530.61% at 100% 52.4%, #6ACCDC 0%, #DBD76B 22.36%, #6CEC81 46.99%, #BD00FF 100%)",
        width: "100%",
        height: "100%"
    },

    heroTitle: { color: theme.palette.primary.main },
    box: {
        // marginTop: 70,
        [theme.breakpoints.up("md")]: {
            // paddingTop: 7
            marginTop: 70
        },
        [theme.breakpoints.up("sm")]: {
            // paddingTop: 41.5,
            marginTop: 40
        },
        [theme.breakpoints.up("xs")]: {
            // paddingTop: 41.5,
            marginTop: 40
        }
    },
    image: {
        [theme.breakpoints.up("sm")]: {
            marginTop: -70,
            width: "110%",
            marginLeft: 40
        },
        [theme.breakpoints.down("sm")]: {
            marginTop: 70,
            width: "130%",
            marginLeft: -80
        },
        width: "100%"
    },
    image2: {
        width: "100%",
        paddingTop: 15
    },

    gridItem: {
        maxWidth: "none"
    },
    buttonWrapper: {
        display: "flex",
        gap: "10px",
        alignItems: "center",
        [theme.breakpoints.down("sm")]: {
            paddingTop: 30,
            flexDirection: "column"
        },
        [theme.breakpoints.up("md")]: {
            paddingTop: 50,
            flexDirection: "row"
        },
        "& div": {
            display: "flex",
            alignItems: "center",
            gap: "6px"
        }
    },
    buttonPadding: {
        padding: "12px 32px",
        textTransform: "none"
    },
    buttonText: {
        fontWeight: 700
        // fontSize: "17px",
        // [theme.breakpoints.down("xs")]: {
        //     fontSize: "10px"
        // },
        // [theme.breakpoints.down("sm")]: {
        //     fontSize: "10px"
        // },
        // [theme.breakpoints.up("md")]: {
        //     fontSize: "12px"
        // }
    },
    fullwidthButton: {
        [theme.breakpoints.down("sm")]: {
            width: "100%"
        }
    },
    termsAndPrivacy: {
        maxWidth: 500,
        margin: "auto",
        color: "#EEEEEE99",
        textAlign: "center",
        marginTop: 50,
        [theme.breakpoints.up("md")]: {
            marginTop: 75
        },
        "& a": {
            color: "#6BCCDB"
        }
    },

    vector: {
        position: "absolute",
        width: "30%",
        height: 202.24,
        left: 0,
        top: 520.33,

        background: "#6BCCDB",
        /* Blur - 320 */

        filter: "blur(160px)",
        transform: "matrix(-0.98, -0.19, -0.19, 0.98, 0, 0)",
        [theme.breakpoints.down("sm")]: {
            width: "50%"
            // height: 100,
        }
    },
    radialGradient: {
        background:
            "radial-gradient(100% 326530.61% at 100% 52.4%, #6ACCDC 0%, #DBD76B 22.36%, #6CEC81 46.99%, #BD00FF 100%)",
        height: 21,
        [theme.breakpoints.down("xs")]: {
            height: 12
        },

        borderRadius: "20px"
    },
    gridContainer: {
        [theme.breakpoints.down("md")]: {
            paddingBottom: 85
            // height: 100,
        },
        [theme.breakpoints.down("sm")]: {
            paddingBottom: 40
            // height: 100,
        }
    },

    communityBox: {
        padding: theme.spacing(2),
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        borderRadius: "20px"
    },
    title: {
        fontFamily: "Darker Grotesque",
        fontSize: "1.125rem",
        fontWeight: 800,
        letterSpacing: "0em",
        textAlign: "left",
        color: "#EEEEEE",
        paddingTop: 47,
        [theme.breakpoints.down("sm")]: {
            fontSize: "1.125rem",
            paddingTop: 30
        }
    },
    image3: {
        maxWidth: "100%",
        opacity: "30%",
        mixBlendMode: "pass-through"
    },
    imagebox: {
        height: 29.74132537841797,
        width: 156.9995574951172,
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    branding: {
        paddingTop: 61,
        [theme.breakpoints.down("sm")]: {
            paddingTop: 48
        }
    }
}));

const Hero = () => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
    // useMediaQuery("(min-width:960px)");
    const classes = useStyles();
    return (
        <div className={classes.box}>
            <Box>
                <Grid className={classes.gridContainer} container spacing={2}>
                    <Grid item xs={12} md={6}>
                        {/* <img
                            src={oneBase}
                            alt=""
                            style={{ maxWidth: "100%", height: "auto" }}
                        /> */}
                        <Box>
                            <Box mb={2}>
                                <Typography
                                    variant={isSmallScreen ? "h3" : "h1"}
                                    style={{ fontWeight: 900, lineHeight: 1 }}
                                >
                                    One Base <br />
                                    For{" "}
                                    <span className={classes.heroTitle}>
                                        {" "}
                                        All Your Accounts
                                    </span>
                                </Typography>
                            </Box>
                            <Typography
                                variant="h5"
                                color="textSecondary"
                                style={{
                                    fontWeight: 400
                                }}
                            >
                                Level up your presence and direct your audience
                                to all your content with one short, memorable
                                link. Join 1000+ users and get started for free
                                today.
                            </Typography>
                        </Box>
                        <div className={classes.buttonWrapper}>
                            <Button
                                className={`${classes.buttonPadding} ${classes.fullwidthButton}`}
                                disableElevation
                                style={{
                                    textTransform: "none",
                                    backgroundColor: "white",
                                    color: "black",
                                    zIndex: 9
                                }}
                                onClick={() =>
                                    (window.location =
                                        config.apiEndPoint +
                                        config.apiRoutes.auth.google)
                                }
                            >
                                <div>
                                    <img src={google} alt="" />
                                    <Typography
                                        className={classes.buttonText}
                                        noWrap
                                    >
                                        Sign in with Google
                                    </Typography>{" "}
                                </div>
                            </Button>
                            {/* <Button
                                className={`${classes.buttonPadding} ${classes.fullwidthButton}`}
                                style={{
                                    textTransform: "none",
                                    backgroundColor: "#7289da",
                                    color: "white",
                                    zIndex: 9
                                }}
                                onClick={() =>
                                    (window.location =
                                        config.apiEndPoint +
                                        config.apiRoutes.auth.discord)
                                }
                            >
                                <div>
                                    <img src={discord} alt="" />
                                    <Typography
                                        className={classes.buttonText}
                                        noWrap
                                    >
                                        Sign in with Discord
                                    </Typography>
                                </div>
                            </Button> */}
                            <Button
                                className={`${classes.buttonPadding} ${classes.fullwidthButton}`}
                                style={{
                                    textTransform: "none",
                                    backgroundColor: "#156EE4",
                                    color: "white",
                                    zIndex: 9
                                }}
                                onClick={() =>
                                    (window.location =
                                        config.apiEndPoint +
                                        config.apiRoutes.auth.facebook)
                                }
                            >
                                <div>
                                    <img src={facebook} alt="" width={13} />
                                    <Typography
                                        className={classes.buttonText}
                                        noWrap
                                    >
                                        Sign in with Facebook
                                    </Typography>
                                </div>
                            </Button>
                            <Button
                                className={`${classes.buttonPadding} ${classes.fullwidthButton}`}
                                style={{
                                    textTransform: "none",
                                    backgroundColor: "#9146ff",
                                    color: "white",
                                    zIndex: 9
                                }}
                                onClick={() =>
                                    (window.location =
                                        config.apiEndPoint +
                                        config.apiRoutes.auth.twitch)
                                }
                            >
                                <div>
                                    <img src={twitch} alt="" />
                                    <Typography
                                        className={classes.buttonText}
                                        noWrap
                                    >
                                        Sign in with Twitch
                                    </Typography>
                                </div>
                            </Button>
                        </div>
                        <Box mt={3}>
                            <Typography
                                // variant="subtitle1"
                                // tcolor="textSecondary"
                                variant="h5"
                                color="textSecondary"
                                style={{
                                    fontWeight: 400
                                }}
                                // style={{ color: "#EEEEEE99" }}
                            >
                                By using Base.gg, you agree to our&nbsp;
                                <Typography
                                    variant="h5"
                                    color="primary"
                                    style={{
                                        fontWeight: 700,
                                        texDecoration: "none"
                                    }}
                                    component="a"
                                    href={config.routes.tos}
                                >
                                    Terms&nbsp;
                                </Typography>
                                and&nbsp;
                                <Typography
                                    variant="h5"
                                    color="primary"
                                    style={{
                                        fontWeight: 700,
                                        texDecoration: "none"
                                    }}
                                    component="a"
                                    href={config.routes.privacy}
                                    // style={{}}
                                >
                                    Privacy Policy.
                                </Typography>
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6} className={classes.gridItem}>
                        {/* {!isSmallScreen && ( */}
                        <Box sx={{ maxWidth: "", mb: 1 }}>
                            <img
                                src={gaming}
                                style={{ height: "100%" }}
                                className={classes.image}
                                alt=""
                            />
                        </Box>
                        {/* )} */}
                    </Grid>
                </Grid>
                {/* <Box className={classes.radialGradient} /> */}
                {/* <Box className={classes.communityBox}>
                    {" "}
                    <Typography className={classes.title}>
                        OUR COMMUNITY IS ON
                    </Typography>
                </Box>
                <Box className={classes.branding}>
                    <Grid container spacing={10}>
                        <Grid
                            className={classes.imagebox}
                            item
                            xs={6}
                            sm={6}
                            md={3}
                        >
                            <img
                                src={discordg}
                                alt=""
                                className={classes.image3}
                            />
                        </Grid>
                        <Grid
                            className={classes.imagebox}
                            item
                            xs={6}
                            sm={6}
                            md={3}
                        >
                            <img
                                src={twitchg}
                                alt=""
                                className={classes.image3}
                            />
                        </Grid>
                        <Grid
                            className={classes.imagebox}
                            item
                            xs={6}
                            sm={6}
                            md={3}
                        >
                            <img
                                src={twitterg}
                                alt=""
                                className={classes.image3}
                            />
                        </Grid>
                        <Grid
                            className={classes.imagebox}
                            item
                            xs={6}
                            sm={6}
                            md={3}
                        >
                            <img
                                src={facebookgaming}
                                alt=""
                                className={classes.image3}
                            />
                        </Grid>
                    </Grid>
                </Box> */}
            </Box>
            {/* <Box className={classes.vector}></Box> */}
        </div>
    );
};

export default Hero;
