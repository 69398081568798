import React from "react";
import { Switch, Typography, Box } from "@material-ui/core";

import CustomBadge from "../../../components/common/CustomBadge";
import LabelWithEmoji from "../../../components/common/LabelWithEmoji";

export default function BaseBrandingUI({
    enableFeature = false,
    isBaseBranding = false,
    disableBaaseBrandingSwitch = false,
    handleBaseBranding = () => {}
}) {
    return (
        <Box display="flex" mt={1}>
            <Box flexGrow={1}>
                {!enableFeature ? (
                    <Box display="flex">
                        <Typography color="textPrimary" variant="h6">
                            <LabelWithEmoji text="Base Branding" emoji="❌" />
                        </Typography>
                        <CustomBadge
                            badgeContent={"plus"}
                            uppercase={true}
                        ></CustomBadge>
                    </Box>
                ) : (
                    <Typography color="textPrimary" variant="h6">
                        <LabelWithEmoji text="Base Branding" emoji="❌" />
                    </Typography>
                )}
            </Box>
            <Box>
                <Switch
                    checked={
                        (enableFeature && isBaseBranding) || !enableFeature
                    }
                    onChange={handleBaseBranding}
                    disabled={!enableFeature || disableBaaseBrandingSwitch}
                    color="primary"
                />
            </Box>
        </Box>
    );
}
