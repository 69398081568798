import React from "react";
import { Box, Grid, makeStyles, Typography } from "@material-ui/core";
import clsx from "clsx";
import config from "../../config";
import { CheckCircle } from "@material-ui/icons";

const BORDER = config.accountStyle.border;
const FILL = config.accountStyle.fill;

const useStyles = makeStyles((theme) => ({
    accountFill: {
        height: theme.spacing(5),
        width: theme.spacing(16),
        backgroundColor: theme.palette.text.primary,
        border: `2px solid ${theme.palette.text.primary}`,
        borderRadius: 0,
        marginBottom: theme.spacing(1),
        [theme.breakpoints.down("xs")]: {
            height: theme.spacing(4),
            width: theme.spacing(8)
        }
    },
    accountBorder: {
        height: theme.spacing(5),
        width: theme.spacing(16),
        border: `2px solid ${theme.palette.text.primary}`,
        borderRadius: 0,
        marginBottom: theme.spacing(1),
        outlineWidth: 1,
        outlineColor: theme.palette.secondary.main,
        [theme.breakpoints.down("xs")]: {
            height: theme.spacing(4),
            width: theme.spacing(8)
        }
    },
    selectedAccountStyle: {
        height: theme.spacing(5),
        width: theme.spacing(16),
        cursor: "pointer",
        backgroundColor: theme.palette.primary.main,
        borderWidth: 1,
        borderStyle: "solid",
        // borderColor: theme.palette.secondary.main,
        borderColor: theme.palette.background.paper,
        marginBottom: theme.spacing(0.5),
        // outlineWidth: 1,
        // outlineColor: theme.palette.primary.main,
        // outlineStyle: "dotted",
        // outlineOffset: 1,
        // MozOutlineRadius: 4,
        // outlineRadius: 4,
        [theme.breakpoints.down("xs")]: {
            height: theme.spacing(4),
            width: theme.spacing(8)
        }
    },
    selectedAccountStyleBorder: {
        height: theme.spacing(5),
        width: theme.spacing(16),
        cursor: "pointer",
        backgroundColor: theme.palette.primary.main,
        borderWidth: 2,
        borderStyle: "solid",
        borderColor: theme.palette.primary.main,
        marginBottom: theme.spacing(0.5),
        // outlineWidth: 1,
        // outlineColor: theme.palette.primary.main,
        // outlineStyle: "dotted",
        // outlineOffset: 2,
        // MozOutlineRadius: 4,
        // outlineRadius: 4,
        [theme.breakpoints.down("xs")]: {
            height: theme.spacing(4),
            width: theme.spacing(8)
        }
    },
    shapesTitle: {
        fontSize: 12,
        fontWeight: 100
    }
}));
export default function AccountStyleSelector({
    setSelectedAccountStyle,
    selectedAccountStyle
}) {
    const classes = useStyles();
    const checkUI = (
        <Box p={0.5} mt={0.4}>
            <CheckCircle
                color="primary"
                fontSize="small"
                style={{
                    fontSize: 14
                }}
            />
        </Box>
    );
    return (
        <Box>
            <Typography color="textPrimary" variant="subtitle1" gutterBottom>
                Fill
            </Typography>
            <Grid container direction="row" spacing={3}>
                <Grid item align={"center"}>
                    <Box
                        onClick={() => setSelectedAccountStyle(BORDER)}
                        className={clsx(
                            selectedAccountStyle === BORDER
                                ? classes.selectedAccountStyleBorder
                                : classes.accountBorder
                        )}
                    />

                    {/* <Typography
                        className={classes.shapesTitle}
                        color="textPrimary"
                    >
                        Outline
                    </Typography> */}
                    <Box display="flex" justifyContent="center">
                        <Box display="flex" alignItems="center">
                            {selectedAccountStyle === BORDER && checkUI}
                            <Typography
                                className={classes.shapesTitle}
                                color="textPrimary"
                            >
                                Outline
                            </Typography>
                        </Box>
                    </Box>
                </Grid>
                <Grid item align={"center"}>
                    <Box
                        onClick={() => setSelectedAccountStyle(FILL)}
                        className={clsx(
                            selectedAccountStyle === FILL
                                ? classes.selectedAccountStyle
                                : classes.accountFill
                        )}
                    />
                    {/* <Typography
                        className={classes.shapesTitle}
                        color="textPrimary"
                    >
                        Fill
                    </Typography> */}
                    <Box display="flex" justifyContent="center">
                        <Box display="flex" alignItems="center">
                            {selectedAccountStyle === FILL && checkUI}
                            <Typography
                                className={classes.shapesTitle}
                                color="textPrimary"
                            >
                                Fill
                            </Typography>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
}
