import config from "../config";
import axios from "../config/axios";

const getAllAccounts = async () => {
    return axios.get(
        `${config.apiEndPoint}${config.apiRoutes.accounts.default}?custom=false`
    );
};
const getAllCustomAccounts = async () => {
    return axios.get(
        `${config.apiEndPoint}${config.apiRoutes.accounts.default}?custom=true`
    );
};
const getAccountById = async (id) => {
    return axios.get(
        `${config.apiEndPoint}${config.apiRoutes.accounts.default}?id=${id}`
    );
};

const addNewAccount = async (account) => {
    return axios.post(
        `${config.apiEndPoint}${config.apiRoutes.accounts.default}`,
        account
    );
};
const editAccountById = async (account) => {
    return axios.put(
        `${config.apiEndPoint}${config.apiRoutes.accounts.default}`,
        account
    );
};
const deleteAccountById = async (id) => {
    return axios.delete(
        `${config.apiEndPoint}${config.apiRoutes.accounts.default}`,
        { data: { id } }
    );
};

export default {
    getAllAccounts,
    addNewAccount,
    deleteAccountById,
    getAllCustomAccounts,
    getAccountById,
    editAccountById
};
