import React, { useState } from "react";
import { Box, makeStyles, Typography } from "@material-ui/core";
// import * as yup from "yup";
// import GetAppIcon from "@material-ui/icons/GetApp";
import utils from "../../utils/index";
import config from "../../config";
import Alert from "@material-ui/lab/Alert";
import * as Yup from "yup";
import { PanoramaOutlined } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
    rootBox: {
        position: "relative",
        padding: 0,
        width: "auto",
        borderWidth: 1,
        borderStyle: "dashed",
        height: theme.spacing(30),
        borderColor: theme.palette.text.primary,
        cursor: "pointer",
        opacity: 0.5
    },
    inputFile: {
        display: "none"
    },
    description: {
        position: "absolute",
        width: "100%",

        // opacity: 0.5,
        // top: "35%",
        // left: "30%",

        height: theme.spacing(30),
        textAlign: "center"
    },
    imagePreview: {
        maxWidth: "100%",
        maxHeight: "100%",
        objectFit: "contain"
    }
}));
let schema = Yup.object().shape({
    file: Yup.string().notRequired().default(undefined).label("File"),
    fileType: Yup.string()
        .notRequired()
        .default(undefined)
        .oneOf(
            ["image/png", "image/jpg", "image/jpeg"],
            config.validationErrors.fileTypeError
        )
        .label("File"),
    fileSize: Yup.number()
        .notRequired()
        .default(undefined)
        .max(
            config.constants.baseBgImageFileSize * 1000000,
            config.validationErrors.fileSizeError +
                config.constants.baseBgImageFileSize +
                "MB"
        )
        .label("File")
});
export default function ImageDropzone({
    selectedFile,
    setSelectedFile,
    previewImg
}) {
    const classes = useStyles();
    const [error, setError] = useState();

    const resetError = () => {
        setTimeout(() => {
            setError("");
        }, 3000);
    };

    const handleFileInput = (e) => {
        e.preventDefault();
        resetError();
        const input = e.currentTarget;
        const file = input.files[0];
        if (file) {
            const fileType = file.type;
            // const fileData = { file: base64File, fileSize: file.size, fileType };

            utils
                .toBase64(file)
                .then((base64File) => {
                    const fileData = {
                        file: base64File,
                        fileSize: file.size,
                        fileType
                    };
                    schema
                        .validate(fileData)
                        .then(() => {
                            setSelectedFile(fileData);
                        })
                        .catch((e) => {
                            setError(e.message);
                            resetError();
                        });
                })
                .catch((e) => {
                    setError(e.message);
                    resetError();
                });
        }
    };
    const clickInput = () => {
        document.getElementById("chooseimagebutton").click();
    };
    function validURL(str) {
        var pattern = new RegExp(
            "^(https?:\\/\\/)?" + // protocol
                "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
                "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
                "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
                "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
                "(\\#[-a-z\\d_]*)?$",
            "i"
        ); // fragment locator
        return !!pattern.test(str);
    }
    return (
        <Box>
            <Box mb={2}>{error && <Alert severity="error">{error}</Alert>}</Box>
            <Box
                className={classes.rootBox}
                onClick={clickInput}
                //   display="flex"
                //   alignItems="center"
                //   justifyContent="center"
            >
                <Box
                    className={classes.description}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                >
                    <Box>
                        <input
                            type="file"
                            id="chooseimagebutton"
                            className={classes.inputFile}
                            onChange={handleFileInput}
                            onClick={(event) => {
                                setError("");
                                event.target.value = null;
                            }}
                            accept="image/*"
                        />
                    </Box>
                    {!selectedFile?.file && (
                        <Box>
                            <PanoramaOutlined
                                size={20}
                                style={{
                                    fontSize: "3rem"
                                }}
                            />
                            <Typography>Click here to upload image.</Typography>
                            <Typography variant="caption" color="textSecondary">
                                Supported PNG, JPG and JPEG
                            </Typography>
                        </Box>
                    )}
                </Box>
                {(selectedFile?.file || validURL(previewImg)) && (
                    <img
                        src={selectedFile?.file || previewImg}
                        className={classes.imagePreview}
                        alt="bg-img"
                    />
                )}
            </Box>
        </Box>
    );
}
