/* eslint-disable react-hooks/exhaustive-deps */
import {
    Box,
    Typography,
    Switch,
    Button,
    TextField,
    InputAdornment,
    CircularProgress,
    ListItemSecondaryAction,
    // Select,
    // MenuItem,
    Grid,
    Collapse,
    FormControl
} from "@material-ui/core";
// import clsx from "clsx";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

import useGlobalStyles from "../../../hooks/useGlobalStyles";
import actionTypes from "../../../redux/actionTypes";
import authService from "../../../services/auth";
// import userService from "../../../services/user";
import managementService from "../../../services/management";
import * as Yup from "yup";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";

import ListItemText from "@material-ui/core/ListItemText";
import Avatar from "@material-ui/core/Avatar";

import baseIcon from "../../../res/images/base-icon1.png";
import config, { managedBaseStatuses } from "../../../config";
import LabelWithEmoji from "../../../components/common/LabelWithEmoji";

const validationSchema = Yup.object({
    baseUrl: Yup.string()
        // .matches(config.regx.url, config.errors.validation.urlError)
        .required()
    // categoryId: Yup.string()
    //     .required()
    //     .label("Category")
});

export default function AddManagedBase() {
    const gs = useGlobalStyles();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const currentUser = useSelector((s) => s.auth.currentUser);
    const isManagementActive = currentUser?.isManagementActive;

    const [manageBaseReq, setManageBaseReq] = useState({
        baseUrl: "",
        categoryId: ""
    });
    const [selectedManagedBaseToDelete, setSelectedManagedBaseToDelete] =
        useState("");

    const [loading, setLoading] = useState(false);
    const [isAddingMangedBaseReq, setIsAddingMangedBaseReq] = useState(false);
    const [isAcceptingMangedBase, setIsAcceptingMangedBase] = useState(false);
    const [actionStatus, setActionStatus] = useState({
        manageBase: false
    });
    const [isDeletingMangedReq, setIsDeletingMangedReq] = useState(false);

    const management = useSelector((s) => s?.auth?.management);

    const isCurrentUserPro = authService.isCurrentUserPro();
    const isCurrentUserFreeOrPlus = authService.isCurrentUserFreeOrPlus();

    const getUserManagement = () => {
        setActionStatus((ps) => ({ ...ps, manageBase: true }));
        managementService
            .getManagedBase()
            .then(async (res) => {
                setActionStatus((ps) => ({ ...ps, manageBase: false }));
                if (res.data.management) {
                    let managementData = res.data.management;

                    if (isCurrentUserPro) {
                        const mcres =
                            await managementService.getManagementCategories();
                        managementData.categoryList =
                            mcres.data.managementCategories;
                    }
                    dispatch({
                        type: actionTypes.SET_MANAGEMENT,
                        payload: { ...res.data.management }
                    });
                }
            })
            .catch((ex) => {
                setActionStatus((ps) => ({ ...ps, manageBase: false }));
                console.log(ex);
            });
    };

    useEffect(() => {
        console.log("onMount");
        console.log(isManagementActive);
        getUserManagement();
    }, []);

    useEffect(() => {
        console.log(" On isManagementActive update", isManagementActive);
        if (isManagementActive) {
            getUserManagement();
        }
    }, [isManagementActive]);

    const handleManageBaseChange = (e) => {
        const input = e.target.value;
        const inputName = e.target.name;
        setManageBaseReq({ ...manageBaseReq, [inputName]: input });
    };

    const handleManagementStatusChange = (e) => {
        setLoading(true);
        authService
            .updateCurrentUser({
                isManagementActive: !isManagementActive
            })
            .then((res) => {
                setLoading(false);
                const payload = {
                    isManagementActive: !isManagementActive
                };
                if (isManagementActive === false) {
                    payload.managedBy = null;
                }
                dispatch({
                    type: actionTypes.UPDATE_CURRENT_USER,
                    payload: payload
                });
            })
            .catch((ex) => {
                setLoading(false);
            });
    };

    const managementHeaderUI = (
        <Box>
            <Box display={"flex"}>
                <Box display="flex" flexGrow={1}>
                    <Typography color="textPrimary" variant="h6">
                        <LabelWithEmoji text="Management" emoji="💼" />
                    </Typography>
                    {/* <CustomBadge badgeContent={"COMING SOON"}></CustomBadge> */}
                </Box>
                {isCurrentUserFreeOrPlus && (
                    <Box>
                        <Switch
                            checked={isManagementActive}
                            onChange={handleManagementStatusChange}
                            disabled={loading}
                            color="primary"
                        />
                    </Box>
                )}
            </Box>
            {/* <Typography color="textPrimary" variant="subtitle1" gutterBottom>
                Manage and configure another Base
            </Typography> */}
        </Box>
    );

    const handleAddManagedBase = (e) => {
        e.preventDefault();
        const data = manageBaseReq;
        validationSchema
            .validate(data)
            .then(async () => {
                setIsAddingMangedBaseReq(true);

                managementService
                    .addManagedBase(data)
                    .then((res) => {
                        enqueueSnackbar(res.data.message, {
                            variant: "success"
                        });
                        dispatch({
                            type: actionTypes.ADD_MANAGEMENT_REQUEST,
                            payload: res.data.managementRequest
                        });
                        setIsAddingMangedBaseReq(false);
                        setManageBaseReq({
                            baseUrl: "",
                            categoryId: ""
                        });
                        // setBaseUrl("");
                    })
                    .catch((ex) => {
                        enqueueSnackbar(ex?.response?.data?.message, {
                            variant: "error"
                        });
                        setIsAddingMangedBaseReq(false);
                    });
            })
            .catch((err) => {
                enqueueSnackbar(err?.message, {
                    variant: "error"
                });
            });
    };

    const addManagedBaseUI = (
        <Box mt={1}>
            <Grid container spacing={2}>
                {/* <Grid item md={6} sm={12} xs={12}>
                    <Box display={"flex"}>
                        <Box flexGrow={1} mt={1}>
                            <Typography
                                flexGrow={1}
                                variant="subtitle1"
                                color="textSecondary"
                            >
                                Category
                            </Typography>
                        </Box>
                    </Box>
                    <Box>
                        <Select
                            labelId="categoryId-select-label"
                            id="categoryId-select"
                            variant="outlined"
                            placeholder="Select Category"
                            value={manageBaseReq.categoryId}
                            name={"categoryId"}
                            onChange={handleManageBaseChange}
                            fullWidth
                            // label="Age"
                            // onChange={handleChange}
                        >
                            {managementCaategoryList?.map((e) => {
                                return (
                                    <MenuItem key={e._id} value={e._id}>
                                        {e.title}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </Box>
                </Grid> */}
                <Grid item md={12} sm={12} xs={12}>
                    <Box display={"flex"}>
                        <Box flexGrow={1} mt={1}>
                            <Typography
                                flexGrow={1}
                                variant="subtitle1"
                                color="textSecondary"
                            >
                                Profile URL
                            </Typography>
                        </Box>
                    </Box>
                    <FormControl fullWidth style={{ position: "relative" }}>
                        <TextField
                            value={manageBaseReq.baseUrl}
                            name={"baseUrl"}
                            variant="outlined"
                            onChange={handleManageBaseChange}
                            fullWidth
                            style={{ width: "100%", flexGrow: 1 }}
                            InputProps={{
                                // style: { justifyContent: "space-between" },
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Typography color="primary">
                                            base.gg/
                                        </Typography>
                                    </InputAdornment>
                                )
                                // endAdornment: (
                                //     <InputAdornment position="end">
                                //         <Button
                                //             color={"primary"}
                                //             size="small"
                                //             onClick={handleAddManagedBase}
                                //             disabled={isAddingMangedBaseReq}
                                //             fullWidth
                                //         >
                                //             {!isAddingMangedBaseReq ? (
                                //                 "Add Base"
                                //             ) : (
                                //                 <CircularProgress size={20} />
                                //             )}
                                //         </Button>
                                //     </InputAdornment>
                                // )
                            }}
                        />
                        <Box top={8} right={8} position={"absolute"}>
                            {/* <InputAdornment position="end"> */}
                            <Button
                                color={"primary"}
                                size="small"
                                onClick={handleAddManagedBase}
                                disabled={isAddingMangedBaseReq}
                                // fullWidth
                            >
                                {!isAddingMangedBaseReq ? (
                                    "Add Base"
                                ) : (
                                    <CircularProgress size={20} />
                                )}
                            </Button>
                            {/* </InputAdornment> */}
                        </Box>
                    </FormControl>
                </Grid>
            </Grid>
        </Box>
    );

    const ManagementRequestsUI = isCurrentUserFreeOrPlus && (
        <Box bgcolor="background.secondary" p={2} mb={1} borderRadius={4}>
            <Typography color="textPrimary" variant="h6">
                <LabelWithEmoji
                    text={
                        isCurrentUserPro
                            ? "Requested Management"
                            : "Management Requests"
                    }
                    emoji=""
                />
            </Typography>
            <Box>
                {management?.managementRequests?.length === 0 && (
                    <Box textAlign={"center"} mt={1}>
                        <Typography variant="caption" color="textSecondary">
                            {isCurrentUserPro
                                ? "You haven't sent any requests yet."
                                : "No Management requests"}
                        </Typography>
                    </Box>
                )}
                <List dense={true}>
                    {management?.managementRequests?.map((e) => {
                        let managedBaseUrl = e?.managedUser?.baseUrl;
                        let managedBaseAvatarUrl = e?.managedUser?.avatarUrl;
                        if (isCurrentUserPro) {
                            managedBaseUrl = e?.managedUser?.baseUrl;
                            managedBaseAvatarUrl = e?.managedUser?.avatarUrl;
                        } else if (isCurrentUserFreeOrPlus) {
                            managedBaseUrl = e?.managedBy?.baseUrl;
                            managedBaseAvatarUrl = e?.managedBy?.avatarUrl;
                        }
                        const isActionDisabled =
                            isAcceptingMangedBase || isDeletingMangedReq;
                        return (
                            <ListItem key={e._id}>
                                <ListItemAvatar>
                                    <Avatar
                                        src={
                                            managedBaseAvatarUrl
                                                ? managedBaseAvatarUrl
                                                : baseIcon
                                        }
                                    />
                                </ListItemAvatar>
                                <ListItemText
                                    primary={
                                        managedBaseUrl ? managedBaseUrl : "N/A"
                                    }
                                />
                                {isCurrentUserFreeOrPlus && (
                                    <ListItemSecondaryAction>
                                        <Button
                                            onClick={() =>
                                                handleAccept(
                                                    e._id,
                                                    managedBaseStatuses.accepted
                                                )
                                            }
                                            color="primary"
                                            size="small"
                                            disabled={isActionDisabled}
                                        >
                                            {!isAcceptingMangedBase ? (
                                                "Accept"
                                            ) : (
                                                <CircularProgress size={20} />
                                            )}
                                        </Button>

                                        <Button
                                            size="small"
                                            disabled={isActionDisabled}
                                            onClick={() =>
                                                handleDeleteManagedRequest(
                                                    e._id
                                                )
                                            }
                                        >
                                            {!isDeletingMangedReq ? (
                                                "Deny"
                                            ) : (
                                                <CircularProgress size={20} />
                                            )}
                                        </Button>
                                    </ListItemSecondaryAction>
                                )}
                            </ListItem>
                        );
                    })}
                </List>
            </Box>
        </Box>
    );

    const ManagedBasesUI = (
        <Box bgcolor="background.secondary" p={2} mb={2} borderRadius={4}>
            <Typography color="textPrimary" variant="h6">
                <LabelWithEmoji
                    text={
                        isCurrentUserPro
                            ? "Base under management"
                            : "Managed By"
                    }
                    emoji=""
                />
            </Typography>
            <Box>
                {management &&
                    management?.managedBases &&
                    Object.keys(management?.managedBases)?.length === 0 && (
                        <Box textAlign={"center"} mt={1}>
                            <Typography
                                variant="caption"
                                color="textSecondary"
                                gutterBottom
                            >
                                {isCurrentUserPro
                                    ? "You are not managing anyone yet."
                                    : "You are not being managed."}
                            </Typography>
                        </Box>
                    )}
                <List dense={true}>
                    {management &&
                        management?.managedBases &&
                        Object.keys(management?.managedBases).length > 0 &&
                        Object.keys(management?.managedBases)?.map(
                            (category) => {
                                return (
                                    <Box key={category}>
                                        {/* {isCurrentUserPro && (
                                            <Box>
                                                <Typography
                                                    variant="subtitle1"
                                                    // color="primary"
                                                    color="textSecondary"
                                                >
                                                    {category}
                                                </Typography>
                                            </Box>
                                        )} */}
                                        <Box mt={1}>
                                            {management?.managedBases[
                                                category
                                            ]?.map((e) => {
                                                let managedBaseUrl =
                                                    e?.managedUser?.baseUrl;
                                                let managedBaseAvatarUrl =
                                                    e?.managedUser?.avatarUrl;
                                                if (isCurrentUserPro) {
                                                    managedBaseUrl =
                                                        e?.managedUser?.baseUrl;
                                                    managedBaseAvatarUrl =
                                                        e?.managedUser
                                                            ?.avatarUrl;
                                                } else if (
                                                    isCurrentUserFreeOrPlus
                                                ) {
                                                    managedBaseUrl =
                                                        e?.managedBy?.baseUrl;
                                                    managedBaseAvatarUrl =
                                                        e?.managedBy?.avatarUrl;
                                                }
                                                return (
                                                    <ListItem key={e._id}>
                                                        <ListItemAvatar>
                                                            <Avatar
                                                                src={
                                                                    managedBaseAvatarUrl
                                                                        ? managedBaseAvatarUrl
                                                                        : baseIcon
                                                                }
                                                            />
                                                        </ListItemAvatar>
                                                        <ListItemText
                                                            primary={
                                                                managedBaseUrl
                                                                    ? managedBaseUrl
                                                                    : "N/A"
                                                            }
                                                        />
                                                        <ListItemSecondaryAction>
                                                            <Button
                                                                className={
                                                                    gs.deleteButton
                                                                }
                                                                size="small"
                                                                disabled={
                                                                    "" !==
                                                                    selectedManagedBaseToDelete
                                                                }
                                                                onClick={() =>
                                                                    handleDeleteManagedBase(
                                                                        e._id,
                                                                        category
                                                                    )
                                                                }
                                                            >
                                                                {e._id !==
                                                                selectedManagedBaseToDelete ? (
                                                                    "Cancel"
                                                                ) : (
                                                                    <CircularProgress
                                                                        size={
                                                                            20
                                                                        }
                                                                    />
                                                                )}
                                                            </Button>
                                                        </ListItemSecondaryAction>
                                                    </ListItem>
                                                );
                                            })}
                                        </Box>
                                    </Box>
                                );
                            }
                        )}
                </List>
            </Box>
        </Box>
    );

    const handleAccept = (managedBaseId, status) => {
        setIsAcceptingMangedBase(true);
        managementService
            .updateManagedBase({ managedBaseId, status })
            .then((res) => {
                enqueueSnackbar(res.data.message, {
                    variant: "success"
                });
                dispatch({
                    type: actionTypes.SET_MANAGED_BASE,
                    payload: res.data.managedBase
                });
                dispatch({
                    type: actionTypes.UPDATE_CURRENT_USER,
                    payload: {
                        managedBy: res.data.managedBy,
                        userType: config.userType.plus
                    }
                });
                dispatch({
                    type: actionTypes.UPDATE_USER,
                    payload: {
                        managedBy: res.data.managedBy,
                        userType: config.userType.plus
                    }
                });
                dispatch({
                    type: actionTypes.REMOVE_MANAGEMENT_REQUEST,
                    payload: managedBaseId
                });
                setIsAcceptingMangedBase(false);
            })
            .catch((ex) => {
                enqueueSnackbar(ex?.response?.data?.message, {
                    variant: "error"
                });
                setIsAcceptingMangedBase(false);
            });
    };

    const handleDeleteManagedBase = (managedBaseId, category) => {
        setSelectedManagedBaseToDelete(managedBaseId);
        managementService
            .deleteManagedBase(managedBaseId)
            .then((res) => {
                enqueueSnackbar(res.data.message, {
                    variant: "success"
                });
                const categoryMangedBaseList =
                    management?.managedBases[category];
                const filteredCategoryMangedBaseList =
                    categoryMangedBaseList.filter(
                        (e) => e._id !== managedBaseId
                    );
                const updatedMnageBases = {
                    ...management?.managedBases,
                    [category]: filteredCategoryMangedBaseList
                };
                dispatch({
                    type: actionTypes.SET_MANAGED_BASE,
                    payload: updatedMnageBases
                });
                if (isCurrentUserFreeOrPlus) {
                    dispatch({
                        type: actionTypes.UPDATE_CURRENT_USER,
                        payload: {
                            managedBy: null,
                            userType: config.userType.free
                        }
                    });
                    dispatch({
                        type: actionTypes.UPDATE_USER,
                        payload: {
                            managedBy: null,
                            userType: config.userType.free
                        }
                    });
                }
                setSelectedManagedBaseToDelete("");
            })
            .catch((ex) => {
                enqueueSnackbar(ex?.response?.data?.message, {
                    variant: "error"
                });
                setSelectedManagedBaseToDelete("");
            });
    };

    const handleDeleteManagedRequest = (managedBaseId) => {
        setIsDeletingMangedReq(true);
        managementService
            .deleteManagedBase(managedBaseId)
            .then((res) => {
                enqueueSnackbar(res.data.message, {
                    variant: "success"
                });
                dispatch({
                    type: actionTypes.REMOVE_MANAGEMENT_REQUEST,
                    payload: managedBaseId
                });
                setIsDeletingMangedReq(false);
            })
            .catch((ex) => {
                enqueueSnackbar(ex?.response?.data?.message, {
                    variant: "error"
                });
                setIsDeletingMangedReq(false);
            });
    };

    //write down an iff condition to show only if user is pro or if not pro then show only if management is active

    return (
        <Box>
            {managementHeaderUI}
            <Box>{isCurrentUserPro && addManagedBaseUI}</Box>
            <Collapse
                in={
                    actionStatus.manageBase ||
                    (isCurrentUserFreeOrPlus && isManagementActive) ||
                    isCurrentUserPro
                }
            >
                {actionStatus.manageBase && (
                    <Box
                        pt={2}
                        display={"flex"}
                        justifyContent={"center"}
                        alignItems={"center"}
                    >
                        <CircularProgress size={20} />
                    </Box>
                )}
                <Box mt={2}>
                    {ManagedBasesUI}
                    {ManagementRequestsUI}
                </Box>
            </Collapse>
        </Box>
    );
}
