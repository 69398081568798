import React from "react";
import { Route, Redirect } from "react-router-dom";
import config from "../../config";

export default function AppUserRoutes({ component: Component, user, ...rest }) {
    // const user = auth.authenticatedUser();
    // const user = useSelector((state) => state.auth.user);
    const appUsers = config.userType;
    const currentUserType = user?.userType;
    return (
        // Show the component only when the user is logged in
        // Otherwise, redirect the user to /signin page
        <Route
            {...rest}
            exact
            render={(props) =>
                user &&
                (currentUserType === appUsers.free ||
                    currentUserType === appUsers.plus ||
                    currentUserType === appUsers.pro) &&
                user.baseUrl ? (
                    <Component {...props} user={user} />
                ) : user && currentUserType === appUsers.admin ? (
                    <Redirect to={config.routes.admin} />
                ) : user && !user.baseUrl ? (
                    <Redirect to={config.routes.completeRegistration} />
                ) : (
                    <Redirect to={config.routes.login} />
                )
            }
        />
    );
}
