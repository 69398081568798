import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
    AppBar,
    Toolbar,
    IconButton,
    // Typography,
    Drawer,
    List,
    ListItem,
    ListItemText,
    Box
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import Logo from "../../../res/images/landingPage/base-gg-logo.png";

// import "./Navbar.css";
// import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
    appBar: {
        backgroundColor: "transparent",
        boxShadow: "none",
        paddingTop: 60
    },

    toolbar: {
        boxShadow: "none",
        padding: 0,
        minHeight: 0
    },
    navlinksContainer: {
        display: "flex",
        [theme.breakpoints.down("sm")]: {
            display: "none"
        }
    },
    navlinks: {
        display: "flex",
        flexDirection: "row"
    },
    customNavlinks: {
        display: "flex",
        flexDirection: "row",
        listStyleType: "none",
        margin: "0 !important"
    },

    drawerPaper: {
        width: 240
    },
    menuButton: {
        marginLeft: "auto",
        [theme.breakpoints.up("md")]: {
            display: "none"
        }
    },
    navMenu: {
        fontFamily: "Darker Grotesque",
        fontSize: 18,
        fontWeight: 700,

        letterSpacing: "0em",
        textAlign: "left",
        textDecoration: "none",
        color: "white",
        marginLeft: 20,
        marginRight: 20
    }
}));

function Navbar() {
    const classes = useStyles();
    const [open, setOpen] = useState(false);

    const handleDrawerToggle = () => {
        setOpen(!open);
    };

    const drawer = (
        <div>
            <List>
                <ListItem button>
                    <ListItemText
                        className={classes.navMenu}
                        primary="FEATURES"
                    />
                </ListItem>
                <ListItem button>
                    <ListItemText
                        className={classes.navMenu}
                        primary="CONTACT"
                    />
                </ListItem>
            </List>
        </div>
    );

    return (
        <>
            <AppBar position="static" className={classes.appBar}>
                <Toolbar className={classes.toolbar}>
                    <Box>
                        <img src={Logo} alt="logo" />
                    </Box>
                    {/* <div className={classes.navlinksContainer}>
                        <ul
                            className={clsx(
                                classes.navlinks,
                                classes.customNavlinks
                            )}
                        >
                            <li className="custom-navlink">
                                <a className={classes.navMenu} href="/">
                                    FEATURES
                                </a>
                            </li>
                            <li className="custom-navlink">
                                <a className={classes.navMenu} href="/">
                                    CONTACT
                                </a>
                            </li>
                        </ul>
                    </div> */}

                    <IconButton
                        edge="end"
                        color="inherit"
                        aria-label="menu"
                        onClick={handleDrawerToggle}
                        className={classes.menuButton}
                    >
                        <MenuIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Drawer
                variant="temporary"
                anchor="right"
                open={open}
                onClose={handleDrawerToggle}
                classes={{ paper: classes.drawerPaper }}
            >
                {drawer}
            </Drawer>
        </>
    );
}

export default Navbar;
