/* eslint-disable react-hooks/exhaustive-deps */
import {
    Box,
    // Grid,
    InputAdornment,
    // Paper,
    TextField,
    Typography
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
// import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { useSnackbar } from "notistack";
import config from "../../../../config";

// import useGlobalStyles from "../../../../hooks/useGlobalStyles";
import actionTypes from "../../../../redux/actionTypes";
// import userService from "../../../../services/user";
import LabelWithEmoji from "../../../../components/common/LabelWithEmoji";
import EditDashboardSectionHeader from "../components/editDashboardSectionHeader";
import auth from "../../../../services/auth";

// const errors = config.validationErrors;
const CONSTANT = config.constants;

let schema = yup.object().shape({
    baseUrl: yup
        .string()
        .required()
        .min(CONSTANT.nameMinCharacter)
        .max(CONSTANT.nameMaxCharacter)
        .label("Url"),
    displayName: yup
        .string()
        .required()
        .min(CONSTANT.nameMinCharacter)
        .max(CONSTANT.nameMaxCharacter)
        .label("Name"),
    bio: yup
        .string()
        .notRequired()
        .default("")
        .min(0)
        .max(CONSTANT.bioMaxCharacter)
        .label("Bio")
});

export default function EditBaseDetails() {
    // const globalClasses = useGlobalStyles();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const user = useSelector((state) => state.auth.user);
    const [baseUrl, setbaseUrl] = useState("");
    const [displayName, setDisplayName] = useState("");
    const [bio, setBio] = useState("");
    const [error, setError] = useState("");
    // const [successMsg, setSuccessMsg] = useState("");
    // const [loading, setLoading] = useState(false);
    // const [saveButtonText, setSaveButtonText] = useState({
    //     operation: "",
    //     status: ""
    // });
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setbaseUrl(user.baseUrl);
        setDisplayName(user.displayName);
        setBio(user.bio);
    }, []);
    useEffect(() => {}, [user]);

    const submitForm = (e) => {
        e.preventDefault();
        if (
            user.bio !== bio ||
            user.displayName !== displayName ||
            user.baseUrl !== baseUrl
        ) {
            let userData;

            userData = {
                bio,
                displayName,
                baseUrl
                // userId: user._id
            };

            schema
                .validate(userData)
                .then(() => {
                    setError("");
                    // setLoading(true);
                    updateBaseDetails(userData);
                })
                .catch((e) => {
                    setError(e.message);
                    setTimeout(() => setError(""), 3000);

                    // scroll.scrollToTop();
                });
        }
    };
    const updateBaseDetails = (data) => {
        // setSaveButtonText({
        //     operation: "saveBaseDetail",
        //     status: "Saving..."
        // });
        setIsLoading(true);
        auth.updateCurrentUser(data)
            .then((res) => {
                enqueueSnackbar(config.defaultNotiStackMessage, {
                    variant: "success"
                });
                // setLoading(false);
                // setSuccessMsg(config.defaultNotiStackMessage);

                const user = res.data.user;

                dispatch({ type: actionTypes.SET_USER, payload: { user } });

                setIsLoading(false);
            })
            .catch((ex) => {
                // setLoading(false);
                // setError(ex?.response?.data?.message);
                enqueueSnackbar(ex?.response?.data?.message, {
                    variant: "error"
                });
                setIsLoading(false);
            });
    };
    return (
        <Box>
            {/* <Grid item md={12} xs={12}> */}
            {/* {loading && (
          <Box align="center">
            <CircularProgress />
          </Box>
        )} */}
            {error && <Alert severity="error">{error}</Alert>}
            {/* {successMsg && <Alert severity="success">{successMsg}</Alert>} */}
            <form onSubmit={submitForm}>
                {/* <Box display="flex">
                            <Box flexGrow={1}>
                                <Typography color="primary" gutterBottom>
                                    <LabelWithEmoji
                                        text="base details"
                                        emoji="☑️"
                                    />
                                </Typography>
                            </Box>
                            <Box>
                                <Button

                                    type="submit"
                                    color="primary"
                                    disabled={saveButtonText.operation !== ""}
                                >
                                    {saveButtonText.operation ===
                                    "saveBaseDetail"
                                        ? saveButtonText.status
                                        : "save"}
                                </Button>
                            </Box>
                        </Box> */}
                <Box mb={2.5}>
                    <EditDashboardSectionHeader
                        headerText={
                            <Typography color="textPrimary" variant="h6">
                                <LabelWithEmoji
                                    text="Base Details"
                                    emoji="☑️"
                                />
                            </Typography>
                        }
                        onSave={submitForm}
                        isLoading={isLoading}
                    />
                </Box>

                <Box mb={2.5}>
                    <Typography variant="subtitle1" color="textSecondary">
                        URL
                    </Typography>
                    <TextField
                        disabled={isLoading}
                        required
                        value={baseUrl}
                        placeholder="username"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Typography color="primary">
                                        base.gg/
                                    </Typography>
                                </InputAdornment>
                            )
                        }}
                        onChange={(e) =>
                            setbaseUrl(
                                e.currentTarget.value.trim().toLowerCase()
                            )
                        }
                        fullWidth
                        variant="outlined"
                    />
                </Box>
                <Box mb={2.5}>
                    <Typography variant="subtitle1" color="textSecondary">
                        Name
                    </Typography>
                    <TextField
                        value={displayName}
                        disabled={isLoading}
                        required
                        fullWidth
                        placeholder="e.g. full name"
                        onChange={(e) => setDisplayName(e.currentTarget.value)}
                        variant="outlined"
                    />
                </Box>
                <Box>
                    <Typography variant="subtitle1" color="textSecondary">
                        Bio
                    </Typography>

                    <TextField
                        multiline
                        fullWidth
                        disabled={isLoading}
                        value={bio}
                        minRows={4}
                        placeholder="write something about yourself"
                        onChange={(e) => setBio(e.currentTarget.value)}
                        variant="outlined"
                    />
                </Box>
                {/* <Button type="submit" color="primary" fullWidth>
                {" "}
                Save
              </Button> */}
            </form>
            {/* </Grid> */}
        </Box>
    );
}
