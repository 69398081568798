import React from "react";
import {
    Avatar,
    Box,
    CircularProgress,
    Grid,
    makeStyles,
    // Tooltip,
    Typography
} from "@material-ui/core";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
    accountsAvatar: {
        width: theme.spacing(5),
        height: theme.spacing(5),
        [theme.breakpoints.down("sm")]: {
            width: theme.spacing(3),
            height: theme.spacing(3)
        }
    },
    // accountName: {
    //     padding: theme.spacing(0.6, 1),
    //     fontSize: 12,
    //     [theme.breakpoints.down("sm")]: {
    //         padding: theme.spacing(0.5),
    //         fontSize: 10
    //     }
    // },
    accountList: {
        height: theme.spacing(30),
        // overflow: "auto"
        overflowY: "scroll",
        overflowX: "hidden"
    },
    account: {
        padding: theme.spacing(1),
        cursor: "pointer",
        "&:hover": {
            backgroundColor: "#787878",
            padding: theme.spacing(1),
            borderRadius: 2
        }
    },
    toggleMargin: {
        marginTop: theme.spacing(2)
    }
}));
export default function AccountCategoryList({ setSelectedAccount }) {
    const classes = useStyles();
    const accountCategoryList = useSelector(
        (state) => state.accountCategories.accountCategoriesList
    );

    //   const [accountList, setAccountList] = useState([]);
    //   const [selectedAccount, setSelectedAccount] = React.useState({});

    return (
        <div>
            <Box
                className={classes.accountList}
                px={2}
                // bgcolor={"background.default"}
            >
                {accountCategoryList &&
                    Object.entries(accountCategoryList).map(([key, value]) => {
                        return (
                            <Box marginTop={2} key={key}>
                                <Box marginTop={2} marginBottom={2}>
                                    <Typography
                                        color="textPrimary"
                                        variant="subtitle1"
                                    >
                                        {/* {key.toUpperCase()} */}
                                        {key}
                                    </Typography>
                                </Box>
                                <Grid container spacing={0}>
                                    {value.map((acc) => (
                                        <Grid item key={acc._id}>
                                            <Box
                                                // display={"flex"}
                                                className={classes.account}
                                                onClick={() =>
                                                    setSelectedAccount(acc)
                                                }
                                            >
                                                <Box display={"flex"}>
                                                    <Avatar
                                                        src={
                                                            acc.accountImageUrl
                                                        }
                                                        className={
                                                            classes.accountsAvatar
                                                        }
                                                    />
                                                    <Box p={1}>
                                                        <Typography
                                                            variant={
                                                                "subtitle1"
                                                            }
                                                            className={
                                                                classes.accountName
                                                            }
                                                        >
                                                            {acc.accountName}
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Box>
                        );
                    })}
            </Box>
            {!accountCategoryList && (
                <Box align="center" mt={0.5}>
                    <CircularProgress color="primary" size={18} />
                </Box>
            )}
        </div>
    );
}
