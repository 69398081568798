/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Box, Typography } from "@material-ui/core";
import * as Yup from "yup";
import { Alert } from "@material-ui/lab";

import SaveActionButton from "./SaveActionButton";
import ColorPickerField from "../../../components/core/ColorPickerField";
import LabelWithEmoji from "../../../components/common/LabelWithEmoji";
import config from "../../../config";

var validationSchema = Yup.object({
    textColor: Yup.string()
        .required()
        .matches(config.regx.color, config.validationErrors.colorError)
});

export default function TextDesignUI({
    textColor,
    onTextDesignUpdate,
    isLoading
}) {
    const [textColorVal, setTextColorVal] = useState("transparent");
    const [error, setError] = useState("");

    useEffect(() => {
        setStates();
    }, []);

    const setStates = () => {
        setTextColorVal(textColor);
    };

    const resetErrroMessage = () => {
        setTimeout(() => {
            setError("");
        }, 3000);
    };

    const handleBaseText = (e) => {
        e.preventDefault();
        if (textColorVal !== textColor) {
            validationSchema
                .validate({ textColor: textColorVal })
                .then(() => {
                    onTextDesignUpdate({ textColor: textColorVal });
                })
                .catch((ex) => {
                    setError(ex.message);
                    resetErrroMessage();
                });
        }
    };

    return (
        <Box>
            <SaveActionButton
                label={
                    <Typography color="textPrimary" variant="h6">
                        <LabelWithEmoji text="Text Design" emoji="ℹ️" />
                    </Typography>
                }
                isLoading={isLoading}
                onSave={handleBaseText}
            />
            {error && (
                <Box mt={1}>
                    <Alert severity="error">{error}</Alert>
                </Box>
            )}
            <Box mt={1}>
                <ColorPickerField
                    fieldLabel={"Base Text Color"}
                    color={textColorVal}
                    onColorPickerValueChange={(color) => {
                        setError("");
                        setTextColorVal(`#${color.hex}`);
                    }}
                    onFieldValueChange={(e) => {
                        setError("");
                        setTextColorVal(`${e.currentTarget.value}`);
                    }}
                />
            </Box>
        </Box>
    );
}
