import config from "../config";
import axios from "../config/axios";

const addManagedBase = async (data) => {
    return axios.post(
        `${config.apiEndPoint}${config.apiRoutes.user.management}`,
        data
    );
};
const getManagedBase = async () => {
    return axios.get(
        `${config.apiEndPoint}${config.apiRoutes.user.management}`
    );
};
const updateManagedBase = async (data) => {
    return axios.put(
        `${config.apiEndPoint}${config.apiRoutes.user.management}`,
        data
    );
};
const deleteManagedBase = async (id) => {
    return axios.delete(
        `${config.apiEndPoint}${config.apiRoutes.user.management}`,
        { data: { id: id } }
    );
};

const addManagementCategory = (data) => {
    return axios.post(
        `${config.apiEndPoint}${config.apiRoutes.user.managementCategory}`,
        data
    );
};

const getManagementCategories = () => {
    return axios.get(
        `${config.apiEndPoint}${config.apiRoutes.user.managementCategory}`
    );
};

export default {
    getManagedBase,
    addManagedBase,
    updateManagedBase,
    deleteManagedBase,
    addManagementCategory,
    getManagementCategories
};
