import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Paper from "@material-ui/core/Paper";
import {
    Avatar,
    Box,
    CircularProgress,
    LinearProgress,
    Typography,
    // Button,
    IconButton
} from "@material-ui/core";
import AdminPageContainer from "../../components/core/AdminPageContainer";
import clsx from "clsx";
import user from "../../services/user";
import Alert from "@material-ui/lab/Alert";
import ConfirmationDialog from "../../components/common/ConfirmationDialog";
// import AddAccount from "./AddAccount";
import accounts from "../../services/accounts";
import config from "../../config";
// import { Link } from "react-router-dom";
// import useGlobalStyles from "../../hooks/useGlobalStyles";
import ExpandableTableRow from "./components/ExpandableTableRow";
import EditAccounts from "./Accounts/EditAccount";
import { Close, Edit } from "@material-ui/icons";

const headCells = [
    //   { id: 'name', numeric: false, disablePadding: true, label: 'Dessert (100g serving)' },
    {
        id: "accountImageUrl",
        numeric: false,
        disablePadding: false,
        label: "Image",
        width: 20
    },
    {
        id: "accountName",
        numeric: false,
        disablePadding: false,
        label: "Account",
        width: 20
    },
    { id: "accountUrl", numeric: false, disablePadding: false, label: "URL" },
    {
        id: "accountCategory",
        numeric: false,
        disablePadding: false,
        label: "Category"
    },
    {
        id: "accountBackgroundColor",
        numeric: false,
        disablePadding: false,
        label: "Account Color"
    },
    {
        id: "accountTextColor",
        numeric: false,
        disablePadding: false,
        label: "Text Color"
    },
    {
        id: "action",
        numeric: false,
        disablePadding: false,
        label: "Action"
    }
    // { id: "action", numeric: false, disablePadding: false, label: "Action" },
];

function descendingComparator(a, b, orderBy) {
    if (b && b.orderBy && a && a.orderBy) {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === "desc"
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
    const { classes, order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        padding={headCell.disablePadding ? "none" : "default"}
                        sortDirection={orderBy === headCell.id ? order : false}
                        className={clsx(
                            classes.tableCell,
                            classes.tableHeadCells
                        )}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : "asc"}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === "desc"
                                        ? "sorted descending"
                                        : "sorted ascending"}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%"
    },
    paper: {
        width: "100%",
        padding: theme.spacing(4),
        marginBottom: theme.spacing(2)
    },
    table: {
        minWidth: 750
    },
    tableCell: {
        // border: `1px solid ${theme.palette.primary.main}`
        border: "none"
    },
    tableHeadCells: {
        color: theme.palette.primary.main
    },
    visuallyHidden: {
        border: 0,
        clip: "rect(0 0 0 0)",
        height: 1,
        margin: -1,
        overflow: "hidden",
        padding: 0,
        position: "absolute",
        top: 20,
        width: 1
    },

    editButton: {
        color: theme.palette.error.light,
        borderColor: theme.palette.error.light
    }
}));

export default function CustomAccounts(props) {
    const classes = useStyles();
    // const globalClasses = useGlobalStyles();
    const [order, setOrder] = React.useState("asc");
    const [orderBy, setOrderBy] = React.useState("accountName");
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(100);

    const [accountList, setAccountList] = useState([]);
    const [loading, setLoading] = useState({ status: true, type: "linear" });
    const [error, setError] = useState("");
    const [successMsg, setSuccessMsg] = useState("");

    const [openConfirmationDialog, setOpenConfirmationDialog] =
        React.useState(false);
    const [selectedAccountToEdit, setSelectedAccountToEdit] =
        React.useState("");

    useEffect(() => {
        accounts
            .getAllCustomAccounts()
            .then((res) => {
                setAccountList(res.data.accounts);
                setLoading({ status: false });
            })
            .catch((err) => {
                setError(err.message);
                setLoading({ status: false });
            });
    }, []);
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleClose = () => {
        setSelectedAccountToEdit("");
        // setOpenConfirmationDialog(false);
    };

    // const addAccount = (account) => {
    //     setAccountList([account, ...accountList]);
    // };

    const deleteUser = () => {
        setOpenConfirmationDialog(false);
        if (selectedAccountToEdit && selectedAccountToEdit._id) {
            const id = selectedAccountToEdit._id;
            setLoading({ status: true, type: "circular" });
            user.deleteUserById(id)
                .then((res) => {
                    setSuccessMsg(config.defaultNotiStackMessage);
                    setLoading({ status: false });
                    const updatedList = accountList.filter(
                        (user) => user._id !== id
                    );
                    setAccountList(updatedList);
                    setTimeout(() => setSuccessMsg(""), 5000);
                })
                .catch((ex) => {
                    setLoading({ status: false });
                    setError(ex?.response?.data?.message);
                    setTimeout(() => setError(""), 5000);
                });
        }
    };
    // const ExpandableTableRow = ({
    //     children,
    //     expandComponent,
    //     isExpanded,
    //     ...otherProps
    // }) => {
    //     // const [isExpanded, setIsExpanded] = React.useState(false);

    //     return (
    //         <>
    //             <TableRow {...otherProps}>
    //                 {/* <TableCell padding="checkbox">
    //                     <IconButton onClick={() => setIsExpanded(!isExpanded)}>
    //                         {isExpanded ? (
    //                             <KeyboardArrowUpIcon />
    //                         ) : (
    //                             <KeyboardArrowDownIcon />
    //                         )}
    //                     </IconButton>
    //                 </TableCell> */}
    //                 {children}
    //             </TableRow>
    //             {isExpanded && (
    //                 <TableRow>
    //                     {/* <TableCell padding="checkbox" /> */}
    //                     <TableCell
    //                         padding={0}
    //                         colSpan={Object.keys(headCells[0]).length + 2}
    //                     >
    //                         {expandComponent}
    //                     </TableCell>
    //                 </TableRow>
    //             )}
    //         </>
    //     );
    // };
    const onSuccessfulEdit = (account) => {
        const updatedAccount = accountList.map((el) =>
            el._id === account._id ? account : el
        );
        setAccountList(updatedAccount);
        setSelectedAccountToEdit("");
    };
    return (
        <AdminPageContainer {...props}>
            <div className={classes.root}>
                {loading && loading.status && loading.type === "linear" && (
                    <LinearProgress />
                )}
                <Paper className={classes.paper}>
                    {loading && loading.status && loading.type === "circular" && (
                        <Box align="center">
                            <CircularProgress />
                        </Box>
                    )}
                    {error && <Alert severity="error">{error}</Alert>}
                    {successMsg && (
                        <Alert severity="success">{successMsg}</Alert>
                    )}

                    <br />
                    <Typography
                        component="h1"
                        variant="h4"
                        color="primary"
                        gutterBottom
                    >
                        Custom Accounts
                    </Typography>
                    <TableContainer>
                        <Table
                            className={classes.table}
                            aria-labelledby="tableTitle"
                            aria-label="enhanced table"
                            size="small"
                        >
                            <EnhancedTableHead
                                classes={classes}
                                order={order}
                                orderBy={orderBy}
                                onRequestSort={handleRequestSort}
                                rowCount={accountList.length}
                            />
                            <TableBody>
                                {stableSort(
                                    accountList,
                                    getComparator(order, orderBy)
                                )
                                    .slice(
                                        page * rowsPerPage,
                                        page * rowsPerPage + rowsPerPage
                                    )
                                    .map((account, index) => {
                                        return (
                                            <ExpandableTableRow
                                                expandedRowcellProps={{
                                                    colSpan:
                                                        Object.keys(
                                                            headCells[0]
                                                        ).length + 2
                                                }}
                                                expandComponent={
                                                    <EditAccounts
                                                        account={account}
                                                        onSuccess={
                                                            onSuccessfulEdit
                                                        }
                                                    />
                                                }
                                                isExpanded={
                                                    selectedAccountToEdit._id ===
                                                    account._id
                                                        ? true
                                                        : false
                                                }
                                                tabIndex={-1}
                                                key={
                                                    account && account._id
                                                        ? account._id
                                                        : index
                                                }
                                            >
                                                <TableCell
                                                    className={
                                                        classes.tableCell
                                                    }
                                                    width={10}
                                                >
                                                    <Avatar
                                                        src={
                                                            account.accountImageUrl
                                                        }
                                                    />
                                                </TableCell>
                                                <TableCell
                                                    className={
                                                        classes.tableCell
                                                    }
                                                >
                                                    {account.accountName}
                                                </TableCell>
                                                <TableCell
                                                    className={
                                                        classes.tableCell
                                                    }
                                                >
                                                    {account.accountUrl}
                                                </TableCell>
                                                <TableCell
                                                    className={
                                                        classes.tableCell
                                                    }
                                                >
                                                    {account.accountCategory}
                                                </TableCell>
                                                <TableCell
                                                    className={
                                                        classes.tableCell
                                                    }
                                                    width={150}
                                                >
                                                    <Box
                                                        width={30}
                                                        height={30}
                                                        borderRadius={8}
                                                        bgcolor={
                                                            account.accountBackgroundColor
                                                        }
                                                    />
                                                </TableCell>
                                                <TableCell
                                                    className={
                                                        classes.tableCell
                                                    }
                                                    width={150}
                                                >
                                                    <Box
                                                        width={30}
                                                        height={30}
                                                        borderRadius={8}
                                                        bgcolor={
                                                            account.accountTextColor
                                                        }
                                                    />
                                                </TableCell>
                                                <TableCell
                                                    className={
                                                        classes.tableCell
                                                    }
                                                    width={50}
                                                >
                                                    {/* <Box
                                                        style={{
                                                            display: "flex"
                                                        }}
                                                    > */}
                                                    {/* <Link
                                                        className={
                                                            globalClasses.aTag
                                                        }
                                                        to={`${
                                                            config.routes.adminEditAccount.split(
                                                                ":"
                                                            )[0]
                                                        }${account._id}`}
                                                    > */}

                                                    {selectedAccountToEdit._id ===
                                                    account._id ? (
                                                        <IconButton
                                                            size="small"
                                                            onClick={() =>
                                                                setSelectedAccountToEdit(
                                                                    ""
                                                                )
                                                            }
                                                            variant="outlined"
                                                        >
                                                            <Close />
                                                        </IconButton>
                                                    ) : (
                                                        <IconButton
                                                            size="small"
                                                            onClick={() =>
                                                                setSelectedAccountToEdit(
                                                                    account
                                                                )
                                                            }
                                                            variant="outlined"
                                                        >
                                                            <Edit />
                                                        </IconButton>
                                                    )}
                                                    {/* </Link> */}
                                                    {/* </Box> */}
                                                </TableCell>
                                            </ExpandableTableRow>
                                        );
                                    })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[100, 150, 200]}
                        component="div"
                        count={accountList.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                </Paper>
                <ConfirmationDialog
                    open={openConfirmationDialog}
                    onClose={handleClose}
                    onConfirm={deleteUser}
                    confirmationDialogTitle={"Delete User"}
                    confirmationDialogDescription={
                        "Are you sure you want to delete this user?"
                    }
                    confirmationBtnTitle={"Delete"}
                />
            </div>
        </AdminPageContainer>
    );
}
